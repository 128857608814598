import { EngineError, ERROR, ERROR_VALUE, ERROR_REF } from '../error';
import { flatten } from './utils';
import { Value, ErrorValue, LogicalValue, NumberValue, TextValue } from '.';
import numbro from 'numbro';

// TODO NOT_DEFINED in https://support.office.com/
export function ARGS2ARRAY(...args) {
  return Array.prototype.slice.call(args, 0);
}

// TODO NOT_DEFINED in https://support.office.com/
export const FLATTEN = flatten;

// TODO NOT_DEFINED in https://support.office.com/
export function JOIN(array, separator) {
  return array.join(separator);
}

// TODO NOT_DEFINED in https://support.office.com/
export function NUMBERS(...args) {
  return flatten(args).filter(el => typeof el === 'number');
}

// TODO NOT_DEFINED in https://support.office.com/
export function NUMERAL(number, format) {
  return numbro(number).format(format);
}

// TODO NOT_DEFINED in https://support.office.com/
export function REFERENCE(context, reference) {
  if (!arguments.length) {
    return new EngineError('REFERENCE', ERROR, arguments);
  }
  const path = reference.split('.');
  let result = context;
  for (let i = 0; i < path.length; ++i) {
    const step = path[i];
    if (step[step.length - 1] === ']') {
      const opening = step.indexOf('[');
      const index = step.substring(opening + 1, step.length - 1);
      result = result[step.substring(0, opening)][index];
    } else {
      result = result[step];
    }
  }
  return result;
}

/**
 * The UNIQUE function returns a list of unique values in a list or range.
 *
 * @param args The range or array from which to return unique values
 *
 * FIXME. Missing two optional params (by_col, occurse_once)
 */
export function UNIQUE(...args) {
  const result = [];
  for (let i = 0; i < args.length; ++i) {
    const element = args[i];
    let hasElement = false;
    // Check if we've already seen this element.
    for (let j = 0; j < result.length; ++j) {
      hasElement = result[j] === element;
      if (hasElement) {
        break;
      }
    }
    // If we did not find it, add it to the result.
    if (!hasElement) {
      result.push(element);
    }
  }
  return result;
}

/**
 * The Excel INDIRECT function returns a valid reference from a given text string.
 * Use INDIRECT when you need to convert a reference assembled as text into a proper reference.
 *
 * FIXME: Invalid formula function signature
 *
 * @param {Value} ref A reference supplied as text.
 * @param {LogicalValue} a1 A boolean to indicate A1 or R1C1-style reference. Default is TRUE = A1 style.
 */

export function INDIRECT(ref: Value, isA1?: LogicalValue) {
  return this.parser.parse(ref);
}

/**
 * The Excel INDEX function returns the value at a given position in a range or array.
 * You can use index to retrieve individual values or entire rows and columns.
 * INDEX is often used with the MATCH function, where MATCH locates and feeds a position to INDEX.
 *
 * FIXME: Invalid formula function signature
 *
 * @param array A range of cells, or an array constant.
 * @param rowNum The row position in the reference or array.
 * @param colNum The column position in the reference or array.
 * @param areaNum The range in reference that should be used.
 */

export function INDEX(array, rowNum, colNum?, areaNum?) {
  const rowIndex = rowNum - 1;
  const colIndex = (colNum || 1) - 1;

  // FIXME: add support for whole row/column returning of values
  if (rowIndex === -1 || colIndex === -1) {
    return new EngineError('INDEX', ERROR_VALUE, arguments);
  }

  if (array.length === 1) {
    const value =
      rowNum && colNum ? array[rowIndex][colIndex] : array[0][rowIndex];
    if (!value) {
      return new EngineError('INDEX', ERROR_REF, arguments);
    }
    return value;
  }

  // Return error when dealing with multi dimensional array without a specified column number
  if (!colNum) {
    return new EngineError('INDEX', ERROR_REF, arguments);
  }

  const value = array[rowIndex][colIndex];
  if (!value) {
    return new EngineError('INDEX', ERROR_REF, arguments);
  }
  return value;
}
