import * as React from 'react';
import '../../../src/pages/error/style.scss';
import { State } from '../../container';
import { __, i18n } from '@spreax/i18n';
import { App } from '../frame/app';

export class Error extends React.PureComponent<State> {
  render() {
    const { route } = this.props;
    const errorCode = route.match.params.code;
    const ERROR = i18n.ERRORS[errorCode] || i18n.ERRORS.DEFAULT;
    return (
      <App {...this.props} documentTitle={__(ERROR.TITLE)}>
        <div className="sx-portal__error">
          <div className="sx-portal__error__title">{__(ERROR.TITLE)}</div>
          <div className="sx-portal__error__message">{__(ERROR.MESSAGE)}</div>
        </div>
      </App>
    );
  }
}
