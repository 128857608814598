import { ReduceStore } from 'flux/utils';
import Dispatcher from '../dispatcher';
import { ActionTypes } from '../action-types';
import * as DataManager from '../data-managers';
import { uuid } from '@spreax/lib';
import { Folder, NewFolder, FileType } from '@spreax/model';

function onReceiveFolders(state: FolderState, action): FolderState {
  const { folders } = action;
  const selectedFolder = state.selectedFolder
    ? folders.find(folder => folder.id === state.selectedFolder.id)
    : null;
  return Object.assign({}, state, {
    folders,
    selectedFolder,
    hasReceivedFolders: true,
    isFetchingFolders: false
  });
}

function onLoadFolders(state: FolderState, action): FolderState {
  setTimeout(() => DataManager.folder.get(action.tenantId), 0);
  return Object.assign({}, state, {
    isFetchingFolders: true
  });
}

function onSelectFolder(state: FolderState, action): FolderState {
  const { folders } = state;
  const selectedFolder = folders.find(folder => folder.id === action.id);
  return Object.assign({}, state, {
    selectedFolder
  });
}

function onCreateFolder(state: FolderState, action): FolderState {
  const folder: NewFolder = {
    type: FileType.Folder,
    title: 'Untitled',
    parentId: action.parent
  };
  DataManager.folder.create(action.tenantId, folder);
  return state;
}

function onMoveFile(state: FolderState, action): FolderState {
  if (action.target === 'ROOT') {
    action.target = null;
  }
  DataManager.folder.update(action.tenantId, {
    id: action.id,
    parentId: action.target
  });
  return state;
}

function onUpdateFolder(state: FolderState, action): FolderState {
  const { selectedFolder, folders } = state;
  const folder = {
    ...selectedFolder,
    [action.field]: action.value
  };
  return Object.assign({}, state, {
    selectedFolder: folder,
    folders: [
      ...folders.filter(folder => folder.id !== selectedFolder.id),
      folder
    ]
  });
}

function onRemoveFolder(state: FolderState, action): FolderState {
  const { selectedFolder, folders } = state;
  DataManager.folder.remove(action.tenantId, action.id);
  return Object.assign({}, state, {
    // selectedFolder: selectedFolder.parentId,
    // folders: folders.filter(folder => folder.id !== action.id),
  });
}

function onSaveFolder(state: FolderState, action): FolderState {
  const { folders } = state;
  const folder = folders.find(folder => folder.id === action.id);
  DataManager.folder.update(action.tenantId, folder);
  return state;
}

function onLogout(state: FolderState, action): FolderState {
  return {
    selectedFolder: null,
    folders: [],
    isFetchingFolders: false,
    hasReceivedFolders: false
  };
}

class FolderStore extends ReduceStore<FolderState, any> {
  constructor() {
    super(Dispatcher);
  }

  getInitialState() {
    return {
      selectedFolder: null,
      folders: [],
      isFetchingFolders: false,
      hasReceivedFolders: false
    };
  }

  reduce(state: FolderState, action): FolderState {
    switch (action.type) {
      case ActionTypes.RECEIVE_FOLDERS:
        return onReceiveFolders(state, action);

      case ActionTypes.LOAD_FOLDERS:
        return onLoadFolders(state, action);

      case ActionTypes.SELECT_FOLDER:
        return onSelectFolder(state, action);

      case ActionTypes.CREATE_FOLDER:
        return onCreateFolder(state, action);

      case ActionTypes.MOVE_FILE:
        return onMoveFile(state, action);

      case ActionTypes.UPDATE_FOLDER:
        return onUpdateFolder(state, action);

      case ActionTypes.REMOVE_FOLDER:
        return onRemoveFolder(state, action);

      case ActionTypes.SAVE_FOLDER:
        return onSaveFolder(state, action);

      case ActionTypes.SELECT_TENANT:
      case ActionTypes.LOGOUT:
        return onLogout(state, action);

      default:
        return state;
    }
  }
}

export interface FolderState {
  folders: Folder[];
  selectedFolder: Folder;
  hasReceivedFolders: boolean;
  isFetchingFolders: boolean;
}

export const Store = new FolderStore();
