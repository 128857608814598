var utils = require("../utils");
var jStat = require("jStat").jStat;

import {
  EngineError,
  ERROR_VALUE,
} from "../../error";

/**
 * Returns the exponential distribution. Use EXPON.DIST to model the time
 * between events, such as how long an automated bank teller takes to deliver
 * cash.
 * 
 * @param x The value of the function.
 * @param lambda The parameter value.
 * @param cumulative A logical value that indicates which form of the
 * exponential function to provide. If cumulative is TRUE, EXPON.DIST returns
 * the cumulative distribution function; if FALSE, it returns the probability
 * density function.
 */
export function EXPON_DIST(x, lambda, cumulative) {
  x = utils.parseNumber(x);
  lambda = utils.parseNumber(lambda);
  if (utils.anyIsError(x, lambda)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  return cumulative
    ? jStat.exponential.cdf(x, lambda)
    : jStat.exponential.pdf(x, lambda);
}