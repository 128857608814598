import { Model, TemplateDefinition, Property, ICell, EncodedSession, DependencyGraph, FormulaParser, Cell, CellMap } from '.';
import { EventEmitter } from 'events';
import { getAddress, getCellReference, CellReference, sanitizeFormula } from '@spreax/xlsx-parser';
import { log, uuid } from '@spreax/lib';
import { EngineError, ERROR_TARGET_NOT_FOUND, ERROR_VALIDATION, ERROR_NOT_AVAILABLE } from './error';

declare global {
  interface Window { engine: any; }
}

export enum EngineState {
  Init,
  Fetching,
  Loaded,
  InitCalculation,
  Calculating,
  Calculated,
}

export class Engine extends EventEmitter {

  id: string;
  private formulaParser: FormulaParser;
  private _state: EngineState;
  private readonly cellMap: CellMap;
  private dependencyGraph: DependencyGraph;
  private formulaCallStack: string[];
  private readonly definition: TemplateDefinition;
  private readonly model: Model;
  private elements: Property[];
  private readonly virtualCells: ICell[];
  public errors: EngineError[];

  constructor(
    definition?,
    model?,
  ) {
    super();
    this.id = uuid();
    this.definition = definition;
    this.model = model;
    this._state = EngineState.Init;
    this.errors = [];

    if (this.definition) {
      this.elements = this.definition.elements.map(this.bootstrapElement.bind(this));
      this.virtualCells = this.elements.reduce(
        (virtualCells, element) => {
          return [
            ...virtualCells,
            ...Object.values(element['$']),
          ];
        },
        [],
      );
    } else {
      this.elements = [];
      this.virtualCells = [];
    }

    this.formulaParser = new FormulaParser();
    this.formulaParser.on('callVariable', this.handleCallVariable.bind(this));
    this.formulaParser.on('callCellValue', this.handleCallCellValue.bind(this));

    this.cellMap = new CellMap([
      ...model.cells,
      ...this.virtualCells,
    ]);

    this.elements = this.elements.map(this.linkVirtualCells.bind(this));

    this.dependencyGraph = new DependencyGraph(this.model, this.definition, this.cellMap);
    this.dependencyGraph.build();

    this.state = EngineState.Loaded;

    if (process.env.NODE_ENV !== 'production') {
      window.engine = this;
    }

  }

  /**
   * Use setter for setting `State` to emit `State` change
   *
   * @param {EngineState} state
   */

  set state(state: EngineState) {
    this._state = state;
    this.emit('stateChange', state);
  }

  /**
   * Get `State`
   *
   * @returns {EngineState}
   */

  get state(): EngineState {
    return this._state;
  }

  /**
   * Validate calculated cells against original cell value as calculated by Excel
   *
   * @returns {void}
   * @private
   */

  private validate(): void {
    const decimalPrecision = 15;
    this.cellMap.cells
      .filter(cell => cell.sheet !== 'Spreax')
      .map((cell) => {
        if (typeof cell.value === 'number') {
          const actual = cell.value && cell.value.toFixed ? +cell.value.toFixed(decimalPrecision) : cell.value;
          const expected = cell.originalValue && cell.originalValue.toFixed ? +cell.originalValue.toFixed(decimalPrecision) : cell.originalValue;
          if (Math.abs(actual - expected) > 0.00000001) {
            this.errors.push(new EngineError('', ERROR_VALIDATION, { cell, expected, actual }));
          }
        }
        if (typeof cell.value === 'string') {
          // @ts-ignore
          const actual = cell.value && cell.value.toFixed ? cell.value.toFixed(decimalPrecision) : cell.value;
          const expected = cell.originalValue && cell.originalValue.toFixed ? cell.originalValue.toFixed(decimalPrecision) : cell.originalValue;
          if (actual !== expected) {
            this.errors.push(new EngineError('', ERROR_VALIDATION, { cell, expected, actual }));
          }
        }
      });
  }

  /**
   * Create virtual `Cells` from element properties
   *
   * @param {Property} element
   * @param {number} index
   * @returns {Property}
   * @private
   */

  private bootstrapElement(element: Property, index: number): Property {
    const $ = {};
    const virtualCells: ICell[] = [];
    const options = ['target', 'value', 'prefix', 'postfix'];
    for (const key of options) {
      if (element[key]) {
        const isFormula = typeof element[key] === 'string' && element[key].substr(0, 1) === '=';
        const expression = isFormula ? sanitizeFormula(this.model.sanitizedWorksheetNames, element[key].substr(1, element[key].length)) : null;
        const ref = getAddress({
          row: index,
          column: virtualCells.length,
          isColumnAbsolute: false,
          isRowAbsolute: false,
        });
        $[key] = {
          ref,
          sheet: 'Spreax',
          sheetRef: 'Spreax',
          row: index,
          column: virtualCells.length,
          formula: isFormula ? { expression } : null,
          value: !isFormula ? element[key] : null,
          isVirtual: true,
          isProxy: isFormula,
          elementId: element.id,
          field: key,
        };
        virtualCells.push($[key]);
      }
    }
    return {
      $,
      ...element,
    };
  }

  /**
   * Link virtual `Cells` to `Element` root property
   *
   * @param {Property} element
   * @returns {Property}
   * @private
   */

  private linkVirtualCells(element: Property): Property {
    const $ = {};
    for (const key in element['$']) {
      $[key] = this.cellMap.getCellByRef(element['$'][key].sheetRef, element['$'][key].ref);
    }
    return {
      ...element,
      $,
    };
  }

  /**
   * Set fields on `Element` based on `(Virtual)Cells` in root
   *
   * @returns {void}
   * @private
   */

  private projectFields(): void {

    function project<T>(object: T): T {
      for (const key in object['$']) {
        object[key] = object['$'][key].value;
      }
      return object;
    }

    function handleProxy(element) {
      const target = element['$'].target;
      if (target) {
        element.value = target.value;
        if (target.dataValidation && target.dataValidation.type === 'list') {
          element.options = [];
          if (Array.isArray(target.dataValidation.value)) {
            target.dataValidation.value.forEach((option) => {
              option.map(option => element.options.push(option));
            });
          } else {
            element.options.push(target.dataValidation.value);
          }
          element.options = element.options
            .map((option) => {
              if (option instanceof EngineError) {
                return null;
              }
              return option;
            })
            .filter(option => !!option);
        }
        element.style = target.style;
      }
    }

    this.elements
      .map(project)
      .map(handleProxy.bind(this));

  }

  /**
   * Get related element / field for cell
   *
   * @param {ICell} cell
   * @private
   */

  private getCellElement(cell: Cell) {
    const virtualCell = this.virtualCells.find(virtualCell => virtualCell.ref === cell.ref);
    return {
      id: virtualCell.elementId,
      field: virtualCell.field,
    };
  }

  /**
   * Get dirty cells with no precedents waiting
   *
   * @returns {Cell[]}
   * @private
   */

  private getCellQueue(): Cell[] {
    return this.cellMap.cells
      .filter((cell) => {
        return cell.precedentsWaiting === 0 && cell.isDirty;
      });
  }

  /**
   * Calculate value for `Cell`
   *
   * @param {Cell} cell
   */

  private calculateCell(cell: Cell): void {
    if (cell) {
      if (cell.formula && !cell.isCustom) {
        this.formulaCallStack = [];
        this.formulaCallStack.push(cell.sheetRef);
        const { result, error } = this.formulaParser.parse(cell);
        if (error) {
          this.errors.push(new EngineError(error.ns, error.name, { cell, args: error.context }));
        }
        cell.value = result;
      }
      cell.isDirty = false;
      if (cell.isProxy && !cell.proxyTarget) {
        this.errors.push(new EngineError('', ERROR_TARGET_NOT_FOUND, { cell, element: this.getCellElement(cell) }));
      }
    }
  }

  /**
   * Start calculating cells based on generated dependence graph. Starst from the bottom and cycles through every layer
   *
   * @private
   */

  private calculateCells(): Promise<void> {

    this.errors = [];
    let queue = this.getCellQueue();
    let step = 0;
    let index = 0;

    return new Promise((resolve, reject) => {
      const processQueue = () => {
        if (!queue[index]) {
          return resolve();
        }
        while (queue[index]) {
          const cell = queue[index];
          index++;
          this.calculateCell(cell);
          if (!queue[index]) {
            step++;
            index = 0;
            queue = this.getCellQueue();
            log.verbose('Calculation cycle', step, 'batch size:', queue.length, queue, 'cells, waiting:', this.cellMap.cells.reduce((a, b) => a + b.precedentsWaiting, 0));
            if (queue[index]) {
              setTimeout(processQueue, 0);
              break;
            } else {
              console.log('Done calculating with,', this.errors.length, 'errors.', 'waiting:', this.cellMap.cells.filter(cell => cell.precedentsWaiting !== 0));
              resolve();
              break;
            }
          }
        }
      };

      processQueue();

    });

  }

  /**
   * Calculate data validations for `Cells`
   *
   * @returns {void}
   */

  private calculateDataValidations(): void {
    this.cellMap.cells
      .filter(cell => cell.sheet !== 'Spreax')
      .filter(cell => cell.dataValidation)
      .map((cell) => {
        this.formulaCallStack = [];
        this.formulaCallStack.push(cell.sheetRef);
        const { result, error } = this.formulaParser.parse(cell.dataValidation);
        if (!error) {
          cell.dataValidation.value = result;
        } else {
          log.error('Error while parsing `dataValidation`:', error);
        }
      });
  }

  /**
   * This method is used to map calculated values to `Parameters`, `Properties` and `Tables`
   *
   * @param object
   * @returns {Property}
   * @private
   */

  private assignValue<T>(object: any): T {
    const cell = this.cellMap.getCellByRef(object.sheetRef, object.ref);
    if (!cell) {
      log.error('Trying to assign value to cell:', object.sheet + '!' + object.ref, 'but cell is not found');
      return object;
    }
    object.value = cell.value;
    return object;
  }

  /**
   * Apply calculation to for example parameters
   *
   * @private
   */

  private reassignValues(): void {
    this.elements = this.elements.map((element) => {
      for (const key in element['$']) {
        element['$'][key] = this.assignValue<Cell>(element['$'][key]);
      }
      return element;
    });
  }

  /**
   * Start model calculation
   *
   * @param {EncodedSession} session
   * @returns {Promise<void>}
   */

  async calculate(session?: EncodedSession): Promise<void> {
    if (this.state <= 1) {
      throw new Error('Requesting calculation, but we do not have a model yet');
    }
    this.state = this.state === EngineState.Init || this.state === EngineState.Loaded ? EngineState.InitCalculation : EngineState.Calculating;
    if (session) {
      this.loadSession(session);
    }
    await this.calculateCells();
    this.calculateDataValidations();
    this.reassignValues();
    this.projectFields();
    this.validate();
    this.state = EngineState.Calculated;
  }

  /**
   * Retrieve singular cell
   *
   * @param {CellReference} cellReference
   * @param done
   * @returns {void}
   * @private
   */

  private handleCallCellValue(cellReference: CellReference, done): void {
    const value = this.cell(cellReference);
    done(value);
  }

  /**
   * Retrieve cell based on defined name
   *
   * @param name
   * @param done
   * @returns {void | boolean}
   * @private
   */

  private handleCallVariable(name, done): void | boolean {
    if (name === 'TRUE') {
      return true;
    }
    if (name === 'FALSE') {
      return false;
    }
    const definedName = this.model.definedNames.find(definedName => definedName.name === name);
    log.verbose('--> Retrieving variable', 'name:', definedName.name, 'formula:', definedName.formula);
    if (definedName && definedName.formula) {
      const formula = this.formulaParser.parse(definedName as any);
      if (formula.error) {
        // FIXME: Handle error
      }
      if (!formula.error) {
        done(formula.result);
      }
    } else {
      log.error('Defined name without `formula`:', name);
    }
  }

  /**
   * Get cell value
   *
   * @param {CellReference} cellReference
   * @returns {any}
   * @private
   */

  private cell(cellReference: CellReference): any {
    const sheet = cellReference.sheet || this.formulaCallStack[this.formulaCallStack.length - 1];
    const cell = this.cellMap.getCellByIndex(sheet, cellReference.row.index, cellReference.column.index);

    if (!cell) {
      // log.error('Cell not found:', sheet, rowIndex, columnIndex);
      return new EngineError('', ERROR_NOT_AVAILABLE, {});
    }

    if (cell.proxySource && cell.proxySource.isCustom) {
      const { sheetRef, row, column } = cell.proxySource;
      return this.cell(getCellReference(sheetRef, row, column));
    }

    // if (typeof cell['$'] !== 'undefined') {
    //   return cell['$'];
    // }

    const value = cell.value;
    const float = parseFloat(value);

    if (isNaN(float) || /[a-z]/i.test(value)) {
      return value;
    }

    return float;
  }

  /**
   * Update element value by setting value of primary virtual cell
   *
   * @param id
   * @param value
   */

  public updateElement(id, value): void {
    const element = this.elements.find(element => element.id === id);
    const primaryElementCell = element['$'].target;
    if (primaryElementCell) {
      const cell = this.cellMap.getCellByRef(primaryElementCell.sheet, primaryElementCell.ref);
      if (cell) {
        log.debug('Updating cell:', cell, 'with value:', value);
        cell.value = value;
        cell.isCustom = value !== null;
        cell.dirty();
      }
    } else {
      log.error('Cannot find primary `cell` for `element`:', element);
    }
  }

  /**
   * Restore all values to their original value
   */

  async reset(): Promise<void> {
    this.cellMap.reset();
    await this.calculate();
  }

  /**
   * Encode current user input into session string
   *
   * @returns {EncodedSession}
   */

  public getSession(serialize: boolean = true): EncodedSession | any {
    const session = {
      version: '1',
      virtualCells: {},
    };

    this.cellMap.cells
      .filter(cell => cell.isVirtual && cell.isCustom)
      .forEach((virtualCell) => {
        session.virtualCells[virtualCell.ref] = {
          value: virtualCell.value,
        };
      });

    if (serialize) {
      return btoa(JSON.stringify(session));
    }

    return session;
  }

  /**
   * Set input from session string
   *
   * @param {EncodedSession} encodedSession
   */

  public loadSession(encodedSession: EncodedSession): void {
    if (this.state === EngineState.Init || this.state === EngineState.Fetching) {
      throw new Error('Trying to load session, but model not loaded yet.');
    }
    const session = JSON.parse(atob(encodedSession));
    this.cellMap.cells
      .filter(cell => cell.isVirtual)
      .forEach((virtualCell) => {
        if (session.virtualCells[virtualCell.ref]) {
          virtualCell.value = session.virtualCells[virtualCell.ref].value;
          virtualCell.isCustom = true;
        }
      });
  }

}
