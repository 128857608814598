import * as React from 'react';
import '../../../src/components/upload-attachment/style.scss';
import { State } from '../../container';
import { __, i18n } from '@spreax/i18n';
import Dropzone from 'react-dropzone';

type File = {
  name: string;
  dataURL: string | ArrayBuffer;
};

interface IUploadAttachment {
  onUpload(file: File): void;
}

export class UploadAttachment extends React.PureComponent<IUploadAttachment> {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(acceptedFiles) {
    const { onUpload } = this.props;
    acceptedFiles.forEach(file => {
      const reader = new FileReader();
      reader.onload = () => {
        onUpload({
          name: file.name,
          dataURL: reader.result
        });
      };
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.readAsDataURL(file);
    });
  }

  render() {
    return (
      <Dropzone
        onDrop={this.onDrop}
        style={{}}
        className="sx-upload-attachment"
        acceptClassName="sx-upload-attachment--accept"
        rejectClassName="sx-upload-attachment--reject"
      >
        <div className="sx-upload-attachment__contents">
          <i className="material-icons sx-upload-attachment__contents__icon">
            cloud_upload
          </i>
          {__(i18n.UPLOAD_ATTACHMENT.DRAG_TO_UPLOAD)}
        </div>
      </Dropzone>
    );
  }
}
