import { onFullfilled, onRejection } from './utils';
import { __, i18n } from '@spreax/i18n';
import { ActionTypes } from '../action-types';
import { Project, NewProject, TenantGUID } from '@spreax/model';
import * as DataManager from '.';
import * as API from '@spreax/api-client';

export namespace project {
  /**
   * Get all `Projects` for current user
   */

  export function get(tenantId: TenantGUID): Promise<void> {
    return API.project.get(tenantId).then(
      onFullfilled(null, null, (data: Project[]) => {
        return {
          type: ActionTypes.RECEIVE_PROJECTS,
          projects: data
        };
      }),
      onRejection()
    );
  }

  export function create(
    tenantId: TenantGUID,
    project: NewProject
  ): Promise<void> {
    return API.project.create(tenantId, project).then(
      onFullfilled(
        i18n.FEEDBACK.PROJECT_CREATED,
        [() => get(tenantId), () => DataManager.folder.get(tenantId)],
        ({ id }) => {
          return {
            type: ActionTypes.CREATED_PROJECT,
            id
          };
        },
        `/project/${project.guid}`
      ),
      onRejection()
    );
  }

  export function save(tenantId: TenantGUID, project: Project): Promise<void> {
    const data = {
      id: project.id,
      title: project.title,
      session: project.session
    };
    return API.project
      .update(data)
      .then(
        onFullfilled(i18n.FEEDBACK.CHANGES_SAVED, [
          () => get(tenantId),
          () => DataManager.folder.get(tenantId)
        ]),
        onRejection()
      );
  }

  export function deactivate(
    tenantId: TenantGUID,
    project: Project
  ): Promise<void> {
    return API.project
      .remove({ id: project.id })
      .then(
        onFullfilled(
          i18n.FEEDBACK.PROJECT_DEACTIVATED,
          [() => get(tenantId), () => DataManager.folder.get(tenantId)],
          null,
          '/'
        ),
        onRejection()
      );
  }
}
