// tslint:disable object-shorthand-properties-first

import Dispatcher from '../dispatcher';
import { ActionTypes } from '../action-types';
import { AttachmentGUID } from '@spreax/model';

export namespace attachment {
  export interface IAttachment {
    upload(id: string, name: string, binary: any): void;
    download(id: AttachmentGUID): void;
    remove(id: AttachmentGUID): void;
  }

  export function upload(parent: string, name: string, binary: any): void {
    Dispatcher.dispatch({
      type: ActionTypes.UPLOAD_ATTACHMENT,
      parent,
      name,
      binary
    });
  }

  export function download(id: AttachmentGUID): void {
    Dispatcher.dispatch({
      type: ActionTypes.DOWNLOAD_ATTACHMENT,
      id
    });
  }

  export function remove(id: AttachmentGUID): void {
    Dispatcher.dispatch({
      type: ActionTypes.REMOVE_ATTACHMENT,
      id
    });
  }
}
