import * as React from 'react';
import * as classNames from 'classnames';
import { InputFactory } from '../input-factory';

function createGridFromElements(elements, selectedParent) {
  return elements
    .filter(
      element => element.type === 'strip' && element.parent === selectedParent
    )
    .sort((a, b) => a.row - b.row)
    .map(element => {
      return [
        ...elements
          .filter(child => child.parent === element.id)
          .sort((a, b) => a.column - b.column)
          .map(column => {
            return {
              element: elements.find(child => child.parent === column.id),
              width: column.width
            };
          })
      ];
    });
}

export function ElementGrid(props) {
  const { elements, actions, selectedParent } = props;
  const grid = createGridFromElements(elements, selectedParent);
  return (
    <div className="sx-grid">
      {grid.map((row, index) => {
        return (
          <div key={index} className="sx-grid__row">
            {row.map((column, index) => {
              return (
                <div
                  key={index}
                  className={classNames(
                    'sx-grid__column',
                    'sx-grid__column--center',
                    `sx-grid__column--width-${column.width}-24`
                  )}
                >
                  {column.element && (
                    <InputFactory actions={actions} property={column.element} />
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
