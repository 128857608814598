import * as React from 'react';
import '../../../src/components/list/style.scss';
import * as classNames from 'classnames';

export function Icon(props) {
  const { icon } = props;
  if (!icon) {
    return null;
  }
  if (typeof icon === 'string') {
    return <i className="sx-list__item__icon material-icons">{icon}</i>;
  }
  return <div className="sx-list__item__icon">{icon}</div>;
}

export function List(props) {
  const {
    items,
    title,
    noItemsMessage,
    onClick,
    theme,
    size,
    showCount
  } = props;
  return (
    <div
      className={classNames('sx-list', {
        ['sx-list--light']: theme === 'light',
        ['sx-list--dark']: theme === 'dark',
        ['sx-list--small']: size === 'small'
      })}
    >
      <div className="sx-list__subtitle">
        {title}{' '}
        {showCount && (
          <span className="sx-list__subtitle__label">{items.length}</span>
        )}
      </div>
      {items.length === 0 && (
        <div className="sx-list__message">{noItemsMessage}</div>
      )}
      {items.map((item, index) => {
        return (
          <div
            key={index}
            className={classNames('sx-list__item', {
              ['sx-list__item--selected']: item.isSelected,
              ['sx-list__item--grayed-out']: item.isGrayedOut
            })}
            onClick={(onClick && onClick(item)) || item.onClick}
          >
            <div className="sx-list__item__meta">
              <div className="sx-list__item__primary">
                <Icon icon={item.icon} /> {item.primary}
              </div>
              {item.secondary && (
                <div className="sx-list__item__secondary">{item.secondary}</div>
              )}
            </div>
            {item.actions && (
              <div className="sx-list__item__actions">{item.actions}</div>
            )}
          </div>
        );
      })}
    </div>
  );
}
