var utils = require("../utils");
var mathTrig = require("../math-and-trigonometry");

import {
  EngineError,
  ERROR_VALUE
} from "../../error";

/**
 * Returns the harmonic mean of a data set. The harmonic mean is the reciprocal
 * of the arithmetic mean of reciprocals.
 * 
 * @param arg[] Number1, number2, ... Number1 is required, subsequent numbers
 * are optional. 1 to 255 arguments for which you want to calculate the mean.
 * You can also use a single array or a reference to an array instead of
 * arguments separated by commas.
 */
export function HARMEAN(...args) {
  var range = utils.parseNumberArray(utils.flatten(args));
  if (range instanceof Error) {
    return range;
  }
  var n = range.length;
  var den = 0;
  for (var i = 0; i < n; i++) {
    den += 1 / range[i];
  }
  return n / den;
}

/**
 * Returns the hypergeometric distribution. HYPGEOM.DIST returns the probability
 * of a given number of sample successes, given the sample size, population
 * successes, and population size.
 * 
 * @param x The number of successes in the sample.
 * @param n The size of the sample.
 * @param M The number of successes in the population.
 * @param N The population size.
 * @param cumulative A logical value that determines the form of the function.
 * If cumulative is TRUE, then HYPGEOM.DIST returns the cumulative distribution
 * function; if FALSE, it returns the probability mass function.
 */
export function HYPGEOM_DIST(x, n, M, N, cumulative) {
  x = utils.parseNumber(x);
  n = utils.parseNumber(n);
  M = utils.parseNumber(M);
  N = utils.parseNumber(N);
  if (utils.anyIsError(x, n, M, N)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  function pdf(x, n, M, N) {
    return (
      (mathTrig.COMBIN(M, x) * mathTrig.COMBIN(N - M, n - x)) /
      mathTrig.COMBIN(N, n)
    );
  }

  function cdf(x, n, M, N) {
    var result = 0;
    for (var i = 0; i <= x; i++) {
      result += pdf(i, n, M, N);
    }
    return result;
  }

  return cumulative ? cdf(x, n, M, N) : pdf(x, n, M, N);
}