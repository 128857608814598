import * as React from 'react';
import { format } from '@spreax/engine';
import { InputField as BaseInputField } from '@spreax/components';

export function InputField (props) {
  const { property, onFocus, onBlur, onChange, edit, editValue, onKeyPress } = props;
  const formattedValue = format.format(property, property.value, false);
  const value = property.readOnly ? formattedValue : edit !== true && !property.readOnly ? formattedValue : editValue;
  return (
    <BaseInputField
      value={value}
      readOnly={property.readOnly}
      onFocus={onFocus && onFocus(property)}
      onBlur={onBlur && onBlur(property)}
      onChange={onChange && onChange(property)}
      onKeyPress={onKeyPress}
      postfix={property.postfix}
      prefix={property.prefix}
      isFocused={edit}
      dataType={typeof property.value}
    />
  );
}
