export const ERROR = 'ERROR';

/**
 * Intended to indicate when any number (including zero) or any error code is divided by zero.
 */

export const ERROR_DIV_ZERO = 'DIV/0';

/**
 * Intended to indicate when what looks like a name is used, but no such name has been defined. [Example: XYZ/3, where
 * XYZ is not a defined name. Total is & A10, where neither Total nor is is a defined name. Presumably,
 * "Total is " & A10 was intended. SUM(A1C10), where the range A1:C10 was intended. end example]
 */

export const ERROR_NAME = 'NAME';

/**
 * Intended to indicate when a designated value is not available. [Example: Some functions, such as SUMX2MY2, perform a
 * series of operations on corresponding elements in two arrays. If those arrays do not have the same number of elements,
 * then for some elements in the longer array, there are no corresponding elements in the shorter one; that is, one or
 * more values in the shorter array are not available. end example] This error value can be produced by calling the
 * function NA
 */

export const ERROR_NOT_AVAILABLE = 'N/A';

/**
 * Intended to indicate when two areas are required to intersect, but do not. [Example: In the case of SUM(B1 C1), the
 * space between B1 and C1 is treated as the binary intersection operator, when a comma was intended. end example]
 */

export const ERROR_NULL = 'NULL';

/**
 * Intended to indicate when an argument to a function has a compatible type, but has a value that is outside the domain
 * over which that function is defined. (This is known as a domain error.) [Example: Certain calls to ASIN, ATANH, FACT,
 * and SQRT might result in domain errors. end example] Intended to indicate that the result of a function cannot be
 * represented in a value of the specified type, typically due to extreme magnitude. (This is known as a range error.)
 * [Example: FACT(1000) might result in a range error. end example]
 */

export const ERROR_NUM = 'NUM';

/**
 * Intended to indicate when a cell reference cannot be evaluated. [Example: If a formula contains a reference to a cell,
 * and then the row or column containing that cell is deleted, a #REF! error results. If a worksheet does not support
 * 20,001 columns, OFFSET(A1,0,20000) will result in a #REF! error. end example]
 */

export const ERROR_REF = 'REF';

/**
 * Intended to indicate when an incompatible type argument is passed to a function, or an incompatible type operand is
 * used with an operator. [Example: In the case of a function argument, text was expected, but a number was provided end
 * example]
 */

export const ERROR_VALUE = 'VALUE';

/**
 * Intended to indicate when a cell reference cannot be evaluated because the value for the cell has not been retrieved
 * or calculated.
 *
 * This error constant differs from #N/A in that #GETTING_DATA is used when there is an expectation that the value for
 * the cell will eventually be available, whereas #N/A is used when there is no such expectation.
 */

export const ERROR_GETTING_DATA = 'GETTING_DATA';

/**
 * Custom engine errors outside the ECMA-376 spec
 */

export const ERROR_TARGET_NOT_FOUND = 'TARGET_NOT_FOUND';
export const ERROR_VALIDATION = 'VALIDATION';

export class EngineError {

  ns: string;
  name: string;
  context: any[];
  type: string;

  constructor (ns, name, context?, type?) {
    this.ns = ns;
    this.name = name;
    this.context = context;
    this.type = type;
  }

}

export function getErrorMessage (error, useHighlighting: boolean = true) {
  const { name, context } = error;
  switch (name) {
    case ERROR:
    case ERROR_DIV_ZERO:
    case ERROR_NAME:
    case ERROR_NOT_AVAILABLE:
    case ERROR_NULL:
    case ERROR_NUM:
    case ERROR_REF:
    case ERROR_VALUE:
    case ERROR_GETTING_DATA:
      return `Error while evaluating formula: ${highlight(context.cell.formula.expression, useHighlighting)}</span>`;
    case ERROR_TARGET_NOT_FOUND:
      return `Cell for \`${highlight(context.element.field, useHighlighting)}\` not found`;
    case ERROR_VALIDATION:
      return `Expected \`${highlight(context.actual, useHighlighting)}\` to be \`${highlight(context.expected, useHighlighting)}\` when evaluating formula: \`${highlight(context.cell.formula.expression, useHighlighting)}\``;
    default:
      return '';
  }
}

function highlight (value, useHighlighting = true) {
  if (!useHighlighting) {
    return value;
  }
  return `<span class="ms-fontColor-themePrimary">${value}</span>`
}
