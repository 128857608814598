export namespace Parser {
  export type Workbook = {
    sharedStrings: SharedString[];
    definedNames: DefinedName[];
    worksheets: WorksheetModel[];
    styles: Style[];
  };

  export type Worksheet = {
    worksheet: WorksheetModel;
    headers: WorksheetHeader;
    rows: WorksheetRow[];
  };

  export type WorksheetModel = {
    id: string;
    name: string;
    ref: string;
    data: WorksheetRow[];
    dataValidations: DataValidation[];
    comments: Comment[];
    filePath: string;
    relationships: Relationship[];
    originalName: string;
  };

  export interface WorksheetHeader {
    type: string;
    url?: string;
  }

  export type WorksheetRow = WorksheetCell[];

  export type WorksheetCell = {
    ref: string;
    sheet: string;
    sheetRef: string;
    value: string;
    formula: string;
    format: string;
    rowIndex: number;
    columnIndex: number;
    comment: string;
    dataValidation: DataValidation;
  };

  export type Comment = {
    ref: string;
    text: string;
  };

  export type SharedString = {
    id: number;
    text: string;
  };

  export type Format = {
    decimals: number;
    unit: string;
  };

  export type Style = {
    id: number;
    name: string;
    formatId: number;
    format: string;
  };

  export type Relationship = {
    id: string;
    type: string;
    target: string;
  };

  export type DefinedName = {
    name: string;
    formula: string;
  };

  export type DataValidation = {
    ref: string;
    type: string;
    formula?: string;
  };
}
