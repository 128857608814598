import * as React from 'react';
import injectSheet from 'react-jss';
import * as classNames from 'classnames';

const styles = theme => ({
  container: {
    float: 'left',
    width: '100%',
    ...theme.tabs && theme.tabs.container,
  },
  tabs: {
    flex: 1,
    display: 'flex',
  },
  tab: {
    float: 'left',
    display: 'flex',
    cursor: 'pointer',
    boxSizing: 'border-box',
    '& i': {
      fontSize: '16px',
      marginRight: '5px',
    },
    ...theme.tabs && theme.tabs.tab,
  },
  tabSelected: {
    extend: 'pivot',
    ...theme.tabs && theme.tabs.selectedTab,
  },
  tabPassed: {
    ...theme.tabs && theme.tabs.passedTab,
  },
  tabLink: {
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    ...theme.tabs && theme.tabs.arrow,
  },
  panels: {
    float: 'left',
    width: '100%',
    ...theme.tabs && theme.tabs.panels,
  },
  panel: {
    float: 'left',
    width: '100%',
    ...theme.tabs && theme.tabs.panel,
  },
});

function TabsComponent(props) {
  const { classes, selectedTab, children, setSelectedTab, showNavigation } = props;
  const onClick = (id) => {
    return () => {
      setSelectedTab(id);
    };
  };
  if (children.length <= 1) {
    return null;
  }
  return (
    <div className={classes.container}>
      <div className={classes.tabs}>
        {children.map((tab, index) => {
          const className = classNames(classes.tab, {
            [classes.tabSelected]: selectedTab === tab.key,
            // [classes.tabPassed]: selectedTab > index,
          });
          return (
            <div
              key={index}
              className={className}
              onClick={onClick(tab.key)}
            >
              {tab}
            </div>
          );
        })}
      </div>
      {showNavigation && <div
        className={classes.arrow}
      >
        <i className="material-icons">navigate_before</i>
      </div>}
      {showNavigation && <div
        className={classes.arrow}
      >
        <i className="material-icons">navigate_next</i>
      </div>}
    </div>
  );
}

export const Tabs = injectSheet(styles)(TabsComponent);

function TabComponent(props) {
  const { classes, children } = props;
  return (
    <a
      className={classes.tabLink}
    >
      {children}
    </a>
  );
}

export const Tab = injectSheet(styles)(TabComponent);

export function PanelComponent(props) {
  const { classes } = props;
  return (
    <div className={classes.panel}>
      {props.children}
    </div>
  );
}

export const Panel = injectSheet(styles)(PanelComponent);

export function PanelsComponent(props) {
  const { classes, children, selectedTab } = props;
  return (
    <div className={classes.panels}>
      {children.map((child, index) => {
        if (selectedTab !== child.key) {
          return null;
        }
        return child;
      })}
    </div>
  );
}

export const Panels = injectSheet(styles)(PanelsComponent);
