var utils = require("../utils");
var jStat = require("jStat").jStat;

import {
  EngineError,
  ERROR_NUM,
  ERROR_VALUE,
  ERROR_NOT_AVAILABLE
} from "../../error";

import { PEARSON } from "./statistical-p";

/**
 * Returns the rank of a number in a list of numbers: its size relative to other
 * values in the list; if more than one value has the same rank, the average
 * rank is returned.
 *
 * @param number The number whose rank you want to find.
 * @param range An array of, or a reference to, a list of numbers. Nonnumeric
 * values in Ref are ignored.
 * @param order Optional. A number specifying how to rank number.
 */
export function RANK_AVG(number, range, order) {
  number = utils.parseNumber(number);
  range = utils.parseNumberArray(utils.flatten(range));
  if (utils.anyIsError(number, range)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  range = utils.flatten(range);
  order = order || false;
  var sort = order
    ? function(a, b) {
        return a - b;
      }
    : function(a, b) {
        return b - a;
      };
  range = range.sort(sort);

  var length = range.length;
  var count = 0;
  for (var i = 0; i < length; i++) {
    if (range[i] === number) {
      count++;
    }
  }

  return count > 1
    ? (2 * range.indexOf(number) + count + 1) / 2
    : range.indexOf(number) + 1;
}

/**
 * Returns the rank of a number in a list of numbers. Its size is relative to
 * other values in the list; if more than one value has the same rank, the top
 * rank of that set of values is returned.
 *
 * @param number The number whose rank you want to find.
 * @param range An array of, or a reference to, a list of numbers. Non-numeric
 * values in Ref are ignored.
 * @param order A number specifying how to rank number.
 */
export function RANK_EQ(number, range, order) {
  number = utils.parseNumber(number);
  range = utils.parseNumberArray(utils.flatten(range));
  if (utils.anyIsError(number, range)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  order = order || false;
  var sort = order
    ? function(a, b) {
        return a - b;
      }
    : function(a, b) {
        return b - a;
      };
  range = range.sort(sort);
  return range.indexOf(number) + 1;
}

/**
 * Returns the row number of a reference.
 *
 * @param matrix
 * @param index The cell or range of cells for which you want the row number.
 */
export function ROW(matrix, index) {
  if (arguments.length !== 2) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NOT_AVAILABLE, arguments);
  }

  if (index < 0) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NUM, arguments);
  }

  if (!(matrix instanceof Array) || typeof index !== "number") {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  if (matrix.length === 0) {
    return undefined;
  }

  return jStat.row(matrix, index);
}

/**
 * Returns the number of rows in a reference or array.
 *
 * @param matrix An array, an array formula, or a reference to a range of cells
 * for which you want the number of rows.
 */
export function ROWS(matrix) {
  if (arguments.length !== 1) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NOT_AVAILABLE, arguments);
  }

  if (!(matrix instanceof Array)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  if (matrix.length === 0) {
    return 0;
  }

  return jStat.rows(matrix);
}

/**
 * Returns the square of the Pearson product moment correlation coefficient
 * through data points in known_y's and known_x's.
 *
 * @param data_x An array or range of data points.
 * @param data_y An array or range of data points.
 */
export function RSQ(data_x, data_y) {
  // no need to flatten here, PEARSON will take care of that
  data_x = utils.parseNumberArray(utils.flatten(data_x));
  data_y = utils.parseNumberArray(utils.flatten(data_y));
  if (utils.anyIsError(data_x, data_y)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  // @ts-ignore
  return Math.pow(PEARSON(data_x, data_y), 2);
}
