var utils = require("../utils");
var jStat = require("jStat").jStat;
var mathTrig = require("../math-and-trigonometry");

import {
  EngineError,
  ERROR_NUM,
  ERROR_VALUE,
  ERROR_NOT_AVAILABLE
} from "../../error";

import { LINEST } from "../statistical";

/**
 * switch or rotate cells.
 *
 * @param matrix
 */
export function TRANSPOSE(matrix) {
  if (!matrix) {
    return new EngineError("TRANSPOSE", ERROR_NOT_AVAILABLE, arguments);
  }
  return jStat.transpose(matrix);
}

/**
 * Returns the two-tailed Student's t-distribution.
 *
 * @param x The numeric value at which to evaluate the distribution.
 * @param df An integer indicating the number of degrees of freedom.
 */
export function T_DIST_2T(x, df) {
  if (arguments.length !== 2) {
    return new EngineError("T_DIST_2T", ERROR_NOT_AVAILABLE, arguments);
  }

  if (x < 0 || df < 1) {
    return new EngineError("T_DIST_2T", ERROR_NUM, arguments);
  }

  if (typeof x !== "number" || typeof df !== "number") {
    return new EngineError("T_DIST_2T", ERROR_VALUE, arguments);
  }

  return (1 - jStat.studentt.cdf(x, df)) * 2;
}

/**
 * Returns the right-tailed Student's t-distribution.
 *
 * @param x The numeric value at which to evaluate the distribution.
 * @param df An integer indicating the number of degrees of freedom.
 */
export function T_DIST_RT(x, df) {
  if (arguments.length !== 2) {
    return new EngineError("T_DIST_RT", ERROR_NOT_AVAILABLE, arguments);
  }

  if (x < 0 || df < 1) {
    return new EngineError("T_DIST_RT", ERROR_NUM, arguments);
  }

  if (typeof x !== "number" || typeof df !== "number") {
    return new EngineError("T_DIST_RT", ERROR_VALUE, arguments);
  }

  return 1 - jStat.studentt.cdf(x, df);
}

/**
 * Returns the left-tailed inverse of the Student's t-distribution.
 *
 * @param probability The probability associated with the Student's
 * t-distribution.
 * @param df The number of degrees of freedom with which to characterize the
 * distribution.
 */
export function T_INV(probability, df) {
  probability = utils.parseNumber(probability);
  df = utils.parseNumber(df);
  if (utils.anyIsError(probability, df)) {
    return new EngineError("T_INV", ERROR_VALUE, arguments);
  }
  return jStat.studentt.inv(probability, df);
}

/**
 * Returns the two-tailed inverse of the Student's t-distribution.
 *
 * @param probability The probability associated with the Student's
 * t-distribution.
 * @param df The number of degrees of freedom with which to characterize the
 * distribution.
 */
export function T_INV_2T(probability, df) {
  probability = utils.parseNumber(probability);
  df = utils.parseNumber(df);
  if (probability <= 0 || probability > 1 || df < 1) {
    return new EngineError("T_INV_2T", ERROR_NUM, arguments);
  }
  if (utils.anyIsError(probability, df)) {
    return new EngineError("T_INV_2T", ERROR_VALUE, arguments);
  }
  return Math.abs(jStat.studentt.inv(probability / 2, df));
}

/**
 * Returns the probability associated with a Student's t-Test. 
 * 
 * @param data_x The first data set.
 * @param data_y The second data set.
 * 
 * FIXME Function signature mismatch
 * https://support.office.com/en-us/article/TTEST-function-1696FFC1-4811-40FD-9D13-A0EAAD83C7AE
 */
export function T_TEST(data_x, data_y) {
  // The algorithm can be found here:
  // http://www.chem.uoa.gr/applets/AppletTtest/Appl_Ttest2.html
  data_x = utils.parseNumberArray(utils.flatten(data_x));
  data_y = utils.parseNumberArray(utils.flatten(data_y));
  if (utils.anyIsError(data_x, data_y)) {
    return new EngineError("T_TEST", ERROR_VALUE, arguments);
  }

  var mean_x = jStat.mean(data_x);
  var mean_y = jStat.mean(data_y);
  var s_x = 0;
  var s_y = 0;
  var i;

  for (i = 0; i < data_x.length; i++) {
    s_x += Math.pow(data_x[i] - mean_x, 2);
  }
  for (i = 0; i < data_y.length; i++) {
    s_y += Math.pow(data_y[i] - mean_y, 2);
  }

  s_x = s_x / (data_x.length - 1);
  s_y = s_y / (data_y.length - 1);

  var t =
    Math.abs(mean_x - mean_y) /
    Math.sqrt(s_x / data_x.length + s_y / data_y.length);

  return T_DIST_2T(t, data_x.length + data_y.length - 2);
}

/**
 * The TREND function returns values along a linear trend.
 * 
 * @param data_y The set of y-values you already know in the relationship
 * y = mx + b
 * @param data_x An optional set of x-values that you may already know in the
 * relationship y = mx + b
 * @param new_data_x New x-values for which you want TREND to return
 * corresponding y-values
 * 
 * FIXME Function signature mismatch
 * https://support.office.com/en-us/article/TREND-function-E2F135F0-8827-4096-9873-9A7CF7B51EF1
 */
export function TREND(data_y, data_x, new_data_x) {
  data_y = utils.parseNumberArray(utils.flatten(data_y));
  data_x = utils.parseNumberArray(utils.flatten(data_x));
  new_data_x = utils.parseNumberArray(utils.flatten(new_data_x));
  if (utils.anyIsError(data_y, data_x, new_data_x)) {
    return new EngineError("TREND", ERROR_VALUE, arguments);
  }
  var linest = LINEST(data_y, data_x);
  var m = linest[0];
  var b = linest[1];
  var result = [];

  new_data_x.forEach(function(x) {
    result.push(m * x + b);
  });

  return result;
}

/**
 * Returns the mean of the interior of a data set. 
 * 
 * @param range The array or range of values to trim and average.
 * @param percent The fractional number of data points to exclude from the
 * calculation. For example, if percent = 0.2, 4 points are trimmed from a data
 * set of 20 points (20 x 0.2): 2 from the top and 2 from the bottom of the set.
 */
export function TRIMMEAN(range, percent) {
  range = utils.parseNumberArray(utils.flatten(range));
  percent = utils.parseNumber(percent);
  if (utils.anyIsError(range, percent)) {
    return new EngineError("TRIMMEAN", ERROR_VALUE, arguments);
  }
  var trim = mathTrig.FLOOR(range.length * percent, 2) / 2;
  return jStat.mean(
    utils.initial(
      utils.rest(
        range.sort(function(a, b) {
          return a - b;
        }),
        trim
      ),
      trim
    )
  );
}
