import { Dispatcher } from 'flux';

class DebugDispatcher extends Dispatcher<any> {

  constructor() {
    super();
  }

  dispatch (payload) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Dispatch:', payload);
    }
    super.dispatch(payload);
  }

}

export function createMiddleware (getState) {
  return async (...fns) => {
    for (const fn of fns) {
      await fn(getState, createMiddleware(getState));
    }
  };
}

export default new DebugDispatcher();
