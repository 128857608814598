// tslint:disable object-shorthand-properties-first

import Dispatcher from '../dispatcher';
import { ActionTypes } from '../action-types';

export namespace app {
  export interface IApp {
    openSidePanel(): void;
    closeSidePanel(): void;
    toggleSidePanel(): void;
    openDrawer(): void;
    closeDrawer(): void;
    setLoading(message?: string): void;
    unsetLoading(): void;
    setSpreaxClient(client): void;
    hasActiveRedirects(props);
  }

  export function openSidePanel(): void {
    Dispatcher.dispatch({
      type: ActionTypes.OPEN_SIDEPANEL
    });
  }

  export function closeSidePanel(): void {
    Dispatcher.dispatch({
      type: ActionTypes.CLOSE_SIDEPANEL
    });
  }

  export function toggleSidePanel(): void {
    Dispatcher.dispatch({
      type: ActionTypes.TOGGLE_SIDEPANEL
    });
  }

  export function openDrawer(): void {
    Dispatcher.dispatch({
      type: ActionTypes.OPEN_DRAWER
    });
  }

  export function closeDrawer(): void {
    Dispatcher.dispatch({
      type: ActionTypes.CLOSE_DRAWER
    });
  }

  export function setLoading(message?: string): void {
    Dispatcher.dispatch({
      type: ActionTypes.SET_LOADING,
      message
    });
  }

  export function unsetLoading(): void {
    Dispatcher.dispatch({
      type: ActionTypes.UNSET_LOADING
    });
  }

  export function hasActiveRedirects(props) {
    return async (getState, dispatch) => {
      const { route } = props;
      const { redirectPath } = getState();
      if (redirectPath) {
        route.history.push(redirectPath);
        await dispatch(() =>
          Dispatcher.dispatch({ type: ActionTypes.UNSET_REDIRECT })
        );
      }
    };
  }
}
