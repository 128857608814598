import * as functions from '../functions';
import { ERROR_NAME, EngineError } from '../error';
import { SupportedFunctions } from '../supported-functions';

function getFunctionName(symbol: string) {
  return symbol.toUpperCase().replace('.', '_');
}

function isSupportedFunction(symbol: string) {
  return SupportedFunctions.indexOf(symbol.toUpperCase()) >= 0;
}

export function formulaFunction(symbol: string) {
  return function formulaFunction(...args) {
    const functionName = getFunctionName(symbol);
    if (!isSupportedFunction(symbol) || !functions[functionName]) {
      return new EngineError(symbol, ERROR_NAME, arguments);
    }
    return functions[functionName].call(this, ...args);
  };
}
