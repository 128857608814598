import * as React from 'react';
import { format } from '@spreax/engine';
import { Label as BaseLabel } from '@spreax/components';

export function Label (props) {
  const { property } = props;
  const formattedValue = format.format(property, property.value, false);
  return (
    <BaseLabel
      variant={property.variant}
      isBold={property.isBold}
      isItalic={property.isItalic}
      hasUnderline={property.hasUnderline}
      textAlignment={property.textAlignment}
    >
      {formattedValue}
    </BaseLabel>
  );
}
