import { ReduceStore } from 'flux/utils';
import Dispatcher from '../dispatcher';
import { ActionTypes } from '../action-types';
import * as DataManager from '../data-managers';
import { User } from '@spreax/model';

function onLoadAccounts(state: SettingsState, action): SettingsState {
  DataManager.account.get(action.tenantId);
  return Object.assign({}, state, {
    selectedAccount: null,
    accounts: []
  });
}

function onReceiveAccounts(state: SettingsState, action): SettingsState {
  return Object.assign({}, state, {
    accounts: action.accounts,
    selectedAccount: getAccountById(state.accounts, action.accountId)
  });
}

function onSelectAccount(state: SettingsState, action): SettingsState {
  const { selectedAccount } = state;

  if (selectedAccount && selectedAccount.id === action.accountId) {
    return state;
  }

  return Object.assign({}, state, {
    selectedAccount: getAccountById(state.accounts, action.accountId)
  });
}

function getAccountById(accounts, id) {
  return accounts.find(account => account.id === id);
}

function onLogout(state: SettingsState, action): SettingsState {
  return {
    accounts: null,
    selectedAccount: null
  };
}

class AccountStore extends ReduceStore<SettingsState, any> {
  constructor() {
    super(Dispatcher);
  }

  getInitialState() {
    return {
      accounts: null,
      selectedAccount: null
    };
  }

  reduce(state: SettingsState, action): SettingsState {
    switch (action.type) {
      case ActionTypes.REQUEST_ACCOUNTS:
        return onLoadAccounts(state, action);

      case ActionTypes.RECEIVE_ACCOUNTS:
        return onReceiveAccounts(state, action);

      case ActionTypes.REQUEST_SELECT_ACCOUNT:
        return onSelectAccount(state, action);

      case ActionTypes.LOGOUT:
        return onLogout(state, action);

      default:
        return state;
    }
  }
}

export interface SettingsState {
  accounts: any;
  selectedAccount: User;
}

export const Store = new AccountStore();
