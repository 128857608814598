import { DataValidation, ICell, Formula } from '.';
import { Style } from '@spreax/xlsx-parser';

export class Cell implements ICell {

  ref: string;
  sheet: string;
  sheetRef: string;
  row: number;
  column: number;
  $: any; // cached value
  _value: any;
  originalValue: any;
  nonStrictValue: any;
  formula: Formula;
  _dataValidation: DataValidation;
  precedents: Cell[];
  dependents: Cell[];
  isDirty: boolean;
  isCustom: boolean;
  isVirtual: boolean;
  isProxy: boolean;
  proxyTarget: Cell;
  proxySource: Cell;
  _style: Style;
  comment: null;

  constructor (cell: ICell) {
    this.ref = cell.ref;
    this.sheet = cell.sheet;
    this.sheetRef = cell.sheetRef;
    this.row = cell.row;
    this.column = cell.column;
    this.formula = cell.formula;
    this._value = cell.value;
    this.nonStrictValue = cell.nonStrictValue;
    this.originalValue = cell.value;
    this._style = cell.style;
    this._dataValidation = cell.dataValidation || null;
    this.precedents = [];
    this.dependents = [];
    this.isDirty = !!cell.formula;
    this.isCustom = false;
    this.isVirtual = cell.isVirtual || false;
    this.isProxy = cell.isProxy || false;
    this.proxyTarget = null;
    this.proxySource = null;
  }

  get style () {
    if (this.isProxy && this.proxyTarget && !this._style) {
      return this.proxyTarget.style;
    }
    return this._style;
  }

  get dataValidation () {
    if (this.isProxy && this.proxyTarget && !this._dataValidation) {
      return this.proxyTarget.dataValidation;
    }
    return this._dataValidation;
  }

  /**
   * Get how many `Cells` still need to be calculated before this `Cell` can be calculated
   *
   * @returns {number}
   */

  get precedentsWaiting () : number {
    return this.precedents.filter(precedentCell => precedentCell.isDirty).length;
  }

  /**
   * Set `Cell` value and mark all dependents as dirty (need to be re-calculated)
   *
   * @param value
   */

  set value (value) {
    this._value = value;
    // this.isCustom = true;
    // this.isDirty = true;
  }

  dirty () {
    this.isDirty = true;
    const queue = [
      ...this.dependents.filter(cell => !cell.isDirty),
    ];
    if (this.isProxy && this.proxyTarget) {
      queue.push(this.proxyTarget);
    }
    let index = 0;
    while (queue[index]) {
      const currentCell = queue[index];
      if (currentCell.dependents) {
        queue.push(
          ...currentCell.dependents.filter(cell => !cell.isDirty),
        );
      }
      currentCell.isDirty = true;
      index++;
    }
  }

  /**
   * Get `Cell` value
   *
   * @returns {any}
   */

  get value () {
    return this._value;
  }

  /**
   * Create relation between `Cells` to be able to determine calculation flow
   *
   * @param {Cell} cell
   */

  public dependsOn (cell: Cell) : void {
    this.precedents.push(cell);
    cell.dependents.push(this);
  }

  /**
   * Create relation between `Cells` to be able to determine calculation flow
   *
   * @param {Cell} cell
   */

  public precedes (cell: Cell) : void {
    this.dependents.push(cell);
    cell.precedents.push(this);
  }

  /**
   * Set `Cell` which will act as proxy to this `Cell`
   *
   * @param cell
   */

  public setProxy (cell: Cell) : void {
    this.proxySource = cell;
    cell.proxyTarget = this;
  }

}
