import * as React from 'react';
import { State } from '../../container';
import { __, i18n } from '@spreax/i18n';
import { App } from '../frame/app';
import { Page, PageTop, Chips, Chip, Button } from '../../components';

// FIXME SPX-178: duplicate
function groups(account) {
  // if (account.isSysAdmin) {
  //   return ['admin'];
  // }

  return account.groups.map(group => group.title);
}

export class EditAccount extends React.PureComponent<State> {
  async componentDidMount() {
    const { actions, middleware } = this.props;
    await middleware(await actions.account.makeSureHasAccounts(this.props));
  }

  async componentDidUpdate() {
    const { actions, middleware } = this.props;
    await middleware(await actions.account.makeSureHasAccounts(this.props));
  }

  render() {
    const { actions, selectedAccount } = this.props;
    // TODO SPX-178: i18n
    // TODO SPX-178: page classname
    return (
      <App {...this.props}>
        <Page>
          <PageTop
            primary="Edit Account"
            actionsRight={[
              <Button
                icon="add"
                onClick={() => console.log('FIXME')}
                iconOnly={true}
                isLean={true}
              />
            ]}
          />
          {selectedAccount && (
            <div className="sx-portal__settings">
              {selectedAccount.email}
              <Chips onDelete={actions.account.deleteGroupFromAccount}>
                {/*{*/}
                {/*groups(selectedAccount)*/}
                {/*.map((group) => {*/}
                {/*return (*/}
                {/*<Chip*/}
                {/*key={group}*/}
                {/*id={group}*/}
                {/*>*/}
                {/*{group}*/}
                {/*</Chip>*/}
                {/*);*/}
                {/*})*/}
                {/*}*/}
              </Chips>
            </div>
          )}
        </Page>
      </App>
    );
  }
}
