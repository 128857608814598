import { call, createFormData, getURL } from '..';

export namespace group {
  const PATH = 'groups';

  export function get(tenantId) {
    return call(getURL('tenants', tenantId, PATH), {
      method: 'GET'
    });
  }

  export function update(group) {
    return call(getURL(PATH, group.id), {
      method: 'PATCH',
      body: createFormData(group)
    });
  }
}
