import {
  EngineError,
  ERROR,
  ERROR_NOT_AVAILABLE,
} from "../../error";

import { parseNumberArray, parseNumber, flatten, anyIsError } from "../utils";

/**
 * Returns the greatest common divisor of two or more integers. The greatest
 * common divisor is the largest integer that divides both number1 and number2
 * without a remainder.
 *
 * @param number[] Number1, number2, ...    Number1 is required, subsequent
 * numbers are optional. 1 to 255 values. If any value is not an integer, it is
 * truncated.
 *
 * adapted http://rosettacode.org/wiki/Greatest_common_divisor#JavaScript
 */
export function GCD(...args: any[]) {
  var range = parseNumberArray(flatten(args));
  if (range instanceof Error) {
    return range;
  }
  var n = range.length;
  var r0 = range[0];
  var x = r0 < 0 ? -r0 : r0;
  for (var i = 1; i < n; i++) {
    var ri = range[i];
    var y = ri < 0 ? -ri : ri;
    while (x && y) {
      if (x > y) {
        x %= y;
      } else {
        y %= x;
      }
    }
    x += y;
  }
  return x;
}

export function GTE(num1, num2) {
  if (arguments.length !== 2) {
    return new EngineError("GTE", ERROR_NOT_AVAILABLE, arguments);
  }
  num1 = parseNumber(num1);
  num2 = parseNumber(num2);
  if (anyIsError(num1, num2)) {
    return new EngineError("{FUNCTION_NAME}", ERROR, arguments);
  }
  return num1 >= num2;
}

