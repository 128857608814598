import { AddressIndices, Address, CellReference } from '.';

const LABEL_EXTRACT_REGEXP = /^([$])?([A-Za-z]+)([$])?([0-9]+)$/;
const COLUMN_LABEL_BASE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const COLUMN_LABEL_BASE_LENGTH = COLUMN_LABEL_BASE.length;

/**
 * Convert row label to index
 *
 * @param {string} label
 * @returns {number}
 */

export function getRowIndex (label: string) : number {
  const result = parseInt(label, 10);
  if (isNaN(result)) {
    throw new Error('Invalid row label');
  }
  return Math.max(result - 1, -1);
}

/**
 * Convert column label to index
 *
 * @param {string} label Column label (eq. 'ABB', 'CNQ')
 * @returns {number} Returns -1 if label is not recognized otherwise proper column index.
 */

export function getColumnIndex (label: string) : number {
  let result = 0;
  for (let i = 0, j = label.length - 1; i < label.length; i += 1, j -= 1) {
    result += Math.pow(COLUMN_LABEL_BASE_LENGTH, j) * (COLUMN_LABEL_BASE.indexOf(label[i].toUpperCase()) + 1);
  }
  --result;
  return result;
}

/**
 * Convert column index to label
 *
 * @param {number} index
 * @returns {string}
 */

export function getRowLabel (index: number) : string {
  if (index >= 0) {
    return (index + 1).toString();
  }
}

/**
 * Convert column index to label
 *
 * @param {number} index
 * @returns {string}
 */

export function getColumnLabel (index: number) : string {
  let result = '';
  let i = index;
  while (i >= 0) {
    result = String.fromCharCode((i % COLUMN_LABEL_BASE_LENGTH) + 97) + result;
    i = Math.floor(i / COLUMN_LABEL_BASE_LENGTH) - 1;
  }
  return result.toUpperCase();
}

/**
 * Convert `AddressIndices` to `Address`, for example `[1,11]` becomes `B12`
 *
 * @param {AddressIndices} indices
 * @returns {Address}
 */

export function getAddress (indices: AddressIndices) : Address {
  return `${indices.isColumnAbsolute ? '$' : ''}` + getColumnLabel(indices.column) + `${indices.isRowAbsolute ? '$' : ''}` + getRowLabel(indices.row);
}

/**
 * Convert address to indices, for example `B12` becomes `[1,11]`
 *
 * @param {Address} address
 * @returns AddressIndices
 */

export function getAddressIndices (address: Address) : AddressIndices {
  const [, columnAbs, column, rowAbs, row] = address.toUpperCase().match(LABEL_EXTRACT_REGEXP);
  const addressIndices = {
    column: getColumnIndex(column),
    isColumnAbsolute: columnAbs && columnAbs.length > 0,
    row: getRowIndex(row),
    isRowAbsolute: rowAbs && rowAbs.length > 0,
  };
   return addressIndices;
}

/**
 * When dealing with addresses, some addresses are defined as ranges e.g. `A1:B12`. This function creates an array
 * of this range.
 *
 * @param {string} address
 * @returns {AddressIndices}
 */

export function interpolateAddress (address: Address) : AddressIndices[] {
  const [start, end] = address.split(':').map(getAddressIndices);
  const startRow = start.row <= end.row ? start.row : end.row;
  const endRow = start.row <= end.row ? end.row : start.row;
  const startColumn = start.column <= end.column ? start.column : end.column;
  const endColumn = start.column <= end.column ? end.column : start.column;
  const addresses = [];
  for (let column = startColumn; column <= endColumn; column++) {
    for (let row = startRow; row <= endRow; row++) {
      addresses.push({
        column,
        row,
      });
    }
  }
  return addresses;
}

/**
 * Parse an `Address` string, for example `B12 D3:D8`, and create an array of all addresses within this range
 *
 * @param {Address} address
 */

export function parseAddress (address: Address) : AddressIndices[] {
  return address
    .split(' ')
    .map((address) => {
      if (address.includes(':')) {
        return interpolateAddress(address);
      }
      return [getAddressIndices(address)];
    })
    .reduce((acc, val) => acc.concat(val), []);
}

/**
 * Create `CellReference` based on row/column
 *
 * @param {string} sheet
 * @param {number} row
 * @param {number} column
 * @returns {CellReference}
 */

export function getCellReference (sheet: string, row: number, column: number) : CellReference {
  return {
    sheet,
    row: {
      index: row,
      label: getRowLabel(row),
    },
    column: {
      index: column,
      label: getColumnLabel(column),
    },
    label: getAddress({
      row,
      column,
      isRowAbsolute: null,
      isColumnAbsolute: null,
    }),
  };
}

export function sanitizeFormula (map, formula) {
  return map.reduce(
    (sanitizedFormula, set) => {
      return sanitizedFormula
        .replace(new RegExp(`'${set[0]}'!`, 'g'), set[1] + '!')
        .replace(new RegExp(`${set[0]}!`, 'g'), set[1] + '!');
    },
    formula,
  );
  // if (sanitizedFormula[0] === '+') {
  //   sanitizedFormula = sanitizedFormula.substr(1, sanitizedFormula.length);
  // }
}
