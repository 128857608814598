import * as statistical from "../statistical";

import { EngineError, ERROR_VALUE, ERROR_NUM, ERROR_NOT_AVAILABLE } from "../../error";
import { ErrorValue, NumberValue } from "..";
import { parseNumber, anyIsError } from "../utils";
import { PRODUCT, SUM } from "../math-and-trigonometry";

/**
 * Computes the absolute value of x.
 *
 * @param {NumberValue} x
 * @returns {NumberValue | ErrorValue}
 */

export function ABS(x): NumberValue | ErrorValue {
  x = parseNumber(x);
  if (x instanceof EngineError) {
    return x;
  }
  return Math.abs(x);
}

/**
 * Computes the arc cosine of x.
 *
 * @param {NumberValue} x
 * @returns {NumberValue | ErrorValue}
 */

export function ACOS(x: NumberValue): NumberValue | ErrorValue {
  // @ts-ignore
  x = parseNumber(x);
  // @ts-ignore
  if (x instanceof EngineError) {
    return x;
  }
  const result = Math.acos(x);
  if (isNaN(result)) {
    return new EngineError("ACOS", ERROR_NUM, arguments);
  }
  return result;
}

/**
 * Computes the inverse hyperbolic cosine of x.
 *
 * @param {NumberValue} x
 * @returns {NumberValue | ErrorValue}
 */

export function ACOSH(x: NumberValue): NumberValue | ErrorValue {
  // @ts-ignore
  x = parseNumber(x);
  // @ts-ignore
  if (x instanceof EngineError) {
    return x;
  }
  const result = Math.log(x + Math.sqrt(x * x - 1));
  if (isNaN(result)) {
    return new EngineError("ACOSH", ERROR_NUM, arguments);
  }
  return result;
}

/**
 * Returns the principal value of the arccotangent, or inverse cotangent, of a
 * number.
 *
 * @param {NumberValue} number the cotangent of the angle you want. This must be
 * a real number.
 */
export function ACOT(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  var result = Math.atan(1 / number);

  return result;
}

/**
 * Returns the inverse hyperbolic cotangent of a number.
 *
 * @param {NumberValue} number The absolute value of Number must be greater
 * than 1.
 */
export function ACOTH(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  const result = 0.5 * Math.log((number + 1) / (number - 1));
  if (isNaN(result)) {
    return new EngineError("ACOTH", ERROR_NUM, arguments);
  }
  return result;
}

// TODO NOT_DEFINED in https://support.office.com/
export function ADD(num1, num2) {
  if (arguments.length !== 2) {
    return new EngineError("ADD", ERROR_NOT_AVAILABLE, arguments);
  }

  num1 = parseNumber(num1);
  num2 = parseNumber(num2);
  if (anyIsError(num1, num2)) {
    return new EngineError("ADD", ERROR_VALUE, arguments);
  }

  return num1 + num2;
}

/**
 * Returns an aggregate in a list or database. The AGGREGATE function can apply
 * different aggregate functions to a list or database with the option to ignore
 * hidden rows and error values.
 *
 * @param functionNum A number 1 to 19 that specifies which function to use.
 * @param options  A numerical value that determines which values to ignore in
 * the evaluation range for the function.
 * @param ref1 The first numeric argument for functions that take multiple
 * numeric arguments for which you want the aggregate value
 * @param ref2 [optional] Numeric arguments 2 to 253 for which you want the
 * aggregate value.
 *
 * FIXME's
 * - options not implemented
 * - only really simple cases are tested
 * - secondary ranges (ref2) not tested
 *
 * @link https://support.office.com/en-us/article/AGGREGATE-function-43B9278E-6AA7-4F17-92B6-E19993FA26DF
 */
export function AGGREGATE(functionNum, options, ref1, ref2) {
  functionNum = parseNumber(functionNum);
  options = parseNumber(functionNum);
  if (anyIsError(functionNum, options)) {
    return new EngineError("AGGREGATE", ERROR_VALUE, arguments);
  }
  switch (functionNum) {
    case 1:
      return statistical.AVERAGE(ref1);
    case 2:
      return statistical.COUNT(ref1);
    case 3:
      return statistical.COUNTA(ref1);
    case 4:
      return statistical.MAX(ref1);
    case 5:
      return statistical.MIN(ref1);
    case 6:
      return PRODUCT(ref1);
    case 7:
      return statistical.STDEV_S(ref1);
    case 8:
      return statistical.STDEV_P(ref1);
    case 9:
      return SUM(ref1);
    case 10:
      return statistical.VAR_S(ref1);
    case 11:
      return statistical.VAR_P(ref1);
    case 12:
      return statistical.MEDIAN(ref1);
    // case 13:
    //   return statistical.MODE_SNGL(ref1);
    case 14:
      return statistical.LARGE(ref1, ref2);
    case 15:
      return statistical.SMALL(ref1, ref2);
    // case 16:
    //   return statistical.PERCENTILE_INC(ref1, ref2);
    // case 17:
    //   return statistical.QUARTILE_INC(ref1, ref2);
    // case 18:
    //   return statistical.PERCENTILE_EXC(ref1, ref2);
    // case 19:
    //   return statistical.QUARTILE_EXC(ref1, ref2);
  }
}

/**
 * Converts a Roman numeral to an Arabic numeral.
 * 
 * @param text A string enclosed in quotation marks, an empty string (""), or a
 * reference to a cell containing text.
 */
export function ARABIC(text) {
  // Credits: Rafa? Kukawski
  if (
    !/^M*(?:D?C{0,3}|C[MD])(?:L?X{0,3}|X[CL])(?:V?I{0,3}|I[XV])$/.test(text)
  ) {
    return new EngineError("ARABIC", ERROR_VALUE, arguments);
  }
  var r = 0;
  text.replace(/[MDLV]|C[MD]?|X[CL]?|I[XV]?/g, function(i) {
    r += {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1
    }[i];
  });
  return r;
}

/**
 * Returns the arcsine, or inverse sine, of a number. The arcsine is the angle
 * whose sine is number. The returned angle is given in radians in the range
 * -pi/2 to pi/2.
 *
 * @param number The sine of the angle you want and must be from -1 to 1.
 *
 * @link https://support.office.com/en-us/article/ASIN-function-81FB95E5-6D6F-48C4-BC45-58F955C6D347
 */
export function ASIN(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  var result = Math.asin(number);
  if (isNaN(result)) {
    return new EngineError("ASIN", ERROR_NUM, arguments);
  }
  return result;
}

/**
 * Returns the inverse hyperbolic sine of a number. The inverse hyperbolic sine
 * is the value whose hyperbolic sine is number, so ASINH(SINH(number)) equals
 * number.
 *
 * @param number Any real number.
 */
export function ASINH(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  return Math.log(number + Math.sqrt(number * number + 1));
}

/**
 * Returns the arctangent, or inverse tangent, of a number. The arctangent is
 * the angle whose tangent is number. The returned angle is given in radians in
 * the range -pi/2 to pi/2.
 *
 * @param number The tangent of the angle you want.
 */
export function ATAN(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  return Math.atan(number);
}

/**
 * Returns the arctangent, or inverse tangent, of the specified x- and
 * y-coordinates. The arctangent is the angle from the x-axis to a line
 * containing the origin (0, 0) and a point with coordinates (x_num, y_num).
 * The angle is given in radians between -pi and pi, excluding -pi.
 *
 * @param number
 */
export function ATAN2(number_x, number_y) {
  number_x = parseNumber(number_x);
  number_y = parseNumber(number_y);
  if (anyIsError(number_x, number_y)) {
    return new EngineError("ATAN2", ERROR_VALUE, arguments);
  }
  return Math.atan2(number_x, number_y);
}

/**
 * Returns the inverse hyperbolic tangent of a number. Number must be between
 * -1 and 1 (excluding -1 and 1). The inverse hyperbolic tangent is the value
 * whose hyperbolic tangent is number, so ATANH(TANH(number)) equals number.
 *
 * @param number Any real number between 1 and -1.
 */
export function ATANH(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  const result = Math.log((1 + number) / (1 - number)) / 2;
  if (isNaN(result)) {
    return new EngineError("ATANH", ERROR_NUM, arguments);
  }
  return result;
}
