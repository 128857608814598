export namespace log {

  export function verbose (...args) {
    //
  }

  export function error (...args) {
    console.error(...args);
  }

  export const debug = process.env['DEBUG'] ? console.log : function noop () {};

}
