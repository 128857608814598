import { FileType } from '@spreax/model';

export function collectDrag (connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

export function collectDrop (connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}

export function beginDrag (props) {
  setTimeout(() => props.onBeginDrag(props), 0);
  return props;
}

export function endDrag (props) {
  setTimeout(() => props.onEndDrag(props), 0);
  return props;
}

export function canDrop (props, monitor) {
  const source = monitor.getItem();
  return props.id !== source.id && props.type === FileType.Folder && !props.path.includes(source.id);
}

export function drop (props, monitor) {
  const source = monitor.getItem();
  source.onMoveFile(props.id);
}

export const DROP_TYPE = 'Item';
