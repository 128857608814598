var utils = require("../utils");
var jStat = require("jStat").jStat;

import {
  EngineError,
  ERROR_NUM,
  ERROR_VALUE,
} from "../../error";

import { VAR_P, VARA, VAR_S, VARPA } from "../statistical";

/**
 * Returns the skewness of a distribution.
 * 
 * @param arg[] Number1, number2, ...    Number1 is required, subsequent numbers
 * are optional. 1 to 255 arguments for which you want to calculate skewness. 
 */
export function SKEW(...args) {
  var range = utils.parseNumberArray(utils.flatten(args));
  if (range instanceof Error) {
    return range;
  }
  var mean = jStat.mean(range);
  var n = range.length;
  var sigma = 0;
  for (var i = 0; i < n; i++) {
    sigma += Math.pow(range[i] - mean, 3);
  }
  return (
    (n * sigma) / ((n - 1) * (n - 2) * Math.pow(jStat.stdev(range, true), 3))
  );
}

/**
 * Returns the skewness of a distribution based on a population: a
 * characterization of the degree of asymmetry of a distribution around its
 * mean.
 * 
 * @param args Number 1, number 2,…    Number 1 is required, subsequent numbers
 * are optional. Number 1, number 2,… are 1 to 254 numbers or names, arrays, or
 * reference that contain numbers for which you want the population skewness.
 */
export function SKEW_P(...args) {
  var range = utils.parseNumberArray(utils.flatten(args));
  if (range instanceof Error) {
    return range;
  }
  var mean = jStat.mean(range);
  var n = range.length;
  var m2 = 0;
  var m3 = 0;
  for (var i = 0; i < n; i++) {
    m3 += Math.pow(range[i] - mean, 3);
    m2 += Math.pow(range[i] - mean, 2);
  }
  m3 = m3 / n;
  m2 = m2 / n;
  return m3 / Math.pow(m2, 3 / 2);
}

/**
 * Returns the slope of the linear regression line through data points in
 * known_y's and known_x's.
 * 
 * @param data_y An array or cell range of numeric dependent data points.
 * @param data_x The set of independent data points.
 */
export function SLOPE(data_y, data_x) {
  data_y = utils.parseNumberArray(utils.flatten(data_y));
  data_x = utils.parseNumberArray(utils.flatten(data_x));
  if (utils.anyIsError(data_y, data_x)) {
    return new EngineError("SLOPE", ERROR_VALUE, arguments);
  }
  var xmean = jStat.mean(data_x);
  var ymean = jStat.mean(data_y);
  var n = data_x.length;
  var num = 0;
  var den = 0;
  for (var i = 0; i < n; i++) {
    num += (data_x[i] - xmean) * (data_y[i] - ymean);
    den += Math.pow(data_x[i] - xmean, 2);
  }
  return num / den;
}

/**
 * Returns the k-th smallest value in a data set. Use this function to return
 * values with a particular relative standing in a data set.
 * 
 * @param range An array or range of numerical data for which you want to
 * determine the k-th smallest value.
 * @param k The position (from the smallest) in the array or range of data to
 * return.
 */
export function SMALL(range, k) {
  range = utils.parseNumberArray(utils.flatten(range));
  k = utils.parseNumber(k);
  if (utils.anyIsError(range, k)) {
    return range;
  }
  return range.sort(function(a, b) {
    return a - b;
  })[k - 1];
}

/**
 * Returns a normalized value from a distribution characterized by mean and
 * standard_dev.
 * 
 * @param x The value you want to normalize.
 * @param mean The arithmetic mean of the distribution.
 * @param sd The standard deviation of the distribution.
 */
export function STANDARDIZE(x, mean, sd) {
  x = utils.parseNumber(x);
  mean = utils.parseNumber(mean);
  sd = utils.parseNumber(sd);
  if (utils.anyIsError(x, mean, sd)) {
    return new EngineError("STANDARDIZE", ERROR_VALUE, arguments);
  }
  return (x - mean) / sd;
}

/**
 * Calculates standard deviation based on the entire population given as
 * arguments (ignores logical values and text).
 * 
 * @param args Number 1, number 2, ... Number 1 is required, subsequent numbers
 * are optional. Numbers corresponding to a population.
 */
export function STDEV_P(...args) {
  const v = VAR_P.apply(this, args);
  const result = Math.sqrt(v);
  if (isNaN(result)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NUM, arguments);
  }
  return result;
}

/**
 * Estimates standard deviation based on a sample (ignores logical values and
 * text in the sample).
 * 
 * @param args Number 1, number 2, ... Number 1 is required, subsequent numbers
 * are optional. Numbers corresponding to a population.
 */
export function STDEV_S(...args) {
  const v = VAR_S.apply(this, args);
  return Math.sqrt(v);
}

/**
 * Estimates standard deviation based on a sample
 * 
 * @param args Value1, value2, ... Value1 is required, subsequent values are
 * optional. 1 to 255 values corresponding to a sample of a population. You can
 * also use a single array or a reference to an array instead of arguments
 * separated by commas.
 */
export function STDEVA(...args) {
  const v = VARA.apply(this, args);
  return Math.sqrt(v);
}

/**
 * Calculates standard deviation based on the entire population given as
 * arguments, including text and logical values.
 * 
 * @param args Value1, value2, ... Value1 is required, subsequent values are
 * optional. 1 to 255 values corresponding to a population. You can also use a
 * single array or a reference to an array instead of arguments separated by
 * commas.
 */
export function STDEVPA(...args) {
  const v = VARPA.apply(this, args);
  const result = Math.sqrt(v);
  if (isNaN(result)) {
    return new EngineError("STDEVPA", ERROR_NUM, arguments);
  }
  return result;
}

/**
 * Returns the standard error of the predicted y-value for each x in the
 * regression. The standard error is a measure of the amount of error in the
 * prediction of y for an individual x.
 * 
 * @param data_y An array or range of dependent data points.
 * @param data_x An array or range of independent data points.
 */
export function STEYX(data_y, data_x) {
  data_y = utils.parseNumberArray(utils.flatten(data_y));
  data_x = utils.parseNumberArray(utils.flatten(data_x));
  if (utils.anyIsError(data_y, data_x)) {
    return new EngineError("STEYX", ERROR_VALUE, arguments);
  }
  var xmean = jStat.mean(data_x);
  var ymean = jStat.mean(data_y);
  var n = data_x.length;
  var lft = 0;
  var num = 0;
  var den = 0;
  for (var i = 0; i < n; i++) {
    lft += Math.pow(data_y[i] - ymean, 2);
    num += (data_x[i] - xmean) * (data_y[i] - ymean);
    den += Math.pow(data_x[i] - xmean, 2);
  }
  return Math.sqrt((lft - (num * num) / den) / (n - 2));
}