import * as React from 'react';
import '../../../src/components/file-browser/style.scss';
import { State } from '../../container';
import { Editable, Button } from '../';
import { FileBrowserLevel } from './file-browser-level';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { FileType } from '@spreax/model';

@DragDropContext(HTML5Backend)
export class FileBrowser extends React.Component<State, any> {
  constructor(props) {
    super(props);
    this.onSelectFile = this.onSelectFile.bind(this);
    this.onMoveFile = this.onMoveFile.bind(this);
    this.onFolderTitleChange = this.onFolderTitleChange.bind(this);
    this.onCreateFolder = this.onCreateFolder.bind(this);
    this.onRemoveFolder = this.onRemoveFolder.bind(this);
    this.onBeginDrag = this.onBeginDrag.bind(this);
    this.onEndDrag = this.onEndDrag.bind(this);
    this.state = {
      isDragging: false
    };
  }

  folderIsEmpty(id) {
    return !this.props.folders.find(folder => folder.parentId === id);
  }

  onSelectFile(id) {
    return () => {
      this.props.onSelectFile(id);
    };
  }

  onMoveFile(id, folder) {
    return target => {
      this.props.onMoveFile(id, folder, target);
    };
  }

  onFolderTitleChange(folder) {
    return ev => {
      this.props.onFolderTitleChange(folder.id, folder, ev.target.value);
    };
  }

  onCreateFolder() {
    this.props.onCreateFolder(
      this.props.selectedFolder.id,
      this.props.selectedFolder
    );
  }

  onRemoveFolder() {
    const { selectedFolder } = this.props;
    const isEmpty = selectedFolder && this.folderIsEmpty(selectedFolder.id);
    if (isEmpty && selectedFolder && selectedFolder.parentId) {
      this.props.onRemoveFolder(selectedFolder.id, selectedFolder);
      if (selectedFolder.parentId) {
        this.props.onSelectParent(selectedFolder.parentId, selectedFolder);
      }
    }
  }

  onSaveFolder(folder) {
    return () => {
      this.props.onSaveFolder(folder.id, folder);
    };
  }

  onBeginDrag() {
    this.setState({
      isDragging: true
    });
  }

  onEndDrag() {
    this.setState({
      isDragging: false
    });
  }

  render() {
    const { selectedFolder, profile, folders } = this.props;
    const { isDragging } = this.state;
    if (!folders) {
      return null;
    }
    const rootFolderPath = !selectedFolder ? ['ROOT'] : selectedFolder.path;
    return (
      <div className="sx-file-browser">
        <div className="sx-file-browser__levels">
          {rootFolderPath.map((path, index) => {
            return (
              <FileBrowserLevel
                key={index}
                folders={folders.filter(
                  folder => (profile && profile.isAdmin) || !folder.isHidden
                )}
                folderLevel={rootFolderPath[index]}
                depth={index}
                path={rootFolderPath}
                onSelectFile={this.onSelectFile}
                onMoveFile={this.onMoveFile}
                onBeginDrag={this.onBeginDrag}
                onEndDrag={this.onEndDrag}
                isDragging={isDragging}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export * from './file-browser-item';
export * from './file-browser-level';
export * from './drag-and-drop';
