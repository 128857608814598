import * as React from 'react';
import injectSheet from 'react-jss';
import * as classNames from 'classnames';

const styles = theme => ({
  container: {
    ...(theme.button && theme.button.container),
    cursor: 'pointer'
  },
  default: {
    ...(theme.button && theme.button.default)
  },
  isDisabled: {
    ...(theme.button && theme.button.isDisabled)
  },
  isPrimary: {
    ...(theme.button && theme.button.isPrimary)
  },
  hasIcon: {
    ...(theme.button && theme.button.hasIcon)
  },
  isLean: {
    ...(theme.button && theme.button.isLean)
  },
  callout: {
    ...(theme.button && theme.button.callout)
  },
  showCallout: {
    ...(theme.button && theme.button.showCallout)
  }
});

export class ButtonComponent extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      showCallout: false
    };
  }

  onMouseOver() {
    this.setState({
      showCallout: !this.state.showCallout
    });
  }

  onMouseMove(e) {
    this.setState({
      x: e.clientX,
      y: e.clientY
    });
  }

  render() {
    const {
      classes,
      label,
      onClick,
      isDisabled,
      show,
      isPrimary,
      icon,
      iconOnly,
      isLean,
      callOutMessage,
      className
    } = this.props;
    if (show === false) {
      return null;
    }
    const callOutPosition = {
      left: this.state.x,
      top: this.state.y
    };
    return (
      <div
        className={classNames(classes.container, className, {
          [classes.default]: !isPrimary && !isLean,
          [classes.isPrimary]: isPrimary,
          [classes.hasIcon]: !!icon && !iconOnly,
          [classes.isDisabled]: isDisabled,
          [classes.isLean]: isLean
        })}
        onClick={!isDisabled ? onClick : null}
      >
        <i className="material-icons">{icon}</i> {!iconOnly && label}
        {label && (
          <div
            className={classNames(classes.callout, {
              [classes.showCallout]: iconOnly && this.state.showCallout
            })}
            style={callOutPosition}
          >
            {label}
          </div>
        )}
      </div>
    );
  }
}

export const Button = injectSheet(styles)(ButtonComponent);
