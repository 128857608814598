import { call, getURL } from '..';

export namespace tenant {
  const PATH = 'tenants';

  export function get(tenantId) {
    return call(getURL(PATH, tenantId), {
      method: 'GET'
    });
  }
}
