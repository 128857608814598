import * as React from 'react';
import { Tracker } from '@spreax/lib';

export class ErrorBoundary extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true
    };
  }

  componentDidCatch(error, info) {
    Tracker.exception(error);
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      return <div>1337 - Something went wrong</div>;
    }
    return this.props.children;
  }
}
