import { ReduceStore } from 'flux/utils';
import Dispatcher from '../dispatcher';
import { ActionTypes } from '../action-types';
import * as ReactGA from 'react-ga';
import { config } from '../config';

function onPageView(state: AnalyticsState, action): AnalyticsState {
  // FIXME: Make this actually functional
  return state;
}

function onAction(state: AnalyticsState, action): AnalyticsState {
  const { gaTrackingId } = state;
  if (gaTrackingId) {
    const [eventCategory, eventAction] = action.type.split('/');
    const eventLabel = action.id || null;
    ReactGA.event({
      category: eventCategory,
      action: eventAction,
      label: eventLabel
    });
  }
  return state;
}

function onLoggedIn(state: AnalyticsState, action): AnalyticsState {
  const { gaTrackingId } = state;
  const { session } = action;
  if (gaTrackingId) {
    ReactGA.initialize(gaTrackingId, {
      debug: process.env.NODE_ENV !== 'production',
      titleCase: false,
      gaOptions: {
        userId: session.profile.id
      }
    });
  }
  return state;
}

class AnalyticsStore extends ReduceStore<AnalyticsState, any> {
  constructor() {
    super(Dispatcher);
  }

  getInitialState() {
    return {
      gaTrackingId: config.GA_TRACKING_ID
    };
  }

  reduce(state, action): AnalyticsState {
    switch (action.type) {
      case ActionTypes.PAGE_VIEW:
        return onPageView(state, action);

      case ActionTypes.LOGGED_IN:
        return onLoggedIn(state, action);

      default:
        return onAction(state, action);
    }
  }
}

export interface AnalyticsState {
  gaTrackingId: string;
}

export const Store = new AnalyticsStore();
