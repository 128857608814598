import { EngineError, ERROR_VALUE, ERROR_NUM } from '../error';
import * as utils from './utils';

const d1900 = new Date(1900, 0, 1);

const WEEK_STARTS = [
  undefined,
  0,
  1,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  1,
  2,
  3,
  4,
  5,
  6,
  0
];

const WEEK_TYPES = [
  [],
  [1, 2, 3, 4, 5, 6, 7],
  [7, 1, 2, 3, 4, 5, 6],
  [6, 0, 1, 2, 3, 4, 5],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [7, 1, 2, 3, 4, 5, 6],
  [6, 7, 1, 2, 3, 4, 5],
  [5, 6, 7, 1, 2, 3, 4],
  [4, 5, 6, 7, 1, 2, 3],
  [3, 4, 5, 6, 7, 1, 2],
  [2, 3, 4, 5, 6, 7, 1],
  [1, 2, 3, 4, 5, 6, 7]
];

const WEEKEND_TYPES = [
  [],
  [6, 0],
  [0, 1],
  [1, 2],
  [2, 3],
  [3, 4],
  [4, 5],
  [5, 6],
  undefined,
  undefined,
  undefined,
  [0, 0],
  [1, 1],
  [2, 2],
  [3, 3],
  [4, 4],
  [5, 5],
  [6, 6]
];

// TODO Can the engine handle date calculations like TODAY() + 5, ... Can we
// test this.

/**
 * Use Excel's DATE function when you need to take three separate values and
 * combine them to form a date.
 *
 * @param year The value of the year argument can include one to four digits.
 * @param month A positive or negative integer representing the month of the
 * year from 1 to 12 (January to December).
 * @param day A positive or negative integer representing the day of the month
 * from 1 to 31.
 */
export function DATE(year, month, day) {
  let result;
  year = utils.parseNumber(year);
  month = utils.parseNumber(month);
  day = utils.parseNumber(day);
  if (utils.anyIsError(year, month, day)) {
    result = new EngineError('DATE', ERROR_VALUE, arguments);
  } else if (year < 0 || month < 0 || day < 0) {
    result = new EngineError('DATE', ERROR_NUM, arguments);
  } else {
    result = new Date(year, month - 1, day);
  }
  return result;
}

/**
 * The DATEVALUE function converts a date that is stored as text to a serial
 * number that Excel recognizes as a date.
 *
 * @param dateText Text that represents a date in an Excel date format
 */
export function DATEVALUE(dateText) {
  if (typeof dateText !== 'string') {
    return new EngineError('DATEVALUE', ERROR_VALUE, arguments);
  }
  const date = Date.parse(dateText);
  if (isNaN(date)) {
    return new EngineError('DATEVALUE', ERROR_VALUE, arguments);
  }
  if (date <= -2203891200000) {
    return (date - +d1900) / 86400000 + 1;
  }
  return (date - +d1900) / 86400000 + 2;
}

/**
 * Returns the day of a date, represented by a serial number. The day is given
 * as an integer ranging from 1 to 31.
 *
 * @param serialNumber The date of the day you are trying to find
 */
export function DAY(serialNumber) {
  const date = utils.parseDate(serialNumber);
  if (date instanceof EngineError) {
    return date;
  }
  return date.getDate();
}

/**
 * Returns the number of days between two dates.
 *
 * @param endDate Start_date and End_date are the two dates between which you
 * want to know the number of days.
 * @param startDate Start_date and End_date are the two dates between which you
 * want to know the number of days.
 */
export function DAYS(endDate, startDate) {
  endDate = utils.parseDate(endDate);
  startDate = utils.parseDate(startDate);
  if (endDate instanceof EngineError) {
    return endDate;
  }
  if (startDate instanceof EngineError) {
    return startDate;
  }
  return serial(endDate) - serial(startDate);
}

/**
 * The DAYS360 function returns the number of days between two dates based on a
 * 360-day year (twelve 30-day months), which is used in some accounting
 * calculations.
 *
 * @param startDate tart_date and End_date are the two dates between which you
 * want to know the number of days.
 * @param endDate tart_date and End_date are the two dates between which you
 * want to know the number of days.
 * @param method Optional. A logical value that specifies whether to use the
 * U.S. or European method in the calculation.
 */
export function DAYS360(startDate, endDate, method) {
  method = utils.parseBool(method || false);
  startDate = utils.parseDate(startDate);
  endDate = utils.parseDate(endDate);

  if (startDate instanceof EngineError) {
    return startDate;
  }
  if (endDate instanceof EngineError) {
    return endDate;
  }
  if (method instanceof EngineError) {
    return method;
  }
  var sm = startDate.getMonth();
  var em = endDate.getMonth();
  var sd, ed;
  if (method) {
    sd = startDate.getDate() === 31 ? 30 : startDate.getDate();
    ed = endDate.getDate() === 31 ? 30 : endDate.getDate();
  } else {
    var smd = new Date(startDate.getFullYear(), sm + 1, 0).getDate();
    var emd = new Date(endDate.getFullYear(), em + 1, 0).getDate();
    sd = startDate.getDate() === smd ? 30 : startDate.getDate();
    if (endDate.getDate() === emd) {
      if (sd < 30) {
        em++;
        ed = 1;
      } else {
        ed = 30;
      }
    } else {
      ed = endDate.getDate();
    }
  }
  return (
    360 * (endDate.getFullYear() - startDate.getFullYear()) +
    30 * (em - sm) +
    (ed - sd)
  );
}

/**
 * Returns the serial number that represents the date that is the indicated
 * number of months before or after a specified date (the start_date).
 *
 * @param startDate A date that represents the start date.
 * @param months The number of months before or after start_date.
 */
export function EDATE(startDate, months) {
  startDate = utils.parseDate(startDate);

  if (startDate instanceof EngineError) {
    return startDate;
  }
  if (isNaN(months)) {
    return new EngineError('EDATE', ERROR_VALUE, arguments);
  }
  months = parseInt(months, 10);
  startDate.setMonth(startDate.getMonth() + months);
  return serial(startDate);
}

/**
 * Returns the serial number for the last day of the month that is the indicated
 * number of months before or after start_date.
 *
 * @param startDate A date that represents the starting date.
 * @param months The number of months before or after start_date.
 */
export function EOMONTH(startDate, months) {
  startDate = utils.parseDate(startDate);
  if (startDate instanceof EngineError) {
    return startDate;
  }
  if (isNaN(months)) {
    return new EngineError('EOMONTH', ERROR_VALUE, arguments);
  }
  months = parseInt(months, 10);
  return serial(
    new Date(startDate.getFullYear(), startDate.getMonth() + months + 1, 0)
  );
}

/**
 * Returns the hour of a time value. The hour is given as an integer, ranging
 * from 0 (12:00 A.M.) to 23 (11:00 P.M.).
 *
 * @param serialNumber The time that contains the hour you want to find.
 */
export function HOUR(serialNumber) {
  serialNumber = utils.parseDate(serialNumber);
  if (serialNumber instanceof EngineError) {
    return serialNumber;
  }
  return serialNumber.getHours();
}

// TODO NOT_DEFINED in https://support.office.com/
export function INTERVAL(second) {
  if (typeof second !== 'number' && typeof second !== 'string') {
    return new EngineError('INTERVAL', ERROR_VALUE, arguments);
  } else {
    // @ts-ignore
    second = parseInt(second, 10);
  }

  var year = Math.floor(second / 946080000);
  second = second % 946080000;
  var month = Math.floor(second / 2592000);
  second = second % 2592000;
  var day = Math.floor(second / 86400);
  second = second % 86400;

  var hour = Math.floor(second / 3600);
  second = second % 3600;
  var min = Math.floor(second / 60);
  second = second % 60;
  var sec = second;

  // @ts-ignore
  year = year > 0 ? year + 'Y' : '';
  // @ts-ignore
  month = month > 0 ? month + 'M' : '';
  // @ts-ignore
  day = day > 0 ? day + 'D' : '';
  // @ts-ignore
  hour = hour > 0 ? hour + 'H' : '';
  // @ts-ignore
  min = min > 0 ? min + 'M' : '';
  sec = sec > 0 ? sec + 'S' : '';

  return 'P' + year + month + day + 'T' + hour + min + sec;
}

/**
 * Returns number of the ISO week number of the year for a given date.
 *
 * @param date Date is the date-time code used by Excel for date and time
 * calculation.
 */
export function ISOWEEKNUM(date) {
  date = utils.parseDate(date);
  if (date instanceof EngineError) {
    return date;
  }
  date.setHours(0, 0, 0);
  date.setDate(date.getDate() + 4 - (date.getDay() || 7));
  const yearStart = new Date(date.getFullYear(), 0, 1);
  // @ts-ignore
  return Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
}

/**
 * Returns the minutes of a time value. The minute is given as an integer,
 * ranging from 0 to 59.
 *
 * @param serialNumber The time that contains the minute you want to find.
 */
export function MINUTE(serialNumber) {
  serialNumber = utils.parseDate(serialNumber);
  if (serialNumber instanceof EngineError) {
    return serialNumber;
  }
  return serialNumber.getMinutes();
}

/**
 * Returns the month of a date represented by a serial number. The month is
 * given as an integer, ranging from 1 (January) to 12 (December).
 *
 * @param serialNumber The date of the month you are trying to find.
 */
export function MONTH(serialNumber) {
  serialNumber = utils.parseDate(serialNumber);
  if (serialNumber instanceof EngineError) {
    return serialNumber;
  }
  return serialNumber.getMonth() + 1;
}

/**
 * Returns the number of whole working days between start_date and end_date.
 * Working days exclude weekends and any dates identified in holidays.
 *
 * @param startDate A date that represents the start date.
 * @param endDate A date that represents the end date.
 * @param holidays Optional. An optional range of one or more dates to exclude
 * from the working calendar, such as state and federal holidays and floating
 * holidays. The list can be either a range of cells that contains the dates or
 * an array constant of the serial numbers that represent the dates.
 */
export function NETWORKDAYS(startDate, endDate, holidays) {
  return NETWORKDAYS_INTL(startDate, endDate, 1, holidays);
}

/**
 * Returns the number of whole workdays between two dates using parameters to
 * indicate which and how many days are weekend days.
 *
 * @param startDate
 * @param endDate
 * @param weekend
 * @param holidays
 */
export function NETWORKDAYS_INTL(startDate, endDate, weekend, holidays) {
  startDate = utils.parseDate(startDate);

  if (startDate instanceof EngineError) {
    return startDate;
  }
  endDate = utils.parseDate(endDate);

  if (endDate instanceof EngineError) {
    return endDate;
  }
  if (weekend === undefined) {
    weekend = WEEKEND_TYPES[1];
  } else {
    weekend = WEEKEND_TYPES[weekend];
  }
  if (!(weekend instanceof Array)) {
    return new EngineError('NETWORKDAYS.INTL', ERROR_VALUE, arguments);
  }
  if (holidays === undefined) {
    holidays = [];
  } else if (!(holidays instanceof Array)) {
    holidays = [holidays];
  }

  for (var i = 0; i < holidays.length; i++) {
    var h = utils.parseDate(holidays[i]);
    if (h instanceof EngineError) {
      return h;
    }
    holidays[i] = h;
  }
  var days = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1;
  var total = days;
  var day = startDate;
  for (i = 0; i < days; i++) {
    var d = new Date().getTimezoneOffset() > 0 ? day.getUTCDay() : day.getDay();
    var dec = false;
    if (d === weekend[0] || d === weekend[1]) {
      dec = true;
    }
    for (var j = 0; j < holidays.length; j++) {
      var holiday = holidays[j];
      if (
        holiday.getDate() === day.getDate() &&
        holiday.getMonth() === day.getMonth() &&
        holiday.getFullYear() === day.getFullYear()
      ) {
        dec = true;
        break;
      }
    }
    if (dec) {
      total--;
    }
    day.setDate(day.getDate() + 1);
  }

  return total;
}

/**
 * Returns the serial number of the current date and time.
 */
export function NOW() {
  return new Date();
}

/**
 * Returns the seconds of a time value. The second is given as an integer in the
 * range 0 (zero) to 59.
 *
 * @param serialNumber The time that contains the seconds you want to find.
 */
export function SECOND(serialNumber) {
  serialNumber = utils.parseDate(serialNumber);
  if (serialNumber instanceof EngineError) {
    return serialNumber;
  }
  return serialNumber.getSeconds();
}

/**
 * Returns the decimal number for a particular time.
 *
 * @param hour A number from 0 (zero) to 32767 representing the hour. Any value
 * greater than 23 will be divided by 24 and the remainder will be treated as
 * the hour value.
 * @param minute A number from 0 to 32767 representing the minute. Any value
 * greater than 59 will be converted to hours and minutes.
 * @param second A number from 0 to 32767 representing the second. Any value
 * greater than 59 will be converted to hours, minutes, and seconds
 */
export function TIME(hour, minute, second) {
  hour = utils.parseNumber(hour);
  minute = utils.parseNumber(minute);
  second = utils.parseNumber(second);
  if (utils.anyIsError(hour, minute, second)) {
    return new EngineError('TIME', ERROR_VALUE, arguments);
  }
  if (hour < 0 || minute < 0 || second < 0) {
    return new EngineError('TIME', ERROR_NUM, arguments);
  }

  return (3600 * hour + 60 * minute + second) / 86400;
}

/**
 * Returns the decimal number of the time represented by a text string.
 *
 * @param timeText A text string that represents a time in any one of the
 * Microsoft Excel time formats
 */
export function TIMEVALUE(timeText) {
  timeText = utils.parseDate(timeText);
  if (timeText instanceof EngineError) {
    return timeText;
  }
  return (
    (3600 * timeText.getHours() +
      60 * timeText.getMinutes() +
      timeText.getSeconds()) /
    86400
  );
}

// Returns the serial number of the current date.
export function TODAY() {
  return new Date();
}

/**
 * Returns the day of the week corresponding to a date. The day is given as an
 * integer, ranging from 1 (Sunday) to 7 (Saturday), by default.
 *
 * @param serialNumber A sequential number that represents the date of the day
 * you are trying to find.
 * @param return_type  Optional. A number that determines the type of return
 * value.
 */
export function WEEKDAY(serialNumber, return_type) {
  serialNumber = utils.parseDate(serialNumber);
  if (serialNumber instanceof EngineError) {
    return serialNumber;
  }
  if (return_type === undefined) {
    return_type = 1;
  }
  var day = serialNumber.getDay();
  return WEEK_TYPES[return_type][day];
}

/**
 * Returns the week number of a specific date. For example, the week containing
 * January 1 is the first week of the year, and is numbered week 1
 *
 * @param serialNumber A date within the week
 * @param returnType Optional. A number that determines on which day the week
 * begins. The default is 1.
 */
export function WEEKNUM(serialNumber, returnType) {
  serialNumber = utils.parseDate(serialNumber);
  if (serialNumber instanceof EngineError) {
    return serialNumber;
  }
  if (returnType === undefined) {
    returnType = 1;
  }
  if (returnType === 21) {
    return ISOWEEKNUM(serialNumber);
  }
  var week_start = WEEK_STARTS[returnType];
  var jan = new Date(serialNumber.getFullYear(), 0, 1);
  var inc = jan.getDay() < week_start ? 1 : 0;
  // @ts-ignore
  jan -= Math.abs(jan.getDay() - week_start) * 24 * 60 * 60 * 1000;
  // @ts-ignore
  return Math.floor((serialNumber - jan) / (1000 * 60 * 60 * 24) / 7 + 1) + inc;
}

/**
 * Returns a number that represents a date that is the indicated number of
 * working days before or after a date (the starting date).
 *
 * @param startDate A date that represents the start date.
 * @param days The number of nonweekend and nonholiday days before or after
 * start_date. A positive value for days yields a future date; a negative value
 * yields a past date.
 * @param holidays  Optional. An optional list of one or more dates to exclude
 * from the working calendar, such as state and federal holidays and floating
 * holidays. The list can be either a range of cells that contain the dates or
 * an array constant of the serial numbers that represent the dates.
 */
export function WORKDAY(startDate, days, holidays) {
  return WORKDAY_INTL(startDate, days, 1, holidays);
}

/**
 * Returns the serial number of the date before or after a specified number of
 * workdays with custom weekend parameters.
 *
 * @param startDate The start date, truncated to integer.
 * @param days The number of workdays before or after the start_date.
 * @param weekend Optional. Indicates the days of the week that are weekend days
 * and are not considered working days. Weekend is a weekend number or string
 * that specifies when weekends occur.
 * @param holidays  Optional. An optional set of one or more dates that are to
 * be excluded from the working day calendar. Holidays shall be a range of cells
 * that contain the dates, or an array constant of the serial values that
 * represent those dates. The ordering of dates or serial values in holidays
 * can be arbitrary.
 */
export function WORKDAY_INTL(startDate, days, weekend, holidays) {
  startDate = utils.parseDate(startDate);
  if (startDate instanceof EngineError) {
    return startDate;
  }
  days = utils.parseNumber(days);
  if (days instanceof EngineError) {
    return days;
  }
  if (days < 0) {
    return new EngineError('WORKDAY.INTL', ERROR_NUM, arguments);
  }
  if (weekend === undefined) {
    weekend = WEEKEND_TYPES[1];
  } else {
    weekend = WEEKEND_TYPES[weekend];
  }
  if (!(weekend instanceof Array)) {
    return new EngineError('WORKDAY.INTL', ERROR_VALUE, arguments);
  }
  if (holidays === undefined) {
    holidays = [];
  } else if (!(holidays instanceof Array)) {
    holidays = [holidays];
  }
  for (let i = 0; i < holidays.length; i += 1) {
    var h = utils.parseDate(holidays[i]);
    if (h instanceof EngineError) {
      return h;
    }
    holidays[i] = h;
  }
  let d = 0;
  while (d < days) {
    startDate.setDate(startDate.getDate() + 1);
    const day = startDate.getDay();
    if (day === weekend[0] || day === weekend[1]) {
      continue;
    }
    for (let j = 0; j < holidays.length; j += 1) {
      const holiday = holidays[j];
      if (
        holiday.getDate() === startDate.getDate() &&
        holiday.getMonth() === startDate.getMonth() &&
        holiday.getFullYear() === startDate.getFullYear()
      ) {
        d--;
        break;
      }
    }
    d++;
  }

  return startDate;
}

/**
 * Returns the year corresponding to a date. The year is returned as an integer
 * in the range 1900-9999.
 *
 * @param serialNumber The date of the year you want to find. Dates should be
 * entered by using the DATE function, or as results of other formulas or
 * functions. For example, use DATE(2008,5,23) for the 23rd day of May, 2008.
 * Problems can occur if dates are entered as text.
 */
export function YEAR(serialNumber) {
  serialNumber = utils.parseDate(serialNumber);
  if (serialNumber instanceof EngineError) {
    return serialNumber;
  }
  return serialNumber.getFullYear();
}

function isLeapYear(year) {
  return new Date(year, 1, 29).getMonth() === 1;
}

// TODO : Use DAYS ?
function daysBetween(startDate, endDate) {
  return Math.ceil((startDate - endDate) / 1000 / 60 / 60 / 24);
}

/**
 * YEARFRAC calculates the fraction of the year represented by the number of
 * whole days between two dates (the start_date and the end_date).
 *
 * @param startDate A date that represents the start date.
 * @param endDate A date that represents the end date.
 * @param basis Optional. The type of day count basis to use.
 */
export function YEARFRAC(startDate, endDate, basis) {
  startDate = utils.parseDate(startDate);
  if (startDate instanceof EngineError) {
    return startDate;
  }

  endDate = utils.parseDate(endDate);
  if (endDate instanceof EngineError) {
    return endDate;
  }

  basis = basis || 0;
  var sd = startDate.getDate();
  var sm = startDate.getMonth() + 1;
  var sy = startDate.getFullYear();
  var ed = endDate.getDate();
  var em = endDate.getMonth() + 1;
  var ey = endDate.getFullYear();

  switch (basis) {
    case 0:
      // US (NASD) 30/360
      if (sd === 31 && ed === 31) {
        sd = 30;
        ed = 30;
      } else if (sd === 31) {
        sd = 30;
      } else if (sd === 30 && ed === 31) {
        ed = 30;
      }
      return (ed + em * 30 + ey * 360 - (sd + sm * 30 + sy * 360)) / 360;
    case 1:
      // Actual/actual
      var feb29Between = function (date1, date2) {
        var year1 = date1.getFullYear();
        var mar1year1 = new Date(year1, 2, 1);
        if (isLeapYear(year1) && date1 < mar1year1 && date2 >= mar1year1) {
          return true;
        }
        var year2 = date2.getFullYear();
        var mar1year2 = new Date(year2, 2, 1);
        return isLeapYear(year2) && date2 >= mar1year2 && date1 < mar1year2;
      };
      var ylength = 365;
      if (
        sy === ey ||
        (sy + 1 === ey && (sm > em || (sm === em && sd >= ed)))
      ) {
        if (
          (sy === ey && isLeapYear(sy)) ||
          feb29Between(startDate, endDate) ||
          (em === 1 && ed === 29)
        ) {
          ylength = 366;
        }
        return daysBetween(startDate, endDate) / ylength;
      }
      var years = ey - sy + 1;
      // @ts-ignore
      var days =
        // @ts-ignore
        (new Date(ey + 1, 0, 1) - new Date(sy, 0, 1)) / 1000 / 60 / 60 / 24;
      var average = days / years;
      return daysBetween(startDate, endDate) / average;
    case 2:
      // Actual/360
      return daysBetween(startDate, endDate) / 360;
    case 3:
      // Actual/365
      return daysBetween(startDate, endDate) / 365;
    case 4:
      // European 30/360
      return (ed + em * 30 + ey * 360 - (sd + sm * 30 + sy * 360)) / 360;
  }
}

function serial(date) {
  const addOn = date > -2203891200000 ? 2 : 1;
  return (date - +d1900) / 86400000 + addOn;
}
