const nl_NL = {
  APP_NAME: "Spreax",
  DOCUMENT_TITLE: "Spreax Portal",
  LABELS: {
    USERNAME: "Gebruikersnaam",
    PASSWORD: "Wachtwoord",
    NAME: "Naam",
    EMAIL: "E-mailadres",
    NEW_PASSWORD: "Nieuw Wachtwoord",
    TITLE: "Titel",
    DESCRIPTION: "Beschrijving"
  },
  LAYERS: {
    PORTAL: "Mijn Portal",
    GROUPS: "Groepen",
    PROJECTS: "Projecten",
    MODELS: "Modellen",
    TAB: "Tab"
  },
  ACTIONS: {
    SELECT: "Selecteren",
    SAVE: "Opslaan",
    REMOVE: "Verwijderen",
    CREATE: "Aanmaken",
    EDIT: "Bewerken",
    DUPLICATE: "Dupliceren",
    RESET: "Resetten",
    CANCEL: "Cancel",
    PRINT: "Afdrukken (beta)",
    LOGIN: "Inloggen",
    LOGOUT: "Uitloggen",
    NEXT: "Volgende",
    BACK: "Terug",
    PREVIEW: "Preview",
    PUBLISH: "Publiceren",
    NEW_PROJECT: "Nieuw Project",
    EXPORT: "Exporteren",
    SETTINGS: "Instellingen",
    RESTORE_BACKUP: "Herstel backup",
    RENAME_TAB: "Hernoem tab",
    FINISH: "Afronden"
  },
  SETTINGS: {
    CLOSE_DIALOG_ON_RANGE_SELECT_LABEL: "Builder sluiten bij selecteren range"
  },
  RESTORE_BACKUP: {
    TEXT: "Herstel de laatste backup (bij catastrofale fout)",
    BUTTON_TEXT: "Herstel"
  },
  ADDIN: {
    START_DESIGNING: "Start met ontwerpen",
    OPEN_BUILDER_TO_START: "Open de Builder om te beginnen met ontwerpen",
    SUBMIT_RANGE: "Stuur range",
    PICK_RANGE: "Kies een range",
    SELECTED_RANGE: "Je hebt geselecteerd",
    SELECTED_RANGE_INVALID: "Selecteer een enkele cell",
    OR_CANCEL: "Of annuleer",
    INITIAL: {
      TITLE: "Publiceer je Spreadsheet als online rekentool",
      BEFORE_DESCRIPTION: "Zonder technische kennis, altijd aanpasbaar en 10x goedkoper dan uitbesteden.",
      AFTER_DESCRIPTION: 'Nog geen Spreax Account? Ontdek op <a href="https://www.spreax.com/" target="_blank">spreax.com</a> hoe makkelijk het is.'
    },
    BUILDING: {
      TITLE: "[BUILD MODE]",
      DESCRIPTION: "Gebruik de builder om je app te maken."
    },
    SELECT_RANGE: {
      TITLE: "Selecteer de cel die je wilt koppelen",
      DESCRIPTION: "Deze cel zal worden gebruikt als referentie voor het element"
    },
    SIGN_UP:
      'Nog geen Spreax account? <a href="https://www.spreax.com/">Meld je hier aan</a>',
    CONTACT_US:
      'We staan altijd voor je klaar via <b>j.de.graaf@spreax.com</b> en <b style="white-space: nowrap">+31 6 43716366</b>.'
  },
  BUILDER: {
    LOGIN: {
      TITLE: "Login with your Spreax Account",
      SUBTITLE: "Use the credentials you received from your Spreax contact."
    },
    EMBED: {
      TITLE: "Embed code",
      DESCRIPTION:
        "You can change the primary color by adding the color property to the url, e.g.: color=0000ff",
      SUCCESS_MESSAGE: "Copied embed-code to clipboard",
      COPY_BUTTON: "Copy"
    },
    MY_TEMPLATES: {
      TITLE: "Mijn Templates",
      SUBTITLE: "BUILDER.MY_TEMPLATES.SUBTITLE"
    },
    ONBOARDING: {
      CREATE_TAB: {
        TITLE: "BUILDER.ONBOARDING.CREATE_TAB.TITLE",
        DESCRIPTION: "BUILDER.ONBOARDING.CREATE_TAB.DESCRIPTION"
      },
      CREATE_STRIP: {
        TITLE: "BUILDER.ONBOARDING.CREATE_STRIP.TITLE",
        DESCRIPTION: "BUILDER.ONBOARDING.CREATE_STRIP.DESCRIPTION"
      },
      CREATE_ELEMENT: {
        TITLE: "BUILDER.ONBOARDING.CREATE_ELEMENT.TITLE",
        DESCRIPTION: "BUILDER.ONBOARDING.CREATE_ELEMENT.DESCRIPTION"
      },
      PREVIEW: {
        TITLE: "BUILDER.ONBOARDING.PREVIEW.TITLE",
        DESCRIPTION: "BUILDER.ONBOARDING.PREVIEW.DESCRIPTION"
      },
      ELEMENT_EDITOR: {
        TITLE: "BUILDER.ONBOARDING.ELEMENT_EDITOR.TITLE",
        DESCRIPTION: "BUILDER.ONBOARDING.ELEMENT_EDITOR.DESCRIPTION"
      }
    }
  },
  PUBLISHER: {
    META: {
      UPDATED_AT: "Laatst aangepast op"
    },
    FOOTER: {
      POWERED_BY: "Powered by"
    }
  },
  FEEDBACK: {
    CHANGES_SAVED: "Wijzigingen opgeslagen",
    PROJECT_CREATED: "Project aangemaakt",
    PROJECT_DEACTIVATED: "Project gedeactiveerd",
    INVALID_CREDENTIALS: "Geen geldig gebruikersnaam of wachtwoord",
    CONFIRM_DELETE_PROJECT: "Weet u zeker dat u dit project wilt verwijderen?",
    CONFIRM_RESET_PROJECT: "Weet u zeker dat u dit project wilt resetten?",
    CONFIRM_PRINT_PROJECT:
      "U staat op het print om dit project te exporteren naar PDF, hiervoor wordt een nieuw venster geopend en kan enige tijd duren.",
    CONFIRM_UNSAVED_CHANGES:
      "Niet alle wijzigingen zijn opgeslagen, weet u zeker dat u wilt doorgaan?",
    EXCEPTION: "Er is iets misgegaan."
  },
  PROJECTS: {
    NO_PROJECTS: "Er zijn nog geen projecten",
    BASED_ON: "Gebasseerd op"
  },
  MODELS: {
    NO_MODELS: "Er zijn nog geen modellen"
  },
  LOGIN: {
    DOCUMENT_TITLE: "Inloggen",
    PRIMARY_TITLE: "Welcome to Spreax",
    PRIMARY_SUBTITLE: "Unleash the true power of spreadsheets",
    SECONDARY_TITLE: "Sign in to continue",
    SECONDARY_SUBTITLE:
      "You don't have an account yet? Contact us at spreax@bbn.nl"
  },
  PROFILE: {
    DOCUMENT_TITLE: "Account",
    PRIMARY_TITLE: "Uw Account",
    CHANGE_PASSWORD: "Wachtwoord aanpassen",
    CHANGE_LANGUAGE: "Taal instellen"
  },
  OVERVIEW: {
    DOCUMENT_TITLE: "Overzicht"
  },
  ACCOUNTS: {
    PRIMARY_TITLE: "Accountoverzicht"
  },
  ATTACHMENTS: {
    RELATED_ATTACHMENTS: "Gerelateerde bijlagen",
    NO_ATTACHMENTS: "Geen bijlagen"
  },
  CONFIGURE_MODEL: {
    DOCUMENT_TITLE: "Configuratie",
    PRIMARY_TITLE: "Model configureren",
    NAME: "Naam",
    DESCRIPTION: "Beschrijving",
    IS_HIDDEN: "Verborgen",
    IS_LOCKED: "Bescherm tegen overschrijven",
    IS_PUBLIC: "Publiek toegankelijk",
    SUPPORTS_PDF: "Ondersteund PDF"
  },
  FILE: {
    FILE_FOUND: {
      TITLE: "Uw bestand wordt gedownload",
      SUBTITLE: "Na het downloaden kunt u dit venster sluiten."
    },
    FILE_NOT_FOUND: {
      TITLE: "Bestand niet gevonden",
      SUBTITLE:
        "Denkt u dit dat gaat om een fout? Neem dan contact op met uw contactpersoon."
    }
  },
  FILE_BROWSER: {
    NO_CONTENT: "Er zijn geen bestanden in deze map",
    DROP_FILE: "Verplaats bestand",
    FILES: "Bestanden",
    FILE: "Bestand"
  },
  UPLOAD_ATTACHMENT: {
    DRAG_TO_UPLOAD: "Sleep bestanden om te uploaden"
  },
  CHIPS_COMPONENT: {
    MORE: "meer"
  },
  SELECT_TENANT: {
    TITLE: "Selecteer Werkomgeving"
  },
  ERRORS: {
    404: {
      TITLE: "404",
      MESSAGE: "Niet gevonden"
    },
    DEFAULT: {
      TITLE: "Oops",
      MESSAGE: "Er is iets misgegaan"
    }
  },
  EMAIL_TEMPLATES: {
    INVITE: {
      SUBJECT: "Welcome to Spreax",
      BEFORE_BODY:
        "Leuk dat je aanwezig was bij de Grote Club. Hieronder vindt je de gegevens waarmee je kunt inloggen en Spreax zelf kan proberen.",
      AFTER_BODY:
        "Mocht je vragen hebben over Spreax, een demonstratie willen, of heb je misschien al direct een mooie use case, neem dan contact op met Milan Saleh."
    }
  },
  CARD_COMPONENT: {
    MORE: "Meer"
  }
};

const en_US = {
  APP_NAME: "Spreax",
  DOCUMENT_TITLE: "Spreax Portal",
  LABELS: {
    USERNAME: "Username",
    PASSWORD: "Password",
    NAME: "Name",
    EMAIL: "Email",
    NEW_PASSWORD: "New Password",
    TITLE: "Title",
    DESCRIPTION: "Description"
  },
  LAYERS: {
    PORTAL: "My Portal",
    GROUPS: "Groups",
    PROJECTS: "Projects",
    MODELS: "Models",
    TAB: "Tab"
  },
  ACTIONS: {
    SELECT: "Select",
    SAVE: "Save changes",
    REMOVE: "Delete",
    CREATE: "Create",
    EDIT: "Edit",
    DUPLICATE: "Duplicate",
    RESET: "Reset",
    CANCEL: "Cancel",
    PRINT: "Print",
    LOGIN: "Login",
    LOGOUT: "Log out",
    NEXT: "Next",
    BACK: "Back",
    PREVIEW: "Preview",
    PUBLISH: "Publish",
    NEW_PROJECT: "New Project",
    EXPORT: "Export",
    SETTINGS: "Settings",
    RESTORE_BACKUP: "Restore backup",
    RENAME_TAB: "Rename tab",
    FINISH: "Finish"
  },
  SETTINGS: {
    CLOSE_DIALOG_ON_RANGE_SELECT_LABEL: "Close builder when selecting range"
  },
  RESTORE_BACKUP: {
    TEXT: "Recover the latest backup (in case of catastrophic failure)",
    BUTTON_TEXT: "Recover"
  },
  ADDIN: {
    START_DESIGNING: "Start designing",
    OPEN_BUILDER_TO_START: "Open the Builder to start designing",
    SUBMIT_RANGE: "Submit range",
    PICK_RANGE: "Please pick a range",
    SELECTED_RANGE: "You selected",
    SELECTED_RANGE_INVALID: "Select a single cell",
    OR_CANCEL: "Or cancel",
    INITIAL: {
      TITLE: "Publish your spreadsheets as an online calculation tool",
      BEFORE_DESCRIPTION:
        "No technical knowledge required, always adjustable and 10x cheaper than outsourcing.",
      AFTER_DESCRIPTION:
        'Don\'t have a Spreax account yet? Discover how easy it is for free on <a href="https://www.spreax.com/" target="_blank">spreax.com</a>.'
    },
    BUILDING: {
      TITLE: "[BUILD MODE]",
      DESCRIPTION: "Use the builder to create your app."
    },
    SELECT_RANGE: {
      TITLE: "Select a single cell you want to connect",
      DESCRIPTION:
        "The value of this cell will be shown in the input and changes to this value will affect the calculation."
    },
    SIGN_UP:
      'Don\'t have an Spreax account yet? <a href="https://www.spreax.com/">Sign up here</a>',
    CONTACT_US:
      'You can always contact us for questions via <b>info@spreax.com</b> and <b style="white-space: nowrap">+31 (0) 88 - 226 24 68</b>'
  },
  BUILDER: {
    LOGIN: {
      TITLE: "Login with your Spreax Account",
      SUBTITLE: ""
    },
    EMBED: {
      TITLE: "Embed code",
      DESCRIPTION:
        "You can use this code to embed the widget on your website. The primary color can be customized by appending the `color` parameter to the iframe src URL, for example 'color=0000ff'",
      SUCCESS_MESSAGE: "De embed code staat nu in je clipboard",
      COPY_BUTTON: "Kopieer"
    },
    MY_TEMPLATES: {
      TITLE: "My Templates",
      SUBTITLE: "BUILDER.MY_TEMPLATES.SUBTITLE"
    },
    ONBOARDING: {
      CREATE_TAB: {
        TITLE: "Use Tabs to keep the canvas simple",
        DESCRIPTION: "Click the '+' icon to add a new tab"
      },
      CREATE_STRIP: {
        TITLE: "Use Strips to divide the canvas into columns",
        DESCRIPTION: "Click the 'Add Strip' button add and select a strip"
      },
      CREATE_ELEMENT: {
        TITLE: "Use Elements to view and input data",
        DESCRIPTION: "Click the '+' icon in the strip to add a new element"
      },
      ELEMENT_EDITOR: {
        TITLE: "Use the Element Editor to configure the element",
        DESCRIPTION: "The Editor allows you to connect the element to a cell in Excel"
      },
      PREVIEW: {
        TITLE: "See how your user would see your template",
        DESCRIPTION: "Click the preview button"
      }
    }
  },
  PUBLISHER: {
    META: {
      UPDATED_AT: "Last updated at"
    },
    FOOTER: {
      POWERED_BY: "Powered by"
    }
  },
  FEEDBACK: {
    CHANGES_SAVED: "Changes saved",
    PROJECT_CREATED: "Project created",
    PROJECT_DEACTIVATED: "Project deactivated",
    INVALID_CREDENTIALS: "Invalid username or password",
    CONFIRM_DELETE_PROJECT: "Are you sure you want to delete this project?",
    CONFIRM_RESET_PROJECT: "Are you sure you want to reset this project?",
    CONFIRM_PRINT_PROJECT:
      "Creating print of this project may take some time. ",
    CONFIRM_UNSAVED_CHANGES:
      "This project has unsaved changes, are you sure you want to continue?",
    EXCEPTION: "Something went wrong."
  },
  PROJECTS: {
    NO_PROJECTS: "No projects",
    BASED_ON: "Based upon"
  },
  MODELS: {
    NO_MODELS: "No models"
  },
  LOGIN: {
    DOCUMENT_TITLE: "Login",
    PRIMARY_TITLE: "Welcome to Spreax",
    PRIMARY_SUBTITLE: "Unleash the true power of spreadsheets",
    SECONDARY_TITLE: "Sign in to continue",
    SECONDARY_SUBTITLE:
      "You don't have an account yet? Contact us at info@spreax.com"
  },
  PROFILE: {
    DOCUMENT_TITLE: "Account",
    PRIMARY_TITLE: "Your account",
    CHANGE_PASSWORD: "Change password",
    CHANGE_LANGUAGE: "Change language"
  },
  OVERVIEW: {
    DOCUMENT_TITLE: "Overview"
  },
  ACCOUNTS: {
    PRIMARY_TITLE: "Account overview"
  },
  ATTACHMENTS: {
    RELATED_ATTACHMENTS: "Related attachments",
    NO_ATTACHMENTS: "No attachments"
  },
  CONFIGURE_MODEL: {
    DOCUMENT_TITLE: "Configuration",
    PRIMARY_TITLE: "Configure Template",
    NAME: "Name",
    DESCRIPTION: "Description",
    IS_HIDDEN: "Hidden",
    IS_LOCKED: "Locked",
    IS_PUBLIC: "Public",
    SUPPORTS_PDF: "Supports PDF export"
  },
  FILE: {
    FILE_FOUND: {
      TITLE: "File will be downloaded",
      SUBTITLE: "After the download has started, you can close this window."
    },
    FILE_NOT_FOUND: {
      TITLE: "File not found",
      SUBTITLE: "If you think is an error, please contact your account manager"
    }
  },
  FILE_BROWSER: {
    NO_CONTENT: "No files in this folder",
    DROP_FILE: "Drop file to move",
    FILES: "Files",
    FILE: "File"
  },
  UPLOAD_ATTACHMENT: {
    DRAG_TO_UPLOAD: "Drag files to upload"
  },
  CHIPS_COMPONENT: {
    MORE: "more"
  },
  SELECT_TENANT: {
    TITLE: "Select Tenant"
  },
  ERRORS: {
    404: {
      TITLE: "404",
      MESSAGE: "Not found"
    },
    DEFAULT: {
      TITLE: "Oops",
      MESSAGE: "Something went wrong"
    }
  },
  EMAIL_TEMPLATES: {
    INVITE: {
      SUBJECT: "EMAIL_TEMPLATES.INVITE.SUBJECT",
      BEFORE_BODY: "EMAIL_TEMPLATES.INVITE.BEFORE_BODY",
      AFTER_BODY: "EMAIL_TEMPLATES.INVITE.AFTER_BODY"
    }
  },
  CARD_COMPONENT: {
    MORE: "More"
  }
};

export const i18n = {
  APP_NAME: "APP_NAME",
  DOCUMENT_TITLE: "DOCUMENT_TITLE",
  LABELS: {
    USERNAME: "LABELS.USERNAME",
    PASSWORD: "LABELS.PASSWORD",
    NAME: "LABELS.NAME",
    EMAIL: "LABELS.EMAIL",
    NEW_PASSWORD: "LABELS.NEW_PASSWORD",
    TITLE: "LABELS.TITLE",
    DESCRIPTION: "LABELS.DESCRIPTION"
  },
  LAYERS: {
    PORTAL: "LAYERS.PORTAL",
    GROUPS: "LAYERS.GROUPS",
    PROJECTS: "LAYERS.PROJECTS",
    MODELS: "LAYERS.MODELS",
    TAB: "LAYERS.TAB"
  },
  ACTIONS: {
    SELECT: "ACTIONS.SELECT",
    SAVE: "ACTIONS.SAVE",
    REMOVE: "ACTIONS.REMOVE",
    CREATE: "ACTIONS.CREATE",
    EDIT: "ACTIONS.EDIT",
    DUPLICATE: "ACTIONS.DUPLICATE",
    RESET: "ACTIONS.RESET",
    CANCEL: "ACTIONS.CANCEL",
    PRINT: "ACTIONS.PRINT",
    LOGIN: "ACTIONS.LOGIN",
    LOGOUT: "ACTIONS.LOGOUT",
    NEXT: "ACTIONS.NEXT",
    BACK: "ACTIONS.BACK",
    PREVIEW: "ACTIONS.PREVIEW",
    PUBLISH: "ACTIONS.PUBLISH",
    NEW_PROJECT: "ACTIONS.NEW_PROJECT",
    EXPORT: "ACTIONS.EXPORT",
    SETTINGS: "ACTIONS.SETTINGS",
    RESTORE_BACKUP: "ACTIONS.RESTORE_BACKUP",
    RENAME_TAB: "ACTIONS.RENAME_TAB",
    FINISH: "ACTIONS.FINISH"
  },
  SETTINGS: {
    CLOSE_DIALOG_ON_RANGE_SELECT_LABEL:
      "SETTINGS.CLOSE_DIALOG_ON_RANGE_SELECT_LABEL"
  },
  RESTORE_BACKUP: {
    TEXT: "RESTORE_BACKUP.TEXT",
    BUTTON_TEXT: "RESTORE_BACKUP.BUTTON_TEXT"
  },
  ADDIN: {
    START_DESIGNING: "ADDIN.START_DESIGNING",
    OPEN_BUILDER_TO_START: "ADDIN.OPEN_BUILDER_TO_START",
    SUBMIT_RANGE: "ADDIN.SUBMIT_RANGE",
    PICK_RANGE: "ADDIN.PICK_RANGE",
    SELECTED_RANGE: "ADDIN.SELECTED_RANGE",
    SELECTED_RANGE_INVALID: "ADDIN.SELECTED_RANGE_INVALID",
    OR_CANCEL: "ADDIN.OR_CANCEL",
    INITIAL: {
      TITLE: "ADDIN.INITIAL.TITLE",
      BEFORE_DESCRIPTION: "ADDIN.INITIAL.BEFORE_DESCRIPTION",
      AFTER_DESCRIPTION: "ADDIN.INITIAL.AFTER_DESCRIPTION"
    },
    BUILDING: {
      TITLE: "ADDIN.BUILDING.TITLE",
      DESCRIPTION: "ADDIN.BUILDING.DESCRIPTION"
    },
    SELECT_RANGE: {
      TITLE: "ADDIN.SELECT_RANGE.TITLE",
      DESCRIPTION: "ADDIN.SELECT_RANGE.DESCRIPTION"
    },
    SIGN_UP: "ADDIN.SIGN_UP",
    CONTACT_US: "ADDIN.CONTACT_US"
  },
  BUILDER: {
    LOGIN: {
      TITLE: "BUILDER.LOGIN.TITLE",
      SUBTITLE: "BUILDER.LOGIN.SUBTITLE"
    },
    EMBED: {
      TITLE: "BUILDER.EMBED.TITLE",
      DESCRIPTION: "BUILDER.EMBED.DESCRIPTION",
      SUCCESS_MESSAGE: "BUILDER.EMBED.SUCCESS_MESSAGE",
      COPY_BUTTON: "BUILDER.EMBED.COPY_BUTTON"
    },
    MY_TEMPLATES: {
      TITLE: "BUILDER.MY_TEMPLATES.TITLE",
      SUBTITLE: "BUILDER.MY_TEMPLATES.SUBTITLE"
    },
    ONBOARDING: {
      CREATE_TAB: {
        TITLE: "BUILDER.ONBOARDING.CREATE_TAB.TITLE",
        DESCRIPTION: "BUILDER.ONBOARDING.CREATE_TAB.DESCRIPTION"
      },
      CREATE_STRIP: {
        TITLE: "BUILDER.ONBOARDING.CREATE_STRIP.TITLE",
        DESCRIPTION: "BUILDER.ONBOARDING.CREATE_STRIP.DESCRIPTION"
      },
      CREATE_ELEMENT: {
        TITLE: "BUILDER.ONBOARDING.CREATE_ELEMENT.TITLE",
        DESCRIPTION: "BUILDER.ONBOARDING.CREATE_ELEMENT.DESCRIPTION"
      },
      PREVIEW: {
        TITLE: "BUILDER.ONBOARDING.PREVIEW.TITLE",
        DESCRIPTION: "BUILDER.ONBOARDING.PREVIEW.DESCRIPTION"
      },
      ELEMENT_EDITOR: {
        TITLE: "BUILDER.ONBOARDING.ELEMENT_EDITOR.TITLE",
        DESCRIPTION: "BUILDER.ONBOARDING.ELEMENT_EDITOR.DESCRIPTION"
      }
    }
  },
  PUBLISHER: {
    META: {
      UPDATED_AT: "PUBLISHER.META.UPDATED_AT"
    },
    FOOTER: {
      POWERED_BY: "PUBLISHER.FOOTER.POWERED_BY"
    }
  },
  FEEDBACK: {
    CHANGES_SAVED: "FEEDBACK.CHANGES_SAVED",
    PROJECT_CREATED: "FEEDBACK.PROJECT_CREATED",
    PROJECT_DEACTIVATED: "FEEDBACK.PROJECT_DEACTIVATED",
    INVALID_CREDENTIALS: "FEEDBACK.INVALID_CREDENTIALS",
    CONFIRM_DELETE_PROJECT: "FEEDBACK.CONFIRM_DELETE_PROJECT",
    CONFIRM_RESET_PROJECT: "FEEDBACK.CONFIRM_RESET_PROJECT",
    CONFIRM_PRINT_PROJECT: "FEEDBACK.CONFIRM_PRINT_PROJECT",
    CONFIRM_UNSAVED_CHANGES: "FEEDBACK.CONFIRM_UNSAVED_CHANGES",
    EXCEPTION: "FEEDBACK.EXCEPTION"
  },
  PROJECTS: {
    NO_PROJECTS: "PROJECTS.NO_PROJECTS",
    BASED_ON: "PROJECTS.BASED_ON"
  },
  MODELS: {
    NO_MODELS: "MODELS.NO_MODELS"
  },
  LOGIN: {
    DOCUMENT_TITLE: "LOGIN.DOCUMENT_TITLE",
    PRIMARY_TITLE: "LOGIN.PRIMARY_TITLE",
    PRIMARY_SUBTITLE: "LOGIN.PRIMARY_SUBTITLE",
    SECONDARY_TITLE: "LOGIN.SECONDARY_TITLE",
    SECONDARY_SUBTITLE: "LOGIN.SECONDARY_SUBTITLE"
  },
  SELECT_TENANT: {
    TITLE: "SELECT_TENANT.TITLE"
  },
  PROFILE: {
    DOCUMENT_TITLE: "PROFILE.DOCUMENT_TITLE",
    PRIMARY_TITLE: "PROFILE.PRIMARY_TITLE",
    CHANGE_PASSWORD: "PROFILE.CHANGE_PASSWORD",
    CHANGE_LANGUAGE: "PROFILE.CHANGE_LANGUAGE"
  },
  OVERVIEW: {
    DOCUMENT_TITLE: "OVERVIEW.DOCUMENT_TITLE"
  },
  ACCOUNTS: {
    PRIMARY_TITLE: "ACCOUNTS.PRIMARY_TITLE"
  },
  ATTACHMENTS: {
    RELATED_ATTACHMENTS: "ATTACHMENTS.RELATED_ATTACHMENTS",
    NO_ATTACHMENTS: "ATTACHMENTS.NO_ATTACHMENTS"
  },
  CONFIGURE_MODEL: {
    DOCUMENT_TITLE: "CONFIGURE_MODEL.DOCUMENT_TITLE",
    PRIMARY_TITLE: "CONFIGURE_MODEL.PRIMARY_TITLE",
    NAME: "CONFIGURE_MODEL.NAME",
    DESCRIPTION: "CONFIGURE_MODEL.DESCRIPTION",
    IS_HIDDEN: "CONFIGURE_MODEL.IS_HIDDEN",
    IS_LOCKED: "CONFIGURE_MODEL.IS_LOCKED",
    IS_PUBLIC: "CONFIGURE_MODEL.IS_PUBLIC",
    SUPPORTS_PDF: "CONFIGURE_MODEL.SUPPORTS_PDF"
  },
  FILE: {
    FILE_FOUND: {
      TITLE: "FILE.FILE_FOUND.TITLE",
      SUBTITLE: "FILE.FILE_FOUND.SUBTITLE"
    },
    FILE_NOT_FOUND: {
      TITLE: "FILE.FILE_NOT_FOUND.TITLE",
      SUBTITLE: "FILE.FILE_NOT_FOUND.SUBTITLE"
    }
  },
  FILE_BROWSER: {
    NO_CONTENT: "FILE_BROWSER.NO_CONTENT",
    DROP_FILE: "FILE_BROWSER.DROP_FILE",
    FILES: "FILE_BROWSER.FILES",
    FILE: "FILE_BROWSER.FILE"
  },
  UPLOAD_ATTACHMENT: {
    DRAG_TO_UPLOAD: "UPLOAD_ATTACHMENT.DRAG_TO_UPLOAD"
  },
  CHIPS_COMPONENT: {
    MORE: "CHIPS_COMPONENT.MORE"
  },
  ERRORS: {
    404: {
      TITLE: "ERRORS.404.TITLE",
      MESSAGE: "ERRORS.404.MESSAGE"
    },
    DEFAULT: {
      TITLE: "ERRORS.DEFAULT.TITLE",
      MESSAGE: "ERRORS.DEFAULT.MESSAGE"
    }
  },
  EMAIL_TEMPLATES: {
    INVITE: {
      SUBJECT: "EMAIL_TEMPLATES.INVITE.SUBJECT",
      BEFORE_BODY: "EMAIL_TEMPLATES.INVITE.BEFORE_BODY",
      AFTER_BODY: "EMAIL_TEMPLATES.INVITE.AFTER_BODY"
    }
  },
  CARD_COMPONENT: {
    MORE: "CARD_COMPONENT.MORE"
  }
};

const LANGUAGES = {
  nl_NL,
  en_US
};

export let LANGUAGE = "nl_NL";

export const SUPPORTED_LANGUAGES = {
  en_US: "English (US)",
  nl_NL: "Nederlands"
};

export function __(path: string, lang: string = LANGUAGE) {
  let tmp = JSON.parse(JSON.stringify(LANGUAGES[lang]));
  path.split(".").forEach(arg => {
    tmp = tmp[arg];
  });
  return tmp;
}

export function setLanguage(name: string) {
  if (name && LANGUAGES[name]) {
    LANGUAGE = name;
  } else if (name && !LANGUAGES[name]) {
    throw new Error(`Invalid language: ${name}`);
  }
}

export function getLanguage() {
  return LANGUAGE;
}
