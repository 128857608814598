import { ReduceStore } from 'flux/utils';
import Dispatcher from '../dispatcher';
import { ActionTypes } from '../action-types';
import * as DataManager from '../data-managers';
import { uuid } from '@spreax/lib';
import { Project, NewProject } from '@spreax/model';

function onReceiveProjects(state: ProjectState, action): ProjectState {
  const { projects } = action;
  const updatedSelectedProject =
    state.selectedProject &&
    projects.find(project => project.id === state.selectedProject.id);
  const selectedProject = !state.hasUnsavedChanges
    ? updatedSelectedProject
    : state.selectedProject &&
      Object.assign({}, state.selectedProject, {
        attachments: updatedSelectedProject.attachments
      });
  return Object.assign({}, state, {
    projects,
    selectedProject,
    hasReceivedProjects: true,
    isFetchingProjects: false
  });
}

function onSelectProject(state: ProjectState, action): ProjectState {
  const projects = state.projects.map((project: any) => {
    project.isSelected = project.id === action.id;
    return project;
  });
  return Object.assign({}, state, {
    projects,
    selectedProject:
      state.projects.find(project => project.id === action.id) || null,
    hasUnsavedChanges: false
  });
}

function onCreateProject(state: ProjectState, action): ProjectState {
  const project: NewProject = {
    guid: uuid(),
    templateId: action.templateId,
    session: state.projectDraft ? state.projectDraft.session : null,
    title: `Untitled ${state.projects.length}`
  };
  DataManager.project.create(action.tenantId, project);
  return Object.assign({}, state, {
    hasUnsavedChanges: false
  });
}

function onDuplicateProject(state: ProjectState, action): ProjectState {
  const parentProject = state.projects.find(
    project => project.id === action.projectID
  );
  const project: NewProject = {
    guid: uuid(),
    templateId: action.templateId,
    session: parentProject.session,
    title: `${parentProject.title} Copy`
  };
  DataManager.project.create(action.tenantId, project);
  return Object.assign({}, state, {
    hasUnsavedChanges: false
  });
}

function onDraftProject(state: ProjectState, action): ProjectState {
  if (action.templateId === null) {
    return Object.assign({}, state, {
      projectDraft: null
    });
  }
  return Object.assign({}, state, {
    projectDraft: {
      templateId: action.templateId,
      session: action.session
    }
  });
}

function onSaveProject(state: ProjectState, action): ProjectState {
  const project = state.projects.find(project => project.id === action.id);
  DataManager.project.save(action.tenantId, project);
  return Object.assign({}, state, {
    hasUnsavedChanges: false
  });
}

function onUpdateProject(state: ProjectState, action): ProjectState {
  const project = state.projects.find(project => project.id === action.id);
  project[action.field] = action.value;
  const projects = state.projects.filter(
    _project => _project.id !== project.id
  );
  return Object.assign({}, state, {
    selectedProject: project,
    projects: [project, ...projects],
    hasUnsavedChanges: true
  });
}

function onDeactivateProject(state: ProjectState, action): ProjectState {
  const project = state.projects.find(project => project.id === action.id);
  DataManager.project.deactivate(action.tenantId, project);
  if (state.selectedProject.id === project.id) {
    return Object.assign({}, state, {
      selectedProject: null
    });
  }
  return state;
}

function onLogout(state: ProjectState, action): ProjectState {
  return {
    selectedProject: null,
    projects: [],
    projectDraft: null,
    hasUnsavedChanges: false,
    hasReceivedProjects: false,
    isFetchingProjects: false
  };
}

class ProjectStore extends ReduceStore<ProjectState, any> {
  constructor() {
    super(Dispatcher);
  }

  getInitialState() {
    return {
      selectedProject: null,
      projects: [],
      projectDraft: null,
      hasUnsavedChanges: false,
      hasReceivedProjects: false,
      isFetchingProjects: false
    };
  }

  reduce(state: ProjectState, action): ProjectState {
    switch (action.type) {
      case ActionTypes.RECEIVE_PROJECTS:
        return onReceiveProjects(state, action);

      case ActionTypes.SELECT_PROJECT:
        return onSelectProject(state, action);

      case ActionTypes.CREATE_PROJECT:
        return onCreateProject(state, action);

      case ActionTypes.DUPLICATE_PROJECT:
        return onDuplicateProject(state, action);

      case ActionTypes.DRAFT_PROJECT:
        return onDraftProject(state, action);

      case ActionTypes.SAVE_PROJECT:
        return onSaveProject(state, action);

      case ActionTypes.UPDATE_PROJECT:
        return onUpdateProject(state, action);

      case ActionTypes.DEACTIVATE_PROJECT:
        return onDeactivateProject(state, action);

      case ActionTypes.LOAD_PROJECTS:
        setTimeout(() => DataManager.project.get(action.tenantId), 0);
        return Object.assign({}, state, {
          isFetchingProjects: true
        });

      case ActionTypes.SELECT_TENANT:
      case ActionTypes.LOGOUT:
        return onLogout(state, action);

      default:
        return state;
    }
  }
}

export interface ProjectState {
  selectedProject: Project;
  projects: Project[];
  projectDraft: Project;
  hasUnsavedChanges: boolean;
  hasReceivedProjects: boolean;
  isFetchingProjects: boolean;
}

export const Store = new ProjectStore();
