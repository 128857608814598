import * as React from 'react';
import '../../../../src/pages/frame/sidepanel/style.scss';
import { State } from '../../../container';
import { __, i18n } from '@spreax/i18n';
import { List } from '../../../components';

export function Sidepanel(props: State) {
  const { actions, selectedTenant, profile, route } = props;
  return (
    <div className="sx-sidepanel">
      <div
        className="sx-sidepanel__logo"
        onClick={() => route.history.push('/')}
      >
        <img src="/static/img/logo-200--color.png" width="100" />
      </div>
      <div className="sx-sidepanel__menu">
        <List
          size="small"
          title="Drive"
          items={[
            {
              id: 'my-folders',
              primary: 'My Folders',
              onClick: () => route.history.push('/folder')
            }
          ]}
        />
      </div>
      {profile && profile.isAdmin && (
        <div className="sx-sidepanel__menu">
          <List
            size="small"
            title="Admin"
            items={[
              {
                id: 'settings/accounts',
                primary: 'Accounts',
                onClick: () => route.history.push('/accounts')
              }
            ]}
          />
        </div>
      )}
      <div className="sx-sidepanel__fill"></div>
      {selectedTenant && (
        <div className="sx-sidepanel__selected-tenant">
          <div className="sx-sidepanel__selected-tenant__logo">
            <img src={selectedTenant.logo} width="64" />
          </div>
          <div className="sx-sidepanel__selected-tenant__action">
            <i
              className="material-icons"
              onClick={() => actions.tenant.selectTenant(null)}
            >
              swap_horiz
            </i>
          </div>
        </div>
      )}
    </div>
  );
}
