import { EngineError, ERROR_NOT_AVAILABLE, ERROR_VALUE } from '../error';
import { ISERROR } from './information';
import { flatten } from './utils';
import { Value, ErrorValue, LogicalValue, NumberValue, TextValue } from '.';

/**
 * Tests if all arguments in argument-list are TRUE. The function evaluates all arguments prior to returning a value.
 *
 * @param {LogicalValue[]} _args
 * @returns {LogicalValue}
 */

export function AND(..._args: LogicalValue[]): LogicalValue {
  const args = flatten(_args);
  // FIXME: If no logical value, return `ERROR_VALUE`
  let result = true;
  for (let i = 0; i < args.length; i++) {
    if (!args[i]) {
      result = false;
    }
  }
  return result;
}

// export function CHOOSE (index, ...args) {
//   if (arguments.length < 2) {
//     return new EngineError('CHOOSE', ERROR_NOT_AVAILABLE, arguments);
//   }
//   if (index < 1 || index > 254) {
//     return new EngineError('CHOOSE', ERROR_VALUE, arguments);
//   }
//   if (arguments.length < index + 1) {
//     return new EngineError('CHOOSE', ERROR_VALUE, arguments);
//   }
//   return arguments[index];
// }

/**
 * Returns the logical value FALSE.
 *
 * @returns {LogicalValue}
 */

export function FALSE(): LogicalValue {
  return false;
}

/**
 * Returns value based on condition.
 *
 * @param {Value} test
 * @param {Value} thenValue
 * @param {Value} otherwiseValue
 * @returns {Value}
 */

export function IF(
  test: Value,
  thenValue: Value = true,
  otherwiseValue: Value = false
): Value {
  return test ? thenValue : otherwiseValue;
}

/**
 * Returns a value you specify if a formula evaluates to an error; otherwise, returns the result of the formula. Use the
 * IFERROR function to trap and handle errors in a formula.
 *
 * @param {Value} value
 * @param {Value} valueIfError
 * @returns {Value}
 */

export function IFERROR(value: Value, valueIfError: Value): Value {
  if (ISERROR(value)) {
    return valueIfError;
  }
  return value;
}

/**
 * Returns the value you specify if the formula returns the #N/A error value; otherwise returns the result of the formula.
 *
 * @param {Value} value
 * @param {Value} valueIfNA
 * @returns {Value}
 */

export function IFNA(value: Value, valueIfNA: Value): Value {
  return value instanceof EngineError && value.name === ERROR_NOT_AVAILABLE
    ? valueIfNA
    : value;
}

/**
 * Use the NOT function, one of the logical functions, when you want to make sure one value is not equal to another.
 *
 * @param {LogicalValue} logical
 * @returns {LogicalValue}
 */

export function NOT(logical: LogicalValue): LogicalValue {
  return !logical;
}

/**
 * Use the OR function, one of the logical functions, to determine if any conditions in a test are TRUE.
 *
 * @param {LogicalValue[]} _args
 * @returns {LogicalValue}
 */

export function OR(..._args: LogicalValue[]): LogicalValue {
  const args = flatten(_args);
  let result = false;
  for (let i = 0; i < args.length; i++) {
    if (args[i]) {
      result = true;
    }
  }
  return result;
}

/**
 * The SWITCH function evaluates one value (called the expression) against a list of values, and returns the result
 * corresponding to the first matching value. If there is no match, an optional default value may be returned.
 *
 * @param {Value} value
 * @param {Value[]} args
 * @returns {Value || ErrorValue}
 */

export function SWITCH(value: Value, ...args: Value[]): Value | ErrorValue {
  let result;

  if (args.length > 0) {
    const targetValue = value;
    const argc = args.length - 1;
    const switchCount = Math.floor(argc / 2);
    const hasDefaultClause = argc % 2 !== 0;
    const defaultClause = argc % 2 === 0 ? null : args[args.length - 1];
    let switchSatisfied = false;
    if (switchCount) {
      for (let index = 0; index < switchCount; index++) {
        if (targetValue === args[index * 2 + 1]) {
          result = args[index * 2 + 2];
          switchSatisfied = true;
          break;
        }
      }
    }
    if (!switchSatisfied) {
      result = hasDefaultClause
        ? defaultClause
        : new EngineError('SWITCH', ERROR_VALUE, arguments);
    }
  } else {
    result = new EngineError('SWITCH', ERROR_VALUE, arguments);
  }
  return result;
}

/**
 * Returns the logical value TRUE.
 *
 * @returns {LogicalValue}
 */

export function TRUE(): LogicalValue {
  return true;
}

/**
 * Returns a logical Exclusive Or of all arguments.
 *
 * @param {LogicalValue[]} _args
 * @returns {LogicalValue}
 */

export function XOR(..._args: LogicalValue[]): LogicalValue {
  const args = flatten(_args);
  let result = 0;
  for (let i = 0; i < args.length; i++) {
    if (args[i]) {
      result++;
    }
  }
  return !!(Math.floor(Math.abs(result)) & 1);
}
