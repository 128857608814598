import * as React from 'react';
import { State } from '../../container';
import { __, i18n } from '@spreax/i18n';
import {
  Drawer,
  DrawerTitle,
  DrawerDescription,
  DrawerRow,
  DrawerWrapper,
  List,
  UploadAttachment
} from '../../components';

export class TemplateDrawer extends React.PureComponent<State> {
  constructor(props) {
    super(props);
    this.onRemove = this.onRemove.bind(this);
    this.onDownload = this.onDownload.bind(this);
    this.onUpload = this.onUpload.bind(this);
  }

  onRemove(file) {
    const { actions } = this.props;
    return e => {
      e.stopPropagation();
      actions.attachment.remove(file.id);
    };
  }

  onDownload(file) {
    const { actions } = this.props;
    return () => {
      actions.attachment.download(file.id);
    };
  }

  onUpload(file) {
    const { actions, selectedTemplate, selectedProject } = this.props;
    const selectedContext = selectedProject || selectedTemplate;
    actions.attachment.upload(selectedContext.id, file.name, file.dataURL);
  }

  mapAttachment(showRemoveAction: boolean = true) {
    return (attachment, index) => {
      return {
        id: attachment.id,
        primary: attachment.title,
        actions: [
          showRemoveAction && (
            <i
              key={index}
              className="material-icons"
              onClick={this.onRemove(attachment)}
            >
              delete
            </i>
          )
        ]
      };
    };
  }

  render() {
    const {
      selectedTemplate,
      selectedProject,
      showContextDrawer,
      profile,
      className
    } = this.props;

    const projectAttachments =
      (selectedProject && selectedProject.attachments) || [];
    const templateAttachments =
      (selectedTemplate && selectedTemplate.attachments) || [];
    const files = [
      ...projectAttachments.map(this.mapAttachment()),
      ...templateAttachments.map(this.mapAttachment(profile && profile.isAdmin))
    ];

    return (
      <Drawer
        showContextDrawer={showContextDrawer}
        className="sx-template__drawer"
      >
        <DrawerTitle>Informatie over {selectedTemplate.title}</DrawerTitle>
        <DrawerDescription>{selectedTemplate.description}</DrawerDescription>
        <DrawerRow>
          <List
            title={__(i18n.ATTACHMENTS.RELATED_ATTACHMENTS)}
            noItemsMessage={__(i18n.ATTACHMENTS.NO_ATTACHMENTS)}
            items={files}
            onClick={this.onDownload}
          />
        </DrawerRow>
        <DrawerRow>
          <UploadAttachment onUpload={this.onUpload} />
        </DrawerRow>
      </Drawer>
    );
  }
}
