import { call, createFormData, getURL } from '..';

export namespace template {
  const PATH = 'templates';

  export function get(tenantId) {
    return call(getURL('tenants', tenantId, PATH), {
      method: 'GET'
    });
  }

  export function publicInformation(tenantId) {
    return call(getURL('templates', tenantId, 'public'), {
      method: 'GET'
    });
  }

  export function update(template) {
    const formData = createFormData(template);
    return call(getURL(PATH, template.id), {
      method: 'PATCH',
      body: formData
    });
  }

  export function create(tenantId, template) {
    const formData = createFormData(template);
    return call(getURL('tenants', tenantId, PATH), {
      method: 'POST',
      body: formData
    });
  }

  export function publish(id, payload) {
    return call(getURL(PATH, id, 'publish'), {
      method: 'POST',
      body: payload
    });
  }
}
