export const SupportedFunctions = [
  'ABS',
  'ACCRINT',
  'ACOS',
  'ACOSH',
  'ACOT',
  'ACOTH',
  'ADD',
  'AGGREGATE',
  'AND',
  'ARABIC',
  'ARGS2ARRAY',
  'ASIN',
  'ASINH',
  'ATAN',
  'ATAN2',
  'ATANH',
  'AVEDEV',
  'AVERAGE',
  'AVERAGEA',
  'AVERAGEIF',
  'AVERAGEIFS',
  'BASE',
  // 'BESSELI',
  // 'BESSELJ',
  // 'BESSELK',
  // 'BESSELY',
  'BETA.DIST',
  'BETA.INV',
  'BETADIST',
  'BETAINV',
  // 'BIN2DEC',
  // 'BIN2HEX',
  // 'BIN2OCT',
  'BINOM.DIST',
  'BINOM.DIST.RANGE',
  'BINOM.INV',
  'BINOMDIST',
  // 'BITAND',
  // 'BITLSHIFT',
  // 'BITOR',
  // 'BITRSHIFT',
  // 'BITXOR',
  'CEILING',
  'CEILINGMATH',
  'CEILINGPRECISE',
  // 'CHAR',
  'CHISQ.DIST',
  'CHISQ.DIST.RT',
  'CHISQ.INV',
  'CHISQ.INV.RT',
  // 'CHOOSE',
  // 'CHOOSE',
  // 'CLEAN',
  // 'CODE',
  'COLUMN',
  'COLUMNS',
  'COMBIN',
  'COMBINA',
  // 'COMPLEX',
  // 'CONCATENATE',
  // 'CONFIDENCE',
  'CONFIDENCE.NORM',
  'CONFIDENCE.T',
  // 'CONVERT',
  'CORREL',
  'COS',
  'COSH',
  'COT',
  'COTH',
  'COUNT',
  'COUNTA',
  'COUNTBLANK',
  'COUNTIF',
  'COUNTIFS',
  'COUNTIN',
  'COUNTUNIQUE',
  'COVARIANCE.P',
  'COVARIANCE.S',
  'CSC',
  'CSCH',
  'CUMIPMT',
  'CUMPRINC',
  'DATE',
  'DATEVALUE',
  'DAY',
  'DAYS',
  'DAYS360',
  'DB',
  'DDB',
  // 'DEC2BIN',
  // 'DEC2HEX',
  // 'DEC2OCT',
  'DECIMAL',
  'DEGREES',
  // 'DELTA',
  'DEVSQ',
  // 'DIVIDE',
  'DOLLAR',
  'DOLLARDE',
  'DOLLARFR',
  'E',
  'EDATE',
  'EFFECT',
  'EOMONTH',
  'EQ',
  // 'ERF',
  // 'ERFC',
  'EVEN',
  // 'EXACT',
  'EXPON.DIST',
  'EXPONDIST',
  'F.DIST',
  'F.DIST.RT',
  'F.INV',
  'F.INV.RT',
  'FACT',
  'FACTDOUBLE',
  // 'FALSE',
  'FDIST',
  'FDISTRT',
  // 'FIND',
  // 'FINV',
  'FINVRT',
  'FISHER',
  'FISHERINV',
  // 'FIXED',
  'FLATTEN',
  'FLOOR',
  'FORECAST',
  'FREQUENCY',
  'FV',
  'FVSCHEDULE',
  'GAMMA',
  'GAMMA.DIST',
  'GAMMA.INV',
  'GAMMADIST',
  'GAMMAINV',
  'GAMMALN',
  'GAMMALN.PRECISE',
  'GAUSS',
  'GCD',
  'GEOMEAN',
  // 'GESTEP',
  'GROWTH',
  'GTE',
  'HARMEAN',
  // 'HEX2BIN',
  // 'HEX2DEC',
  // 'HEX2OCT',
  'HOUR',
  // 'HTML2TEXT',
  'HYPGEOM.DIST',
  'HYPGEOMDIST',
  'IF',
  // 'IMABS',
  // 'IMAGINARY',
  // 'IMARGUMENT',
  // 'IMCONJUGATE',
  // 'IMCOS',
  // 'IMCOSH',
  // 'IMCOT',
  // 'IMCSC',
  // 'IMCSCH',
  // 'IMDIV',
  // 'IMEXP',
  // 'IMLN',
  // 'IMLOG10',
  // 'IMLOG2',
  // 'IMPOWER',
  // 'IMPRODUCT',
  // 'IMREAL',
  // 'IMSEC',
  // 'IMSECH',
  // 'IMSIN',
  // 'IMSINH',
  // 'IMSQRT',
  // 'IMSUB',
  // 'IMSUM',
  // 'IMTAN',
  'INDIRECT',
  'INDEX',
  'INT',
  'INTERCEPT',
  'INTERVAL',
  'IPMT',
  'IRR',
  // 'ISBINARY',
  'ISBLANK',
  // 'ISEVEN',
  // 'ISLOGICAL',
  // 'ISNONTEXT',
  // 'ISNUMBER',
  // 'ISODD',
  // 'ISODD',
  'ISOWEEKNUM',
  'ISPMT',
  'ISTEXT',
  'KURT',
  'LARGE',
  'LCM',
  // 'LEFT',
  'LEN',
  'LINEST',
  'LN',
  'LOG',
  'LOG10',
  'LOGEST',
  'LOGNORM.DIST',
  'LOGNORM.INV',
  'LOGNORMDIST',
  'LOGNORMINV',
  // 'LOWER',
  'LT',
  'LTE',
  'MATCH',
  'MAX',
  'MAXA',
  'MEDIAN',
  // 'MID',
  'MIN',
  'MINA',
  'MINUS',
  'MINUTE',
  'MIRR',
  'MOD',
  'MODE.MULT',
  'MODE.SNGL',
  'MODEMULT',
  'MODESNGL',
  'MONTH',
  'MROUND',
  'MULTINOMIAL',
  // 'MULTIPLY',
  'NA',
  'NE',
  'NEGBINOM.DIST',
  'NEGBINOMDIST',
  'NETWORKDAYS',
  'NOMINAL',
  'NORM.DIST',
  'NORM.INV',
  'NORM.S.DIST',
  'NORM.S.INV',
  'NORMDIST',
  'NORMINV',
  'NORMSDIST',
  'NORMSINV',
  'NOT',
  'NUMBERS',
  'NUMERAL',
  'NOW',
  'NPER',
  'NPV',
  // 'OCT2BIN',
  // 'OCT2DEC',
  // 'OCT2HEX',
  'ODD',
  'OR',
  'PDURATION',
  'PEARSON',
  'PERCENTILEEXC',
  'PERCENTILEINC',
  'PERCENTRANKEXC',
  'PERCENTRANKINC',
  'PERMUT',
  'PERMUTATIONA',
  'PHI',
  'PI',
  'PMT',
  'POISSON.DIST',
  'POISSONDIST',
  'POW',
  'POWER',
  'PPMT',
  'PROB',
  'PRODUCT',
  // 'PROPER',
  'PV',
  'QUARTILE.EXC',
  'QUARTILE.INC',
  'QUARTILEEXC',
  'QUARTILEINC',
  'QUOTIENT',
  'RADIANS',
  'RAND',
  'RANDBETWEEN',
  'RANK.AVG',
  'RANK.EQ',
  'RANKAVG',
  'RANKEQ',
  'RATE',
  'REFERENCE',
  // 'REGEXEXTRACT',
  // 'REGEXMATCH',
  // 'REGEXREPLACE',
  // 'REPLACE',
  // 'REPT',
  // 'RIGHT',
  'ROMAN',
  'ROUND',
  'ROUNDDOWN',
  'ROUNDUP',
  'ROW',
  'ROWS',
  'RRI',
  'RSQ',
  // 'SEARCH',
  'SEC',
  'SECH',
  'SECOND',
  'SERIESSUM',
  'SIGN',
  'SIN',
  'SINH',
  'SKEW',
  'SKEW.P',
  'SKEWP',
  'SLN',
  'SLOPE',
  'SMALL',
  // 'SPLIT',
  // 'SPLIT',
  'SQRT',
  'SQRTPI',
  'STANDARDIZE',
  'STDEV.P',
  'STDEV.S',
  'STDEVA',
  'STDEVP',
  'STDEVPA',
  'STDEVS',
  'STEYX',
  // 'SUBSTITUTE',
  'SUBTOTAL',
  'SUM',
  'SUMIF',
  'SUMIFS',
  'SUMPRODUCT',
  'SUMSQ',
  'SUMX2MY2',
  'SUMX2PY2',
  'SUMXMY2',
  // 'SWITCH',
  'SYD',
  // 'T',
  'T.DIST',
  'T.DIST.2T',
  'T.DIST.RT',
  'T.INV',
  'T.INV.2T',
  'TAN',
  'TANH',
  'TBILLEQ',
  'TBILLPRICE',
  'TBILLYIELD',
  'TDIST',
  'TDIST2T',
  'TDISTRT',
  'TEXT',
  'TIME',
  'TIMEVALUE',
  'TINV',
  'TINV2T',
  'TODAY',
  'TRANSPOSE',
  'TREND',
  // 'TRIM',
  // 'TRIMMEAN',
  // 'TRUE',
  'TRUNC',
  // 'UNICHAR',
  // 'UNICODE',
  'UNIQUE',
  // 'UPPER',
  // 'VALUE',
  'VAR.P',
  'VAR.S',
  'VARA',
  'VARP',
  'VARPA',
  // 'VARS',
  'WEEKDAY',
  'WEEKNUM',
  'WEIBULL.DIST',
  'WEIBULLDIST',
  'WORKDAY',
  // 'XIRR',
  'XNPV',
  // 'XOR',
  'YEAR',
  'YEARFRAC',
  'VLOOKUP',
  'HLOOKUP',
  'IFERROR',
];
