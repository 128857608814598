import {
  EngineError,
  ERROR_VALUE,
  ERROR_NOT_AVAILABLE,
  ERROR
} from "../../error";
import { parseNumber, parseNumberArray, flatten, anyIsError } from "../utils";

/**
 * Returns the least common multiple of integers. The least common multiple is
 * the smallest positive integer that is a multiple of all integer arguments
 * number1, number2, and so on. Use LCM to add fractions with different
 * denominators.
 *
 * @param number[] Number1, number2,...    Number1 is required, subsequent
 * numbers are optional. 1 to 255 values for which you want the least common
 * multiple. If value is not an integer, it is truncated.
 */
export function LCM(...args: any[]) {
  // Credits: Jonas Raoni Soares Silva
  var o = parseNumberArray(flatten(args));
  if (o instanceof Error) {
    return o;
  }
  for (var i, j, n, d, r = 1; (n = o.pop()) !== undefined; ) {
    while (n > 1) {
      if (n % 2) {
        for (i = 3, j = Math.floor(Math.sqrt(n)); i <= j && n % i; i += 2) {
          //empty
        }
        d = i <= j ? i : n;
      } else {
        d = 2;
      }
      for (
        n /= d, r *= d, i = o.length;
        i;
        o[--i] % d === 0 && (o[i] /= d) === 1 && o.splice(i, 1)
      ) {
        //empty
      }
    }
  }
  return r;
}

/**
 * Returns the natural logarithm of a number. Natural logarithms are based on
 * the constant e (2.71828182845904).
 *
 * @param number The positive real number for which you want the natural
 * logarithm.
 */
export function LN(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  return Math.log(number);
}

// TODO NOT_DEFINED in https://support.office.com/
export function LN10() {
  return Math.log(10);
}

// TODO NOT_DEFINED in https://support.office.com/
export function LN2() {
  return Math.log(2);
}

// TODO NOT_DEFINED in https://support.office.com/
export function LOG10E() {
  return Math.LOG10E;
}

// TODO NOT_DEFINED in https://support.office.com/
export function LOG2E() {
  return Math.LOG2E;
}

/**
 * Returns the logarithm of a number to the base you specify.
 *
 * @param number The positive real number for which you want the logarithm.
 * @param base The base of the logarithm. If base is omitted, it is assumed to
 * be 10.
 */
export function LOG(number, base) {
  number = parseNumber(number);
  base = parseNumber(base);
  if (anyIsError(number, base)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  base = base === undefined ? 10 : base;
  return Math.log(number) / Math.log(base);
}

/**
 * Returns the base-10 logarithm of a number.
 *
 * @param number The positive real number for which you want the base-10
 * logarithm.
 */
export function LOG10(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  return Math.log(number) / Math.log(10);
}

// TODO NOT_DEFINED in https://support.office.com/
export function LT(num1, num2) {
  if (arguments.length !== 2) {
    return new EngineError("LT", ERROR_NOT_AVAILABLE, arguments);
  }
  num1 = parseNumber(num1);
  num2 = parseNumber(num2);
  if (anyIsError(num1, num2)) {
    return new EngineError("{FUNCTION_NAME}", ERROR, arguments);
  }
  return num1 < num2;
}

// TODO NOT_DEFINED in https://support.office.com/
export function LTE(num1, num2) {
  if (arguments.length !== 2) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NOT_AVAILABLE, arguments);
  }

  num1 = parseNumber(num1);
  num2 = parseNumber(num2);
  if (anyIsError(num1, num2)) {
    return new EngineError("{FUNCTION_NAME}", ERROR, arguments);
  }

  return num1 <= num2;
}
