import { EngineError, ERROR_VALUE } from "../../error";
import { parseNumber, anyIsError } from "../utils";

/**
 * Converts a number into a text representation with the given radix (base).
 *
 * @param number The number that you want to convert. Must be an integer greater
 * than or equal to 0 and less than 2^53
 * @param radix The base radix that you want to convert the number into. Must be
 * an integer greater than or equal to 2 and less than or equal to 36.
 * @param minLength Optional. The minimum length of the returned string. Must be
 * an integer greater than or equal to 0.
 */
export function BASE(number, radix, minLength = 0) {
  number = parseNumber(number);
  radix = parseNumber(radix);
  // @ts-ignore
  minLength = parseNumber(minLength);
  if (anyIsError(number, radix, minLength)) {
    return new EngineError("BASE", ERROR_VALUE, arguments);
  }
  minLength = minLength === undefined ? 0 : minLength;
  var result = number.toString(radix);
  return (
    new Array(Math.max(minLength + 1 - result.length, 0)).join("0") + result
  );
}
