import * as React from 'react';
import '../../../src/pages/login/style.scss';
import { __, i18n } from '@spreax/i18n';
import { Feedback, DocumentTitle } from '../../components';
import { State } from '../../container';

interface ILogin {
  username: string;
  password: string;
}

export class Login extends React.Component<State, ILogin> {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      password: null
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async componentDidMount() {
    const { actions, middleware } = this.props;
    await middleware(actions.user.makeSureUserIsLoggedIn(this.props));
  }

  async componentDidUpdate() {
    const { actions, middleware } = this.props;
    await middleware(actions.user.makeSureUserIsLoggedIn(this.props));
  }

  onChange(field: string) {
    return e => this.setState({ [field]: e.target.value } as any);
  }

  onSubmit(e) {
    const { actions } = this.props;
    e.preventDefault();
    actions.user.login(this.state.username, this.state.password);
  }

  render() {
    const { messages } = this.props;
    return (
      <div className="sx-login">
        <DocumentTitle title={__(i18n.LOGIN.DOCUMENT_TITLE)} />
        <div className="sx-login__content">
          <div className="sx-login__content__header">
            <div className="sx-login__content__wrapper">
              <div className="sx-login__content__header__logo">
                <img src="/static/img/logo-200.png" />
              </div>
              <div className="sx-login__content__header__title">
                {__(i18n.LOGIN.PRIMARY_TITLE)}
              </div>
              <div className="sx-login__content__header__subtitle">
                {__(i18n.LOGIN.PRIMARY_SUBTITLE)}
              </div>
            </div>
          </div>

          <form className="sx-login__form" onSubmit={this.onSubmit}>
            <div className="sx-login__content__wrapper">
              <div className="sx-login__form__header">
                <div className="sx-login__form__title">
                  {__(i18n.LOGIN.SECONDARY_TITLE)}
                </div>
                <div className="sx-login__form__subtitle">
                  {__(i18n.LOGIN.SECONDARY_SUBTITLE)}
                </div>
              </div>

              <label className="sx-login__form__label">
                {__(i18n.LABELS.USERNAME)}
              </label>
              <input
                type="text"
                className="sx-login__form__input"
                name="username"
                placeholder="username"
                onChange={this.onChange('username')}
              />

              <label className="sx-login__form__label">
                {__(i18n.LABELS.PASSWORD)}
              </label>
              <input
                type="password"
                className="sx-login__form__input"
                name="password"
                placeholder="password"
                onChange={this.onChange('password')}
              />

              <button type="submit" className="sx-login__form__button">
                {__(i18n.ACTIONS.LOGIN)}
              </button>
            </div>
          </form>
        </div>

        <Feedback messages={messages} />
      </div>
    );
  }
}
