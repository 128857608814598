import {
  EngineError,
  ERROR_VALUE,
  ERROR_NOT_AVAILABLE,
  ERROR_NUM
} from "../../error";

var utils = require("../utils");
var jStat = require("jStat").jStat;
var misc = require("../miscellaneous");

/**
 * Returns the chi-squared distribution.
 * 
 * @param x The value at which you want to evaluate the distribution.
 * @param k The number of degrees of freedom.
 * @param cumulative A logical value that determines the form of the function.
 * If cumulative is TRUE, CHISQ.DIST returns the cumulative distribution
 * function; if FALSE, it returns the probability density function.
 */
export function CHISQ_DIST(x, k, cumulative) {
  x = utils.parseNumber(x);
  k = utils.parseNumber(k);
  if (utils.anyIsError(x, k)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  return cumulative ? jStat.chisquare.cdf(x, k) : jStat.chisquare.pdf(x, k);
}

/**
 * Returns the right-tailed probability of the chi-squared distribution.
 * 
 * @param x The value at which you want to evaluate the distribution.
 * @param k The number of degrees of freedom.
 */
export function CHISQ_DIST_RT(x, k) {
  if (!x || !k) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NOT_AVAILABLE, arguments);
  }

  if (x < 1 || k > Math.pow(10, 10)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NUM, arguments);
  }

  if (typeof x !== "number" || typeof k !== "number") {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  return 1 - jStat.chisquare.cdf(x, k);
}

/**
 * Returns the inverse of the left-tailed probability of the chi-squared
 * distribution.
 * 
 * @param probability A probability associated with the chi-squared distribution.
 * @param k The number of degrees of freedom.
 */
export function CHISQ_INV(probability, k) {
  probability = utils.parseNumber(probability);
  k = utils.parseNumber(k);
  if (utils.anyIsError(probability, k)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  return jStat.chisquare.inv(probability, k);
}

/**
 * Returns the inverse of the right-tailed probability of the chi-squared
 * distribution.
 * 
 * @param p A probability associated with the chi-squared distribution.
 * @param k The number of degrees of freedom.
 */
export function CHISQ_INV_RT(p, k) {
  if (!p || !k) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NOT_AVAILABLE, arguments);
  }

  if (p < 0 || p > 1 || k < 1 || k > Math.pow(10, 10)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NUM, arguments);
  }

  if (typeof p !== "number" || typeof k !== "number") {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  return jStat.chisquare.inv(1.0 - p, k);
}

/**
 * Returns the test for independence.
 * 
 * @param observed The range of data that contains observations to test against
 * expected values.
 * @param expected The range of data that contains the ratio of the product of
 * row totals and column totals to the grand total.
 */
export function CHISQ_TEST(observed, expected) {
  if (arguments.length !== 2) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NOT_AVAILABLE, arguments);
  }

  if (!(observed instanceof Array) || !(expected instanceof Array)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  if (observed.length !== expected.length) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  if (observed[0] && expected[0] && observed[0].length !== expected[0].length) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  var row = observed.length;
  var tmp, i, j;

  // Convert single-dimension array into two-dimension array
  for (i = 0; i < row; i++) {
    if (!(observed[i] instanceof Array)) {
      tmp = observed[i];
      observed[i] = [];
      observed[i].push(tmp);
    }
    if (!(expected[i] instanceof Array)) {
      tmp = expected[i];
      expected[i] = [];
      expected[i].push(tmp);
    }
  }

  var col = observed[0].length;
  var dof = col === 1 ? row - 1 : (row - 1) * (col - 1);
  var xsqr = 0;
  var Pi = Math.PI;

  for (i = 0; i < row; i++) {
    for (j = 0; j < col; j++) {
      xsqr += Math.pow(observed[i][j] - expected[i][j], 2) / expected[i][j];
    }
  }

  // Get independency by X square and its degree of freedom
  function ChiSq(xsqr, dof) {
    var p = Math.exp(-0.5 * xsqr);
    if (dof % 2 === 1) {
      p = p * Math.sqrt((2 * xsqr) / Pi);
    }
    var k = dof;
    while (k >= 2) {
      p = (p * xsqr) / k;
      k = k - 2;
    }
    var t = p;
    var a = dof;
    while (t > 0.0000000001 * p) {
      a = a + 2;
      t = (t * xsqr) / a;
      p = p + t;
    }
    return 1 - p;
  }

  return Math.round(ChiSq(xsqr, dof) * 1000000) / 1000000;
}

/**
 * The COLUMN function returns the column number of the given cell reference.
 * 
 * @param matrix 
 * @param index Optional. The cell or range of cells for which you want to 
 * return the column number.
 */
export function COLUMN(matrix, index) {
  if (arguments.length !== 2) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NOT_AVAILABLE, arguments);
  }

  if (index < 0) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NUM, arguments);
  }

  if (!(matrix instanceof Array) || typeof index !== "number") {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  if (matrix.length === 0) {
    return undefined;
  }

  return jStat.col(matrix, index);
}

/**
 * Returns the number of columns in an array or reference.
 * 
 * @param matrix  An array or array formula, or a reference to a range of cells
 * for which you want the number of columns.
 */
export function COLUMNS(matrix) {
  if (arguments.length !== 1) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NOT_AVAILABLE, arguments);
  }

  if (!(matrix instanceof Array)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  if (matrix.length === 0) {
    return 0;
  }

  return jStat.cols(matrix);
}

/**
 * Returns the confidence interval for a population mean, using a normal
 * distribution.
 * 
 * @param alpha The significance level used to compute the confidence level. The
 * confidence level equals 100*(1 - alpha)%, or in other words, an alpha of 0.05
 * indicates a 95 percent confidence level.
 * @param sd The population standard deviation for the data range and is
 * assumed to be known.
 * @param n The sample size.
 */
export function CONFIDENCE_NORM(alpha, sd, n) {
  alpha = utils.parseNumber(alpha);
  sd = utils.parseNumber(sd);
  n = utils.parseNumber(n);
  if (utils.anyIsError(alpha, sd, n)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  return jStat.normalci(1, alpha, sd, n)[1] - 1;
}

/**
 * Returns the confidence interval for a population mean, using a Student's t
 * distribution.
 * 
 * @param alpha  The significance level used to compute the confidence level.
 * The confidence level equals 100*(1 - alpha)%, or in other words, an alpha of
 * 0.05 indicates a 95 percent confidence level.
 * @param sd he population standard deviation for the data range and is assumed
 * to be known.
 * @param n The sample size.
 */
export function CONFIDENCE_T(alpha, sd, n) {
  alpha = utils.parseNumber(alpha);
  sd = utils.parseNumber(sd);
  n = utils.parseNumber(n);
  if (utils.anyIsError(alpha, sd, n)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  return jStat.tci(1, alpha, sd, n)[1] - 1;
}

/**
 * The CORREL function returns the correlation coefficient of two cell ranges.
 * Use the correlation coefficient to determine the relationship between two
 * properties.
 * 
 * @param array1 A range of cell values.
 * @param array2 A second range of cell values.
 */
export function CORREL(array1, array2) {
  array1 = utils.parseNumberArray(utils.flatten(array1));
  array2 = utils.parseNumberArray(utils.flatten(array2));
  if (utils.anyIsError(array1, array2)) {
    return new EngineError("CORREL", ERROR_VALUE, arguments);
  }
  return jStat.corrcoeff(array1, array2);
}

/**
 * The COUNT function counts the number of cells that contain numbers, and
 * counts numbers within the list of arguments.
 * 
 * @param args The first item, cell reference, or range within which you want to
 * count numbers.
 */
export function COUNT(...args) {
  return utils.numbers(utils.flatten(args)).length;
}

/**
 * The COUNTA function counts the number of cells that are not empty in a range.
 * 
 * @param arg[] The arguments representing the values that you want to count.
 *
 */
export function COUNTA(...args) {
  const range = utils.flatten(args);
  return range.length - COUNTBLANK(range);
}

// TODO NOT_DEFINED in https://support.office.com/
export function COUNTIN(range, value) {
  var result = 0;

  range = utils.flatten(range);

  for (var i = 0; i < range.length; i++) {
    if (range[i] === value) {
      result++;
    }
  }
  return result;
}

/**
 * Use the COUNTBLANK function to count the number of empty cells in a range of
 * cells.
 * 
 * @param args The range from which you want to count the blank cells.
 */
export function COUNTBLANK(...args) {
  const range = utils.flatten(args);
  let blanks = 0;
  for (let i = 0; i < range.length; i += 1) {
    const element = range[i];
    if (element === null || element === "") {
      blanks++;
    }
  }
  return blanks;
}

/**
 * Use COUNTIF to count the number of cells that meet a criterion
 * 
 * @param range The group of cells you want to count. 
 * @param criteria A number, expression, cell reference, or text string that
 * determines which cells will be counted. 
 */
export function COUNTIF(range, criteria) {
  range = utils.flatten(range);
  if (!/[<>=!]/.test(criteria)) {
    criteria = '=="' + criteria + '"';
  }
  var matches = 0;
  for (var i = 0; i < range.length; i++) {
    if (typeof range[i] !== "string") {
      if (eval(range[i] + criteria)) {
        // jshint ignore:line
        matches++;
      }
    } else {
      if (eval('"' + range[i] + '"' + criteria)) {
        // jshint ignore:line
        matches++;
      }
    }
  }
  return matches;
}

/**
 * The COUNTIFS function applies criteria to cells across multiple ranges and
 * counts the number of times all criteria are met.
 * 
 * @param _args
 * 
 * FIXME function signature mismatch
 * https://support.office.com/en-us/article/COUNTIFS-function-DDA3DC6E-F74E-4AEE-88BC-AA8C2A866842
 */
export function COUNTIFS(..._args) {
  const args = utils.argsToArray(_args);
  const results = new Array(utils.flatten(args[0]).length);
  for (let i = 0; i < results.length; i += 1) {
    results[i] = true;
  }
  for (let i = 0; i < args.length; i += 2) {
    const range = utils.flatten(args[i]);
    let criteria = args[i + 1];
    if (!/[<>=!]/.test(criteria)) {
      criteria = '=="' + criteria + '"';
    }
    for (let j = 0; j < range.length; j += 1) {
      if (typeof range[j] !== "string") {
        results[j] = results[j] && eval(range[j] + criteria); // jshint ignore:line
      } else {
        results[j] = results[j] && eval('"' + range[j] + '"' + criteria); // jshint ignore:line
      }
    }
  }
  let result = 0;
  for (let i = 0; i < results.length; i += 1) {
    if (results[i]) {
      result++;
    }
  }
  return result;
}

// NOT_DEFINED in https://support.office.com/
export function COUNTUNIQUE(...args) {
  return misc.UNIQUE.apply(null, utils.flatten(args)).length;
}

/**
 * Returns population covariance, the average of the products of deviations for
 * each data point pair in two data sets.
 * 
 * @param array1 The first cell range of integers.
 * @param array2 The second cell range of integers.
 */
export function COVARIANCE_P(array1, array2) {
  array1 = utils.parseNumberArray(utils.flatten(array1));
  array2 = utils.parseNumberArray(utils.flatten(array2));
  if (utils.anyIsError(array1, array2)) {
    return new EngineError("COVARIANCE_P", ERROR_VALUE, arguments);
  }
  var mean1 = jStat.mean(array1);
  var mean2 = jStat.mean(array2);
  var result = 0;
  var n = array1.length;
  for (var i = 0; i < n; i++) {
    result += (array1[i] - mean1) * (array2[i] - mean2);
  }
  return result / n;
}

/**
 * Returns the sample covariance, the average of the products of deviations for
 * each data point pair in two data sets.
 * 
 * @param array1 The first cell range of integers.
 * @param array2 The second cell range of integers.
 */
export function COVARIANCE_S(array1, array2) {
  array1 = utils.parseNumberArray(utils.flatten(array1));
  array2 = utils.parseNumberArray(utils.flatten(array2));
  if (utils.anyIsError(array1, array2)) {
    return new EngineError("COVARIANCE_S", ERROR_VALUE, arguments);
  }
  return jStat.covariance(array1, array2);
}