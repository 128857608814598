import * as React from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import * as Pages from './pages';
import { Routes } from './navigator';
import { State } from './container';
import { ErrorBoundary } from './error-boundary';
import { Theme } from '@spreax/theme';

function render(props: State, Component) {
  return route => {
    return <Component {...props} route={route} />;
  };
}

export function AppView(props: State) {
  const theme = {
    primaryColor: props.selectedTenant ? props.selectedTenant.color : null
  };
  return (
    <ErrorBoundary>
      <Theme theme={theme}>
        <HashRouter>
          <Switch>
            <Route
              exact
              path={Routes.ROOT}
              render={render(props, Pages.Overview)}
            />
            <Route
              exact
              path={Routes.LOGIN}
              render={render(props, Pages.Login)}
            />
            <Route
              exact
              path={Routes.SELECT_TENANT}
              render={render(props, Pages.SelectTenant)}
            />
            <Route
              exact
              path={Routes.MODEL}
              render={render(props, Pages.Template)}
            />
            <Route
              exact
              path={Routes.PROJECT}
              render={render(props, Pages.Template)}
            />
            <Route
              exact
              path={Routes.CONFIGURE_MODEL}
              render={render(props, Pages.ConfigureTemplate)}
            />
            <Route
              exact
              path={Routes.FOLDER}
              render={render(props, Pages.Folder)}
            />
            <Route
              exact
              path={Routes.PROFILE}
              render={render(props, Pages.Profile)}
            />
            <Route
              exact
              path={Routes.ACCOUNTS}
              render={render(props, Pages.Accounts)}
            />
            <Route
              exact
              path={Routes.EDIT_ACCOUNT}
              render={render(props, Pages.EditAccount)}
            />
            <Route
              exact
              path={Routes.ERROR}
              render={render(props, Pages.Error)}
            />
          </Switch>
        </HashRouter>
      </Theme>
    </ErrorBoundary>
  );
}
