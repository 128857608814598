import * as React from 'react';
import { State } from '../../container';
import { __, i18n } from '@spreax/i18n';
import * as moment from 'moment';

import { PageTop, Loader, Editable, Button } from '../../components';

function createActions(props: State) {
  const {
    actions,
    selectedProject,
    selectedTemplate,
    isLoading,
    profile,
    showContextDrawer,
    client,
    clientError,
    selectedTenant
  } = props;
  return [
    {
      label: __(i18n.ACTIONS.SAVE),
      icon: 'save',
      show: !!selectedProject,
      isPrimary: true,
      isDisabled: isLoading, // FIXME: || clientError
      onClick: () => {
        if (isLoading) {
          return;
        }
        if (selectedProject) {
          actions.project.save(selectedTenant.id, selectedProject.id);
        }
      }
    },
    {
      label: __(i18n.ACTIONS.NEW_PROJECT),
      icon: 'add',
      show: !selectedProject,
      isPrimary: true,
      isDisabled: isLoading, // FIXME: || clientError
      onClick: () => {
        if (isLoading) {
          return;
        }
        actions.project.create(selectedTenant.id, selectedTemplate.id);
      }
    },
    {
      label: __(i18n.ACTIONS.DUPLICATE),
      icon: 'file_copy',
      show: !!selectedProject,
      iconOnly: true,
      isDisabled: isLoading, // FIXME: || clientError
      onClick: () => {
        actions.project.duplicate(
          selectedTenant.id,
          selectedTemplate.id,
          selectedProject.id
        );
      }
    },
    {
      label: __(i18n.ACTIONS.RESET),
      icon: 'refresh',
      iconOnly: true,
      isDisabled: isLoading || clientError,
      onClick: () => {
        if (isLoading) {
          return;
        }
        const isConfirmed = confirm(__(i18n.FEEDBACK.CONFIRM_RESET_PROJECT));
        if (isConfirmed) {
          actions.project.draft(selectedTemplate.id, null);
          if (selectedProject) {
            actions.project.update(
              selectedTenant.id,
              selectedProject.id,
              'session',
              null
            );
          }
        }
      }
    },
    {
      label: __(i18n.ACTIONS.REMOVE),
      icon: 'delete',
      show: !!selectedProject,
      iconOnly: true,
      isDisabled: isLoading,
      onClick: () => {
        if (isLoading) {
          return;
        }
        if (selectedProject) {
          const isConfirmed = confirm(__(i18n.FEEDBACK.CONFIRM_DELETE_PROJECT));
          if (isConfirmed) {
            actions.project.deactivate(selectedTenant.id, selectedProject.id);
          }
        }
      }
    },
    {
      label: __(i18n.ACTIONS.SETTINGS),
      icon: 'settings',
      show: profile && profile.isAdmin && !selectedProject,
      iconOnly: true,
      isDisabled: isLoading,
      onClick: () => {
        if (isLoading) {
          return;
        }
        props.route.history.push(`/template/${selectedTemplate.id}/configure`);
      }
    },
    {
      label: __(i18n.ACTIONS.SETTINGS),
      icon: 'menu',
      show: true,
      iconOnly: true,
      isDisabled: isLoading,
      onClick: () => {
        if (showContextDrawer) {
          actions.app.closeDrawer();
        } else {
          actions.app.openDrawer();
        }
      }
    }
  ];
}

export function Top(props: State) {
  const {
    actions,
    profile,
    selectedProject,
    selectedTemplate,
    selectedTenant,
    route
  } = props;

  const onChange = function(ev) {
    actions.project.update(
      selectedTenant.id,
      selectedProject.id,
      'title',
      ev.target.value
    );
  };

  const primary = (
    <Editable
      disabled={!props.selectedProject}
      onChange={onChange}
      html={selectedProject ? selectedProject.title : selectedTemplate.title}
      title={selectedTemplate && selectedTemplate.description}
    ></Editable>
  );

  const secondary = [
    (profile && selectedProject) || selectedTemplate
      ? moment((selectedProject || selectedTemplate).dateLastUpdated).calendar()
      : null,
    profile &&
      selectedProject &&
      selectedTemplate.id === selectedProject.templateId &&
      (profile.isAdmin || !selectedTemplate.isHidden) && (
        <div>
          {__(i18n.PROJECTS.BASED_ON)}{' '}
          <a
            onClick={() =>
              route.history.push('/template/' + selectedTemplate.id)
            }
          >
            {selectedTemplate.title}
          </a>
        </div>
      ),
    selectedProject && selectedTemplate.isHidden && !profile.isAdmin && (
      <div>
        {__(i18n.PROJECTS.BASED_ON)} {selectedTemplate.title}
      </div>
    )
  ];

  const actionsRight = createActions(props)
    .filter(buttonProps => buttonProps.show)
    .map((buttonProps, index) => {
      return <Button {...buttonProps} />;
    });

  return (
    <PageTop
      primary={primary}
      secondary={secondary.filter(item => !!item)}
      actionsRight={[<Loader {...props} />, ...actionsRight]}
    />
  );
}
