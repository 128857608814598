import * as numeral from 'numeral';
import { LANGUAGE } from '@spreax/i18n';
import { Property } from './types';
import * as ssf from 'ssf';

export namespace format {

  numeral.register('locale', 'nl_NL', {
    delimiters: {
      thousands: '.',
      decimal: ',',
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't',
    },
    ordinal: function ordinal (number) {
      return number === 1 ? 'er' : 'ème';
    },
    currency: {
      symbol: '€',
    },
  });

  numeral.register('locale', 'en_US', {
    delimiters: {
      thousands: ',',
      decimal: '.',
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't',
    },
    ordinal : function ordinal (number) {
      return number === 1 ? 'er' : 'ème';
    },
    currency: {
      symbol: '$',
    },
  });

  /**
   * Format value as display value
   *
   * @param property
   * @param value
   * @param {boolean} editFriendly
   * @returns {string}
   */

  export function format (property: Property, value: any, editFriendly: boolean = false) : string {
    numeral.locale(LANGUAGE);
    if (editFriendly) {
      return value;
    }
    if (property.style && property.style.format) {
      return ssf.format(property.style.format, value);
    }
    return value;
  }

  /**
   * Take a simple number and serialize it into a string using i18n based decimal delimiter
   *
   * @param {number} value
   * @returns {string}
   */

  export function serialize (value: number) : string {
    numeral.locale(LANGUAGE);
    return numeral(value).format('0.00');
  }

  /**
   * Parse display values
   *
   * @param {Property} property
   * @param {string} value
   * @returns {number | string}
   */

  export function parse (property: Property, value: string) : number | string {
    numeral.locale(LANGUAGE);
    if (property.type === 'dropdown' || property.type === 'checkbox' || property.type === 'radio' || /[a-z]/i.test(value)) {
      return value;
    }
    return numeral(value).value();
  }

  /**
   * Validate value by comparing it to property settings
   *
   * @param {Property} property
   * @param {string | number} value
   * @returns {string | number}
   */

  export function validate (property: Property, value: string | number) : string | number {
    if (property.type === 'dropdown' || property.type === 'checkbox' || property.type === 'radio') {
      return value;
    }
    const min = numeral(property.min).value();
    const max = numeral(property.max).value();
    if (property.hasOwnProperty('min') && min !== null && value < min) {
      return property.min;
    }
    if (property.hasOwnProperty('max') && max !== null && value > max) {
      return property.max;
    }
    return value;
  }

  /**
   * Try to cast value to `Number`
   *
   * @param {string | number} value
   * @param {boolean} isStrict
   * @returns {any}
   */

  function castValue (value: any, isStrict: boolean = false) {
    if (!isStrict && value ===  '') {
      return value;
    }
    if (/[a-z]/i.test(value)) {
      return value;
    }
    if (!isNaN(+value)) {
      return +value;
    }
  }

  /**
   * Get `Prefix` based on property
   *
   * @param {Property} property
   * @returns {string}
   */

  export function getPrefix (property: Property) : string {
    // switch (property.format ? property.format.unit : null) {
    //   case 'EURO':
    //     return '€';
    //   case 'USD':
    //     return '$';
    // }
    return null;
  }

}
