import * as Operators from './operators';
import { EngineError, ERROR_NAME } from './error';
import { FormulaParseResult } from '.';
import { SupportedFunctions } from './supported-functions';

const availableOperators = Object.create(null);
const FLOAT_PRECISION = 15;

enum OperatorTypes {
  ADD = '+',
  AMPERSAND = '&',
  DIVIDE = '/',
  EQUAL = '=',
  GREATER_THAN = '>',
  GREATER_THEN_OR_EQUAL = '>=',
  LESS_THAN = '<',
  LESS_THAN_OR_EQUAL = '<=',
  MINUS = '-',
  MULTIPLY = '*',
  NOT_EQUAL = '<>',
  POWER = '^',
}

/**
 * Evaluate values by operator id.git
 *
 * @param {string} operatorName Operator id
 * @param {any[]} params Arguments to evaluate
 * @returns {FormulaParseResult}
 */

export default function evaluateByOperator(operatorName: string, params: any[] = []): FormulaParseResult {
  const operator = availableOperators[operatorName.toUpperCase()];
  if (!operator) {
    throw new EngineError('evaluateByOperator', ERROR_NAME, [operatorName, params]);
  }
  const result = operator.call(this, ...params);
  return fixFloatingPointInaccuracy(result);
}

/**
 * Register operator.
 *
 * @param {string|array} symbol Symbol to register.
 * @param {function} func Logic to register for this symbol.
 * @param {boolean} isFactory
 * @returns {void}
 */

function registerOperation(symbol, func, isFactory = false): void {
  if (!Array.isArray(symbol)) {
    symbol = [symbol.toUpperCase()];
  }
  symbol.forEach((s) => {
    if (isFactory) {
      availableOperators[s] = func(s);
    } else {
      availableOperators[s] = func;
    }
  });
}

/**
 * Set precision for numbers to prevent rounding errors when dealing with floats
 *
 * @param {FormulaParseResult} value
 * @returns {FormulaParseResult}
 */

function fixFloatingPointInaccuracy(value: FormulaParseResult): FormulaParseResult {
  if (typeof value === 'number') {
    return +value.toPrecision(FLOAT_PRECISION);
  }
  return value;
}

registerOperation(OperatorTypes.ADD, Operators.add);
registerOperation(OperatorTypes.AMPERSAND, Operators.ampersand);
registerOperation(OperatorTypes.DIVIDE, Operators.divide);
registerOperation(OperatorTypes.EQUAL, Operators.equal);
registerOperation(OperatorTypes.POWER, Operators.power);
registerOperation(OperatorTypes.GREATER_THAN, Operators.greaterThan);
registerOperation(OperatorTypes.GREATER_THEN_OR_EQUAL, Operators.greaterThanOrEqual);
registerOperation(OperatorTypes.LESS_THAN, Operators.lessThan);
registerOperation(OperatorTypes.LESS_THAN_OR_EQUAL, Operators.lessThanOrEqual);
registerOperation(OperatorTypes.MULTIPLY, Operators.multiply);
registerOperation(OperatorTypes.NOT_EQUAL, Operators.notEqual);
registerOperation(OperatorTypes.MINUS, Operators.minus);
registerOperation(SupportedFunctions, Operators.formulaFunction, true);
