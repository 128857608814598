import { ReduceStore } from 'flux/utils';
import Dispatcher from '../dispatcher';
import { ActionTypes } from '../action-types';
import * as DataManager from '../data-managers';
import { User } from '@spreax/model';

function onUserLoggedIn(state, action) {
  return Object.assign({}, state, {
    tenants: action.session.profile.tenants
  });
}

function onSelectTenant(state, action) {
  const selectedTenant = state.tenants.find(tenant => tenant.id === action.id);
  if (!selectedTenant) {
    localStorage.removeItem('selected_tenant');
    return Object.assign({}, state, {
      selectedTenant: null,
      theme: {}
    });
  }
  localStorage.setItem('selected_tenant', action.id);
  return Object.assign({}, state, {
    selectedTenant,
    theme: {
      primaryColor: selectedTenant.color,
      logo: selectedTenant.logo
    }
  });
}

class TenantStore extends ReduceStore<TenantState, any> {
  constructor() {
    super(Dispatcher);
  }

  getInitialState() {
    return {
      tenants: [],
      selectedTenant: null,
      theme: {}
    };
  }

  reduce(state: TenantState, action): TenantState {
    switch (action.type) {
      case ActionTypes.SELECT_TENANT:
        return onSelectTenant(state, action);

      case ActionTypes.LOGGED_IN:
        return onUserLoggedIn(state, action);

      default:
        return state;
    }
  }
}

export interface TenantState {
  tenants: any[];
  selectedTenant: null;
  theme: any;
}

export const Store = new TenantStore();
