import * as React from 'react';
import '../../../src/pages/configure-template/style.scss';
import { State } from '../../container';
import { __, i18n } from '@spreax/i18n';
import { App } from '../frame/app';
import { PageTop, Loader } from '../../components';
import { get } from 'lodash';
import { Checkbox, InputField, Button } from '@spreax/components';

declare const Spreax;

enum FieldTypes {
  String,
  Boolean
}

export class ConfigureTemplate extends React.PureComponent<State> {
  constructor(props) {
    super(props);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  async componentDidMount() {
    const { actions, middleware } = this.props;
    await middleware(
      await actions.template.makeSureTemplateIsSelected(this.props),
      actions.template.makeSureHasAccessToTemplate(this.props)
    );
  }

  async componentDidUpdate() {
    const { actions, middleware } = this.props;
    await middleware(
      await actions.template.makeSureTemplateIsSelected(this.props),
      actions.template.makeSureHasAccessToTemplate(this.props)
    );
  }

  onFieldChange(key, type: FieldTypes = FieldTypes.String) {
    const { actions, selectedTemplate } = this.props;
    return ev => {
      const { value } = ev.target;
      switch (type) {
        case FieldTypes.Boolean:
          actions.template.update(
            selectedTemplate.id,
            key,
            !get(selectedTemplate, key)
          );
          break;
        case FieldTypes.String:
        default:
          actions.template.update(selectedTemplate.id, key, value);
          break;
      }
    };
  }

  onSave() {
    const {
      actions,
      selectedTemplate,
      selectedTenant,
      isLoading,
      route
    } = this.props;
    if (isLoading) {
      return;
    }
    actions.template.save(selectedTenant.id, selectedTemplate.id);
  }

  render() {
    const { actions, selectedTemplate, route } = this.props;
    return (
      <App
        {...this.props}
        documentTitle={__(i18n.CONFIGURE_MODEL.DOCUMENT_TITLE)}
      >
        {selectedTemplate && (
          <div className="sx-page">
            <PageTop
              primary={selectedTemplate.title}
              secondary={[__(i18n.CONFIGURE_MODEL.PRIMARY_TITLE)]}
              actionsRight={[
                <Loader {...this.props} />,
                ...[
                  <Button
                    label={__(i18n.ACTIONS.SAVE)}
                    isPrimary={true}
                    isDisabled={this.props.isLoading}
                    onClick={this.onSave}
                  />
                ]
              ]}
            />
            <div className="sx-page__contents">
              <form className="sx-form">
                <fieldset>
                  <label>{__(i18n.CONFIGURE_MODEL.NAME)}</label>
                  <InputField
                    className="sx-form__input"
                    value={selectedTemplate.title}
                    onChange={this.onFieldChange('title')}
                  />
                </fieldset>

                <fieldset>
                  <label>{__(i18n.CONFIGURE_MODEL.DESCRIPTION)}</label>
                  <InputField
                    className="sx-form__input"
                    value={selectedTemplate.description}
                    onChange={this.onFieldChange('description')}
                    multiline={10}
                  />
                </fieldset>

                <fieldset>
                  <Checkbox
                    className="sx-form__input"
                    checked={!!selectedTemplate.isHidden}
                    onChange={this.onFieldChange(
                      'isHidden',
                      FieldTypes.Boolean
                    )}
                    label={__(i18n.CONFIGURE_MODEL.IS_HIDDEN)}
                  />
                </fieldset>

                <fieldset>
                  <Checkbox
                    className="sx-form__input"
                    checked={!!selectedTemplate.isLocked}
                    onChange={this.onFieldChange(
                      'isLocked',
                      FieldTypes.Boolean
                    )}
                    label={__(i18n.CONFIGURE_MODEL.IS_LOCKED)}
                  />
                </fieldset>

                <fieldset>
                  <Checkbox
                    className="sx-form__input"
                    checked={selectedTemplate.isPublic}
                    onChange={this.onFieldChange(
                      'isPublic',
                      FieldTypes.Boolean
                    )}
                    label={__(i18n.CONFIGURE_MODEL.IS_PUBLIC)}
                  />
                </fieldset>
              </form>
            </div>
          </div>
        )}
      </App>
    );
  }
}
