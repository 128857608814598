export function getTextStyles(theme) {
  return {
    p: {
      fontSize: '1rem',
      ...(theme.textStyles && theme.textStyles.p)
    },
    h1: {
      fontSize: '2rem',
      ...(theme.textStyles && theme.textStyles.h1)
    },
    h2: {
      fontSize: '1.8rem',
      ...(theme.textStyles && theme.textStyles.h2)
    },
    h3: {
      fontSize: '1.6rem',
      ...(theme.textStyles && theme.textStyles.h3)
    },
    h4: {
      fontSize: '1.4rem',
      ...(theme.textStyles && theme.textStyles.h4)
    },
    h5: {
      fontSize: '1.2rem',
      ...(theme.textStyles && theme.textStyles.h5)
    },
    isBold: {
      fontWeight: 'bold',
      ...(theme.textStyles && theme.textStyles.bold)
    },
    isItalic: {
      fontStyle: 'italic',
      ...(theme.textStyles && theme.textStyles.italic)
    },
    hasUnderline: {
      textDecoration: 'underline',
      ...(theme.textStyles && theme.textStyles.underline)
    },
    alignLeft: {
      textAlign: 'left',
      justifyContent: 'flex-start'
    },
    alignCenter: {
      textAlign: 'center',
      justifyContent: 'center'
    },
    alignRight: {
      textAlign: 'right',
      justifyContent: 'flex-end'
    }
  };
}

export function getTextStyleClasses(classes, property) {
  return {
    [classes.p]: !property.variant || property.variant === 'p',
    [classes.h1]: property.variant === 'h1',
    [classes.h2]: property.variant === 'h2',
    [classes.h3]: property.variant === 'h3',
    [classes.h4]: property.variant === 'h4',
    [classes.h5]: property.variant === 'h5',
    [classes.isBold]: property.isBold,
    [classes.isItalic]: property.isItalic,
    [classes.hasUnderline]: property.hasUnderline,
    [classes.alignLeft]: property.textAlignment === 'left',
    [classes.alignCenter]: property.textAlignment === 'center',
    [classes.alignRight]: property.textAlignment === 'right'
  };
}
