import { toNumber } from '../utils';
import { ERROR_VALUE, EngineError } from '../error';

export function power(exp1, exp2) {
  const result = Math.pow(toNumber(exp1), toNumber(exp2));

  if (isNaN(result)) {
    return new EngineError('POWER', ERROR_VALUE, [exp1, exp2]);
  }

  return result;
}
