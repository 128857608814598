import { useEffect, useState } from 'react';
import { Engine, EngineState, TemplateDefinition } from '@spreax/engine';

export function useError({ onError = null } = {}) {
  const [error, setErrorState] = useState(null);

  function setError(error) {
    setErrorState(error);
    if (onError) {
      onError(error);
    }
  }

  return [error, setError];
}

export function useTemplate({ apiEndpoint, templateId, setError }) {
  const [template, setTemplate] = useState(null);

  useEffect(() => {
    fetch(`${apiEndpoint}/templates/${templateId}`, {
      method: 'GET',
      headers: {
        'X-Auth-Token': null
      }
    })
      .then(response => response.json())
      .then(({ data, errors }) => {
        if (errors && errors.length > 0) {
          setError(errors[0]);
          return;
        }
        setTemplate(data);
      });
  }, [templateId, apiEndpoint]);

  return template;
}

export function useEngine({
  template,
  setError,
  templateDefinition = null,
  session = null,
  changeCounter = null
}) {
  const [engine, setEngine] = useState(null);
  const [engineCalculateCounter, setEngineCalculateCounter] = useState(-1); // force calculation on startup
  const _templateDefinition: TemplateDefinition =
    templateDefinition || (template && template.templateDefinition);
  useEffect(() => {
    if (template && changeCounter !== engineCalculateCounter) {
      if (!_templateDefinition) {
        setError(new Error('No `TemplateDefinition` found'));
      }

      setEngineCalculateCounter(changeCounter);
      const engine = new Engine(_templateDefinition, template);
      setEngine(engine);
      // FIXME why the settimeout() out? Jaimy
      setTimeout(() => engine.calculate(session), 100);
    }
  }, [template, changeCounter]);
  return [engine, setEngine];
}

export function useEngineActions(engine) {
  return [
    {
      onChange: function onChange(id, value) {
        engine.updateElement(id, value);
      },
      onReset: async function onReset(sheet, ref, value) {
        if (engine.state >= 2) {
          await engine.reset();
          await engine.calculate();
        }
      },
      onFocus: function onFocus(sheet, ref, value) {
        // no-op
      },
      onBlur: async function onBlur(sheet, ref, value) {
        await engine.calculate();
      }
    }
  ];
}

export function useEngineSession({ engine, session }) {
  useEffect(() => {
    if (engine && session) {
      engine.loadSession(session);
      engine.calculate();
    }
  }, [engine, session]);
}

export function useEngineState({
  engine,
  onStateChange = null,
  onUpdate = null
}) {
  const [engineState, setEngineState] = useState(null);

  function handleStateChange(state) {
    if (state === EngineState.Calculated) {
      setEngineState({
        elements: engine.elements
      });
      if (onUpdate) {
        onUpdate(engine.getSession());
      }
    }
  }

  useEffect(() => {
    engine && engine.on('stateChange', handleStateChange);
    engine && onStateChange && engine.on('stateChange', onStateChange);
    return function cleanup() {
      engine && engine.removeListener('stateChange', handleStateChange);
      engine &&
        onStateChange &&
        engine.removeListener('stateChange', onStateChange);
    };
  }, [engine && engine.id]);

  return [engineState, setEngineState];
}
