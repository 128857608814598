import * as React from 'react';
import { Checkbox as BaseCheckbox } from '@spreax/components';

export function Checkbox (props) {
  const { property, onChange, onBlur } = props;
  return (
    <BaseCheckbox
      checked={Boolean(property.value)}
      onChange={(e) => {
        onChange(property)(+e.target.checked);
        setTimeout(onBlur(property), 0);
      }}
      label={property.label}
    />
  );
}
