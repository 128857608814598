import { FormulaParser } from './formula-parser';
import { Cell } from './cell';
import { CellMap } from './cell-map';
import { log } from '@spreax/lib';
import { Property, Model, FormulaParseError, CellValue } from '.';
import { CellReference } from '@spreax/xlsx-parser';

export class DependencyGraph {

  private formulaCallStack: string[];
  private currentPrecedenceCell: Cell;
  private formulaParser: FormulaParser;
  private cellMap: CellMap;
  private model;

  constructor (
    model: Model,
    definition,
    cellMap: CellMap,
  ) {
    this.model = model;
    this.cellMap = cellMap;
    this.formulaParser = new FormulaParser();
    this.formulaParser.on('callVariable', this.handleCallVariable.bind(this));
    this.formulaParser.on('callCellValue', this.handleCallCellValue.bind(this));
    this.formulaCallStack = [];
    this.currentPrecedenceCell = null;
  }

  /**
   * Find out which cells are dependent on each other and which cell provide a default value.
   *
   * @returns void
   */

  public build () : void {

    const errors = this.cellMap.cells
      .map(this.traceCalculationFlow.bind(this))
      .filter(error => !!error);

    if (errors.length > 0) {
      log.error('Errors during building dependency graph:', errors);
    }

  }

  /**
   * Parse a `Formula` and see which cells it calls
   *
   * @param {Cell} cell
   * @returns {FormulaParseError};
   */

  traceCalculationFlow (cell: Cell) : FormulaParseError {
    if (cell.formula) {
      this.formulaCallStack = [];
      this.formulaCallStack.push(cell.sheetRef);
      this.currentPrecedenceCell = cell;
      const { error } = this.formulaParser.parse(cell);
      if (error) {
        return error;
      }
    }
  }

  /**
   * Handler passed to parser that links cells when called and returns a sane value.
   *
   * @param cellReference
   * @returns CellValue
   */

  cell (cellReference: CellReference) : CellValue {
    const sheet = cellReference.sheet || this.formulaCallStack[this.formulaCallStack.length - 1];
    const cell = this.cellMap.getCellByIndex(sheet, cellReference.row.index, cellReference.column.index);
    if (this.currentPrecedenceCell.isProxy && cell) {
      cell.setProxy(this.currentPrecedenceCell);
      // return cell && cell.value || 0;
    }
    if (cell && !(cell.ref === this.currentPrecedenceCell.ref && cell.sheetRef === this.currentPrecedenceCell.sheetRef)) {
      this.currentPrecedenceCell.dependsOn(cell);
    }
    return cell && cell.value || 0;
  }

  /**
   * Retrieve singular cell
   *
   * @param cell
   * @param done
   * @returns {void}
   * @private
   */

  private handleCallCellValue (cell: CellReference, done) : void {
    const value = this.cell(cell);
    done(value);
  }

  /**
   * Retrieve cell based on defined name
   *
   * @param name
   * @param done
   * @returns {void | boolean}
   * @private
   */

  private handleCallVariable (name: string, done) : void | boolean {
    if (name === 'TRUE') {
      return true;
    }
    if (name === 'FALSE') {
      done(false);
      return true;
    }
    const definedName = this.model.definedNames.find(definedName => definedName.name === name);
    if (definedName && definedName.formula) {
      const formula = this.formulaParser.parse(definedName.formula);
      if (formula.error) {
        log.error('Error while parsing formula during building of dependency graph:', formula.error);
      }
      if (!formula.error) {
        done(formula.result);
      }
    } else {
      console.warn('Defined name without `formula`:', name);
    }
  }

}
