import * as React from 'react';
import { Paragraph as BaseParagraph } from '@spreax/components';

export function Paragraph (props) {
  const { classes, property } = props;
  return (
    <BaseParagraph
      isBold={property.isBold}
      isItalic={property.isItalic}
      hasUnderline={property.hasUnderline}
      textAlignment={property.textAlignment}
    >
      {property.value}
    </BaseParagraph>
  );
}
