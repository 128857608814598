import * as React from 'react';
import '../../../src/components/page/style.scss';

export function Page(props) {
  const { isReady, children } = props;
  if (isReady === false) {
    return null;
  }
  return <div className="sx-page">{children}</div>;
}

export function PageTop(props) {
  const { primary, secondary, actionsRight } = props;
  return (
    <div className="sx-page__top">
      <div className="sx-page__top__col sx-page__top__col--flex">
        <div className="sx-page__top__primary">{primary}</div>
        <div className="sx-page__top__secondary">
          {secondary &&
            secondary.map((item, index) => {
              return (
                <div key={index} className="sx-page__top__secondary__item">
                  {item}
                </div>
              );
            })}
        </div>
      </div>
      <div className="sx-page__top__col">
        <div className="sx-page__top__actions">
          {actionsRight &&
            actionsRight.map((item, index) => {
              return (
                <div key={index} className="sx-page__top__actions__item">
                  {item}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
