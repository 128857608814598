// tslint:disable object-shorthand-properties-first

import Dispatcher from '../dispatcher';
import { ActionTypes } from '../action-types';
import { __, i18n } from '@spreax/i18n';
import { feedback } from '.';

export namespace client {
  export interface IClient {
    setClient(client);
    setState(state: number);
    setError(error: Error);
    handleClientState(props);
    handleInitialization();
  }

  export function setClient(client) {
    setTimeout(() => {
      Dispatcher.dispatch({
        type: ActionTypes.SET_SPREAX_CLIENT,
        client
      });
    }, 0);
  }

  export function setState(state: number) {
    setTimeout(() => {
      Dispatcher.dispatch({
        type: ActionTypes.SET_SPREAX_STATE,
        state
      });
    }, 0);
  }

  export function setError(error) {
    Dispatcher.dispatch({
      type: ActionTypes.SET_SPREAX_ERROR,
      error
    });
    feedback.add('error', __(i18n.FEEDBACK.EXCEPTION));
  }

  export function handleClientState(props) {
    return async (getState, dispatch) => {
      const { route } = props;
      const { actions, clientState, isLoading, clientError } = getState();
      if (clientError) {
        actions.app.unsetLoading();
        return;
      }
      if (clientState <= 4 && !isLoading) {
        actions.app.setLoading();
      } else if (clientState === 5 && isLoading) {
        actions.app.unsetLoading();
      }
    };
  }
}
