import { toNumber } from '../utils';
import { ERROR_VALUE, EngineError } from '../error';

export function multiply(first, ...rest) {
  const result = rest.reduce(
    (acc, value) => acc * toNumber(value),
    toNumber(first)
  );

  if (isNaN(result)) {
    return new EngineError('MULTIPLY', ERROR_VALUE, [first, ...rest]);
  }

  return result;
}
