import * as React from 'react';
import { ErrorView } from './error-view';
import { Tracker } from '@spreax/lib';

export class ErrorBoundary extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError (error) {
    return {
      hasError: true,
    };
  }

  componentDidCatch (error, info) {
    const { onError } = this.props;
    Tracker.exception(error);
    console.error(error);
    if (onError) {
      onError(error);
    }
  }

  render () {
    if (this.state.hasError) {
      return (
        <ErrorView />
      );
    }
    return this.props.children;
  }
}
