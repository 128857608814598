import { EngineError, ERROR_VALUE, ERROR_NUM, ERROR_DIV_ZERO } from '../error';
import { arrayEach, rest } from './utils';
import { SUM, COUNTA, VAR_S, VAR_P, STDEV_S, STDEV_P, COUNT } from '.';

function compact(array) {
  const result = [];
  arrayEach(array, value => {
    if (value) {
      result.push(value);
    }
  });
  return result;
}

export function FINDFIELD(database, title) {
  let index = null;
  arrayEach(database, (value, i) => {
    if (value[0] === title) {
      index = i;
      return false;
    }
  });
  // Return error if the input field title is incorrect
  if (index == null) {
    return new EngineError('FINDFIELD', ERROR_VALUE, arguments);
  }
  return index;
}

function findResultIndex(database, criterias) {
  const matches = {};

  for (let i = 1; i < database[0].length; ++i) {
    matches[i] = true;
  }

  let maxCriteriaLength = criterias[0].length;
  for (let i = 1; i < criterias.length; ++i) {
    if (criterias[i].length > maxCriteriaLength) {
      maxCriteriaLength = criterias[i].length;
    }
  }

  for (let k = 1; k < database.length; ++k) {
    for (let l = 1; l < database[k].length; ++l) {
      let currentCriteriaResult = false;
      let hasMatchingCriteria = false;
      for (let j = 0; j < criterias.length; ++j) {
        const criteria = criterias[j];
        if (criteria.length < maxCriteriaLength) {
          continue;
        }
        const criteriaField = criteria[0];
        if (database[k][0] !== criteriaField) {
          continue;
        }
        hasMatchingCriteria = true;
        for (let p = 1; p < criteria.length; ++p) {
          currentCriteriaResult =
            currentCriteriaResult || eval(database[k][l] + criteria[p]); // jshint ignore:line
        }
      }
      if (hasMatchingCriteria) {
        matches[l] = matches[l] && currentCriteriaResult;
      }
    }
  }

  const result = [];
  for (let n = 0; n < database[0].length; ++n) {
    if (matches[n]) {
      result.push(n - 1);
    }
  }

  return result;
}

// Database functions
export function DAVERAGE(database, field, criteria) {
  // Return error if field is not a number and not a string
  if (isNaN(field) && typeof field !== 'string') {
    return new EngineError('DAVERAGE', ERROR_VALUE, [
      database,
      field,
      criteria
    ]);
  }
  const resultIndexes = findResultIndex(database, criteria);
  let targetFields = [];

  if (typeof field === 'string') {
    const index = FINDFIELD(database, field);
    targetFields = rest(database[index]);
  } else {
    targetFields = rest(database[field]);
  }

  let sum = 0;

  arrayEach(resultIndexes, value => (sum += targetFields[value]));

  return resultIndexes.length === 0
    ? new EngineError('{FUNCTION_NAME}', ERROR_DIV_ZERO, arguments)
    : sum / resultIndexes.length;
}

export function DCOUNT(database, field, criteria) {
  // Return error if field is not a number and not a string
  if (isNaN(field) && typeof field !== 'string') {
    return new EngineError('DCOUNT', ERROR_VALUE, arguments);
  }

  const resultIndexes = findResultIndex(database, criteria);
  let targetFields = [];

  if (typeof field === 'string') {
    const index = FINDFIELD(database, field);
    targetFields = rest(database[index]);
  } else {
    targetFields = rest(database[field]);
  }

  const targetValues = [];

  arrayEach(resultIndexes, value => {
    targetValues.push(targetFields[value]);
  });

  return COUNT(targetValues);
}

export function DCOUNTA(database, field, criteria) {
  // Return error if field is not a number and not a string
  if (isNaN(field) && typeof field !== 'string') {
    return new EngineError('DCOUNTA', ERROR_VALUE, arguments);
  }

  const resultIndexes = findResultIndex(database, criteria);
  let targetFields = [];

  if (typeof field === 'string') {
    const index = FINDFIELD(database, field);
    targetFields = rest(database[index]);
  } else {
    targetFields = rest(database[field]);
  }

  const targetValues = [];

  arrayEach(resultIndexes, value => {
    targetValues.push(targetFields[value]);
  });

  return COUNTA(targetValues);
}

export function DGET(database, field, criteria) {
  // Return error if field is not a number and not a string
  if (isNaN(field) && typeof field !== 'string') {
    return new EngineError('DGET', ERROR_VALUE, arguments);
  }

  const resultIndexes = findResultIndex(database, criteria);
  let targetFields = [];

  if (typeof field === 'string') {
    const index = FINDFIELD(database, field);
    targetFields = rest(database[index]);
  } else {
    targetFields = rest(database[field]);
  }

  // Return error if no record meets the criteria
  if (resultIndexes.length === 0) {
    return new EngineError('DGET', ERROR_VALUE, arguments);
  }
  // Returns the #NUM! error value because more than one record meets the
  // criteria
  if (resultIndexes.length > 1) {
    return new EngineError('DGET', ERROR_NUM, arguments);
  }

  return targetFields[resultIndexes[0]];
}

export function DMAX(database, field, criteria) {
  // Return error if field is not a number and not a string
  if (isNaN(field) && typeof field !== 'string') {
    return new EngineError('DMAX', ERROR_VALUE, arguments);
  }

  const resultIndexes = findResultIndex(database, criteria);
  let targetFields = [];

  if (typeof field === 'string') {
    const index = FINDFIELD(database, field);
    targetFields = rest(database[index]);
  } else {
    targetFields = rest(database[field]);
  }

  let maxValue = targetFields[resultIndexes[0]];

  arrayEach(resultIndexes, value => {
    if (maxValue < targetFields[value]) {
      maxValue = targetFields[value];
    }
  });

  return maxValue;
}

export function DMIN(database, field, criteria) {
  // Return error if field is not a number and not a string
  if (isNaN(field) && typeof field !== 'string') {
    return new EngineError('DMIN', ERROR_VALUE, arguments);
  }

  const resultIndexes = findResultIndex(database, criteria);
  let targetFields = [];

  if (typeof field === 'string') {
    const index = FINDFIELD(database, field);
    targetFields = rest(database[index]);
  } else {
    targetFields = rest(database[field]);
  }

  let minValue = targetFields[resultIndexes[0]];

  arrayEach(resultIndexes, value => {
    if (minValue > targetFields[value]) {
      minValue = targetFields[value];
    }
  });

  return minValue;
}

export function DPRODUCT(database, field, criteria) {
  // Return error if field is not a number and not a string
  if (isNaN(field) && typeof field !== 'string') {
    return new EngineError('DPRODUCT', ERROR_VALUE, arguments);
  }

  const resultIndexes = findResultIndex(database, criteria);
  let targetFields = [];

  if (typeof field === 'string') {
    const index = FINDFIELD(database, field);
    targetFields = rest(database[index]);
  } else {
    targetFields = rest(database[field]);
  }

  let targetValues = [];

  arrayEach(resultIndexes, value => {
    targetValues.push(targetFields[value]);
  });

  targetValues = compact(targetValues);

  let result = 1;

  arrayEach(targetValues, value => {
    result *= value;
  });

  return result;
}

export function DSTDEV(database, field, criteria) {
  // Return error if field is not a number and not a string
  if (isNaN(field) && typeof field !== 'string') {
    return new EngineError('DSTDEV', ERROR_VALUE, arguments);
  }

  const resultIndexes = findResultIndex(database, criteria);
  let targetFields = [];

  if (typeof field === 'string') {
    const index = FINDFIELD(database, field);
    targetFields = rest(database[index]);
  } else {
    targetFields = rest(database[field]);
  }

  let targetValues = [];

  arrayEach(resultIndexes, value => {
    targetValues.push(targetFields[value]);
  });

  targetValues = compact(targetValues);

  return STDEV_S(targetValues);
}

export function DSTDEVP(database, field, criteria) {
  // Return error if field is not a number and not a string
  if (isNaN(field) && typeof field !== 'string') {
    return new EngineError('DSTDEVP', ERROR_VALUE, arguments);
  }

  const resultIndexes = findResultIndex(database, criteria);
  let targetFields = [];

  if (typeof field === 'string') {
    const index = FINDFIELD(database, field);
    targetFields = rest(database[index]);
  } else {
    targetFields = rest(database[field]);
  }

  let targetValues = [];

  arrayEach(resultIndexes, value => {
    targetValues.push(targetFields[value]);
  });

  targetValues = compact(targetValues);

  return STDEV_P(targetValues);
}

export function DSUM(database, field, criteria) {
  // Return error if field is not a number and not a string
  if (isNaN(field) && typeof field !== 'string') {
    return new EngineError('DSUM', ERROR_VALUE, arguments);
  }

  const resultIndexes = findResultIndex(database, criteria);
  let targetFields = [];

  if (typeof field === 'string') {
    const index = FINDFIELD(database, field);
    targetFields = rest(database[index]);
  } else {
    targetFields = rest(database[field]);
  }

  const targetValues = [];

  arrayEach(resultIndexes, value => {
    targetValues.push(targetFields[value]);
  });

  return SUM(targetValues);
}

export function DVAR(database, field, criteria) {
  // Return error if field is not a number and not a string
  if (isNaN(field) && typeof field !== 'string') {
    return new EngineError('DVAR', ERROR_VALUE, arguments);
  }

  const resultIndexes = findResultIndex(database, criteria);
  let targetFields = [];

  if (typeof field === 'string') {
    const index = FINDFIELD(database, field);
    targetFields = rest(database[index]);
  } else {
    targetFields = rest(database[field]);
  }

  const targetValues = [];

  arrayEach(resultIndexes, value => {
    targetValues.push(targetFields[value]);
  });

  return VAR_S(targetValues);
}

export function DVARP(database, field, criteria) {
  // Return error if field is not a number and not a string
  if (isNaN(field) && typeof field !== 'string') {
    return new EngineError('DVARP', ERROR_VALUE, arguments);
  }

  const resultIndexes = findResultIndex(database, criteria);
  let targetFields = [];

  if (typeof field === 'string') {
    const index = FINDFIELD(database, field);
    targetFields = rest(database[index]);
  } else {
    targetFields = rest(database[field]);
  }

  const targetValues = [];

  arrayEach(resultIndexes, value => {
    targetValues.push(targetFields[value]);
  });

  return VAR_P(targetValues);
}
