import { EngineError, ERROR_NOT_AVAILABLE, ERROR_REF } from '../error';
import { flatten, transpose } from './utils';
import { Value, ErrorValue, LogicalValue, NumberValue } from '.';

/**
 * The MATCH function searches for a specified item in a range of cells, and
 * then returns the relative position of that item in the range. For example, if
 * the range A1:A3 contains the values 5, 25, and 38, then the formula
 * =MATCH(25,A1:A3,0) returns the number 2, because 25 is the second item in the
 * range.
 *
 * @param {Value} lookupValue The value that you want to match in lookup_array.
 * For example, when you look up someone's number in a telephone book, you are
 * using the person's name as the lookup value, but the telephone number is the
 * value you want.
 * @param {Value} lookupArray The range of cells being searched.
 * @param {NumberValue} matchType Optional. The number -1, 0, or 1. The
 * match_type argument specifies how Excel matches lookup_value with values in
 * lookup_array. The default value for this argument is 1.
 */

export function MATCH(
  lookupValue: Value,
  lookupArray: Value,
  matchType: NumberValue = 1
): NumberValue | ErrorValue {
  if (!lookupValue && !lookupArray) {
    return new EngineError('MATCH', ERROR_NOT_AVAILABLE, arguments);
  }
  if (!(lookupArray instanceof Array)) {
    return new EngineError('MATCH', ERROR_NOT_AVAILABLE, arguments);
  }
  if (matchType !== -1 && matchType !== 0 && matchType !== 1) {
    return new EngineError('MATCH', ERROR_NOT_AVAILABLE, arguments);
  }
  let index;
  let indexValue;
  lookupArray = flatten(lookupArray);
  // @ts-ignore
  for (let idx = 0; idx < lookupArray.length; idx++) {
    if (matchType === 1) {
      if (lookupArray[idx] === lookupValue) {
        return idx + 1;
      } else if (lookupArray[idx] < lookupValue) {
        if (!indexValue) {
          index = idx + 1;
          indexValue = lookupArray[idx];
        } else if (lookupArray[idx] > indexValue) {
          index = idx + 1;
          indexValue = lookupArray[idx];
        }
      }
    }
    if (matchType === 0) {
      if (typeof lookupValue === 'string') {
        lookupValue = lookupValue.replace(/\?/g, '.');
        if (lookupArray[idx].toLowerCase().match(lookupValue.toLowerCase())) {
          return idx + 1;
        }
      } else {
        if (lookupArray[idx] === lookupValue) {
          return idx + 1;
        }
      }
    }
    if (matchType === -1) {
      if (lookupArray[idx] === lookupValue) {
        return idx + 1;
      } else if (lookupArray[idx] > lookupValue) {
        if (!indexValue) {
          index = idx + 1;
          indexValue = lookupArray[idx];
        } else if (lookupArray[idx] < indexValue) {
          index = idx + 1;
          indexValue = lookupArray[idx];
        }
      }
    }
  }
  return index
    ? index
    : new EngineError('MATCH', ERROR_NOT_AVAILABLE, arguments);
}

/**
 * Searches for a value in the top row of a table or an array of values, and
 * then returns a value in the same column from a row you specify in the table
 * or array. Use HLOOKUP when your comparison values are located in a row across
 * the top of a table of data, and you want to look down a specified number of
 * rows. Use VLOOKUP when your comparison values are located in a column to the
 * left of the data you want to find.
 *
 * @param {Value} needle The value to be found in the first row of the table.
 * Lookup_value can be a value, a reference, or a text string.
 * @param {Value} table A table of information in which data is looked up. Use a
 * reference to a range or a range name.
 * @param {NumberValue} index The row number in table_array from which the
 * matching value will be returned
 * @param {LogicalValue} rangeLookup Optional. A logical value that specifies
 * whether you want HLOOKUP to find an exact match or an approximate match.
 */

export function HLOOKUP(
  needle: Value,
  table: any /* FIXME. Jaimy I changed the type from Value to any */,
  index: NumberValue,
  rangeLookup: LogicalValue = false
): Value | ErrorValue {
  if (!needle || !table || !index) {
    return new EngineError('HLOOKUP', ERROR_NOT_AVAILABLE, arguments);
  }
  const transposedTable = transpose(table);
  for (let i = 0; i < transposedTable.length; i++) {
    let row = transposedTable[i];
    // @ts-ignore
    if (
      (!rangeLookup && row[0] === needle) ||
      (row[0] === needle ||
        (rangeLookup &&
          typeof row[0] === 'string' &&
          row[0].toLowerCase().indexOf((needle as string).toLowerCase()) !==
            -1))
    ) {
      return index < row.length + 1
        ? row[index - 1]
        : new EngineError('{FUNCTION_NAME}', ERROR_REF, arguments);
    }
  }

  return new EngineError('HLOOKUP', ERROR_NOT_AVAILABLE, arguments);
}

/**
 * Use VLOOKUP, one of the lookup and reference functions, when you need to find
 * things in a table or a range by row. For example, look up a price of an
 * automotive part by the part number.
 *
 * @param {Value} needle The value you want to look up. The value you want to
 * look up must be in the first column of the range of cells you specify in the
 * table_array argument.
 * @param {Value} table The range of cells in which the VLOOKUP will search for
 * the lookup_value and the return value.
 * @param {NumberValue} index The column number (starting with 1 for the
 * left-most column of table_array) that contains the return value.
 * @param {LogicalValue} rangeLookup A logical value that specifies whether you
 * want VLOOKUP to find an approximate or an exact match:
 */

export function VLOOKUP(
  needle: Value,
  table: any /* FIXME. Jaimy I changed the type from Value to any */,
  index: NumberValue,
  rangeLookup: LogicalValue = false
): Value | ErrorValue {
  if (!needle || !table || !index) {
    return new EngineError('VLOOKUP', ERROR_NOT_AVAILABLE, arguments);
  }
  // @ts-ignore
  for (let i = 0; i < table.length; i++) {
    const row = table[i];
    const n = needle.toString().toLowerCase();
    const v = row[0].toString().toLowerCase();
    const withoutRangeLookup = !rangeLookup && v === n;
    const withRangeLookup =
      v === n ||
      (rangeLookup && typeof row[0] === 'string' && v.indexOf(n) !== -1);
    if (withoutRangeLookup || withRangeLookup) {
      return index < row.length + 1
        ? row[index - 1]
        : new EngineError('{FUNCTION_NAME}', ERROR_REF, arguments);
    }
  }
  return new EngineError('VLOOKUP', ERROR_NOT_AVAILABLE, arguments);
}
