var utils = require("../utils");
var jStat = require("jStat").jStat;

import { EngineError, ERROR_NUM, ERROR_VALUE } from "../../error";

/**
 * Returns the negative binomial distribution, the probability that there will
 * be Number_f failures before the Number_s-th success, with Probability_s
 * probability of a success.
 * 
 * @param k The number of failures.
 * @param r The threshold number of successes.
 * @param p The probability of a success.
 * @param cumulative A logical value that determines the form of the function.
 * If cumulative is TRUE, NEGBINOM.DIST returns the cumulative distribution
 * function; if FALSE, it returns the probability density function.
 */
export function NEGBINOM_DIST(k, r, p, cumulative) {
  k = utils.parseNumber(k);
  r = utils.parseNumber(r);
  p = utils.parseNumber(p);
  if (utils.anyIsError(k, r, p)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  return cumulative ? jStat.negbin.cdf(k, r, p) : jStat.negbin.pdf(k, r, p);
}

/**
 * Returns the normal distribution for the specified mean and standard
 * deviation.
 * 
 * @param x The arithmetic mean of the distribution.
 * @param mean The arithmetic mean of the distribution.
 * @param sd  The standard deviation of the distribution.
 * @param cumulative A logical value that determines the form of the function.
 * If cumulative is TRUE, NORM.DIST returns the cumulative distribution
 * function; if FALSE, it returns the probability density function.
 */
export function NORM_DIST(x, mean, sd, cumulative) {
  x = utils.parseNumber(x);
  mean = utils.parseNumber(mean);
  sd = utils.parseNumber(sd);
  if (utils.anyIsError(x, mean, sd)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  if (sd <= 0) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NUM, arguments);
  }

  // Return normal distribution computed by jStat [http://jstat.org]
  return cumulative
    ? jStat.normal.cdf(x, mean, sd)
    : jStat.normal.pdf(x, mean, sd);
}

/**
 * Returns the inverse of the normal cumulative distribution for the specified
 * mean and standard deviation.
 * 
 * @param probability  A probability corresponding to the normal distribution.
 * @param mean The arithmetic mean of the distribution.
 * @param sd The standard deviation of the distribution.
 */
export function NORM_INV(probability, mean, sd) {
  probability = utils.parseNumber(probability);
  mean = utils.parseNumber(mean);
  sd = utils.parseNumber(sd);
  if (utils.anyIsError(probability, mean, sd)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  return jStat.normal.inv(probability, mean, sd);
}

/**
 * Returns the standard normal distribution (has a mean of zero and a standard
 * deviation of one).
 * 
 * @param z The value for which you want the distribution.
 * @param cumulative Cumulative is a logical value that determines the form of
 * the function. If cumulative is TRUE, NORMS.DIST returns the cumulative
 * distribution function; if FALSE, it returns the probability mass function.
 */
export function NORM_S_DIST(z, cumulative) {
  z = utils.parseNumber(z);
  if (z instanceof Error) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  return cumulative ? jStat.normal.cdf(z, 0, 1) : jStat.normal.pdf(z, 0, 1);
}

/**
 * Returns the inverse of the standard normal cumulative distribution. The
 * distribution has a mean of zero and a standard deviation of one.
 * 
 * @param probability A probability corresponding to the normal distribution.
 */
export function NORM_S_INV(probability) {
  probability = utils.parseNumber(probability);
  if (probability instanceof Error) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  return jStat.normal.inv(probability, 0, 1);
}