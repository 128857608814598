import { onFullfilled, onRejection } from './utils';
import { __, i18n } from '@spreax/i18n';
import { ActionTypes } from '../action-types';
import { Template, TenantGUID } from '@spreax/model';
import * as DataManager from '.';
import * as API from '@spreax/api-client';

export namespace template {
  export function get(tenantId: TenantGUID): Promise<void> {
    return API.template.get(tenantId).then(
      onFullfilled(null, null, (data: Template[]) => {
        return {
          type: ActionTypes.RECEIVE_TEMPLATES,
          templates: data
        };
      }),
      onRejection()
    );
  }

  export function save(
    tenantId: TenantGUID,
    template: Template
  ): Promise<void> {
    const data = {
      id: template.id,
      title: template.title,
      description: template.description,
      isHidden: template.isHidden,
      isLocked: template.isLocked,
      isPublic: template.isPublic
    };
    return API.template.update(data).then(
      onFullfilled(
        i18n.FEEDBACK.CHANGES_SAVED,
        [() => get(tenantId), () => DataManager.folder.get(tenantId)],
        data => {
          return {
            type: ActionTypes.SAVED_TEMPLATE,
            id: template.id
          };
        }
        // '/template/' + template.id + '/configure',
      ),
      onRejection()
    );
  }
}
