import { UserGUID } from '.';

export type FolderId = number;
export type FolderGUID = string;

export interface Folder {
  id: FolderGUID;
  guid: FolderGUID;
  type: FileType;
  parentId: FolderGUID;
  path: FolderGUID[];
  title: string;
  isActive: boolean;
  lastUpdatedBy: UserGUID;
  dateCreated: Date;
  dateLastUpdated: Date;
  dateDeleted: Date;
}

export interface FolderDelta {
  title?: string;
  parentId?: FolderGUID;
}

export interface NewFolder {
  type: FileType;
  parentId?: FolderGUID;
  title: string;
  guid?: string;
  objectId?: string;
}

export enum FileType {
  Folder = 'FOLDER',
  Project = 'PROJECT',
  Template = 'TEMPLATE',
  Attachment = 'ATTACHMENT'
}
