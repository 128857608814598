var utils = require("../utils");
var jStat = require("jStat").jStat;

import { EngineError, ERROR_VALUE } from "../../error";

/**
 * Returns the sum of squares of deviations of data points from their sample
 * mean.
 * 
 * @param arg[] arg1 is required, subsequent args are optional. 1 to 255
 * arguments for which you want to calculate the sum of squared deviations. 
 */
export function DEVSQ(...args) {
  var range = utils.parseNumberArray(utils.flatten(args));
  if (range instanceof Error) {
    return range;
  }
  var mean = jStat.mean(range);
  var result = 0;
  for (var i = 0; i < range.length; i++) {
    result += Math.pow(range[i] - mean, 2);
  }
  return result;
}

// TODO NOT_DEFINED in https://support.office.com/
export function DIST(x, df, cumulative) {
  x = utils.parseNumber(x);
  df = utils.parseNumber(df);
  if (utils.anyIsError(x, df)) {
    return new EngineError("DIST", ERROR_VALUE, arguments);
  }
  return cumulative ? jStat.studentt.cdf(x, df) : jStat.studentt.pdf(x, df);
}