import * as React from 'react';
import { useState, useEffect } from 'react';
import { setLanguage } from '@spreax/i18n';
import { ElementGrid } from './element-grid';
import { Tabs, Tab, Panels, Panel } from './tabs';
import { Actions } from './actions';
import { ErrorView } from './error-view';
import { ErrorBoundary } from './error-boundary';
import { useTemplate, useEngine, useEngineState, useEngineSession, useEngineActions, useError } from './hooks';

import '../css/base.scss';

export function WidgetView (props) {
  const { apiEndpoint, templateId, templateDefinition, session, onStateChange, onError, onUpdate, lang } = props;

  setLanguage(lang);
  const [error, setError] = useError({ onError });

  const template = props.template || useTemplate({ apiEndpoint, templateId, setError });
  const [engine, setEngine] = useEngine({ template, templateDefinition, session, setError });
  const [engineState, setEngineState] = useEngineState({ engine, onUpdate, onStateChange });
  const [engineActions] = useEngineActions(engine);
  useEngineSession({ engine, session });

  const tabs = engineState && engineState.elements.filter(element => element.type === 'tab');
  const initialTab = tabs && tabs[0] ? tabs[0].id : null;
  const [selectedTab, setSelectedTab] = useState(initialTab);

  if (!selectedTab && initialTab) {
    setSelectedTab(initialTab);
  }

  if (error) {
    return (
      <ErrorView
        message={error.message}
      />
    );
  }

  if (!engine || !template || !engineState) {
    return (
      <div
        className="sx-widget"
      >
        <div
          className="sx-widget__loader"
        >
          Loading..
        </div>
      </div>
    );
  }

  const state = {
    ...props,
    tabs,
    selectedTab,
    setSelectedTab,
    template,
    actions: engineActions,
  };

  return (
    <div
      className="sx-widget"
    >
      <Tabs
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      >
        {tabs.map((tab, index) => {
          return (
            <Tab
              key={tab.id}
            >
              {tab.value}
            </Tab>
          );
        })}
      </Tabs>
      <Panels
        selectedTab={selectedTab}
      >
        {tabs.map((tab, index) => {
          return (
            <Panel
              key={tab.id}
            >
              <ElementGrid
                {...props}
                {...engineState}
                actions={engineActions}
                selectedParent={selectedTab}
              />
            </Panel>
          );
        })}
      </Panels>
      <Actions
        {...state}
      />
    </div>
  );
}

export function Widget (props) {
  return (
    <ErrorBoundary>
      <WidgetView {...props} />
    </ErrorBoundary>
  );
}
