var utils = require("../utils");

import {
  EngineError,
  ERROR_VALUE,
  ERROR_NOT_AVAILABLE
} from "../../error";

import { FORECAST } from "./statistical-f";

/**
 * Calculates the point at which a line will intersect the y-axis by using
 * existing x-values and y-values. The intercept point is based on a best-fit
 * regression line plotted through the known x-values and known y-values.
 * 
 * @param known_y The dependent set of observations or data.
 * @param known_x The independent set of observations or data
 */
export function INTERCEPT(known_y, known_x) {
  known_y = utils.parseNumberArray(known_y);
  known_x = utils.parseNumberArray(known_x);
  if (utils.anyIsError(known_y, known_x)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  if (known_y.length !== known_x.length) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NOT_AVAILABLE, arguments);
  }
  return FORECAST(0, known_y, known_x);
}