import * as Parser from '@spreax/xlsx-parser';
import { Model, TemplateDefinition, TemplateElement } from './types';
import { uuid } from '@spreax/lib';

export namespace TemplateParser {

  /**
   * Get `Template` based on parsed `Workbook`
   *
   * @param {Workbook} workbook
   * @returns {Model}
   */

  export function parse (workbook: Parser.Workbook) : Model {
    if (!workbook) {
      return null;
    }
    return {
      definedNames: getDefinedNames(workbook),
      cells: getCells(workbook),
      templateDefinition: getTemplateDefinition(workbook),
      sanitizedWorksheetNames: workbook.sanitizedWorksheetNames,
    };
  }

  /**
   * Get all cells in all worksheet
   *
   * @param {Workbook} workbook
   * @returns {Cell[]}
   */

  export function getCells (workbook: Parser.Workbook) : Parser.WorksheetCell[] {
    const cells = [];
    for (const worksheet of workbook.worksheets) {
      if (worksheet && worksheet.data) {
        for (const row of worksheet.data) {
          for (const cell of row) {
            cell.sheet = worksheet.name;
            cell.sheetRef = worksheet.ref;
            cells.push(cell);
          }
        }
      }
    }
    return cells.filter(cell => cell.sheet !== 'SPREAX_ELEMENTS');
  }

  /**
   * Get all defined names for whole workbook
   *
   * @param {Parser.Workbook} workbook
   * @returns {DefinedName[]}
   */

  export function getDefinedNames (workbook: Parser.Workbook) : Parser.DefinedName[] {
    return workbook.definedNames.map((definedName) => {
      return definedName;
    });
  }

  /**
   *
   * @param {Parser.Workbook} workbook
   * @returns {TemplateDefinition}
   */

  export function getTemplateDefinition (workbook: Parser.Workbook) : TemplateDefinition {
    const elementWorksheet = workbook.worksheets.find(worksheet => worksheet.name === 'SPREAX_ELEMENTS');
    if (!elementWorksheet || !elementWorksheet.data) {
      return null;
    }
    const elements = elementWorksheet.data
      .filter((row, index) => index >= 1)
      .map((row) => {
        return elementWorksheet.data[0].reduce(
          (element, field) => {
            const property = row.find(cell => cell.column === field.column);
            if (property) {
              element[field.value] = property.formula && `=${property.formula.expression}` || property.value;
            }
            return element;
          },
          {} as TemplateElement,
        );
      });
    return {
      elements,
    };
  }

}
