import * as React from 'react';
import { Dropdown as DropdownBase } from '@spreax/components';

function sanitize (value) {
  return value.toString().toLowerCase();
}

export function Dropdown (props) {
  const { property, onBlur, onChange, onKeyPress } = props;
  if (!property.options) {
    console.log('No options found for property:', property);
    property.options = [];
  }
  return (
    <DropdownBase
      value={property.value ? sanitize(property.value) : ''}
      options={property.options.map((option) => {
        return {
          key: option,
          value: sanitize(option),
          label: option,
        };
      })}
      onChange={(e) => {
        const option = property.options.find(option => sanitize(option) === e.target.value);
        onChange(property)(option);
        setTimeout(onBlur(property), 0);
      }}
      onKeyPress={onKeyPress}
    />
  );
}
