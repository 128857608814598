import * as React from 'react';
import * as classNames from 'classnames';
import { __, i18n } from '@spreax/i18n';
import { FileBrowserItem } from '.';
import { DropTarget } from 'react-dnd';
import { collectDrop, drop, DROP_TYPE } from './drag-and-drop';

function canDrop(props, monitor) {
  const source = monitor.getItem();
  return (
    props.folderLevel !== source.parentId && !props.path.includes(source.id)
  );
}

function FileBrowserLevelDropComponent(props) {
  const {
    onSelectFile,
    path,
    depth,
    connectDropTarget,
    canDrop,
    isOver,
    isDragging
  } = props;
  if (!isDragging) {
    return null;
  }
  return connectDropTarget(
    <div
      className={classNames('sx-file-browser__level__blank', {
        'sx-file-browser__level__blank--is-dragging': isDragging,
        'sx-file-browser__level__blank--is-target': canDrop && isOver
      })}
      onClick={onSelectFile(path[depth])}
    >
      {<React.Fragment>{__(i18n.FILE_BROWSER.DROP_FILE)}</React.Fragment>}
    </div>
  );
}

export const FileBrowserLevelDrop = DropTarget(
  DROP_TYPE,
  { canDrop, drop },
  collectDrop
)(FileBrowserLevelDropComponent);

export function FileBrowserLevel(props) {
  const {
    folders,
    onSelectFile,
    onMoveFile,
    onBeginDrag,
    onEndDrag,
    folderLevel,
    path
  } = props;

  const filteredFolders = folders
    .filter(
      folder =>
        folder.parentId === folderLevel ||
        (folderLevel === 'ROOT' && !folder.parentId)
    )
    .sort(
      (a, b) => +new Date(b.dateLastUpdated) - +new Date(a.dateLastUpdated)
    );

  const folderCount = filteredFolders.length;

  return (
    <div className="sx-file-browser__level">
      <div className="sx-file-browser__headers">
        <div className="sx-file-browser__headers__icon"></div>
        <div className="sx-file-browser__headers__name">Name</div>
        <div className="sx-file-browser__headers__last-updated">
          Last Updated
        </div>
        <div className="sx-file-browser__headers__extra"></div>
      </div>
      {filteredFolders.map((folder, index) => {
        return (
          <FileBrowserItem
            key={index}
            {...folder}
            onClick={onSelectFile(folder.id, folder)}
            isSelected={path.includes(folder.id)}
            onMoveFile={onMoveFile(folder.id, folder)}
            onBeginDrag={onBeginDrag}
            onEndDrag={onEndDrag}
          />
        );
      })}
      {folderCount === 0 && (
        <div className="sx-file-browser__level__no-content">
          {__(i18n.FILE_BROWSER.NO_CONTENT)}
        </div>
      )}
      <FileBrowserLevelDrop {...props} id={folderLevel} />
      <div className="sx-file-browser__footer">
        {folderCount}{' '}
        {__(
          folderCount === 1 ? i18n.FILE_BROWSER.FILE : i18n.FILE_BROWSER.FILES
        )}
      </div>
    </div>
  );
}
