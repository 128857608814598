import { call, createFormData, getURL } from '..';

export namespace project {
  const PATH = 'projects';

  export function get(tenantId) {
    return call(getURL('tenants', tenantId, PATH), {
      method: 'GET'
    });
  }

  export function create(tenantId, project) {
    const formData = createFormData(project);
    return call(getURL('tenants', tenantId, PATH), {
      method: 'POST',
      body: formData
    });
  }

  export function update(project) {
    const formData = createFormData(project);
    return call(getURL(PATH, project.id), {
      method: 'PATCH',
      body: formData
    });
  }

  export function remove(project) {
    return call(getURL(PATH, project.id), {
      method: 'DELETE'
    });
  }
}
