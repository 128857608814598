import { EngineError, ERROR_NOT_AVAILABLE } from "../../error";
import { CEILING } from "../math-and-trigonometry";

import { parseNumber } from "../utils";

// TODO NOT_DEFINED in https://support.office.com/
export function E() {
  return Math.E;
}

// TODO NOT_DEFINED in https://support.office.com/
export function EQ(value1, value2) {
  if (arguments.length !== 2) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NOT_AVAILABLE, arguments);
  }

  return value1 === value2;
}

/**
 * Returns number rounded up to the nearest even integer. You can use this
 * function for processing items that come in twos. For example, a packing crate
 * accepts rows of one or two items. The crate is full when the number of items,
 * rounded up to the nearest two, matches the crate's capacity.
 *
 * @param number The value to round.
 */
export function EVEN(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  return CEILING(number, -2, -1);
}

/**
 * Returns e raised to the power of number. The constant e equals
 * 2.71828182845904, the base of the natural logarithm.
 *
 * @param number The exponent applied to the base e.
 */
export const EXP = Math.exp;
