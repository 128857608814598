import { parseNumber } from "../utils";
import { CEILING } from "./math-c";
import { SupportedFunctions } from "src/supported-functions";

/**
 * Rounds a number down to the nearest integer.
 *
 * @param number The real number you want to round down to an integer.
 */
export function INT(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  return Math.floor(number);
}

/**
 * Returns a number that is rounded up to the nearest integer or to the nearest
 * multiple of significance. Regardless of the sign of the number, the number is
 * rounded up. However, if the number or the significance is zero, zero is
 * returned.
 *
 * @param number The real number you want to round down to an integer.
 * @param significance Optional. The optional multiple to which number is to
 * be rounded.
 * 
 * TODO:
 * - verify
 * - DOTTED_ACCESS: STDEV_S vs STDEV.S 
 */

export const ISO = {
  CEILING: CEILING
};
