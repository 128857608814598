// tslint:disable object-shorthand-properties-first

import Dispatcher from '../dispatcher';
import { ActionTypes } from '../action-types';
import * as Actions from '.';
import { TemplateId, TenantGUID } from '@spreax/model';

export namespace template {
  export interface ITemplate {
    select(id: TemplateId): void;
    update(id: TemplateId, field: string, value: any): void;
    save(id: TemplateId): void;
    print(id: TemplateId, session: string): void;
    makeSureTemplateIsSelected(props);
    makeSureHasAccessToTemplate(props);
  }

  export function select(id: TemplateId): void {
    Actions.project.draft(null, null);
    Actions.project.select(null);
    Dispatcher.dispatch({
      type: ActionTypes.SELECT_TEMPLATE,
      id
    });
  }

  export function update(id: TemplateId, field: string, value: any): void {
    Dispatcher.dispatch({
      type: ActionTypes.UPDATE_TEMPLATE,
      id,
      field,
      value
    });
  }

  export function save(tenantId: TenantGUID, id: TemplateId) {
    Actions.app.setLoading();
    Dispatcher.dispatch({
      type: ActionTypes.SAVE_TEMPLATE,
      tenantId,
      id
    });
  }

  export function print(id: TemplateId, session: string): void {
    Dispatcher.dispatch({
      type: ActionTypes.PRINT_TEMPLATE,
      id,
      session
    });
  }

  export function makeSureTemplateIsSelected(props) {
    return async (getState, dispatch) => {
      const { route } = props;
      const {
        actions,
        selectedTemplate,
        hasReceivedTemplates,
        hasReceivedProjects,
        templates
      } = getState();
      const templateId = route.match.params.templateId;
      if (
        hasReceivedTemplates &&
        hasReceivedProjects &&
        templateId &&
        (!selectedTemplate || selectedTemplate.id !== templateId)
      ) {
        const hasTemplate = templates.find(
          template => template.id === templateId
        );
        if (hasTemplate) {
          await dispatch(() => actions.template.select(templateId));
        } else {
          route.history.push('/error/404');
        }
      }
    };
  }

  export function makeSureHasAccessToTemplate(props) {
    return async (getState, dispatch) => {
      const { route } = props;
      const { selectedTemplate, selectedProject, profile } = getState();
      // if (!selectedProject && selectedTemplate && selectedTemplate.isHidden && !profile.isSysAdmin) {
      //   route.history.push('/');
      // }
    };
  }
}
