import { ReduceStore } from 'flux/utils';
import Dispatcher from '../dispatcher';
import { ActionTypes } from '../action-types';
import * as DataManager from '../data-managers';
import { uuid } from '@spreax/lib';

function onUploadAttachment(state: AttachmentState, action): AttachmentState {
  DataManager.attachment.upload(
    {
      id: uuid(),
      parentId: action.parent,
      title: action.name
    },
    action.binary
  );
  return state;
}

function onDownloadAttachment(state: AttachmentState, { id }): AttachmentState {
  DataManager.attachment.download(id);
  return state;
}

function onRemoveAttachment(state: AttachmentState, { id }): AttachmentState {
  DataManager.attachment.remove(id);
  return state;
}

function onLogout(state: AttachmentState, action): AttachmentState {
  return {};
}

class AttachmentStore extends ReduceStore<AttachmentState, any> {
  constructor() {
    super(Dispatcher);
  }

  getInitialState() {
    return {};
  }

  reduce(state: AttachmentState, action): AttachmentState {
    switch (action.type) {
      case ActionTypes.UPLOAD_ATTACHMENT:
        return onUploadAttachment(state, action);

      case ActionTypes.DOWNLOAD_ATTACHMENT:
        return onDownloadAttachment(state, action);

      case ActionTypes.REMOVE_ATTACHMENT:
        return onRemoveAttachment(state, action);

      case ActionTypes.LOGOUT:
        return onLogout(state, action);

      default:
        return state;
    }
  }
}

export interface AttachmentState {}

export const Store = new AttachmentStore();
