import * as React from 'react';
import '../../../src/components/persona/style.scss';
import { __, i18n } from '@spreax/i18n';
import * as classNames from 'classnames';

export function Persona(props) {
  const { profile, onClick } = props;
  return (
    <div className="sx-persona" onClick={onClick}>
      <div className="sx-persona__avatar">JG</div>
      <div className="sx-persona__name">Jaimy de Graaf</div>
    </div>
  );
}
