var utils = require("../utils");
var jStat = require("jStat").jStat;

import { EngineError, ERROR_NUM } from "../../error";

/**
 * Returns the largest value in a set of values.
 * 
 * @param args Number1, number2, ... Number1 is required, subsequent numbers are
 * optional. 1 to 255 numbers for which you want to find the maximum value.
 */
export function MAX(...args: any[]) {
  var range = utils.numbers(utils.flatten(args));
  return range.length === 0 ? 0 : Math.max.apply(Math, range);
}

/**
 * Returns the largest value in a list of arguments.
 * 
 * @param args Number1, number2, ... Number1 is required, subsequent numbers are
 * optional. 1 to 255 numbers for which you want to find the maximum value.
 */
export function MAXA(...args: any[]) {
  var range = utils.arrayValuesToNumbers(utils.flatten(args));
  return range.length === 0 ? 0 : Math.max.apply(Math, range);
}

/**
 * Returns the median of the given numbers. The median is the number in the
 * middle of a set of numbers.
 * 
 * @param args Number1, number2, ... Number1 is required, subsequent numbers are
 * optional. 1 to 255 numbers for which you want the median.
 */
export function MEDIAN(...args: any[]) {
  var range = utils.arrayValuesToNumbers(utils.flatten(args));
  var result = jStat.median(range);
  if (isNaN(result)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NUM, args);
  }
  return result;
}

/**
 * Returns the smallest number in a set of values.
 * 
 * @param args Number1, number2, ... Number1 is optional, subsequent numbers
 * are optional. 1 to 255 numbers for which you want to find the minimum value.
 */
export function MIN(...args: any[]) {
  var range = utils.numbers(utils.flatten(args));
  return range.length === 0 ? 0 : Math.min.apply(Math, range);
}

/**
 * Returns the smallest value in the list of arguments. 
 * 
 * @param args Number1, number2, ... Number1 is optional, subsequent numbers
 * are optional. 1 to 255 numbers for which you want to find the minimum value.
 */
export function MINA(...args: any[]) {
  var range = utils.arrayValuesToNumbers(utils.flatten(args));
  return range.length === 0 ? 0 : Math.min.apply(Math, range);
}

/**
 * Returns a vertical array of the most frequently occurring, or repetitive
 * values in an array or range of data. 
 * 
 * @param range Number1, number2, ... Number1 is optional, subsequent numbers
 * are optional. 1 to 255 numbers for which you want to find the minimum value.
 */
export function MODE_MULT(range) {
  // Credits: Roönaän
  var range = utils.parseNumberArray(utils.flatten(arguments));
  if (range instanceof Error) {
    return range;
  }
  var n = range.length;
  var count = {};
  var maxItems = [];
  var max = 0;
  var currentItem;

  for (var i = 0; i < n; i++) {
    currentItem = range[i];
    count[currentItem] = count[currentItem] ? count[currentItem] + 1 : 1;
    if (count[currentItem] > max) {
      max = count[currentItem];
      maxItems = [];
    }
    if (count[currentItem] === max) {
      maxItems[maxItems.length] = currentItem;
    }
  }
  return maxItems;
}

/**
 * Returns the most frequently occurring, or repetitive, value in an array or
 * range of data. 
 * 
 * @param args arg1, arg2, ... arg1 is optional, subsequent numbers
 * are optional. 1 to 255 numbers for which you want to find the minimum value.
 */
export function MODE_SNGL(...args) {
  var range = utils.parseNumberArray(utils.flatten(args));
  if (range instanceof Error) {
    return range;
  }
  // @ts-ignore
  return MODE_MULT(range).sort(function(a, b) {
    return a - b;
  })[0];
}
