export const config = {
  API_ENDPOINT: null,
  SENTRY_URI: null,
  GA_TRACKING_ID: null,
};

export function loadConfig (delta) {
  for (const key in config) {
    config[key] = delta[key] || config[key];
  }
}
