import * as React from 'react';
import '../../../src/pages/folder/style.scss';
import { State } from '../../container';
import { __, i18n } from '@spreax/i18n';
import { App } from '../frame/app';
import { FileBrowser, Page, PageTop, Editable, Button } from '../../components';
import { FileType } from '@spreax/model';

export class Folder extends React.PureComponent<State> {
  constructor(props) {
    super(props);
    this.onSelectFile = this.onSelectFile.bind(this);
    this.onSaveFolder = this.onSaveFolder.bind(this);
    this.onRemoveFolder = this.onRemoveFolder.bind(this);
    this.onSelectParent = this.onSelectParent.bind(this);
    this.onCreateFolder = this.onCreateFolder.bind(this);
    this.onMoveFile = this.onMoveFile.bind(this);
    this.onFolderTitleChange = this.onFolderTitleChange.bind(this);
  }

  onSelectFile(id) {
    const { actions, route, folders } = this.props;
    const file = folders.find(file => file.id === id);
    switch (file.type) {
      case FileType.Folder:
        actions.group.selectFolder(file.id);
        break;
      case FileType.Template:
        route.history.push(`/template/${file.objectId}`);
        actions.app.closeSidePanel();
        return;
      case FileType.Project:
        route.history.push(`/project/${file.objectId}`);
        actions.app.closeSidePanel();
        return;
      default:
      //
    }
  }

  onSaveFolder(id) {
    const { actions, selectedTenant } = this.props;
    actions.group.saveFolder(selectedTenant.id, id);
  }

  onRemoveFolder(id) {
    const { actions, selectedTenant } = this.props;
    actions.group.removeFolder(selectedTenant.id, id);
  }

  onSelectParent(parentId) {
    const { actions } = this.props;
    if (parentId) {
      actions.group.selectFolder(parentId);
    }
  }

  onCreateFolder(parentId) {
    const { actions, selectedTenant } = this.props;
    actions.group.createFolder(selectedTenant.id, parentId);
  }

  onMoveFile(id, folder, target) {
    const { actions, selectedTenant } = this.props;
    actions.group.moveFile(selectedTenant.id, id, target, folder.type);
  }

  onFolderTitleChange(id, folder, value) {
    const { actions, selectedTenant } = this.props;
    actions.group.updateFolder(selectedTenant.id, id, 'title', value);
  }

  render() {
    const { folders, selectedFolder } = this.props;
    return (
      <App {...this.props} documentTitle="">
        <Page>
          <PageTop
            primary={
              <Editable
                className="sx-editable sx-file-browser__selected-folder"
                disabled={!selectedFolder}
                onChange={ev =>
                  this.onFolderTitleChange(
                    selectedFolder.id,
                    selectedFolder,
                    ev.target.value
                  )
                }
                onBlur={() => this.onSaveFolder(selectedFolder.id)}
                html={selectedFolder ? selectedFolder.title || '' : ''}
              ></Editable>
            }
            actionsRight={[
              <Button
                icon="create_new_folder"
                onClick={() =>
                  this.onCreateFolder(selectedFolder ? selectedFolder.id : null)
                }
                iconOnly={true}
                isLean={true}
              />,
              <Button
                icon="delete"
                onClick={() => this.onRemoveFolder(selectedFolder.id)}
                iconOnly={true}
                isLean={true}
                // isDisabled={!selectedFolder.parentId || !this.folderIsEmpty(selectedFolder.id)}
              />
            ]}
          />
          <div className="sx-page__contents">
            <FileBrowser
              {...this.props}
              folders={folders}
              selectedFolder={selectedFolder}
              onSelectFile={this.onSelectFile}
              onSaveFolder={this.onSaveFolder}
              onRemoveFolder={this.onRemoveFolder}
              onSelectParent={this.onSelectParent}
              onCreateFolder={this.onCreateFolder}
              onMoveFile={this.onMoveFile}
              onFolderTitleChange={this.onFolderTitleChange}
            />
          </div>
        </Page>
      </App>
    );
  }
}
