export const ActionTypes = {

  LOAD_TEMPLATES: 'template/load',
  SELECT_TEMPLATE: 'template/select',
  RECEIVE_TEMPLATES: 'template/receive',
  UPDATE_TEMPLATE: 'template/update',
  SAVE_TEMPLATE: 'template/save',
  SAVED_TEMPLATE: 'template/saved',
  PRINT_TEMPLATE: 'template/print',

  LOAD_PROJECTS: 'project/load',
  SELECT_PROJECT: 'project/select',
  CREATE_PROJECT: 'project/create',
  DUPLICATE_PROJECT: 'project/duplicate',
  SAVE_PROJECT: 'project/save',
  UPDATE_PROJECT: 'project/update',
  DRAFT_PROJECT: 'project/draft',
  DEACTIVATE_PROJECT: 'project/deactivate',
  RECEIVE_PROJECTS: 'project/receive',
  CREATED_PROJECT: 'project/created',

  LOAD_FOLDERS: 'folder/load',
  RECEIVE_FOLDERS: 'folder/receive',

  MOVE_FILE: 'file/move',

  SELECT_FOLDER: 'folder/select',
  CREATE_FOLDER: 'folder/create',
  REMOVE_FOLDER: 'folder/remove',
  UPDATE_FOLDER: 'folder/update',
  SAVE_FOLDER: 'folder/save',

  UPLOAD_ATTACHMENT: 'attachment/upload',
  UPLOADED_ATTACHMENT: 'attachment/uploaded',
  DOWNLOAD_ATTACHMENT: 'attachment/download',
  DOWNLOADED_ATTACHMENT: 'attachment/downloaded',
  REMOVE_ATTACHMENT: 'attachment/remove',
  REMOVED_ATTACHMENT: 'attachment/removed',

  ADD_FEEDBACK: 'feedback/add',
  REMOVE_FEEDBACK: 'feedback/remove',

  LOGIN: 'auth/login',
  LOGOUT: 'auth/logout',
  LOGGED_IN: 'auth/logged-in',

  SELECT_TENANT: 'tenant/select',

  SELECT_GROUP: 'group/select',

  OPEN_SIDEPANEL: 'app/open-sidepanel',
  CLOSE_SIDEPANEL: 'app/close-sidepanel',
  TOGGLE_SIDEPANEL: 'app/toggle-sidepanel',

  OPEN_DRAWER: 'app/open-drawer',
  CLOSE_DRAWER: 'app/close-drawer',

  SET_LOADING: 'app/set-loading',
  UNSET_LOADING: 'app/unset-loading',

  PAGE_VIEW: 'app/page-view',
  SET_REDIRECT: 'app/set-redirect',
  UNSET_REDIRECT: 'app/unset-redirect',

  UPDATE_PROFILE: 'profile/update',
  SAVE_PROFILE: 'profile/save',

  SET_SPREAX_CLIENT: 'client/set-spreax-client',
  SET_SPREAX_STATE: 'client/set-spreax-state',
  SET_SPREAX_ERROR: 'client/set-spreax-error',

  REQUEST_ACCOUNTS: 'settings/request-accounts',
  RECEIVE_ACCOUNTS: 'settings/receive-accounts',
  REQUEST_SELECT_ACCOUNT: 'settings/request-select-account',
  REQUEST_DELETE_GROUP_FROM_ACCOUNT: 'settings/request-delete-group-from-account',

};
