import * as React from 'react';
import '../../../src/pages/select-tenant/style.scss';
import { __, i18n } from '@spreax/i18n';
import { Feedback, DocumentTitle, Card } from '../../components';
import { State } from '../../container';
import * as moment from 'moment';

interface ISelectTenant {
  username: string;
  password: string;
}

export class SelectTenant extends React.Component<State, ISelectTenant> {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const { actions, middleware } = this.props;
    await middleware(
      actions.user.makeSureUserIsLoggedIn(this.props),
      actions.tenant.makeSureHasSelectedTenant(this.props)
    );
  }

  async componentDidUpdate() {
    const { actions, middleware } = this.props;
    await middleware(
      actions.user.makeSureUserIsLoggedIn(this.props),
      actions.tenant.makeSureHasSelectedTenant(this.props)
    );
  }

  render() {
    const { actions, messages, tenants } = this.props;
    return (
      <div className="sx-select-tenant">
        <DocumentTitle title={__(i18n.LOGIN.DOCUMENT_TITLE)} />

        <div className="sx-select-tenant__content">
          <div className="sx-select-tenant__content__wrapper">
            <div className="sx-select-tenant__content__header">
              <div className="sx-select-tenant__content__header__title">
                {__(i18n.SELECT_TENANT.TITLE)}
              </div>
            </div>
            <div className="sx-select-tenant__tenants">
              {tenants.map((tenant, index) => {
                return (
                  <Card
                    key={index}
                    className="sx-select-tenant__tenant"
                    onClick={() => actions.tenant.selectTenant(tenant.id)}
                  >
                    <div className="sx-select-tenant__tenant__logo">
                      <img src={tenant.logo} width="150" />
                    </div>
                  </Card>
                );
              })}
            </div>
          </div>
        </div>
        <Feedback messages={messages} />
      </div>
    );
  }
}
