import { ReduceStore } from 'flux/utils';
import Dispatcher from '../dispatcher';
import { ActionTypes } from '../action-types';

function onAddFeedback(state: FeedbackState, action): FeedbackState {
  return Object.assign({}, state, {
    messages: [
      {
        level: action.level,
        message: action.message
      }
    ]
  });
}

function onRemoveFeedback(state: FeedbackState, action): FeedbackState {
  return Object.assign({}, state, {
    messages: []
  });
}

class FeedbackStore extends ReduceStore<FeedbackState, any> {
  constructor() {
    super(Dispatcher);
  }

  getInitialState() {
    return {
      messages: []
    };
  }

  reduce(state: FeedbackState, action): FeedbackState {
    switch (action.type) {
      case ActionTypes.ADD_FEEDBACK:
        return onAddFeedback(state, action);

      case ActionTypes.REMOVE_FEEDBACK:
        return onRemoveFeedback(state, action);

      default:
        return state;
    }
  }
}

export interface Feedback {
  level: string;
  message: string;
}

export interface FeedbackState {
  messages: Feedback[];
}

export const Store = new FeedbackStore();
