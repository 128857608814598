import * as React from 'react';
import '../../../src/pages/template/style.scss';
import { State } from '../../container';
import { __, i18n } from '@spreax/i18n';
import { App } from '../frame/app';
import { Top } from './top';
import { TemplateDrawer } from './drawer';
import { Widget } from '@spreax/widget';
import { config } from '../../config';

function WidgetLoader(props) {
  const {
    actions,
    selectedTemplate,
    selectedProject,
    projectDraft,
    token,
    language
  } = props;
  const session = selectedProject
    ? selectedProject.session
    : projectDraft
    ? projectDraft.session
    : null;
  const widgetProps = {
    session,
    token,
    language,
    apiEndpoint: config.API_ENDPOINT,
    templateId: selectedTemplate.id,
    onInit: actions.client.setClient,
    onStateChange: actions.client.setState,
    onError: actions.client.setError,
    onUpdate: session => {
      if (selectedProject && session !== selectedProject.session) {
        actions.project.update(selectedProject.id, 'session', session);
      } else if (!selectedProject) {
        actions.project.draft(selectedTemplate.id, session);
      }
    }
  };
  return <Widget {...widgetProps} />;
}

export class Template extends React.PureComponent<State> {
  async componentDidMount() {
    const { actions, middleware } = this.props;
    await middleware(
      actions.template.makeSureTemplateIsSelected(this.props),
      actions.project.makeSureProjectIsSelected(this.props),
      actions.template.makeSureHasAccessToTemplate(this.props),
      actions.project.makeSureHasNotUnsavedChanges(this.props),
      actions.client.handleClientState(this.props)
    );
  }

  async componentDidUpdate() {
    const { actions, middleware } = this.props;
    await middleware(
      await actions.template.makeSureTemplateIsSelected(this.props),
      actions.project.makeSureProjectIsSelected(this.props),
      actions.template.makeSureHasAccessToTemplate(this.props),
      actions.project.makeSureHasNotUnsavedChanges(this.props),
      actions.client.handleClientState(this.props)
    );
  }

  render() {
    const { selectedTemplate, selectedProject } = this.props;
    const selectedContext = selectedProject || selectedTemplate;

    return (
      <App
        {...this.props}
        documentTitle={selectedContext && selectedContext.title}
      >
        {selectedTemplate && (
          <div className="sx-page sx-template">
            <Top {...this.props} />
            <div className="sx-page__contents sx-page__contents--flex">
              <div id="sx-widget-container" className="sx-template__widget">
                <WidgetLoader {...this.props} />
              </div>
              <TemplateDrawer {...this.props} />
            </div>
          </div>
        )}
      </App>
    );
  }
}
