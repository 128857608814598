import { EngineError, ERROR_NOT_AVAILABLE } from '../error';
import { Value, ErrorValue, LogicalValue, NumberValue } from '.';

// export function CELL () {
//   throw new Error('CELL is not implemented');
// }

// export function INFO () {
//   throw new Error('INFO is not implemented');
// }

/**
 * `Value` is empty
 *
 * @param {Value} value
 * @returns {LogicalValue}
 */

export function ISBLANK(value: Value): LogicalValue {
  return value === null || value === undefined;
}

// export function ISBINARY (number) {
//   return (/^[01]{1,10}$/).test(number);
// }

/**
 * `Value` refers to any error value except #N/A
 *
 * @param {Value} value
 * @returns {LogicalValue}
 */

export function ISERR(value: Value): LogicalValue {
  return value instanceof EngineError && value.name !== ERROR_NOT_AVAILABLE;
}

/**
 * `Value` refers to any error value (#N/A, #VALUE!, #REF!, #DIV/0!, #NUM!, #NAME?, or #NULL!)
 *
 * @param {Value} value
 * @returns {LogicalValue}
 */

export function ISERROR(value: Value): LogicalValue {
  return value instanceof EngineError;
}

/**
 * Returns TRUE if number is even, or FALSE if number is odd
 *
 * @param {Value} number
 * @returns {LogicalValue}
 */

export function ISEVEN(number: Value): LogicalValue {
  return !!(Math.floor(Math.abs(+number)) & 1);
}

// export function ISFORMULA () {
//   throw new Error('ISFORMULA is not implemented');
// }

/**
 * `Value` refers to a logical value
 * @param {Value} value
 * @returns {LogicalValue}
 */

export function ISLOGICAL(value: Value): LogicalValue {
  return value === true || value === false;
}

/**
 * `Value` refers to the #N/A (value not available) error value
 *
 * @param {Value} value
 * @returns {LogicalValue}
 */

export function ISNA(value: Value): LogicalValue {
  return value instanceof EngineError && value.name === ERROR_NOT_AVAILABLE;
}

/**
 * Value refers to any item that is not text. (Note that this function returns TRUE if the value refers to a blank cell.)
 *
 * @param {Value} value
 * @returns {LogicalValue}
 */

export function ISNONTEXT(value: Value): LogicalValue {
  return typeof value !== 'string';
}

/**
 * `Value` refers to a number
 *
 * @param {Value} value
 * @returns {LogicalValue}
 */

export function ISNUMBER(value: Value): LogicalValue {
  return typeof value === 'number' && !isNaN(value) && isFinite(value);
}

/**
 * Returns TRUE if number is even, or FALSE if number is odd
 *
 * @param {Value} number
 * @returns {LogicalValue}
 */

export function ISODD(number: Value): LogicalValue {
  // @ts-ignore
  return !!(Math.floor(Math.abs(number)) & 1);
}

// export function ISREF () {
//   throw new Error('ISREF is not implemented');
// }

/**
 * Check if value is text
 *
 * @param {Value} value
 */

export function ISTEXT(value: Value): LogicalValue {
  return typeof value === 'string';
}

/**
 * Returns a value converted to a number
 *
 * @param {Value} value
 * @returns {NumberValue}
 */

export function N(value: Value): NumberValue {
  if (ISNUMBER(value)) {
    return +value;
  }
  if (value instanceof Date) {
    return value.getTime();
  }
  if (value === true) {
    return 1;
  }
  if (value === false) {
    return 0;
  }
  if (ISERROR(value)) {
    // @ts-ignore
    return value;
  }
  return 0;
}

/**
 * Returns the error value #N/A. #N/A is the error value that means "no value is available." Use NA to mark empty cells.
 * By entering #N/A in cells where you are missing information, you can avoid the problem of unintentionally including
 * empty cells in your calculations. (When a formula refers to a cell containing #N/A, the formula returns the #N/A
 * error value.)
 *
 * @returns {ErrorValue}
 */

export function NA(): ErrorValue {
  return new EngineError('NA', ERROR_NOT_AVAILABLE, arguments);
}

// export function SHEET () {
//   throw new Error('SHEET is not implemented');
// }

// export function SHEETS () {
//   throw new Error('SHEETS is not implemented');
// }

/**
 * Returns the type of value. Use TYPE when the behavior of another function depends on the type of value in a
 * particular cell.
 *
 * @param {Value} value
 * @returns {Value}
 */

export function TYPE(value: Value): Value {
  if (ISNUMBER(value)) {
    return 1;
  }
  if (ISTEXT(value)) {
    return 2;
  }
  if (ISLOGICAL(value)) {
    return 4;
  }
  if (ISERROR(value)) {
    return 16;
  }
  if (Array.isArray(value)) {
    return 64;
  }
}
