import {
  EngineError,
  ERROR_NUM,
  ERROR_NOT_AVAILABLE,
} from "../../error";

var utils = require("../utils");
var jStat = require("jStat").jStat;


/**
 * Returns the average of the absolute deviations of data points from their
 * mean. AVEDEV is a measure of the variability in a data set.
 * 
 * @param arg[] arg1 is required, subsequent args are optional. 1 to 255
 * arguments for which you want the average of the absolute deviations. You can
 * also use a single array or a reference to an array instead of arguments
 * separated by commas. 
 */
export function AVEDEV(...args) {
  const range = utils.parseNumberArray(utils.flatten(args));
  if (range instanceof EngineError) {
    return range;
  }
  return (
    jStat.sum(
      jStat(range)
        .subtract(jStat.mean(range))
        .abs()[0]
    ) / range.length
  );
}

/**
 * Returns the average (arithmetic mean) of the arguments. For example, if the
 * range A1:A20 contains numbers, the formula =AVERAGE(A1:A20) returns the
 * average of those numbers.
 * 
 * @param arg[] arg1 is required, subsequent args are optional. Numbers, cell
 * references or ranges for which you want the average, up to a maximum of 255.
 */
export function AVERAGE(...args) {
  const range = utils.numbers(utils.flatten(args));
  const n = range.length;
  let sum = 0;
  let count = 0;
  let result;
  for (let i = 0; i < n; i++) {
    sum += range[i];
    count += 1;
  }
  result = sum / count;
  if (isNaN(result)) {
    return new EngineError("AVERAGE", ERROR_NUM, arguments);
  }
  return result;
}

/**
 * Calculates the average (arithmetic mean) of the values in the list of
 * arguments.
 * 
 * @param args Value1, value2, ... Value1 is required, subsequent values are
 * optional. 1 to 255 cells, ranges of cells, or values for which you want the
 * average.
 */
export function AVERAGEA(...args) {
  const range = utils.flatten(args);
  const n = range.length;
  let sum = 0;
  let count = 0;
  let result;
  for (let i = 0; i < n; i += 1) {
    const el = range[i];
    if (typeof el === "number") {
      sum += el;
    }
    if (el === true) {
      sum++;
    }
    if (el !== null) {
      count++;
    }
  }
  result = sum / count;
  if (isNaN(result)) {
    return new EngineError("AVERAGEA", ERROR_NUM, arguments);
  }
  return result;
}

/**
 * Returns the average (arithmetic mean) of all the cells in a range that meet
 * a given criteria.
 * 
 * @param range One or more cells to average, including numbers or names,
 * arrays, or references that contain numbers.
 * @param criteria The criteria in the form of a number, expression, cell
 * reference, or text that defines which cells are averaged.
 * @param average_range  Optional. The actual set of cells to average. If
 * omitted, range is used.
 */
export function AVERAGEIF(range, criteria, average_range) {
  if (arguments.length <= 1) {
    return new EngineError("AVERAGEIF", ERROR_NOT_AVAILABLE, arguments);
  }
  average_range = average_range || range;
  range = utils.flatten(range);
  average_range = utils.parseNumberArray(utils.flatten(average_range));
  if (average_range instanceof Error) {
    return average_range;
  }
  var average_count = 0;
  var result = 0;
  for (var i = 0; i < range.length; i++) {
    if (eval(range[i] + criteria)) {
      // jshint ignore:line
      result += average_range[i];
      average_count++;
    }
  }
  return result / average_count;
}

/**
 * Returns the average (arithmetic mean) of all cells that meet multiple criteria.
 * 
 * @param averageRange One or more cells to average, including numbers or names,
 * arrays, or references that contain numbers.
 * @param Criteria_range,  criteria_range1, criteria_range2, ...
 * Criteria_range1 is required, subsequent criteria_ranges are optional. 1 to
 * 127 ranges in which to evaluate the associated criteria.
 * @param Criteria1 criteria1, criteria2, ... Criteria1 is required, subsequent
 * criteria are optional. 1 to 127 criteria in the form of a number, expression,
 * cell reference, or text that define which cells will be averaged.
 * 
 * FIXME Does not work with multi dimensional ranges yet!
 * http://office.microsoft.com/en-001/excel-help/averageifs-function-HA010047493.aspx
 */
export function AVERAGEIFS(...argsIn) {
  var args = utils.argsToArray(argsIn);
  var criteria = (args.length - 1) / 2;
  var range = utils.flatten(args[0]);
  var count = 0;
  var result = 0;
  for (var i = 0; i < range.length; i++) {
    var condition = "";
    for (var j = 0; j < criteria; j++) {
      condition += args[2 * j + 1][i] + args[2 * j + 2];
      if (j !== criteria - 1) {
        condition += "&&";
      }
    }
    if (eval(condition)) {
      // jshint ignore:line
      result += range[i];
      count++;
    }
  }

  var average = result / count;
  if (isNaN(average)) {
    return 0;
  } else {
    return average;
  }
}