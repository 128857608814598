import * as React from 'react';
import injectSheet from 'react-jss';
import * as classNames from 'classnames';
import { Button } from '@spreax/components';
import { __ } from '@spreax/i18n';

const styles = theme => ({
  container: {
    float: 'left',
    width: '100%',
    boxSizing: 'border-box',
    ...theme.actions && theme.actions.container,
  },
  action: {
    float: 'left',
    ...theme.actions && theme.actions.action,
  },
});

export function ActionsComponent(props) {
  const { classes, selectedTab, setSelectedTab, tabs } = props;
  const selectedTabIndex = tabs.map(tab => tab.id).indexOf(selectedTab);
  const actions = [
    {
      onClick: () => setSelectedTab(tabs[selectedTabIndex - 1].id),
      isDisabled: selectedTabIndex === 0,
      show: tabs.length > 1,
      label: __('ACTIONS.BACK'),
    },
    {
      onClick: () => setSelectedTab(tabs[selectedTabIndex + 1].id),
      isDisabled: selectedTabIndex === tabs.length - 1,
      show: tabs.length > 1,
      label: __('ACTIONS.NEXT'),
    },
  ];
  const hasActions = actions.filter((action) => action.show).length > 0;
  if (!hasActions) {
    return null;
  }
  return (
    <div className={classNames(classes.container)}>
      {actions.map((action, index) => {
        return (
          <div
            key={index}
            className={classNames(classes.action)}
          >
            <Button
              key={index}
              {...action}
            />
          </div>
        );
      })}
    </div>
  );
}

export const Actions = injectSheet(styles)(ActionsComponent);
