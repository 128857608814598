import * as React from 'react';
import injectSheet from 'react-jss';
import * as classNames from 'classnames';
import { getTextStyles, getTextStyleClasses } from './helpers';

const styles = theme => ({
  container: {
    float: 'left',
    outline: 'none',
    boxSizing: 'border-box',
    width: '100%',
    minHeight: '26px',
    display: 'flex',
    alignItems: 'center'
  },
  ...getTextStyles(theme)
});

function LabelComponent(props) {
  const { classes, children } = props;
  return (
    <div
      className={classNames(classes.container, {
        ...getTextStyleClasses(classes, props)
      })}
    >
      {children}
    </div>
  );
}

export const Label = injectSheet(styles)(LabelComponent);
