var utils = require("../utils");

import { PERCENTILE_EXC, PERCENTILE_INC } from "./statistical-p";
import { EngineError, ERROR_NUM, ERROR_VALUE } from "../../error";

/**
 * Returns the quartile of the data set, based on percentile values from 0..1,
 * exclusive.
 * 
 * @param range The array or cell range of numeric values for which you want
 * the quartile value.
 * @param quart Indicates which value to return.
 */
export function QUARTILE_EXC(range, quart) {
  range = utils.parseNumberArray(utils.flatten(range));
  quart = utils.parseNumber(quart);
  if (utils.anyIsError(range, quart)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  switch (quart) {
    case 1:
      return PERCENTILE_EXC(range, 0.25);
    case 2:
      return PERCENTILE_EXC(range, 0.5);
    case 3:
      return PERCENTILE_EXC(range, 0.75);
    default:
      return new EngineError("{FUNCTION_NAME}", ERROR_NUM, arguments);
  }
}

/**
 * Returns the quartile of a data set, based on percentile values from 0..1,
 * inclusive.
 * 
 * @param range The array or cell range of numeric values for which you want the
 * quartile value.
 * @param quart Indicates which value to return.
 */
export function QUARTILE_INC(range, quart) {
  range = utils.parseNumberArray(utils.flatten(range));
  quart = utils.parseNumber(quart);
  if (utils.anyIsError(range, quart)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  switch (quart) {
    case 1:
      return PERCENTILE_INC(range, 0.25);
    case 2:
      return PERCENTILE_INC(range, 0.5);
    case 3:
      return PERCENTILE_INC(range, 0.75);
    default:
      return new EngineError("{FUNCTION_NAME}", ERROR_NUM, arguments);
  }
}