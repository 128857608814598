// tslint:disable object-shorthand-properties-first

import Dispatcher from '../dispatcher';
import { ActionTypes } from '../action-types';
import { State } from '../container';
import { __, i18n } from '@spreax/i18n';
import { TenantGUID } from '@spreax/model';
import { Routes } from '../navigator';

export namespace tenant {
  export interface ITenant {
    makeSureHasSelectedTenant(props: State);
    makeSureHasTenantData(props: State);
    selectTenant(id: TenantGUID);
  }

  export function selectTenant(id: TenantGUID) {
    Dispatcher.dispatch({
      type: ActionTypes.SELECT_TENANT,
      id
    });
  }

  export function makeSureHasSelectedTenant(props: State) {
    return async (getState, dispatch) => {
      const { route } = props;
      const { actions, tenants, selectedTenant } = getState();
      if (
        !selectedTenant &&
        tenants.length > 0 &&
        localStorage.getItem('selected_tenant')
      ) {
        selectTenant(localStorage.getItem('selected_tenant'));
      }
      if (!selectedTenant && route.match.path !== Routes.SELECT_TENANT) {
        localStorage.setItem('redirect_url', route.match.url);
        route.history.push('/select-tenant');
      }
      if (selectedTenant && route.match.path === Routes.SELECT_TENANT) {
        route.history.push(localStorage.getItem('redirect_url') || '/');
        localStorage.removeItem('redirect_url');
      }
    };
  }

  export function makeSureHasTenantData(props: State) {
    return async (getState, dispatch) => {
      const {
        hasReceivedTemplates,
        hasReceivedProjects,
        hasReceivedFolders,
        isFetchingProjects,
        isFetchingTemplates,
        isFetchingFolders,
        loggedIn,
        profile,
        selectedTenant
      } = getState();
      if (!loggedIn || !selectedTenant) {
        return Promise.resolve();
      }
      if (profile) {
        // setLanguage(profile.language);
      }
      const isIdle =
        !isFetchingProjects && !isFetchingFolders && !isFetchingTemplates;
      dispatch(
        () =>
          isIdle &&
          !hasReceivedProjects &&
          Dispatcher.dispatch({
            type: ActionTypes.LOAD_PROJECTS,
            tenantId: selectedTenant.id
          }),
        () =>
          isIdle &&
          !hasReceivedTemplates &&
          Dispatcher.dispatch({
            type: ActionTypes.LOAD_TEMPLATES,
            tenantId: selectedTenant.id
          }),
        () =>
          isIdle &&
          !hasReceivedFolders &&
          Dispatcher.dispatch({
            type: ActionTypes.LOAD_FOLDERS,
            tenantId: selectedTenant.id
          })
      );
    };
  }
}
