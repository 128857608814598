import * as React from 'react';
import injectSheet from 'react-jss';
import * as classNames from 'classnames';

const styles = theme => ({
  container: {
    float: 'left',
    outline: 'none',
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    minHeight: theme.themeOptions.inputHeight || 30,
    ...(theme.inputs &&
      theme.inputs.checkbox &&
      theme.inputs.checkbox.container)
  },
  input: {
    // height: '26px',
    ...(theme.inputs && theme.inputs.checkbox && theme.inputs.checkbox.input)
  },
  label: {
    marginLeft: '5px',
    ...(theme.inputs && theme.inputs.checkbox && theme.inputs.checkbox.label)
  }
});

function CheckboxComponent(props) {
  const { classes, className, onChange, checked, label, disabled } = props;
  return (
    <div className={classNames(className, classes.container)}>
      <input
        type="checkbox"
        className={classes.input}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label className={classes.label}>{label}</label>
    </div>
  );
}

export const Checkbox = injectSheet(styles)(CheckboxComponent);
