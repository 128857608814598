import { ReduceStore } from 'flux/utils';
import Dispatcher from '../dispatcher';
import { ActionTypes } from '../action-types';

function onOpenSidepanel(state: AppState, action): AppState {
  return Object.assign({}, state, {
    sidePanelOpen: true
  });
}

function onCloseSidepanel(state: AppState, action): AppState {
  return Object.assign({}, state, {
    sidePanelOpen: false
  });
}

function onToggleSidepanel(state: AppState, action): AppState {
  return Object.assign({}, state, {
    sidePanelOpen: !state.sidePanelOpen
  });
}

function onSetLoading(state: AppState, action): AppState {
  return Object.assign({}, state, {
    isLoading: true,
    loadingMessage: action.message || null
  });
}

function onUnsetLoading(state: AppState, action): AppState {
  return Object.assign({}, state, {
    isLoading: false
  });
}

function onUserLoggedIn(state: AppState, action): AppState {
  return Object.assign({}, state, {
    language: action.session.profile.language || 'en_US'
  });
}

function onLogout(state: AppState, action): AppState {
  return {
    sidePanelOpen: false,
    isLoading: false,
    loadingMessage: null,
    language: 'en_US',
    redirectPath: null,
    showContextDrawer: false
  };
}

class AppStore extends ReduceStore<AppState, any> {
  constructor() {
    super(Dispatcher);
  }

  getInitialState() {
    return {
      sidePanelOpen: false,
      isLoading: false,
      loadingMessage: null,
      language: 'en_US',
      redirectPath: null,
      showContextDrawer: localStorage.getItem('showContextDrawer') === 'true'
    };
  }

  reduce(state: AppState, action): AppState {
    switch (action.type) {
      case ActionTypes.OPEN_SIDEPANEL:
        return onOpenSidepanel(state, action);

      case ActionTypes.CLOSE_SIDEPANEL:
        return onCloseSidepanel(state, action);

      case ActionTypes.TOGGLE_SIDEPANEL:
        return onToggleSidepanel(state, action);

      case ActionTypes.SET_LOADING:
        return onSetLoading(state, action);

      case ActionTypes.UNSET_LOADING:
        return onUnsetLoading(state, action);

      case ActionTypes.LOGGED_IN:
        return onUserLoggedIn(state, action);

      case ActionTypes.OPEN_DRAWER:
        localStorage.setItem('showContextDrawer', 'true');
        return Object.assign({}, state, {
          showContextDrawer: true
        });

      case ActionTypes.CLOSE_DRAWER:
        localStorage.setItem('showContextDrawer', 'false');
        return Object.assign({}, state, {
          showContextDrawer: false
        });

      case ActionTypes.SET_REDIRECT:
        return Object.assign({}, state, {
          redirectPath: action.redirect
        });

      case ActionTypes.UNSET_REDIRECT:
        return Object.assign({}, state, {
          redirectPath: null
        });

      case ActionTypes.LOGOUT:
        return onLogout(state, action);

      default:
        return state;
    }
  }
}

export interface AppState {
  sidePanelOpen: boolean;
  isLoading: boolean;
  loadingMessage: string;
  language: string;
  redirectPath: string;
  showContextDrawer: boolean;
}

export const Store = new AppStore();
