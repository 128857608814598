import { EngineError, ERROR_VALUE } from "../../error";
import { parseNumber, anyIsError } from "../utils";

/**
 * Returns the tangent of the given angle.
 * 
 * @param number The angle in radians for which you want the tangent.
 */
export function TAN(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  return Math.tan(number);
}

/**
 * Returns the hyperbolic tangent of a number.
 * 
 * @param number Any real number.
 */
export function TANH(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  const e2 = Math.exp(2 * number);
  return (e2 - 1) / (e2 + 1);
}

/**
 * Truncates a number to an integer by removing the fractional part of the
 * number.
 * 
 * @param number The number you want to truncate.
 * @param digits  Optional. A number specifying the precision of the truncation.
 * The default value for num_digits is 0 (zero).
 */
export function TRUNC(number, digits = 0) {
  number = parseNumber(number);
  // @ts-ignore
  digits = parseNumber(digits);
  if (anyIsError(number, digits)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  const sign = number > 0 ? 1 : -1;
  return (
    (sign * Math.floor(Math.abs(number) * Math.pow(10, digits))) /
    Math.pow(10, digits)
  );
}
