import { onFullfilled, onRejection } from './utils';
import { __, i18n } from '@spreax/i18n';
import { ActionTypes } from '../action-types';
import { TenantGUID } from '@spreax/model';
import * as API from '@spreax/api-client';

export namespace account {
  export function get(tenantId: TenantGUID) {
    return API.account.get(tenantId).then(
      onFullfilled(null, null, data => {
        return {
          type: ActionTypes.RECEIVE_ACCOUNTS,
          accounts: data
        };
      }),
      onRejection()
    );
  }
}
