import * as React from 'react';
import '../../../css/app.scss';
import '../../../src/pages/frame/style.scss';
import { State } from '../../container';
import { Sidebar } from './sidebar';
import { Sidepanel } from './sidepanel';
import { Feedback, DocumentTitle, Persona } from '../../components';

export class App extends React.PureComponent<State> {
  async componentDidMount() {
    const { actions, middleware } = this.props;
    middleware(
      actions.user.makeSureUserIsLoggedIn(this.props),
      actions.tenant.makeSureHasSelectedTenant(this.props),
      actions.tenant.makeSureHasTenantData(this.props),
      actions.app.hasActiveRedirects(this.props)
    );
  }

  async componentDidUpdate() {
    const { actions, middleware } = this.props;
    middleware(
      actions.user.makeSureUserIsLoggedIn(this.props),
      actions.tenant.makeSureHasSelectedTenant(this.props),
      actions.tenant.makeSureHasTenantData(this.props),
      actions.app.hasActiveRedirects(this.props)
    );
  }

  render() {
    const { children, messages, documentTitle } = this.props;
    return (
      <div className="sx-portal">
        <DocumentTitle title={documentTitle} />
        <Sidepanel {...this.props} />
        <div className="sx-portal__content">
          <div className="sx-portal__super-top">
            <div className="sx-portal__super-top__action">
              <Persona
                profile={this.props.profile}
                onClick={() => this.props.route.history.push('/profile')}
              />
            </div>
          </div>
          {children}
        </div>
        <Feedback messages={messages} />
      </div>
    );
  }
}
