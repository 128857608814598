import {
  EngineError,
  ERROR_VALUE,
  ERROR_NOT_AVAILABLE,
  ERROR_DIV_ZERO
} from "../../error";

import { parseNumber, anyIsError } from "../utils";

/**
 * Converts a text representation of a number in a given base into a decimal
 * number.
 *
 * @param number
 * @param radix Radix must be an integer.
 */
export function DECIMAL(number, radix) {
  if (arguments.length < 1) {
    return new EngineError("DECIMAL", ERROR_VALUE, arguments);
  }
  return parseInt(number, radix);
}

/**
 * Converts radians into degrees.
 *
 * @param number The angle in radians that you want to convert.
 */
export function DEGREES(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  return (number * 180) / Math.PI;
}

// TODO NOT_DEFINED in https://support.office.com/
export function DIVIDE(dividend, divisor) {
  if (arguments.length !== 2) {
    return new EngineError("DIVIDE", ERROR_NOT_AVAILABLE, arguments);
  }
  dividend = parseNumber(dividend);
  divisor = parseNumber(divisor);
  if (anyIsError(dividend, divisor)) {
    return new EngineError("DIVIDE", ERROR_VALUE, arguments);
  }
  if (divisor === 0) {
    return new EngineError("DIVIDE", ERROR_DIV_ZERO, arguments);
  }
  return dividend / divisor;
}
