import * as React from 'react';
import injectSheet from 'react-jss';
import * as classNames from 'classnames';

const styles = theme => ({
  container: {
    float: 'left',
    outline: 'none',
    boxSizing: 'border-box',
    width: '100%'
  },
  base: {
    width: '100%',
    height: 30,
    boxSizing: 'border-box',
    float: 'left',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    border: ['1px', 'solid', '#000'],
    '&:hover': {
      ...(theme.inputs &&
        theme.inputs.inputField &&
        theme.inputs.inputField.baseHover)
    },
    ...(theme.inputs && theme.inputs.inputField && theme.inputs.inputField.base)
  },
  isDisabled: {
    extend: 'base',
    padding: '0',
    ...(theme.inputs &&
      theme.inputs.inputField &&
      theme.inputs.inputField.disabled)
  },
  isFocused: {
    extend: 'base',
    // border: ['2px', 'solid', theme.primaryColor, '!important'],
    ...(theme.inputs &&
      theme.inputs.inputField &&
      theme.inputs.inputField.focused)
  },
  isNumber: {
    '& input, & textarea': {
      textAlign: 'right'
    }
  },
  isString: {
    '& input, & textarea': {
      textAlign: 'left'
    }
  },
  isMultiline: {
    height: 'fit-content'
  },
  input: {
    flex: '1',
    height: '100%',
    padding: '0 0!important',
    boxSizing: 'border-box',
    border: '0!important',
    outline: 'none',
    textAlign: 'right',
    minWidth: '0px',
    backgroundColor: 'transparent',
    width: 'inherit'
  },
  label: {
    width: '100%',
    float: 'left',
    textAlign: 'right',
    paddingRight: '10px',
    boxSizing: 'border-box'
  },
  prefix: {
    padding: '0 5px',
    ...(theme.inputs &&
      theme.inputs.inputField &&
      theme.inputs.inputField.prefix)
  },
  postfix: {
    padding: '0 5px',
    ...(theme.inputs &&
      theme.inputs.inputField &&
      theme.inputs.inputField.postfix)
  }
});

function InputFieldComponent(props) {
  const {
    classes,
    className,
    onFocus,
    onBlur,
    onChange,
    onKeyPress,
    value,
    readOnly,
    isFocused,
    label,
    prefix,
    postfix,
    dataType,
    multiline,
    type = 'text'
  } = props;
  const inputFieldClassName = classNames({
    [classes.base]: true,
    // [classes.base]: isFocused === false && !readOnly,
    [classes.isDisabled]: readOnly,
    [classes.isFocused]: isFocused,
    [classes.isNumber]: dataType === 'number',
    [classes.isString]: dataType === 'string' || !dataType,
    [classes.isMultiline]: !!multiline
  });
  if (!!multiline) {
    return (
      <div className={classNames(className, classes.container)}>
        <div className={classes.label}>{label}</div>
        <div className={inputFieldClassName}>
          <textarea
            className={classes.input}
            readOnly={readOnly}
            value={value || value === 0 ? value : ''}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            rows={multiline}
          ></textarea>
        </div>
      </div>
    );
  }
  return (
    <div className={classNames(className, classes.container)}>
      <div className={classes.label}>{label}</div>
      <div className={inputFieldClassName}>
        <div className={classes.prefix}>{prefix}</div>
        <input
          type={type}
          className={classes.input}
          readOnly={readOnly}
          value={value || value === 0 ? value : ''}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          onKeyPress={onKeyPress}
          tabIndex={readOnly ? -1 : 0}
        />
        <div className={classes.postfix}>{postfix}</div>
      </div>
    </div>
  );
}

export const InputField = injectSheet(styles)(InputFieldComponent);
