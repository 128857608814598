import { API } from '.';

export function call (path: string, opts: any = { method: 'GET' }) : Promise<any> {
  if (!API) {
    throw new Error('No `API_ENDPOINT` set');
  }
  if (!opts.headers) {
    opts.headers = new Headers({
      'X-Auth-Token': localStorage.getItem('token') || ''
    });
  }


  return fetch(API + path, opts)
    .then(
      async (response) => {
        const { errors, data } = await response.json();
        if (response.status >= 400) {
          return Promise.reject({
            response,
            errors,
            data,
          });
        }
        return Promise.resolve(data);
      },
    );
}
