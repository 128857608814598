import * as React from 'react';
import injectSheet from 'react-jss';

const styles = theme => ({
  container: {},
  option: {
    marginBottom: '8px'
  },
  radio: {
    marginRight: '16px'
  },
  label: {
    ...theme.paragraph,
    display: 'flex',
    alignItems: 'center',
    ...(theme.inputs && theme.inputs.radio && theme.inputs.radio.label)
  }
});

function RadioComponent(props) {
  const { onChange, options } = props;
  return (
    <div className={props.classes.container}>
      {options.map(({ key, value, isChecked, label }) => {
        return (
          <div key={key} className={props.classes.option}>
            <label className={props.classes.label}>
              <input
                type="radio"
                value={value}
                checked={isChecked}
                onChange={onChange}
                className={props.classes.radio}
              />
              {label}
            </label>
          </div>
        );
      })}
    </div>
  );
}

export const Radio = injectSheet(styles)(RadioComponent);
