// tslint:disable object-shorthand-properties-first

import Dispatcher from '../dispatcher';
import { ActionTypes } from '../action-types';
import { TenantGUID, FolderGUID, FileType } from '@spreax/model';

export namespace group {
  export interface IGroup {
    moveFile(id: FolderGUID, target: FolderGUID, fileType: FileType): void;
    selectFolder(id: FolderGUID): void;
    createFolder(parent: FolderGUID): void;
    updateFolder(id: FolderGUID, field: string, value: any): void;
    removeFolder(id: FolderGUID): void;
    saveFolder(id: FolderGUID): void;
  }

  export function moveFile(
    tenantId: TenantGUID,
    id: FolderGUID,
    target: string,
    fileType: FileType
  ): void {
    Dispatcher.dispatch({
      type: ActionTypes.MOVE_FILE,
      tenantId,
      id,
      target,
      fileType
    });
  }

  export function selectFolder(id: FolderGUID): void {
    Dispatcher.dispatch({
      type: ActionTypes.SELECT_FOLDER,
      id
    });
  }

  export function createFolder(tenantId: TenantGUID, parent: FolderGUID): void {
    Dispatcher.dispatch({
      type: ActionTypes.CREATE_FOLDER,
      tenantId,
      parent
    });
  }

  export function updateFolder(
    tenantId: TenantGUID,
    id: FolderGUID,
    field: string,
    value: any
  ): void {
    Dispatcher.dispatch({
      type: ActionTypes.UPDATE_FOLDER,
      tenantId,
      id,
      field,
      value
    });
  }

  export function removeFolder(tenantId: TenantGUID, id: FolderGUID): void {
    Dispatcher.dispatch({
      type: ActionTypes.REMOVE_FOLDER,
      tenantId,
      id
    });
  }

  export function saveFolder(tenantId: TenantGUID, id: FolderGUID): void {
    Dispatcher.dispatch({
      type: ActionTypes.SAVE_FOLDER,
      tenantId,
      id
    });
  }
}
