import { EngineError, ERROR_VALUE } from "../../error";
import { parseNumber, anyIsError } from "../utils";

/**
 * Returns the integer portion of a division. Use this function when you want
 * to discard the remainder of a division.
 * 
 * @param numerator The dividend.
 * @param denominator The divisor.
 */
export function QUOTIENT(numerator, denominator) {
  numerator = parseNumber(numerator);
  denominator = parseNumber(denominator);
  if (anyIsError(numerator, denominator)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  // @ts-ignore
  return parseInt(numerator / denominator, 10);
}