import {
  EngineError,
  ERROR_NUM,
  ERROR_NOT_AVAILABLE,
  ERROR_VALUE
} from "../../error";

var jStat = require("jStat").jStat;
var utils = require("../utils");


/**
 * Return the gamma function value.
 * 
 * @param number Returns a number.
 */
export function GAMMA(number) {
  number = utils.parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  if (number === 0) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NUM, arguments);
  }
  if (parseInt(number, 10) === number && number < 0) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NUM, arguments);
  }
  return jStat.gammafn(number);
}


/**
 * Returns the gamma distribution. 
 * 
 * @param value The value at which you want to evaluate the distribution.
 * @param alpha A parameter to the distribution.
 * @param beta A parameter to the distribution. If beta = 1, GAMMA.DIST returns
 * the standard gamma distribution.
 * @param cumulative A logical value that determines the form of the function.
 * If cumulative is TRUE, GAMMA.DIST returns the cumulative distribution
 * function; if FALSE, it returns the probability density function.
 */
export function GAMMA_DIST(value, alpha, beta, cumulative) {
  if (arguments.length !== 4) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NOT_AVAILABLE, arguments);
  }

  if (value < 0 || alpha <= 0 || beta <= 0) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  if (
    typeof value !== "number" ||
    typeof alpha !== "number" ||
    typeof beta !== "number"
  ) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  return cumulative
    ? jStat.gamma.cdf(value, alpha, beta, true)
    : jStat.gamma.pdf(value, alpha, beta, false);
}

/**
 * Returns the inverse of the gamma cumulative distribution.
 * 
 * @param probability The probability associated with the gamma distribution.
 * @param alpha A parameter to the distribution.
 * @param beta A parameter to the distribution. If beta = 1, GAMMA.INV returns
 * the standard gamma distribution.
 */
export function GAMMA_INV(probability, alpha, beta) {
  if (arguments.length !== 3) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NOT_AVAILABLE, arguments);
  }

  if (probability < 0 || probability > 1 || alpha <= 0 || beta <= 0) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NUM, arguments);
  }

  if (
    typeof probability !== "number" ||
    typeof alpha !== "number" ||
    typeof beta !== "number"
  ) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  return jStat.gamma.inv(probability, alpha, beta);
}

/**
 * Returns the natural logarithm of the gamma function
 * 
 * @param number  The value for which you want to calculate GAMMALN.
 */
export function GAMMALN(number) {
  number = utils.parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  return jStat.gammaln(number);
}

/**
 * Returns the natural logarithm of the gamma function
 * 
 * @param x The value for which you want to calculate GAMMALN.PRECISE.
 */
export function GAMMALN_PRECISE(x) {
  if (arguments.length !== 1) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NOT_AVAILABLE, arguments);
  }

  if (x <= 0) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NUM, arguments);
  }

  if (typeof x !== "number") {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  return jStat.gammaln(x);
}

/**
 * Calculates the probability that a member of a standard normal population will
 * fall between the mean and z standard deviations from the mean.
 * 
 * @param z Returns a number.
 */
export function GAUSS(z) {
  z = utils.parseNumber(z);
  if (z instanceof Error) {
    return z;
  }
  return jStat.normal.cdf(z, 0, 1) - 0.5;
}

/**
 * Returns the geometric mean of an array or range of positive data.
 * 
 * @param arg[] Number1 is required, subsequent numbers are optional. 1 to 255
 * arguments for which you want to calculate the mean. You can also use a single
 * array or a reference to an array instead of arguments separated by commas.
 */
export function GEOMEAN(...argsIn) {
  var args = utils.parseNumberArray(utils.flatten(argsIn));
  if (args instanceof Error) {
    return args;
  }
  return jStat.geomean(args);
}

/**
 * Calculates predicted exponential growth by using existing data. GROWTH
 * returns the y-values for a series of new x-values that you specify by using
 * existing x-values and y-values.
 * 
 * @param known_y The set of y-values you already know in the relationship
 * y = b*m^x.
 * @param known_x Optional. An optional set of x-values that you may already
 * know in the relationship y = b*m^x.
 * @param new_x Optional. Are new x-values for which you want GROWTH to return
 * corresponding y-values.
 * @param use_const Optional. A logical value specifying whether to force the
 * constant b to equal 1.
 */
export function GROWTH(known_y, known_x, new_x, use_const) {
  // Credits: Ilmari Karonen (http://stackoverflow.com/questions/14161990/how-to-implement-growth-function-in-javascript)

  known_y = utils.parseNumberArray(known_y);
  if (known_y instanceof Error) {
    return known_y;
  }

  // Default values for optional parameters:
  var i;
  if (known_x === undefined) {
    known_x = [];
    for (i = 1; i <= known_y.length; i++) {
      known_x.push(i);
    }
  }
  if (new_x === undefined) {
    new_x = [];
    for (i = 1; i <= known_y.length; i++) {
      new_x.push(i);
    }
  }

  known_x = utils.parseNumberArray(known_x);
  new_x = utils.parseNumberArray(new_x);
  if (utils.anyIsError(known_x, new_x)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  if (use_const === undefined) {
    use_const = true;
  }

  // Calculate sums over the data:
  var n = known_y.length;
  var avg_x = 0;
  var avg_y = 0;
  var avg_xy = 0;
  var avg_xx = 0;
  for (i = 0; i < n; i++) {
    var x = known_x[i];
    var y = Math.log(known_y[i]);
    avg_x += x;
    avg_y += y;
    avg_xy += x * y;
    avg_xx += x * x;
  }
  avg_x /= n;
  avg_y /= n;
  avg_xy /= n;
  avg_xx /= n;

  // Compute linear regression coefficients:
  var beta;
  var alpha;
  if (use_const) {
    beta = (avg_xy - avg_x * avg_y) / (avg_xx - avg_x * avg_x);
    alpha = avg_y - beta * avg_x;
  } else {
    beta = avg_xy / avg_xx;
    alpha = 0;
  }

  // Compute and return result array:
  var new_y = [];
  for (i = 0; i < new_x.length; i++) {
    new_y.push(Math.exp(alpha + beta * new_x[i]));
  }
  return new_y;
}
