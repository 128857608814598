/**
 * Convert value into number.
 *
 * @param {any} value
 * @returns {number}
 */

export function toNumber (value: any) : number {
  if (typeof value === 'boolean') {
    return value ? 1 : 0;
  }
  if (typeof value === 'string') {
    return value.indexOf('.') > -1 ? parseFloat(value) : parseInt(value, 10);
  }
  return value;
}

/**
 * Invert provided number.
 *
 * @param {number} number
 * @returns {number} Returns inverted number.
 */

export function invertNumber (number: number) : number {
  return -1 * toNumber(number);
}

/**
 * Trim value by cutting character starting from the beginning and ending at the same time.
 *
 * @param {string} string String to trimming.
 * @param {number} margin Number of character to cut.
 * @returns {string}
 */

export function trimEdges (string: string, margin: number = 1) : string {
  return string.substring(margin, string.length - margin);
}
