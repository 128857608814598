import {
  EngineError,
  ERROR_VALUE,
  ERROR_DIV_ZERO,
  ERROR_NUM,
  ERROR_NOT_AVAILABLE
} from "../../error";
import { parseNumber, parseNumberArray, flatten, anyIsError } from "../utils";

import { FACT } from "./math-f";

// TODO NOT_DEFINED in https://support.office.com/
export function MINUS(num1, num2) {
  if (arguments.length !== 2) {
    return new EngineError("MINUS", ERROR_NOT_AVAILABLE, arguments);
  }

  num1 = parseNumber(num1);
  num2 = parseNumber(num2);
  if (anyIsError(num1, num2)) {
    return new EngineError("MINUS", ERROR_VALUE, arguments);
  }

  return num1 - num2;
}

/**
 * Returns the remainder after number is divided by divisor. The result has the
 * same sign as divisor.
 * 
 * @param dividend The number for which you want to find the remainder.
 * @param divisor The number by which you want to divide number.
 */
export function MOD(dividend, divisor) {
  dividend = parseNumber(dividend);
  divisor = parseNumber(divisor);
  if (anyIsError(dividend, divisor)) {
    return new EngineError("MOD", ERROR_VALUE, arguments);
  }
  if (divisor === 0) {
    return new EngineError("MOD", ERROR_DIV_ZERO, arguments);
  }
  var modulus = Math.abs(dividend % divisor);
  return divisor > 0 ? modulus : -modulus;
}

/**
 * MROUND returns a number rounded to the desired multiple.
 * 
 * @param number The value to round.
 * @param multiple The multiple to which you want to round number.
 */
export function MROUND(number, multiple) {
  number = parseNumber(number);
  multiple = parseNumber(multiple);
  if (anyIsError(number, multiple)) {
    return new EngineError("MROUND", ERROR_VALUE, arguments);
  }
  if (number * multiple < 0) {
    return new EngineError("MROUND", ERROR_NUM, arguments);
  }

  return Math.round(number / multiple) * multiple;
}


/**
 * Returns the ratio of the factorial of a sum of values to the product of
 * factorials.
 * 
 * @param number[] Number1, number2, ...    Number1 is required, subsequent
 * numbers are optional. 1 to 255 values for which you want the multinomial.
 */
export function MULTINOMIAL(...argsIn: any[]) {
  var args = parseNumberArray(flatten(argsIn));
  if (args instanceof Error) {
    return args;
  }
  var sum = 0;
  var divisor = 1;
  for (var i = 0; i < args.length; i++) {
    sum += args[i];
    divisor *= FACT(args[i]);
  }
  return FACT(sum) / divisor;
}

// TODO NOT_DEFINED in https://support.office.com/
export function MULTIPLY(factor1, factor2) {
  if (arguments.length !== 2) {
    return new EngineError("MULTIPLY", ERROR_NOT_AVAILABLE, arguments);
  }
  factor1 = parseNumber(factor1);
  factor2 = parseNumber(factor2);
  if (anyIsError(factor1, factor2)) {
    return new EngineError("MULTIPLY", ERROR_VALUE, arguments);
  }
  return factor1 * factor2;
}
