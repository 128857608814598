import { EngineError, ERROR_VALUE, ERROR_NUM, ERROR_DIV_ZERO } from '../error';
import { DAYS360, DAYS, YEARFRAC } from '.';
var utils = require('./utils');

function validDate(d) {
  return d && d.getTime && !isNaN(d.getTime());
}

function ensureDate(d) {
  return d instanceof Date ? d : new Date(d);
}

/**
 * Returns the accrued interest for a security that pays periodic interest.
 *
 * @param issue The security's issue date.
 * @param first The security's first interest date.
 * @param settlement  The security's settlement date. The security settlement
 * date is the date after the issue date when the security is traded to the
 * buyer.
 * @param rate The security's annual coupon rate.
 * @param par The security's par value. If you omit par, ACCRINT uses $1,000.
 * @param frequency The number of coupon payments per year. For annual payments,
 * frequency = 1; for semiannual, frequency = 2; for quarterly, frequency = 4.
 * @param basis Optional. The type of day count basis to use.
 */
export function ACCRINT(issue, first, settlement, rate, par, frequency, basis) {
  // Return error if either date is invalid
  issue = ensureDate(issue);
  first = ensureDate(first);
  settlement = ensureDate(settlement);

  if (!validDate(issue) || !validDate(first) || !validDate(settlement)) {
    return new EngineError('ACCRINT', ERROR_VALUE, arguments);
  }

  // Return error if either rate or par are lower than or equal to zero
  if (rate <= 0 || par <= 0) {
    return new EngineError('ACCRINT', ERROR_NUM, arguments);
  }

  // Return error if frequency is neither 1, 2, or 4
  if ([1, 2, 4].indexOf(frequency) === -1) {
    return new EngineError('ACCRINT', ERROR_NUM, arguments);
  }

  // Return error if basis is neither 0, 1, 2, 3, or 4
  if ([0, 1, 2, 3, 4].indexOf(basis) === -1) {
    return new EngineError('ACCRINT', ERROR_NUM, arguments);
  }

  // Return error if settlement is before or equal to issue
  if (settlement <= issue) {
    return new EngineError('ACCRINT', ERROR_NUM, arguments);
  }

  // Set default values
  par = par || 0;
  basis = basis || 0;

  // Compute accrued interest
  // @ts-ignore
  return par * rate * YEARFRAC(issue, settlement, basis);
}

// TODO
export function ACCRINTM() {
  throw new Error('ACCRINTM is not implemented');
}

// TODO
export function AMORDEGRC() {
  throw new Error('AMORDEGRC is not implemented');
}

// TODO
export function AMORLINC() {
  throw new Error('AMORLINC is not implemented');
}

// TODO
export function COUPDAYBS() {
  throw new Error('COUPDAYBS is not implemented');
}

// TODO
export function COUPDAYS() {
  throw new Error('COUPDAYS is not implemented');
}

// TODO
export function COUPDAYSNC() {
  throw new Error('COUPDAYSNC is not implemented');
}

// TODO
export function COUPNCD() {
  throw new Error('COUPNCD is not implemented');
}

// TODO
export function COUPNUM() {
  throw new Error('COUPNUM is not implemented');
}

// TODO
export function COUPPCD() {
  throw new Error('COUPPCD is not implemented');
}

/**
 * Returns the cumulative interest paid on a loan between start_period and
 * end_period.
 *
 * @param rate The interest rate.
 * @param periods The total number of payment periods.
 * @param value The present value.
 * @param start The first period in the calculation. Payment periods are
 * numbered beginning with 1.
 * @param end The last period in the calculation.
 * @param type The timing of the payment.
 */
export function CUMIPMT(rate, periods, value, start, end, type) {
  // Credits: algorithm inspired by Apache OpenOffice
  // Credits: Hannes Stiebitzhofer for the translations of function and variable names
  // Requires exports.FV() and exports.PMT() from exports.js [http://stoic.com/exports/]

  rate = utils.parseNumber(rate);
  periods = utils.parseNumber(periods);
  value = utils.parseNumber(value);
  if (utils.anyIsError(rate, periods, value)) {
    return new EngineError('CUMIPMT', ERROR_VALUE, arguments);
  }

  // Return error if either rate, periods, or value are lower than or equal to zero
  if (rate <= 0 || periods <= 0 || value <= 0) {
    return new EngineError('CUMIPMT', ERROR_NUM, arguments);
  }

  // Return error if start < 1, end < 1, or start > end
  if (start < 1 || end < 1 || start > end) {
    return new EngineError('CUMIPMT', ERROR_NUM, arguments);
  }

  // Return error if type is neither 0 nor 1
  if (type !== 0 && type !== 1) {
    return new EngineError('CUMIPMT', ERROR_NUM, arguments);
  }

  // Compute cumulative interest
  var payment = exports.PMT(rate, periods, value, 0, type);
  var interest = 0;

  if (start === 1) {
    if (type === 0) {
      interest = -value;
      start++;
    }
  }

  for (var i = start; i <= end; i++) {
    if (type === 1) {
      interest += exports.FV(rate, i - 2, payment, value, 1) - payment;
    } else {
      interest += exports.FV(rate, i - 1, payment, value, 0);
    }
  }
  interest *= rate;

  // Return cumulative interest
  return interest;
}

/**
 * Returns the cumulative principal paid on a loan between start_period and
 * end_period.
 *
 * @param rate The interest rate
 * @param periods The total number of payment periods.
 * @param value The present value.
 * @param start The first period in the calculation. Payment periods are
 * numbered beginning with 1.
 * @param end The last period in the calculation.
 * @param type The timing of the payment.
 */
export function CUMPRINC(rate, periods, value, start, end, type) {
  // Credits: algorithm inspired by Apache OpenOffice
  // Credits: Hannes Stiebitzhofer for the translations of function and variable names

  rate = utils.parseNumber(rate);
  periods = utils.parseNumber(periods);
  value = utils.parseNumber(value);
  if (utils.anyIsError(rate, periods, value)) {
    return new EngineError('CUMPRINC', ERROR_VALUE, arguments);
  }

  // Return error if either rate, periods, or value are lower than or equal to zero
  if (rate <= 0 || periods <= 0 || value <= 0) {
    return new EngineError('CUMPRINC', ERROR_NUM, arguments);
  }

  // Return error if start < 1, end < 1, or start > end
  if (start < 1 || end < 1 || start > end) {
    return new EngineError('CUMPRINC', ERROR_NUM, arguments);
  }

  // Return error if type is neither 0 nor 1
  if (type !== 0 && type !== 1) {
    return new EngineError('CUMPRINC', ERROR_NUM, arguments);
  }

  // Compute cumulative principal
  var payment = exports.PMT(rate, periods, value, 0, type);
  var principal = 0;
  if (start === 1) {
    if (type === 0) {
      principal = payment + value * rate;
    } else {
      principal = payment;
    }
    start++;
  }
  for (var i = start; i <= end; i++) {
    if (type > 0) {
      principal +=
        payment - (exports.FV(rate, i - 2, payment, value, 1) - payment) * rate;
    } else {
      principal += payment - exports.FV(rate, i - 1, payment, value, 0) * rate;
    }
  }

  // Return cumulative principal
  return principal;
}

/**
 * Returns the depreciation of an asset for a specified period using the
 * fixed-declining balance method.
 *
 * @param cost The initial cost of the asset.
 * @param salvage The value at the end of the depreciation (sometimes called the
 * salvage value of the asset).
 * @param life The number of periods over which the asset is being depreciated
 * (sometimes called the useful life of the asset).
 * @param period The period for which you want to calculate the depreciation.
 * Period must use the same units as life.
 * @param month The number of months in the first year. If month is omitted, it
 * is assumed to be 12.
 */
export function DB(cost, salvage, life, period, month = 12) {
  // Initialize month
  cost = utils.parseNumber(cost);
  salvage = utils.parseNumber(salvage);
  life = utils.parseNumber(life);
  period = utils.parseNumber(period);
  month = utils.parseNumber(month);

  if (utils.anyIsError(cost, salvage, life, period, month)) {
    return new EngineError('DB', ERROR_VALUE, arguments);
  }

  // Return error if any of the parameters is negative
  if (cost < 0 || salvage < 0 || life < 0 || period < 0) {
    return new EngineError('DB', ERROR_NUM, arguments);
  }

  // Return error if month is not an integer between 1 and 12
  if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].indexOf(month) === -1) {
    return new EngineError('DB', ERROR_NUM, arguments);
  }

  // Return error if period is greater than life
  if (period > life) {
    return new EngineError('DB', ERROR_NUM, arguments);
  }

  // Return 0 (zero) if salvage is greater than or equal to cost
  if (salvage >= cost) {
    return 0;
  }

  // Rate is rounded to three decimals places
  var rate = +(1 - Math.pow(salvage / cost, 1 / life)).toFixed(3);

  // Compute initial depreciation
  var initial = (cost * rate * month) / 12;

  // Compute total depreciation
  var total = initial;
  var current = 0;
  var ceiling = period === life ? life - 1 : period;
  for (var i = 2; i <= ceiling; i++) {
    current = (cost - total) * rate;
    total += current;
  }

  // Depreciation for the first and last periods are special cases
  if (period === 1) {
    // First period
    return initial;
  } else if (period === life) {
    // Last period
    return (cost - total) * rate;
  } else {
    return current;
  }
}

/**
 * Returns the depreciation of an asset for a specified period using the
 * double-declining balance method or some other method you specify.
 *
 * @param cost The initial cost of the asset.
 * @param salvage The value at the end of the depreciation (sometimes called the
 * salvage value of the asset). This value can be 0.
 * @param life The number of periods over which the asset is being depreciated
 * (sometimes called the useful life of the asset).
 * @param period The period for which you want to calculate the depreciation.
 * Period must use the same units as life.
 * @param factor Optional. The rate at which the balance declines. If factor is
 * omitted, it is assumed to be 2 (the double-declining balance method).
 */
export function DDB(cost, salvage, life, period, factor = 2) {
  // Initialize factor
  cost = utils.parseNumber(cost);
  salvage = utils.parseNumber(salvage);
  life = utils.parseNumber(life);
  period = utils.parseNumber(period);
  factor = utils.parseNumber(factor);

  if (utils.anyIsError(cost, salvage, life, period, factor)) {
    return new EngineError('DDB', ERROR_VALUE, arguments);
  }

  // Return error if any of the parameters is negative or if factor is null
  if (cost < 0 || salvage < 0 || life < 0 || period < 0 || factor <= 0) {
    return new EngineError('DDB', ERROR_NUM, arguments);
  }

  // Return error if period is greater than life
  if (period > life) {
    return new EngineError('DDB', ERROR_NUM, arguments);
  }

  // Return 0 (zero) if salvage is greater than or equal to cost
  if (salvage >= cost) {
    return 0;
  }

  // Compute depreciation
  var total = 0;
  var current = 0;
  for (var i = 1; i <= period; i++) {
    current = Math.min(
      (cost - total) * (factor / life),
      cost - salvage - total
    );
    total += current;
  }

  // Return depreciation
  return current;
}

// TODO
export function DISC() {
  throw new Error('DISC is not implemented');
}

/**
 * Converts a dollar price expressed as an integer part and a fraction part,
 * such as 1.02, into a dollar price expressed as a decimal number.
 *
 * @param dollar A number expressed as an integer part and a fraction part,
 * separated by a decimal symbol.
 * @param fraction The integer to use in the denominator of the fraction
 */
export function DOLLARDE(dollar, fraction) {
  // Credits: algorithm inspired by Apache OpenOffice

  dollar = utils.parseNumber(dollar);
  fraction = utils.parseNumber(fraction);
  if (utils.anyIsError(dollar, fraction)) {
    return new EngineError('DOLLARDE', ERROR_VALUE, arguments);
  }

  // Return error if fraction is negative
  if (fraction < 0) {
    return new EngineError('DOLLARDE', ERROR_NUM, arguments);
  }

  // Return error if fraction is greater than or equal to 0 and less than 1
  if (fraction >= 0 && fraction < 1) {
    return new EngineError('DOLLARDE', ERROR_DIV_ZERO, arguments);
  }

  // Truncate fraction if it is not an integer
  fraction = parseInt(fraction, 10);

  // Compute integer part
  var result = parseInt(dollar, 10);

  // Add decimal part
  result +=
    ((dollar % 1) * Math.pow(10, Math.ceil(Math.log(fraction) / Math.LN10))) /
    fraction;

  // Round result
  var power = Math.pow(10, Math.ceil(Math.log(fraction) / Math.LN2) + 1);
  result = Math.round(result * power) / power;

  // Return converted dollar price
  return result;
}

/**
 * Use DOLLARFR to convert decimal numbers to fractional dollar numbers, such as
 * securities prices.
 *
 * @param dollar A decimal number.
 * @param fraction The integer to use in the denominator of a fraction.
 */
export function DOLLARFR(dollar, fraction) {
  // Credits: algorithm inspired by Apache OpenOffice

  dollar = utils.parseNumber(dollar);
  fraction = utils.parseNumber(fraction);
  if (utils.anyIsError(dollar, fraction)) {
    return new EngineError('DOLLARFR', ERROR_VALUE, arguments);
  }

  // Return error if fraction is negative
  if (fraction < 0) {
    return new EngineError('DOLLARFR', ERROR_NUM, arguments);
  }

  // Return error if fraction is greater than or equal to 0 and less than 1
  if (fraction >= 0 && fraction < 1) {
    return new EngineError('DOLLARFR', ERROR_DIV_ZERO, arguments);
  }

  // Truncate fraction if it is not an integer
  fraction = parseInt(fraction, 10);

  // Compute integer part
  var result = parseInt(dollar, 10);

  // Add decimal part
  result +=
    (dollar % 1) *
    Math.pow(10, -Math.ceil(Math.log(fraction) / Math.LN10)) *
    fraction;

  // Return converted dollar price
  return result;
}

// TODO
export function DURATION() {
  throw new Error('DURATION is not implemented');
}

/**
 * Returns the effective annual interest rate, given the nominal annual interest
 * rate and the number of compounding periods per year.
 *
 * @param rate The nominal interest rate.
 * @param periods The number of compounding periods per year.
 */
export function EFFECT(rate, periods) {
  rate = utils.parseNumber(rate);
  periods = utils.parseNumber(periods);
  if (utils.anyIsError(rate, periods)) {
    return new EngineError('EFFECT', ERROR_VALUE, arguments);
  }

  // Return error if rate <=0 or periods < 1
  if (rate <= 0 || periods < 1) {
    return new EngineError('EFFECT', ERROR_NUM, arguments);
  }

  // Truncate periods if it is not an integer
  periods = parseInt(periods, 10);

  // Return effective annual interest rate
  return Math.pow(1 + rate / periods, periods) - 1;
}

/**
 * calculates the future value of an investment based on a constant interest
 * rate.
 *
 * @param rate The interest rate per period.
 * @param periods The total number of payment periods in an annuity.
 * @param payment The payment made each period; it cannot change over the life
 * of the annuity. Typically, pmt contains principal and interest but no other
 * fees or taxes. If pmt is omitted, you must include the pv argument.
 * @param value Optional. The present value, or the lump-sum amount that a
 * series of future payments is worth right now. If pv is omitted, it is assumed
 * to be 0 (zero), and you must include the pmt argument.
 * @param type Optional. The number 0 or 1 and indicates when payments are due.
 * If type is omitted, it is assumed to be 0.
 */
export function FV(rate, periods, payment, value = 0, type = 0) {
  // Credits: algorithm inspired by Apache OpenOffice
  rate = utils.parseNumber(rate);
  periods = utils.parseNumber(periods);
  payment = utils.parseNumber(payment);
  value = utils.parseNumber(value);
  type = utils.parseNumber(type);
  if (utils.anyIsError(rate, periods, payment, value, type)) {
    return new EngineError('EFFECT', ERROR_VALUE, arguments);
  }

  // Return future value
  var result;
  if (rate === 0) {
    result = value + payment * periods;
  } else {
    var term = Math.pow(1 + rate, periods);
    if (type === 1) {
      result = value * term + (payment * (1 + rate) * (term - 1)) / rate;
    } else {
      result = value * term + (payment * (term - 1)) / rate;
    }
  }
  return -result;
}

/**
 * Returns the future value of an initial principal after applying a series of
 * compound interest rates
 *
 * @param principal The present value.
 * @param schedule An array of interest rates to apply.
 */
export function FVSCHEDULE(principal, schedule) {
  principal = utils.parseNumber(principal);
  schedule = utils.parseNumberArray(utils.flatten(schedule));
  if (utils.anyIsError(principal, schedule)) {
    return new EngineError('FVSCHEDULE', ERROR_VALUE, arguments);
  }

  var n = schedule.length;
  var future = principal;

  // Apply all interests in schedule
  for (var i = 0; i < n; i++) {
    // Apply scheduled interest
    future *= 1 + schedule[i];
  }

  // Return future value
  return future;
}

// TODO
export function INTRATE() {
  throw new Error('INTRATE is not implemented');
}

/**
 * Returns the interest payment for a given period for an investment based on
 * periodic, constant payments and a constant interest rate.
 *
 * @param rate The interest rate per period.
 * @param period The period for which you want to find the interest and must be
 * in the range 1 to nper.
 * @param periods The total number of payment periods in an annuity.
 * @param present The present value, or the lump-sum amount that a series of
 * future payments is worth right now.
 * @param future Optional. The future value, or a cash balance you want to
 * attain after the last payment is made. If fv is omitted, it is assumed to be
 * 0 (the future value of a loan, for example, is 0).
 * @param type  Optional. The number 0 or 1 and indicates when payments are due.
 * If type is omitted, it is assumed to be 0
 */
export function IPMT(rate, period, periods, present, future = 0, type = 0) {
  // Credits: algorithm inspired by Apache OpenOffice

  rate = utils.parseNumber(rate);
  period = utils.parseNumber(period);
  periods = utils.parseNumber(periods);
  present = utils.parseNumber(present);
  future = utils.parseNumber(future);
  type = utils.parseNumber(type);
  if (utils.anyIsError(rate, period, periods, present, future, type)) {
    return new EngineError('IPMT', ERROR_VALUE, arguments);
  }

  // Compute payment
  var payment = exports.PMT(rate, periods, present, future, type);

  // Compute interest
  var interest;
  if (period === 1) {
    if (type === 1) {
      interest = 0;
    } else {
      interest = -present;
    }
  } else {
    if (type === 1) {
      interest = exports.FV(rate, period - 2, payment, present, 1) - payment;
    } else {
      interest = exports.FV(rate, period - 1, payment, present, 0);
    }
  }

  // Return interest
  return interest * rate;
}

/**
 * Returns the internal rate of return for a series of cash flows represented by
 * the numbers in values
 *
 * @param values An array or a reference to cells that contain numbers for which
 * you want to calculate the internal rate of return.
 * @param guess Optional. A number that you guess is close to the result of IRR.
 */
export function IRR(values, guess = 0) {
  // Credits: algorithm inspired by Apache OpenOffice
  values = utils.parseNumberArray(utils.flatten(values));
  guess = utils.parseNumber(guess);

  if (utils.anyIsError(values, guess)) {
    return new EngineError('IRR', ERROR_VALUE, arguments);
  }

  // Calculates the resulting amount
  var irrResult = function (values, dates, rate) {
    var r = rate + 1;
    var result = values[0];
    for (var i = 1; i < values.length; i++) {
      result += values[i] / Math.pow(r, (dates[i] - dates[0]) / 365);
    }
    return result;
  };

  // Calculates the first derivation
  var irrResultDeriv = function (values, dates, rate) {
    var r = rate + 1;
    var result = 0;
    for (var i = 1; i < values.length; i++) {
      var frac = (dates[i] - dates[0]) / 365;
      result -= (frac * values[i]) / Math.pow(r, frac + 1);
    }
    return result;
  };

  // Initialize dates and check that values contains at least one positive value and one negative value
  var dates = [];
  var positive = false;
  var negative = false;
  for (var i = 0; i < values.length; i++) {
    dates[i] = i === 0 ? 0 : dates[i - 1] + 365;
    if (values[i] > 0) {
      positive = true;
    }
    if (values[i] < 0) {
      negative = true;
    }
  }

  // Return error if values does not contain at least one positive value and one negative value
  if (!positive || !negative) {
    return new EngineError('IRR', ERROR_NUM, arguments);
  }

  // Initialize guess and resultRate
  guess = guess === undefined ? 0.1 : guess;
  var resultRate = guess;

  // Set maximum epsilon for end of iteration
  var epsMax = 1e-10;

  // Implement Newton's method
  var newRate, epsRate, resultValue;
  var contLoop = true;
  do {
    resultValue = irrResult(values, dates, resultRate);
    newRate =
      resultRate - resultValue / irrResultDeriv(values, dates, resultRate);
    epsRate = Math.abs(newRate - resultRate);
    resultRate = newRate;
    contLoop = epsRate > epsMax && Math.abs(resultValue) > epsMax;
  } while (contLoop);

  // Return internal rate of return
  return resultRate;
}

/**
 * Calculates the interest paid (or received) for the specified period of a loan
 * (or investment) with even principal payments.
 *
 * @param rate The interest rate for the investment.
 * @param period The period for which you want to find the interest, and must be
 * between 1 and Nper.
 * @param periods The total number of payment periods for the investment.
 * @param value The present value of the investment. For a loan, Pv is the loan
 * amount.
 */
export function ISPMT(rate, period, periods, value) {
  rate = utils.parseNumber(rate);
  period = utils.parseNumber(period);
  periods = utils.parseNumber(periods);
  value = utils.parseNumber(value);
  if (utils.anyIsError(rate, period, periods, value)) {
    return new EngineError('ISPMT', ERROR_VALUE, arguments);
  }

  // Return interest
  return value * rate * (period / periods - 1);
}

// TODO
export function MDURATION() {
  throw new Error('MDURATION is not implemented');
}

/**
 * Returns the modified internal rate of return for a series of periodic cash
 * flows. MIRR considers both the cost of the investment and the interest
 * received on reinvestment of cash.
 *
 * @param values An array or a reference to cells that contain numbers. These
 * numbers represent a series of payments (negative values) and income (positive
 * values) occurring at regular periods.
 * @param finance_rate The interest rate you pay on the money used in the cash
 * flows.
 * @param reinvest_rate The interest rate you receive on the cash flows as you
 * reinvest them.
 */
export function MIRR(values, finance_rate, reinvest_rate) {
  values = utils.parseNumberArray(utils.flatten(values));
  finance_rate = utils.parseNumber(finance_rate);
  reinvest_rate = utils.parseNumber(reinvest_rate);
  if (utils.anyIsError(values, finance_rate, reinvest_rate)) {
    return new EngineError('MIRR', ERROR_VALUE, arguments);
  }

  // Initialize number of values
  var n = values.length;

  // Lookup payments (negative values) and incomes (positive values)
  var payments = [];
  var incomes = [];
  for (var i = 0; i < n; i++) {
    if (values[i] < 0) {
      payments.push(values[i]);
    } else {
      incomes.push(values[i]);
    }
  }

  // Return modified internal rate of return
  var num =
    -exports.NPV(reinvest_rate, incomes) * Math.pow(1 + reinvest_rate, n - 1);
  var den = exports.NPV(finance_rate, payments) * (1 + finance_rate);
  return Math.pow(num / den, 1 / (n - 1)) - 1;
}

/**
 * Returns the nominal annual interest rate, given the effective rate and the
 * number of compounding periods per year.
 *
 * @param rate The effective interest rate.
 * @param periods The number of compounding periods per year.
 */
export function NOMINAL(rate, periods) {
  rate = utils.parseNumber(rate);
  periods = utils.parseNumber(periods);
  if (utils.anyIsError(rate, periods)) {
    return new EngineError('NOMINAL', ERROR_VALUE, arguments);
  }

  // Return error if rate <=0 or periods < 1
  if (rate <= 0 || periods < 1) {
    return new EngineError('NOMINAL', ERROR_NUM, arguments);
  }

  // Truncate periods if it is not an integer
  periods = parseInt(periods, 10);

  // Return nominal annual interest rate
  return (Math.pow(rate + 1, 1 / periods) - 1) * periods;
}

/**
 * Returns the number of periods for an investment based on periodic, constant
 * payments and a constant interest rate.
 *
 * @param rate The interest rate per period.
 * @param payment The payment made each period; it cannot change over the life
 * of the annuity. Typically, pmt contains principal and interest but no other
 * fees or taxes.
 * @param present The present value, or the lump-sum amount that a series of
 * future payments is worth right now.
 * @param future Optional. The future value, or a cash balance you want to
 * attain after the last payment is made. If fv is omitted, it is assumed to be
 * 0 (the future value of a loan, for example, is 0).
 * @param type Optional. The number 0 or 1 and indicates when payments are due.
 */
export function NPER(rate, payment, present, future, type) {
  type = type === undefined ? 0 : type;
  future = future === undefined ? 0 : future;

  rate = utils.parseNumber(rate);
  payment = utils.parseNumber(payment);
  present = utils.parseNumber(present);
  future = utils.parseNumber(future);
  type = utils.parseNumber(type);
  if (utils.anyIsError(rate, payment, present, future, type)) {
    return new EngineError('NPER', ERROR_VALUE, arguments);
  }

  // Return number of periods
  var num = payment * (1 + rate * type) - future * rate;
  var den = present * rate + payment * (1 + rate * type);
  return Math.log(num / den) / Math.log(1 + rate);
}

export function NPV() {
  var args = utils.parseNumberArray(utils.flatten(arguments));
  if (args instanceof Error) {
    return args;
  }
  // Lookup rate
  var rate = args[0];
  // Initialize net present value
  var value = 0;
  // Loop on all values
  for (var j = 1; j < args.length; j++) {
    value += args[j] / Math.pow(1 + rate, j);
  }
  // Return net present value
  return value;
}

// TODO
export function ODDFPRICE() {
  throw new Error('ODDFPRICE is not implemented');
}

// TODO
export function ODDFYIELD() {
  throw new Error('ODDFYIELD is not implemented');
}

// TODO
export function ODDLPRICE() {
  throw new Error('ODDLPRICE is not implemented');
}

// TODO
export function ODDLYIELD() {
  throw new Error('ODDLYIELD is not implemented');
}

/**
 * Returns the number of periods required by an investment to reach a specified
 * value.
 *
 * @param rate Rate is the interest rate per period.
 * @param present Pv is the present value of the investment.
 * @param future Fv is the desired future value of the investment.
 */
export function PDURATION(rate, present, future) {
  rate = utils.parseNumber(rate);
  present = utils.parseNumber(present);
  future = utils.parseNumber(future);
  if (utils.anyIsError(rate, present, future)) {
    return new EngineError('PDURATION', ERROR_VALUE, arguments);
  }
  // Return error if rate <=0
  if (rate <= 0) {
    return new EngineError('PDURATION', ERROR_NUM, arguments);
  }
  // Return number of periods
  return (Math.log(future) - Math.log(present)) / Math.log(1 + rate);
}

/**
 * calculates the payment for a loan based on constant payments and a constant
 * interest rate.
 *
 * @param rate The interest rate for the loan.
 * @param periods The total number of payments for the loan.
 * @param present The present value, or the total amount that a series of
 * future payments is worth now; also known as the principal.
 * @param future Optional. The future value, or a cash balance you want to
 * attain after the last payment is made. If fv is omitted, it is assumed to be
 * 0 (zero), that is, the future value of a loan is 0.
 * @param type  Optional. The number 0 (zero) or 1 and indicates when payments
 * are due.
 */
export function PMT(rate, periods, present, future = 0, type = 0) {
  // Credits: algorithm inspired by Apache OpenOffice
  rate = utils.parseNumber(rate);
  periods = utils.parseNumber(periods);
  present = utils.parseNumber(present);
  future = utils.parseNumber(future);
  type = utils.parseNumber(type);
  if (utils.anyIsError(rate, periods, present, future, type)) {
    return new EngineError('PMT', ERROR_VALUE, arguments);
  }
  // Return payment
  var result;
  if (rate === 0) {
    result = (present + future) / periods;
  } else {
    var term = Math.pow(1 + rate, periods);
    if (type === 1) {
      result =
        ((future * rate) / (term - 1) + (present * rate) / (1 - 1 / term)) /
        (1 + rate);
    } else {
      result = (future * rate) / (term - 1) + (present * rate) / (1 - 1 / term);
    }
  }
  return -result;
}

/**
 * Returns the payment on the principal for a given period for an investment
 * based on periodic, constant payments and a constant interest rate.
 *
 * @param rate The interest rate per period.
 * @param period Specifies the period and must be in the range 1 to nper.
 * @param periods The total number of payment periods in an annuity.
 * @param present The present value — the total amount that a series of future
 * payments is worth now.
 * @param future Optional. The future value, or a cash balance you want to
 * attain after the last payment is made. If fv is omitted, it is assumed to be
 * 0 (zero), that is, the future value of a loan is 0.
 * @param type Optional. The number 0 or 1 and indicates when payments are due.
 */
export function PPMT(rate, period, periods, present, future = 0, type = 0) {
  rate = utils.parseNumber(rate);
  periods = utils.parseNumber(periods);
  present = utils.parseNumber(present);
  future = utils.parseNumber(future);
  type = utils.parseNumber(type);
  if (utils.anyIsError(rate, periods, present, future, type)) {
    return new EngineError('PPMT', ERROR_VALUE, arguments);
  }
  return (
    exports.PMT(rate, periods, present, future, type) -
    exports.IPMT(rate, period, periods, present, future, type)
  );
}

// TODO
export function PRICE() {
  throw new Error('PRICE is not implemented');
}

// TODO
export function PRICEDISC() {
  throw new Error('PRICEDISC is not implemented');
}

// TODO
export function PRICEMAT() {
  throw new Error('PRICEMAT is not implemented');
}

/**
 * calculates the present value of a loan or an investment, based on a constant interest rate.
 *
 * @param rate The interest rate per period.
 * @param periods The total number of payment periods in an annuity.
 * @param payment The payment made each period and cannot change over the life
 * of the annuity.
 * @param future Optional. The future value, or a cash balance you want to
 * attain after the last payment is made. If fv is omitted, it is assumed to be
 * 0 (the future value of a loan, for example, is 0).
 * @param type Optional. The number 0 or 1 and indicates when payments are due.
 */
export function PV(rate, periods, payment, future = 0, type = 0) {
  rate = utils.parseNumber(rate);
  periods = utils.parseNumber(periods);
  payment = utils.parseNumber(payment);
  future = utils.parseNumber(future);
  type = utils.parseNumber(type);
  if (utils.anyIsError(rate, periods, payment, future, type)) {
    return new EngineError('PV', ERROR_VALUE, arguments);
  }
  // Return present value
  if (rate === 0) {
    return -payment * periods - future;
  }
  return (
    (((1 - Math.pow(1 + rate, periods)) / rate) * payment * (1 + rate * type) -
      future) /
    Math.pow(1 + rate, periods)
  );
}

/**
 * Returns the interest rate per period of an annuity.
 *
 * @param periods  The total number of payment periods in an annuity.
 * @param payment The payment made each period and cannot change over the life
 * of the annuity.
 * @param present  The present value — the total amount that a series of future
 * payments is worth now.
 * @param future Optional. The future value, or a cash balance you want to
 * attain after the last payment is made.
 * @param type Optional. The number 0 or 1 and indicates when payments are due.
 * @param guess Optional. Your guess for what the rate will be.
 */
export function RATE(
  periods,
  payment,
  present,
  future = 0,
  type = 0,
  guess = 0.01
) {
  // Credits: rabugento
  periods = utils.parseNumber(periods);
  payment = utils.parseNumber(payment);
  present = utils.parseNumber(present);
  future = utils.parseNumber(future);
  type = utils.parseNumber(type);
  guess = utils.parseNumber(guess);

  if (utils.anyIsError(periods, payment, present, future, type, guess)) {
    return new EngineError('RATE', ERROR_VALUE, arguments);
  }

  // Set maximum epsilon for end of iteration
  var epsMax = 1e-10;

  // Set maximum number of iterations
  var iterMax = 50;

  // Implement Newton's method
  var y,
    y0,
    y1,
    x0,
    x1 = 0,
    f = 0,
    i = 0;
  var rate = guess;
  if (Math.abs(rate) < epsMax) {
    y =
      present * (1 + periods * rate) +
      payment * (1 + rate * type) * periods +
      future;
  } else {
    f = Math.exp(periods * Math.log(1 + rate));
    y = present * f + payment * (1 / rate + type) * (f - 1) + future;
  }
  y0 = present + payment * periods + future;
  y1 = present * f + payment * (1 / rate + type) * (f - 1) + future;
  i = x0 = 0;
  x1 = rate;
  while (Math.abs(y0 - y1) > epsMax && i < iterMax) {
    rate = (y1 * x0 - y0 * x1) / (y1 - y0);
    x0 = x1;
    x1 = rate;
    if (Math.abs(rate) < epsMax) {
      y =
        present * (1 + periods * rate) +
        payment * (1 + rate * type) * periods +
        future;
    } else {
      f = Math.exp(periods * Math.log(1 + rate));
      y = present * f + payment * (1 / rate + type) * (f - 1) + future;
    }
    y0 = y1;
    y1 = y;
    ++i;
  }
  return rate;
}

// TODO
export function RECEIVED() {
  throw new Error('RECEIVED is not implemented');
}

/**
 * Returns an equivalent interest rate for the growth of an investment.
 *
 * @param periods Nper is the number of periods for the investment.
 * @param present Pv is the present value of the investment.
 * @param future Fv is the future value of the investment.
 */
export function RRI(periods, present, future) {
  periods = utils.parseNumber(periods);
  present = utils.parseNumber(present);
  future = utils.parseNumber(future);
  if (utils.anyIsError(periods, present, future)) {
    return new EngineError('RRI', ERROR_VALUE, arguments);
  }

  // Return error if periods or present is equal to 0 (zero)
  if (periods === 0 || present === 0) {
    return new EngineError('RRI', ERROR_NUM, arguments);
  }

  // Return equivalent interest rate
  return Math.pow(future / present, 1 / periods) - 1;
}

/**
 * Returns the straight-line depreciation of an asset for one period.
 *
 * @param cost The initial cost of the asset.
 * @param salvage The value at the end of the depreciation (sometimes called
 * the salvage value of the asset).
 * @param life he number of periods over which the asset is depreciated
 * (sometimes called the useful life of the asset).
 */
export function SLN(cost, salvage, life) {
  cost = utils.parseNumber(cost);
  salvage = utils.parseNumber(salvage);
  life = utils.parseNumber(life);
  if (utils.anyIsError(cost, salvage, life)) {
    return new EngineError('SLN', ERROR_VALUE, arguments);
  }

  // Return error if life equal to 0 (zero)
  if (life === 0) {
    return new EngineError('SLN', ERROR_NUM, arguments);
  }

  // Return straight-line depreciation
  return (cost - salvage) / life;
}

/**
 * Returns the sum-of-years' digits depreciation of an asset for a specified
 * period.
 *
 * @param cost The initial cost of the asset.
 * @param salvage The value at the end of the depreciation (sometimes called
 * the salvage value of the asset).
 * @param life The number of periods over which the asset is depreciated
 * (sometimes called the useful life of the asset).
 * @param period The period and must use the same units as life.
 */
export function SYD(cost, salvage, life, period) {
  // Return error if any of the parameters is not a number
  cost = utils.parseNumber(cost);
  salvage = utils.parseNumber(salvage);
  life = utils.parseNumber(life);
  period = utils.parseNumber(period);
  if (utils.anyIsError(cost, salvage, life, period)) {
    return new EngineError('SYD', ERROR_VALUE, arguments);
  }

  // Return error if life equal to 0 (zero)
  if (life === 0) {
    return new EngineError('SYD', ERROR_NUM, arguments);
  }

  // Return error if period is lower than 1 or greater than life
  if (period < 1 || period > life) {
    return new EngineError('SYD', ERROR_NUM, arguments);
  }

  // Truncate period if it is not an integer
  period = parseInt(period, 10);

  // Return straight-line depreciation
  return ((cost - salvage) * (life - period + 1) * 2) / (life * (life + 1));
}

/**
 * Returns the bond-equivalent yield for a Treasury bill.
 *
 * @param settlement The Treasury bill's settlement date. The security
 * settlement date is the date after the issue date when the Treasury bill is
 * traded to the buyer.
 * @param maturity The Treasury bill's maturity date. The maturity date is the
 * date when the Treasury bill expires.
 * @param discount The Treasury bill's discount rate.
 */
export function TBILLEQ(settlement, maturity, discount) {
  settlement = utils.parseDate(settlement);
  maturity = utils.parseDate(maturity);
  discount = utils.parseNumber(discount);
  if (utils.anyIsError(settlement, maturity, discount)) {
    return new EngineError('TBILLEQ', ERROR_VALUE, arguments);
  }

  // Return error if discount is lower than or equal to zero
  if (discount <= 0) {
    return new EngineError('TBILLEQ', ERROR_NUM, arguments);
  }

  // Return error if settlement is greater than maturity
  if (settlement > maturity) {
    return new EngineError('TBILLEQ', ERROR_NUM, arguments);
  }

  // Return error if maturity is more than one year after settlement
  if (maturity - settlement > 365 * 24 * 60 * 60 * 1000) {
    return new EngineError('TBILLEQ', ERROR_NUM, arguments);
  }

  // Return bond-equivalent yield
  // @ts-ignore
  return (
    (365 * discount) / (360 - discount * +DAYS360(settlement, maturity, false))
  );
}

/**
 * Returns the price per $100 face value for a Treasury bill.
 *
 * @param settlement The Treasury bill's settlement date. The security
 * settlement date is the date after the issue date when the Treasury bill is
 * traded to the buyer.
 * @param maturity The Treasury bill's maturity date. The maturity date is the
 * date when the Treasury bill expires.
 * @param discount The Treasury bill's discount rate.
 */
export function TBILLPRICE(settlement, maturity, discount) {
  settlement = utils.parseDate(settlement);
  maturity = utils.parseDate(maturity);
  discount = utils.parseNumber(discount);
  if (utils.anyIsError(settlement, maturity, discount)) {
    return new EngineError('TBILLPRICE', ERROR_VALUE, arguments);
  }

  // Return error if discount is lower than or equal to zero
  if (discount <= 0) {
    return new EngineError('TBILLPRICE', ERROR_NUM, arguments);
  }

  // Return error if settlement is greater than maturity
  if (settlement > maturity) {
    return new EngineError('TBILLPRICE', ERROR_NUM, arguments);
  }

  // Return error if maturity is more than one year after settlement
  if (maturity - settlement > 365 * 24 * 60 * 60 * 1000) {
    return new EngineError('TBILLPRICE', ERROR_NUM, arguments);
  }

  // Return bond-equivalent yield
  // @ts-ignore
  return 100 * (1 - (discount * DAYS360(settlement, maturity, false)) / 360);
}

/**
 * Returns the yield for a Treasury bill.
 *
 * @param settlement The Treasury bill's settlement date. The security
 * settlement date is the date after the issue date when the Treasury bill is traded to the buyer.
 * @param maturity The Treasury bill's maturity date. The maturity date is the
 * date when the Treasury bill expires.
 * @param price The Treasury bill's price per $100 face value.
 */
export function TBILLYIELD(settlement, maturity, price) {
  settlement = utils.parseDate(settlement);
  maturity = utils.parseDate(maturity);
  price = utils.parseNumber(price);
  if (utils.anyIsError(settlement, maturity, price)) {
    return new EngineError('TBILLPRICE', ERROR_VALUE, arguments);
  }

  // Return error if price is lower than or equal to zero
  if (price <= 0) {
    return new EngineError('TBILLPRICE', ERROR_NUM, arguments);
  }

  // Return error if settlement is greater than maturity
  if (settlement > maturity) {
    return new EngineError('TBILLPRICE', ERROR_NUM, arguments);
  }

  // Return error if maturity is more than one year after settlement
  if (maturity - settlement > 365 * 24 * 60 * 60 * 1000) {
    return new EngineError('TBILLPRICE', ERROR_NUM, arguments);
  }

  // Return bond-equivalent yield
  // @ts-ignore
  return ((100 - price) * 360) / (price * DAYS360(settlement, maturity, false));
}

// TODO
export function VDB() {
  throw new Error('VDB is not implemented');
}

// TODO needs better support for date
// exports.XIRR = function(values, dates, guess) {
//   // Credits: algorithm inspired by Apache OpenOffice
//
//   values = utils.parseNumberArray(utils.flatten(values));
//   dates = utils.parseDateArray(utils.flatten(dates));
//   guess = utils.parseNumber(guess);
//
//   if (utils.anyIsError(values, dates, guess)) {
//     return new EngineError('{FUNCTION_NAME}', ERROR_VALUE, arguments);
//   }
//
//   // Calculates the resulting amount
//   var irrResult = function(values, dates, rate) {
//     var r = rate + 1;
//     var result = values[0];
//     for (var i = 1; i < values.length; i++) {
//       result += values[i] / Math.pow(r, dateTime.DAYS(dates[i], dates[0]) / 365);
//     }
//     return result;
//   };
//
//   // Calculates the first derivation
//   var irrResultDeriv = function(values, dates, rate) {
//     var r = rate + 1;
//     var result = 0;
//     for (var i = 1; i < values.length; i++) {
//       var frac = dateTime.DAYS(dates[i], dates[0]) / 365;
//       result -= frac * values[i] / Math.pow(r, frac + 1);
//     }
//     return result;
//   };
//
//   // Check that values contains at least one positive value and one negative value
//   var positive = false;
//   var negative = false;
//   for (var i = 0; i < values.length; i++) {
//     if (values[i] > 0) {
//       positive = true;
//     }
//     if (values[i] < 0) {
//       negative = true;
//     }
//   }
//
//   // Return error if values does not contain at least one positive value and one negative value
//   if (!positive || !negative) {
//     return new EngineError('{FUNCTION_NAME}', ERROR_NUM, arguments);
//   }
//
//   // Initialize guess and resultRate
//   guess = guess || 0.1;
//   var resultRate = guess;
//
//   // Set maximum epsilon for end of iteration
//   var epsMax = 1e-10;
//
//   // Implement Newton's method
//   var newRate, epsRate, resultValue;
//   var contLoop = true;
//   do {
//     resultValue = irrResult(values, dates, resultRate);
//     newRate = resultRate - resultValue / irrResultDeriv(values, dates, resultRate);
//     epsRate = Math.abs(newRate - resultRate);
//     resultRate = newRate;
//     contLoop = (epsRate > epsMax) && (Math.abs(resultValue) > epsMax);
//   } while (contLoop);
//
//   // Return internal rate of return
//   return resultRate;
// };

/**
 * Returns the net present value for a schedule of cash flows that is not
 * necessarily periodic.
 *
 * @param rate The discount rate to apply to the cash flows.
 * @param values  A series of cash flows that corresponds to a schedule of
 * payments in dates.
 * @param dates  A schedule of payment dates that corresponds to the cash flow
 * payments.
 */
export function XNPV(rate, values, dates) {
  rate = utils.parseNumber(rate);
  values = utils.parseNumberArray(utils.flatten(values));
  dates = utils.parseDateArray(utils.flatten(dates));
  if (utils.anyIsError(rate, values, dates)) {
    return new EngineError('XNPV', ERROR_VALUE, arguments);
  }

  var result = 0;
  for (var i = 0; i < values.length; i++) {
    // @ts-ignore
    result += values[i] / Math.pow(1 + rate, DAYS(dates[i], dates[0]) / 365);
  }
  return result;
}

// TODO
export function YIELD() {
  throw new Error('YIELD is not implemented');
}

// TODO
export function YIELDDISC() {
  throw new Error('YIELDDISC is not implemented');
}

// TODO
export function YIELDMAT() {
  throw new Error('YIELDMAT is not implemented');
}
