// tslint:disable object-shorthand-properties-first

import Dispatcher from '../dispatcher';
import { ActionTypes } from '../action-types';

export namespace feedback {
  export interface IFeedback {
    add(level: string, message: string, showFor?: number): void;
    remove(): void;
  }

  export function add(
    level: string,
    message: string,
    showFor: number = 2000
  ): void {
    Dispatcher.dispatch({
      type: ActionTypes.ADD_FEEDBACK,
      level,
      message
    });
    setTimeout(() => {
      remove();
    }, showFor);
  }

  export function remove(): void {
    Dispatcher.dispatch({
      type: ActionTypes.REMOVE_FEEDBACK
    });
  }
}
