import { EngineError, ERROR_VALUE } from "../../error";

import { parseNumber, anyIsError } from "../utils";
import { ROUND, FACT } from "../math-and-trigonometry";

/**
 * Returns number rounded up, away from zero, to the nearest multiple of
 * significance.
 *
 * @param number The value you want to round.
 * @param significance The multiple to which you want to round.
 * @param mode
 */
export function CEILING(number, significance, mode) {
  significance = significance === undefined ? 1 : Math.abs(significance);
  mode = mode || 0;

  number = parseNumber(number);
  significance = parseNumber(significance);
  mode = parseNumber(mode);
  if (anyIsError(number, significance, mode)) {
    return new EngineError("CEILING", ERROR_VALUE, arguments);
  }
  if (significance === 0) {
    return 0;
  }
  var precision = -Math.floor(Math.log(significance) / Math.log(10));
  if (number >= 0) {
    return ROUND(Math.ceil(number / significance) * significance, precision);
  } else {
    if (mode === 0) {
      return -ROUND(
        Math.floor(Math.abs(number) / significance) * significance,
        precision
      );
    } else {
      return -ROUND(
        Math.ceil(Math.abs(number) / significance) * significance,
        precision
      );
    }
  }
}

/**
 * Rounds a number up to the nearest integer or to the nearest multiple of
 * significance.
 *
 * @param number The value you want to round.
 * @param significance The multiple to which Number is to be rounded
 * @param mode Optional. For negative numbers, controls whether Number is
 * rounded toward or away from zero.
 */
export const CEILING_MATH = CEILING;

/**
 * Returns a number that is rounded up to the nearest integer or to the nearest
 * multiple of significance. Regardless ofthe sign of the number, the number is
 * rounded up. However, if the number or the significance is zero, zero is
 * returned.
 *
 * @param number The value you want to round.
 * @param significance The multiple to which Number is to be rounded
 */
export const CEILING_PRECISE = CEILING;

/**
 * Returns the number of combinations for a given number of items. Use COMBIN
 * to determine the total possible number of groups for a given number of items.
 *
 * @param number The number of items.
 * @param numberChosen The number of items in each combination.
 */
export function COMBIN(number, numberChosen) {
  number = parseNumber(number);
  numberChosen = parseNumber(numberChosen);
  if (anyIsError(number, numberChosen)) {
    return new EngineError("COMBIN", ERROR_VALUE, arguments);
  }
  return FACT(number) / (FACT(numberChosen) * FACT(number - numberChosen));
}

/**
 * Returns the number of combinations (with repetitions) for a given number of
 * items.
 *
 * @param number Must be greater than or equal to 0, and greater than or equal
 * to Number_chosen. Non-integer values are truncated.
 * @param numberChosen Must be greater than or equal to 0. Non-integer values
 * are truncated.
 */
export function COMBINA(number, numberChosen) {
  number = parseNumber(number);
  numberChosen = parseNumber(numberChosen);
  if (anyIsError(number, numberChosen)) {
    return new EngineError("COMBINA", ERROR_VALUE, arguments);
  }
  return number === 0 && numberChosen === 0
    ? 1
    : COMBIN(number + numberChosen - 1, number - 1);
}

/**
 * Returns the cosine of the given angle.
 *
 * @param number The angle in radians for which you want the cosine.
 */
export function COS(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  return Math.cos(number);
}

/**
 * Returns the hyperbolic cosine of a number.
 *
 * @param number Any real number for which you want to find the hyperbolic
 * cosine.
 */
export function COSH(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  return (Math.exp(number) + Math.exp(-number)) / 2;
}

/**
 * Return the cotangent of an angle specified in radians.
 *
 * @param number The angle in radians for which you want the cotangent.
 */
export function COT(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  return 1 / Math.tan(number);
}

/**
 * Return the hyperbolic cotangent of a hyperbolic angle.
 *
 * @param number
 */
export function COTH(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  var e2 = Math.exp(2 * number);
  return (e2 + 1) / (e2 - 1);
}

/**
 * Returns the cosecant of an angle specified in radians.
 *
 * @param number
 */
export function CSC(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  return 1 / Math.sin(number);
}

/**
 * Return the hyperbolic cosecant of an angle specified in radians.
 *
 * @param number
 */
export function CSCH(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  return 2 / (Math.exp(number) - Math.exp(-number));
}
