import * as React from 'react';
import * as classNames from 'classnames';
import { DragSource, DropTarget } from 'react-dnd';
import * as moment from 'moment';
import { FileType } from '@spreax/model';
import {
  collectDrag,
  collectDrop,
  beginDrag,
  endDrag,
  canDrop,
  drop,
  DROP_TYPE
} from './drag-and-drop';

function FileBrowserItemComponent(props) {
  const {
    type,
    title,
    dateLastUpdated,
    onClick,
    isSelected,
    connectDragSource,
    connectDropTarget,
    canDrop,
    isOver,
    isHidden,
    extra
  } = props;
  const iconMap = {
    [FileType.Folder]: 'folder',
    [FileType.Project]: 'insert_drive_file',
    [FileType.Template]: 'book'
  };
  return connectDragSource(
    connectDropTarget(
      <div
        className={classNames('sx-file-browser__item', {
          'sx-file-browser__folder': type === FileType.Folder,
          'sx-file-browser__file': type === FileType.Project,
          'sx-file-browser__item--is-selected': isSelected,
          'sx-file-browser__item--is-target': canDrop && isOver,
          'sx-file-browser__item--is-hidden': isHidden
        })}
        onClick={onClick}
      >
        <i className="material-icons sx-file-browser__item__icon">
          {iconMap[type]}
        </i>
        <div className="sx-file-browser__item__title">{title}</div>
        <div className="sx-file-browser__item__last-updated">
          {type === FileType.Folder ? '-' : moment(dateLastUpdated).calendar()}
        </div>
        <div className="sx-file-browser__item__extra">{extra}</div>
        {/*<div className="sx-portal__file-browser__item__created-by">*/}
        {/*{createdBy || '-'}*/}
        {/*</div>*/}
      </div>
    )
  );
}

export const FileBrowserItem = DragSource(
  DROP_TYPE,
  { beginDrag, endDrag },
  collectDrag
)(
  DropTarget(DROP_TYPE, { canDrop, drop }, collectDrop)(
    FileBrowserItemComponent
  )
);
