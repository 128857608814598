import {
  EngineError,
  ERROR_VALUE,
  ERROR_NUM,
  ERROR_NOT_AVAILABLE,
  ERROR
} from "../../error";
import { parseNumber, parseNumberArray, flatten, anyIsError } from "../utils";


/**
 * Returns the number 3.14159265358979, the mathematical constant pi
 */
export function PI() {
  return Math.PI;
}

// TODO NOT_DEFINED in https://support.office.com/
export function POW(base, exponent) {
  if (arguments.length !== 2) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NOT_AVAILABLE, arguments);
  }

  base = parseNumber(base);
  exponent = parseNumber(exponent);
  if (anyIsError(base, exponent)) {
    return new EngineError("{FUNCTION_NAME}", ERROR, arguments);
  }

  return POWER(base, exponent);
}

/**
 * Returns the result of a number raised to a power.
 * 
 * @param number The base number. It can be any real number.
 * @param power The exponent to which the base number is raised.
 */
export function POWER(number, power) {
  number = parseNumber(number);
  power = parseNumber(power);
  if (anyIsError(number, power)) {
    return new EngineError("POWER", ERROR_VALUE, arguments);
  }
  var result = Math.pow(number, power);
  if (isNaN(result)) {
    return new EngineError("POWER", ERROR_NUM, arguments);
  }
  return result;
}

/**
 * The PRODUCT function multiplies all the numbers given as arguments and
 * returns the product. The PRODUCT function is useful when you need to multiply
 * many cells together. For example, the formula =PRODUCT(A1:A3, C1:C3) is
 * equivalent to =A1 * A2 * A3 * C1 * C2 * C3.
 * 
 * @param number[] Number1, number2,... Number1 is required, subsequent
 * numbers are optional. Numbers that you want multiplied
 */
export function PRODUCT(...args) {
  // @ts-ignore
  var args = parseNumberArray(flatten(args));
  if (args instanceof Error) {
    return args;
  }
  var result = 1;
  for (var i = 0; i < args.length; i++) {
    result *= args[i];
  }
  return result;
}
