var utils = require("../utils");
var jStat = require("jStat").jStat;

/**
 * Returns the kurtosis of a data set. Kurtosis characterizes the relative
 * peakedness or flatness of a distribution compared with the normal
 * distribution.
 * 
 * @param arg[] Number1, number2, ...    Number1 is required, subsequent numbers
 * are optional. 1 to 255 arguments for which you want to calculate kurtosis.
 * You can also use a single array or a reference to an array instead of
 * arguments separated by commas.
 */
export function KURT(...args) {
  var range = utils.parseNumberArray(utils.flatten(args));
  if (range instanceof Error) {
    return range;
  }
  var mean = jStat.mean(range);
  var n = range.length;
  var sigma = 0;
  for (var i = 0; i < n; i++) {
    sigma += Math.pow(range[i] - mean, 4);
  }
  sigma = sigma / Math.pow(jStat.stdev(range, true), 4);

  return (
    ((n * (n + 1)) / ((n - 1) * (n - 2) * (n - 3))) * sigma -
    (3 * (n - 1) * (n - 1)) / ((n - 2) * (n - 3))
  );
}