import * as React from 'react';
import '../../../src/components/editable/style.scss';
import ContentEditable from 'react-contenteditable';

/**
 * Prevent multiple lines on `Enter` event
 *
 * @param event
 * @returns {void}
 */

function onKeyPress(event): void {
  if (event.charCode === 13) {
    event.preventDefault();
  }
}

/**
 * First strip HTML and then execute `onChange` as defined by props
 *
 * @param handler
 * @returns {void}
 */

function onChange(handler): (event) => void {
  return event => {
    event.target.value = stripHTML(event.target.value);
    handler(event);
  };
}

/**
 * `ContentEditable` returns the value in the `onChange` handler as HTML, this function converts it to text.
 *
 * @param html
 */

function stripHTML(html: string): string {
  const tmp = document.createElement('div');
  tmp.innerHTML = html;
  return tmp.innerText;
}

export function Editable(props) {
  return (
    <ContentEditable
      className="sx-editable"
      {...props}
      onKeyPress={onKeyPress}
      onChange={onChange(props.onChange)}
    ></ContentEditable>
  );
}
