// tslint:disable object-shorthand-properties-first

import Dispatcher from '../dispatcher';
import { ActionTypes } from '../action-types';
import * as Actions from '.';
import { __, i18n } from '@spreax/i18n';
import { ProjectGUID, TemplateGUID, TenantGUID } from '@spreax/model';
import { State } from '../container';

export namespace project {
  export interface IProject {
    select(id: ProjectGUID): void;
    create(templateId: TemplateGUID): void;
    duplicate(templateId: TemplateGUID, projectID: ProjectGUID): void;
    draft(templateId: TemplateGUID, session: string): void;
    save(id: ProjectGUID);
    update(id: ProjectGUID, field: string, value: any): void;
    deactivate(id: ProjectGUID): void;
    makeSureProjectIsSelected(props);
    makeSureHasNotUnsavedChanges(props);
  }

  export function select(id: ProjectGUID): void {
    project.draft(null, null);
    Dispatcher.dispatch({
      type: ActionTypes.SELECT_PROJECT,
      id
    });
  }

  export function create(tenantId: TenantGUID, templateId: TemplateGUID): void {
    Actions.app.setLoading();
    Dispatcher.dispatch({
      type: ActionTypes.CREATE_PROJECT,
      tenantId,
      templateId
    });
  }

  export function duplicate(
    tenantId: TenantGUID,
    templateId: TemplateGUID,
    projectID: ProjectGUID
  ): void {
    Actions.app.setLoading();
    Dispatcher.dispatch({
      type: ActionTypes.DUPLICATE_PROJECT,
      tenantId,
      templateId,
      projectID
    });
  }

  export function draft(templateId: TemplateGUID, session: string): void {
    Dispatcher.dispatch({
      type: ActionTypes.DRAFT_PROJECT,
      templateId,
      session
    });
  }

  export function save(tenantId: TenantGUID, id: ProjectGUID) {
    Actions.app.setLoading();
    Dispatcher.dispatch({
      type: ActionTypes.SAVE_PROJECT,
      tenantId,
      id
    });
  }

  export function update(
    tenantId: TenantGUID,
    id: ProjectGUID,
    field: string,
    value: any
  ): void {
    Dispatcher.dispatch({
      type: ActionTypes.UPDATE_PROJECT,
      tenantId,
      id,
      field,
      value
    });
  }

  export function deactivate(tenantId: TenantGUID, id: ProjectGUID): void {
    Actions.app.setLoading();
    Dispatcher.dispatch({
      type: ActionTypes.DEACTIVATE_PROJECT,
      tenantId,
      id
    });
  }

  export function makeSureProjectIsSelected(props) {
    return async (getState: () => State, dispatch) => {
      const { route } = props;
      const {
        actions,
        selectedProject,
        hasReceivedProjects,
        hasReceivedTemplates,
        selectedTemplate,
        projects,
        templates
      } = getState();
      const projectId = route.match.params.projectId;
      if (
        hasReceivedProjects &&
        hasReceivedTemplates &&
        projectId &&
        (!selectedProject || selectedProject.id !== projectId)
      ) {
        const hasProject = projects.find(project => project.id === projectId);
        if (hasProject) {
          await dispatch(() => actions.project.select(projectId));
        } else {
          route.history.push('/error/404');
        }
      }
      if (
        selectedProject &&
        (!selectedTemplate ||
          selectedTemplate.id !== selectedProject.templateId)
      ) {
        const hasTemplate = templates.find(
          template => template.id === selectedProject.templateId
        );
        if (hasTemplate) {
          await dispatch(() =>
            actions.template.select(selectedProject.templateId)
          );
        } else {
          route.history.push('/error/404');
        }
      }
      if (selectedProject && !projectId) {
        await dispatch(() => actions.project.select(null));
      }
    };
  }

  export function makeSureHasNotUnsavedChanges() {
    return async (getState: () => State, dispatch) => {
      const { hasUnsavedChanges } = getState();
      window.onbeforeunload = function() {
        if (hasUnsavedChanges) {
          return __(i18n.FEEDBACK.CONFIRM_UNSAVED_CHANGES);
        }
      };
    };
  }
}
