import * as React from 'react';
import { __, i18n } from '@spreax/i18n';
import '../../../src/components/chips/style.scss';
import { Chip } from '../';

function renderMoreChip(total, max) {
  if (max && total > max) {
    return (
      <Chip id="more">
        {total - max} {__(i18n.CHIPS_COMPONENT.MORE)}...
      </Chip>
    );
  }

  return null;
}

export function Chips(props) {
  const { children, maxChips, onDelete } = props;

  if (!children) {
    return null;
  }

  let visibleChips = children;
  if (maxChips && children.length > maxChips) {
    visibleChips = children.slice(0, maxChips);
  }

  return (
    <p className="sx-chips">
      {React.Children.map(visibleChips, chip =>
        React.cloneElement(chip as any, { onDelete })
      )}
      {renderMoreChip(children.length, maxChips)}
    </p>
  );
}
