import { onFullfilled, onRejection } from './utils';
import { __, i18n } from '@spreax/i18n';
import { Folder, NewFolder, FolderGUID, TenantGUID } from '@spreax/model';
import { ActionTypes } from '../action-types';
import * as DataManager from '.';
import * as API from '@spreax/api-client';

export namespace folder {
  export function get(tenantId: TenantGUID): Promise<void> {
    return API.folder.get(tenantId).then(
      onFullfilled(null, null, (data: Folder[]) => {
        return {
          type: ActionTypes.RECEIVE_FOLDERS,
          folders: data
        };
      }),
      onRejection()
    );
  }

  export function create(
    tenantId: TenantGUID,
    folder: NewFolder
  ): Promise<void> {
    return API.folder
      .create(tenantId, folder)
      .then(
        onFullfilled(i18n.FEEDBACK.CHANGES_SAVED, () => get(tenantId)),
        onRejection()
      );
  }

  export function remove(tenantId: TenantGUID, id: FolderGUID): Promise<void> {
    return API.folder
      .remove({ id })
      .then(
        onFullfilled(i18n.FEEDBACK.CHANGES_SAVED, () => get(tenantId)),
        onRejection()
      );
  }

  export function update(tenantId: TenantGUID, folder): Promise<void> {
    return API.folder
      .update(folder)
      .then(
        onFullfilled(i18n.FEEDBACK.CHANGES_SAVED, () => get(tenantId)),
        onRejection()
      );
  }
}
