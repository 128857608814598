export let API = process.env['API_ENDPOINT'];
export let ENDPOINT = API;
export * from './call';
export * from './utils';
export * from './endpoints';

export function connect (url) {
  API = url;
  ENDPOINT = url;
}
