import { onFullfilled, onRejection } from './utils';
import { __, i18n } from '@spreax/i18n';
import Dispatcher from '../dispatcher';
import { ActionTypes } from '../action-types';
import * as DataManager from '.';
import { AttachmentId, TenantGUID } from '@spreax/model';
import * as API from '@spreax/api-client';

export namespace attachment {
  const PATH = '/attachment';

  export function upload(meta, binary) {
    const data = {
      ...meta,
      binary
    };
    return API.attachment.upload(data).then(
      onFullfilled(
        null,
        [DataManager.template.get, DataManager.project.get],
        () => {
          return {
            type: ActionTypes.UPLOADED_ATTACHMENT
          };
        }
      ),
      onRejection()
    );
  }

  export function download(id: AttachmentId): Promise<void> {
    const opts = {
      method: 'GET',
      headers: new Headers({
        'X-Auth-Token': localStorage.getItem('token') || ''
      })
    };
    return fetch(API.ENDPOINT + PATH + '/' + id, opts)
      .then(async response => {
        const fileName = response.headers
          .get('content-disposition')
          .split('filename=')[1];
        const url = URL.createObjectURL(await response.blob());
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
      }, onRejection())
      .then(() => {
        Dispatcher.dispatch({
          type: ActionTypes.DOWNLOADED_ATTACHMENT
        });
      });
  }

  export function remove(id: AttachmentId): Promise<void> {
    return API.attachment.remove({ id }).then(
      onFullfilled(
        null,
        [DataManager.template.get, DataManager.project.get],
        () => {
          return {
            type: ActionTypes.REMOVED_ATTACHMENT
          };
        }
      ),
      onRejection()
    );
  }
}
