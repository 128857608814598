import * as React from 'react';
import '../../../src/components/drawer/style.scss';
import { __, i18n } from '@spreax/i18n';
import * as classNames from 'classnames';

export function DrawerTitle(props) {
  const { children } = props;
  return <div className="sx-context-drawer__title">{children}</div>;
}

export function DrawerDescription(props) {
  const { children } = props;
  return <div className="sx-context-drawer__description">{children}</div>;
}

export function DrawerRow(props) {
  const { children } = props;
  return <div className="sx-context-drawer__row">{children}</div>;
}

export function DrawerWrapper(props) {
  const { children } = props;
  return <div className="sx-context-drawer__wrapper">{children}</div>;
}

export function DrawerSeparator(props) {
  return <div className="sx-context-drawer__separator"></div>;
}

export function Drawer(props) {
  const { showContextDrawer, children, className } = props;
  if (!showContextDrawer) {
    return null;
  }
  return (
    <div className={classNames('sx-context-drawer', className)}>{children}</div>
  );
}
