import * as React from 'react';
import injectSheet, {
  ThemeProvider,
  SheetsRegistry,
  JssProvider,
  jss
} from 'react-jss';
import { loadTheme } from 'office-ui-fabric-react/lib/Styling';

const sheets = new SheetsRegistry();

const colors = {
  primary: '#0747A6',
  lightGray: '#f6f6f6',
  gray: '#3a3a3a'
};

function getStyle(theme: any = {}) {
  const primaryColor = theme.primaryColor || colors.primary;
  return {
    primaryColor,
    themeOptions: {},
    tabs: {
      container: {
        // marginBottom: '20px',
        // padding: '0 10px',
        margin: ['0', '2px'],
        boxSizing: 'border-box',
        padding: ['8px']
        // backgroundColor: '#eff0ef',
      },
      tab: {
        // padding: '15px 30px',
        margin: '0px 8px',
        padding: ['8px', '0'],
        '&:first-child': {
          marginLeft: 0
        }
      },
      selectedTab: {
        // backgroundColor: '#fff',
        borderBottom: ['2px', 'solid', primaryColor],
        fontWeight: 'bold',
        color: primaryColor
        // color: '#0747A6',
      },
      panels: {
        padding: ['8px', '0']
      },
      panel: {
        padding: ['8px', '0']
      }
    },
    textStyles: {
      p: {
        color: '#343434'
      }
    },
    inputs: {
      inputField: {
        base: {
          border: ['1px', 'solid', '#d1d1d1'],
          backgroundColor: '#fff'
        },
        baseHover: {
          borderColor: '#707070'
        },
        focused: {
          borderColor: '#0747A6'
        },
        disabled: {
          border: 'none!important',
          backgroundColor: '#f6f7f6'
        }
      }
    },
    button: {
      container: {
        padding: ['10px', '20px'],
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#f6f6f6',
        color: '#505f79',
        fontSize: '12px',
        fontWeight: 700
      },
      default: {
        '&:hover': {
          backgroundColor: '#0747A6',
          color: '#fff'
        }
      },
      isPrimary: {
        backgroundColor: '#0747A6',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#042960'
        }
      },
      isLean: {
        padding: 0,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
          color: '#0747A6'
        }
      },
      isDisabled: {
        opacity: 0.3
      },
      hasIcon: {
        '& > i': {
          marginRight: '10px',
          marginLeft: '-2px'
        }
      },
      callout: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: '#ffffff',
        fontSize: '10px',
        padding: '7px',
        position: 'fixed',
        textAlign: 'center',
        top: 0,
        left: 0,
        display: 'none',
        marginTop: '10px',
        marginLeft: '10px'
      },
      showCallout: {
        display: 'block'
      }
    },
    actions: {
      container: {
        padding: ['8px', '0']
      },
      action: {
        padding: '8px'
      }
    }
  };
}

export function Theme(props) {
  const { theme, children } = props;
  return (
    <JssProvider registry={sheets}>
      <ThemeProvider theme={getStyle(theme)}>{children}</ThemeProvider>
    </JssProvider>
  );
}

export function loadFabricTheme() {
  loadTheme({
    palette: {
      themePrimary: '#0747a6',
      themeLighterAlt: '#f2f6fb',
      themeLighter: '#ccdbf1',
      themeLight: '#a2bde4',
      themeTertiary: '#5584c9',
      themeSecondary: '#1b57b0',
      themeDarkAlt: '#063f95',
      themeDark: '#05357e',
      themeDarker: '#04275d',
      neutralLighterAlt: '#f8f8f8',
      neutralLighter: '#f4f4f4',
      neutralLight: '#eaeaea',
      neutralQuaternaryAlt: '#dadada',
      neutralQuaternary: '#d0d0d0',
      neutralTertiaryAlt: '#c8c8c8',
      neutralTertiary: '#595959',
      neutralSecondary: '#373737',
      neutralPrimaryAlt: '#2f2f2f',
      neutralPrimary: '#000000',
      neutralDark: '#151515',
      black: '#0b0b0b',
      white: '#ffffff'
    }
  });
}
