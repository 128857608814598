import { EngineError, ERROR_VALUE, ERROR_NUM } from "../../error";
import { parseNumber, anyIsError } from "../utils";
import { ROUND } from "../math-and-trigonometry";


/**
 * Returns the factorial of a number. The factorial of a number is equal to
 * 1*2*3*...* number.
 * 
 * @param number The nonnegative number for which you want the factorial. If
 * number is not an integer, it is truncated.
 */
var MEMOIZED_FACT = [];
export function FACT(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  var n = Math.floor(number);
  if (n === 0 || n === 1) {
    return 1;
  } else if (MEMOIZED_FACT[n] > 0) {
    return MEMOIZED_FACT[n];
  } else {
    MEMOIZED_FACT[n] = FACT(n - 1) * n;
    return MEMOIZED_FACT[n];
  }
}

/**
 * Returns the double factorial of a number.
 * 
 * @param number The value for which to return the double factorial. If number
 * is not an integer, it is truncated.
 */
export function FACTDOUBLE(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  var n = Math.floor(number);
  if (n <= 0) {
    return 1;
  } else {
    return n * FACTDOUBLE(n - 2);
  }
}

/**
 * Rounds number down, toward zero, to the nearest multiple of significance.
 * 
 * @param number The numeric value you want to round.
 * @param significance The multiple to which you want to round.
 */
export function FLOOR(number, significance) {
  number = parseNumber(number);
  significance = parseNumber(significance);
  if (anyIsError(number, significance)) {
    return new EngineError("FLOOR", ERROR_VALUE, arguments);
  }
  if (significance === 0) {
    return 0;
  }

  if (!(number > 0 && significance > 0) && !(number < 0 && significance < 0)) {
    return new EngineError("FLOOR", ERROR_NUM, arguments);
  }

  significance = Math.abs(significance);
  var precision = -Math.floor(Math.log(significance) / Math.log(10));
  if (number >= 0) {
    return ROUND(Math.floor(number / significance) * significance, precision);
  } else {
    return -ROUND(Math.ceil(Math.abs(number) / significance), precision);
  }
}

//TODO: Verify
/**
 * Round a number down to the nearest integer or to the nearest multiple of
 * significance
 * 
 * @param number The number to be rounded down.
 * @param significance Optional. The multiple to which you want to round.
 * @param mode Optional. The direction (toward or away from 0) to round negative
 * numbers.
 */
export function FLOOR_MATH(number, significance = 1, mode = 0) {
  number = parseNumber(number);
  // @ts-ignore
  significance = parseNumber(significance);
  // @ts-ignore
  mode = parseNumber(mode);
  if (anyIsError(number, significance, mode)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  if (significance === 0) {
    return 0;
  }
  significance = significance ? Math.abs(significance) : 1;
  var precision = -Math.floor(Math.log(significance) / Math.log(10));
  if (number >= 0) {
    return ROUND(Math.floor(number / significance) * significance, precision);
  } else if (mode === 0 || mode === undefined) {
    return -ROUND(
      Math.ceil(Math.abs(number) / significance) * significance,
      precision
    );
  }
  return -ROUND(
    Math.floor(Math.abs(number) / significance) * significance,
    precision
  );
}

/**
 * Returns a number that is rounded down to the nearest integer or to the
 * nearest multiple of significance. Regardlessof the sign of the number, the
 * number is rounded down. However, if the number or the significance is zero,
 * zero is returned.
 * 
 * @param number The number to be rounded down.
 * @param significance Optional. The multiple to which you want to round.
 * 
 * @deprecated (marked as deprecated by Jaimy)
 */
export const FLOOR_PRECISE = FLOOR_MATH;