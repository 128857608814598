var utils = require("../utils");

import { EngineError, ERROR_VALUE } from "../../error";

/**
 * Returns the Weibull distribution.
 * 
 * @param x The value at which to evaluate the function.
 * @param alpha A parameter to the distribution.
 * @param beta A parameter to the distribution.
 * @param cumulative Determines the form of the function.
 * 
 * FIXME missing implementation for WEIBULL() function
 */
export function WEIBULL_DIST(x, alpha, beta, cumulative) {
  x = utils.parseNumber(x);
  alpha = utils.parseNumber(alpha);
  beta = utils.parseNumber(beta);
  if (utils.anyIsError(x, alpha, beta)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  return cumulative
    ? 1 - Math.exp(-Math.pow(x / beta, alpha))
    : (Math.pow(x, alpha - 1) * Math.exp(-Math.pow(x / beta, alpha)) * alpha) /
        Math.pow(beta, alpha);
}
