import { get as getByPath } from 'lodash';

export function createFormData (object, fields = Object.keys(object)) {
  const form = new FormData();
  const data = fields.reduce(
    (current, field) => {
      current[field] = getByPath(object, field);
      return current;
    },
    {},
  );
  form.append('data', JSON.stringify(data));
  return form;
}

export function getURL (...args) {
  return '/' + args.join('/');
}
