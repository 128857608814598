import * as React from 'react';
import { __, i18n } from '@spreax/i18n';
import '../../../src/components/chip/style.scss';

function renderDelete(chip, onDelete, id) {
  return (
    <span onClick={() => onDelete(id)} className="sx-chips__chip__delete">
      ✖
    </span>
  );
}

export function Chip(props) {
  const { children, id, onDelete } = props;

  return (
    <span key={id} className="sx-chips__chip">
      {children}
      {onDelete && renderDelete(children, onDelete, id)}
    </span>
  );
}
