var utils = require("../utils");

import { EngineError, ERROR_VALUE } from "../../error";

import { AVERAGE, NORM_S_DIST , STDEV_S } from "../statistical";

/**
 * Returns the one-tailed P-value of a z-test.
 * 
 * @param range The array or range of data against which to test x.
 * @param x The value to test.
 * @param sd Optional. The population (known) standard deviation. If omitted,
 * the sample standard deviation is used.
 */
export function Z_TEST(range, x, sd) {
  range = utils.parseNumberArray(utils.flatten(range));
  x = utils.parseNumber(x);
  if (utils.anyIsError(range, x)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  sd = sd || STDEV_S(range);
  const n = range.length;
  return 1 - NORM_S_DIST((AVERAGE(range) - x) / (sd / Math.sqrt(n)), true);
}
