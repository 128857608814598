import * as React from 'react';
import '../../../src/pages/accounts/style.scss';
import { State } from '../../container';
import { __, i18n } from '@spreax/i18n';
import { App } from '../frame/app';
import { PageTop, Card, Chips, Chip } from '../../components';

function groups(account) {
  if (account.isAdmin) {
    return ['admin'];
  }
  return account.groups.map(group => group.title);
}

export class Accounts extends React.PureComponent<State> {
  async componentDidMount() {
    const { actions, middleware } = this.props;
    await middleware(await actions.account.makeSureHasAccounts(this.props));
  }

  async componentDidUpdate() {
    const { actions, middleware } = this.props;
    await middleware(await actions.account.makeSureHasAccounts(this.props));
  }

  render() {
    const { accounts, route } = this.props;

    function onAccountSelect(id) {
      route.history.push(`/accounts/${id}`);
    }

    return (
      <App {...this.props}>
        <div className="sx-page sx-settings">
          <PageTop primary={__(i18n.ACCOUNTS.PRIMARY_TITLE)} />
          <div className="sx-page__contents">
            {accounts &&
              accounts.map(account => {
                return (
                  <Card
                    id={account.id}
                    key={account.id}
                    title={account.name}
                    lines={[account.email, account.organization.title]}
                    onClick={card => onAccountSelect(card.id)}
                  >
                    <Chips maxChips={5}>
                      {groups(account).map(group => {
                        return (
                          <Chip key={group} id={group}>
                            {group}
                          </Chip>
                        );
                      })}
                    </Chips>
                  </Card>
                );
              })}
          </div>
        </div>
      </App>
    );
  }
}
