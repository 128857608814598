import { EngineError, ERROR_VALUE } from "../../error";

var utils = require("../utils");
var jStat = require("jStat").jStat;
var mathTrig = require("../math-and-trigonometry");

/**
 * Returns the beta distribution. The beta distribution is commonly used to
 * study variation in the percentage of something across samples, such as the
 * fraction of the day people spend watching television.
 *
 * @param x The value between A and B at which to evaluate the function
 * @param alpha A parameter of the distribution.
 * @param beta A parameter of the distribution.
 * @param cumulative A logical value that determines the form of the function.
 * If cumulative is TRUE, BETA.DIST returns the cumulative distribution
 * function; if FALSE, it returns the probability density function.
 * @param A Optional. A lower bound to the interval of x.
 * @param B Optional. An upper bound to the interval of x.
 */
export function BETA_DIST(x, alpha, beta, cumulative, A, B) {
  if (arguments.length < 4) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  A = A === undefined ? 0 : A;
  B = B === undefined ? 1 : B;

  x = utils.parseNumber(x);
  alpha = utils.parseNumber(alpha);
  beta = utils.parseNumber(beta);
  A = utils.parseNumber(A);
  B = utils.parseNumber(B);
  if (utils.anyIsError(x, alpha, beta, A, B)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  x = (x - A) / (B - A);
  return cumulative
    ? jStat.beta.cdf(x, alpha, beta)
    : jStat.beta.pdf(x, alpha, beta);
}

/**
 * Returns the inverse of the beta cumulative probability density function
 * (BETA.DIST).
 *
 * @param probability A probability associated with the beta distribution.
 * @param alpha A parameter of the distribution.
 * @param beta A parameter the distribution.
 * @param A Optional. A lower bound to the interval of x.
 * @param B Optional. An upper bound to the interval of x.
 */
export function BETA_INV(probability, alpha, beta, A, B) {
  A = A === undefined ? 0 : A;
  B = B === undefined ? 1 : B;

  probability = utils.parseNumber(probability);
  alpha = utils.parseNumber(alpha);
  beta = utils.parseNumber(beta);
  A = utils.parseNumber(A);
  B = utils.parseNumber(B);
  if (utils.anyIsError(probability, alpha, beta, A, B)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  return jStat.beta.inv(probability, alpha, beta) * (B - A) + A;
}

/**
 * Returns the individual term binomial distribution probability
 *
 * @param successes the number of successes in trials.
 * @param trials The number of independent trials.
 * @param probability The probability of success on each trial.
 * @param cumulative A logical value that determines the form of the function.
 * If cumulative is TRUE, then BINOM.DIST returns the cumulative distribution
 * function, which is the probability that there are at most number_s successes;
 * if FALSE, it returns the probability mass function, which is the probability
 * that there are number_s successes.
 */
export function BINOM_DIST(successes, trials, probability, cumulative) {
  successes = utils.parseNumber(successes);
  trials = utils.parseNumber(trials);
  probability = utils.parseNumber(probability);
  cumulative = utils.parseNumber(cumulative);
  if (utils.anyIsError(successes, trials, probability, cumulative)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  return cumulative
    ? jStat.binomial.cdf(successes, trials, probability)
    : jStat.binomial.pdf(successes, trials, probability);
}

/**
 * Returns the probability of a trial result using a binomial distribution.
 *
 * @param trials The number of independent trials. Must be greater than or equal
 * to 0.
 * @param probability The probability of success in each trial. Must be greater
 * than or equal to 0 and less than or equal to 1.
 * @param successes The number of successes in trials. Must be greater than or
 * equal to 0 and less than or equal to Trials.
 * @param successes2 Optional. If provided, returns the probability that the
 * number of successful trials will fall between Number_s and number_s2. Must be
 * greater than or equal to Number_s and less than or equal to Trials.
 */
export function BINOM_DIST_RANGE(trials, probability, successes, successes2) {
  successes2 = successes2 === undefined ? successes : successes2;

  trials = utils.parseNumber(trials);
  probability = utils.parseNumber(probability);
  successes = utils.parseNumber(successes);
  successes2 = utils.parseNumber(successes2);
  if (utils.anyIsError(trials, probability, successes, successes2)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  var result = 0;
  for (var i = successes; i <= successes2; i++) {
    result +=
      mathTrig.COMBIN(trials, i) *
      Math.pow(probability, i) *
      Math.pow(1 - probability, trials - i);
  }
  return result;
}

/**
 * Returns the smallest value for which the cumulative binomial distribution is
 * greater than or equal to a criterion value.
 *
 * @param trials The number of Bernoulli trials.
 * @param probability The probability of a success on each trial.
 * @param alpha The criterion value.
 */
export function BINOM_INV(trials, probability, alpha) {
  trials = utils.parseNumber(trials);
  probability = utils.parseNumber(probability);
  alpha = utils.parseNumber(alpha);
  if (utils.anyIsError(trials, probability, alpha)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  var x = 0;
  while (x <= trials) {
    if (jStat.binomial.cdf(x, trials, probability) >= alpha) {
      return x;
    }
    x++;
  }
}
