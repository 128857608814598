export const Routes = {
  ROOT: '/',
  LOGIN: '/login',
  SELECT_TENANT: '/select-tenant',
  PROJECT: '/project/:projectId',
  MODEL: '/template/:templateId',
  CONFIGURE_MODEL: '/template/:templateId/configure',
  PROFILE: '/profile',
  ACCOUNTS: '/accounts',
  EDIT_ACCOUNT: '/accounts/:accountId',
  FOLDER: '/folder',
  ERROR: '/error/:code',
};
