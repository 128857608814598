import { parseNumber } from "../utils";

/**
 * Returns number rounded up to the nearest odd integer.
 * 
 * @param number The value to round.
 */
export function ODD(number) {
  number = parseNumber(number);
  
  if (number instanceof Error) {
    return number;
  }

  var temp = Math.ceil(Math.abs(number));
  temp = temp & 1 ? temp : temp + 1;
  return number > 0 ? temp : -temp;
}
