import * as React from 'react';
import '../../../src/components/loader/style.scss';

export function Loader(props) {
  if (props.isLoading) {
    return (
      <div className="sx-loader">
        <img src="/static/img/ajax-loader.gif" width="20" />
        {props.loadingMessage && (
          <div className="sx-loader__message">{props.loadingMessage}</div>
        )}
      </div>
    );
  }
  return null;
}
