import { onFullfilled, onRejection } from './utils';
import { __, i18n } from '@spreax/i18n';
import { ActionTypes } from '../action-types';
import { User } from '@spreax/model';
import * as API from '@spreax/api-client';

export namespace user {
  export function get(): Promise<void> {
    return API.user.get().then(
      onFullfilled(null, null, data => {
        return {
          type: ActionTypes.LOGGED_IN,
          session: data
        };
      }),
      onRejection()
    );
  }

  export function login(username: string, password: string): Promise<void> {
    const data = {
      username,
      password
    };
    return API.user.auth(data).then(
      onFullfilled(null, get, data => {
        return {
          type: ActionTypes.LOGGED_IN,
          session: data
        };
      }),
      onRejection(i18n.FEEDBACK.INVALID_CREDENTIALS)
    );
  }

  export function save(profile: User): Promise<void> {
    const { id, name, password, email, language } = profile;
    // const data = {
    //   name,
    //   email,
    //   language,
    //   // password,
    // };
    // if (!password) {
    //   delete data.password;
    // }
    const data = {
      id,
      name,
      email,
      language
    };
    return API.user.update(data).then(
      onFullfilled(i18n.FEEDBACK.CHANGES_SAVED, !password && get, data => {
        // if (password) {
        //   return {
        //     type: ActionTypes.LOGOUT,
        //   };
        // }
        return null;
      }),
      onRejection()
    );
  }
}
