import * as ReactGA from 'react-ga';
import * as Raven from 'raven-js';

export namespace Tracker {

  const registeredTrackers = {
    ga: false,
    sentry: false,
  };

  /**
   * Setup Google Analytics
   *
   * @param {string} trackingId
   * @param {boolean} debug
   */

  export function setupGA (trackingId: string, debug: boolean = false) : void {
    ReactGA.initialize(trackingId, {
      debug,
      titleCase: false,
    });
    registeredTrackers.ga = true;
  }

  /**
   * Setup Sentry
   *
   * @param {string} trackingId
   */

  export function setupRaven (trackingId: string) : void {
    Raven.config(trackingId).install();
    registeredTrackers.sentry = true;
  }

  /**
   * Send action event to Google Analytics
   *
   * @param {ReactGA.EventArgs} event
   */

  export function action (event: ReactGA.EventArgs) : void {
    if (registeredTrackers.ga) {
      ReactGA.event(event);
    }
  }

  /**
   * Send exception to Sentry
   *
   * @param exception
   */

  export function exception (exception) : void {
    if (registeredTrackers.sentry) {
      Raven.captureException(exception);
    }
  }

}
