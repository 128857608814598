import * as React from 'react';
import '../../../src/pages/overview/style.scss';
import { State } from '../../container';
import { __, i18n } from '@spreax/i18n';
import * as moment from 'moment';
import { App } from '../frame/app';
import { Card, PageTop, Message } from '../../components';

export class Overview extends React.PureComponent<State> {
  render() {
    const { projects, route } = this.props;
    return (
      <App {...this.props} documentTitle={__(i18n.OVERVIEW.DOCUMENT_TITLE)}>
        <div className="sx-overview sx-page">
          <PageTop primary={__(i18n.LAYERS.PROJECTS)} />
          {projects.length === 0 && (
            <div className="sx-page__message">
              <Message>{__(i18n.PROJECTS.NO_PROJECTS)}</Message>
            </div>
          )}
          {projects.length > 0 && (
            <div className="sx-page__contents">
              {projects
                .sort(
                  (a, b) =>
                    +new Date(b.dateLastUpdated) - +new Date(a.dateLastUpdated)
                )
                .map((project, index) => {
                  return (
                    <Card
                      key={index}
                      title={project.title}
                      lines={[
                        project.template && project.template.title,
                        moment(project.dateLastUpdated).calendar()
                      ]}
                      onClick={() =>
                        route.history.push('/project/' + project.id)
                      }
                      isDisabled={!project.template}
                    />
                  );
                })}
            </div>
          )}
        </div>
      </App>
    );
  }
}
