import * as React from 'react';
import '../../../src/components/feedback/style.scss';

type Message = {
  level: string;
  message: string;
};

interface IFeedback {
  messages: Message[];
}

export function Feedback(props: IFeedback) {
  function feedbackIcon(feedbackItem) {
    switch (feedbackItem.level) {
      case 'error':
        return 'warning';
      case 'info':
      default:
        return 'check';
    }
  }

  if (props.messages.length === 0) {
    return <div className="sx-feedback sx-feedback--hidden"></div>;
  }

  return (
    <div className="sx-feedback">
      {props.messages.map((feedbackItem, index) => {
        return (
          <div
            key={index}
            className={`sx-feedback__item sx-feedback__item--${feedbackItem.level}`}
          >
            <i className="material-icons">{feedbackIcon(feedbackItem)}</i>{' '}
            {feedbackItem.message}
          </div>
        );
      })}
    </div>
  );
}
