import { EventEmitter } from 'events';
import evaluateByOperator from './evaluate-by-operator';
import { Parser as GrammarParser } from './grammar-parser/grammar-parser';
import { ERROR_NAME } from './error';
import { trimEdges, invertNumber, toNumber } from './utils';
import { CellValue, Cell, EngineError } from '.';
import { getAddressIndices, getCellReference, getAddress, CellReference } from '@spreax/xlsx-parser';

export type FormulaParseResponse = {
  error: FormulaParseError;
  result: FormulaParseResult;
};

export type FormulaParseResult = CellValue;

export type FormulaParseError = EngineError;

export class FormulaParser extends EventEmitter {

  parser: any;
  variables: any;
  context: Cell;

  constructor() {
    super();

    this.parser = new GrammarParser();
    this.parser.yy = {
      trimEdges,
      invertNumber,
      evaluateByOperator,
      toNumber,
      throwError: FormulaParser.throwError,
      callVariable: this.callVariable.bind(this),
      callFunction: evaluateByOperator.bind(this),
      cellValue: this.callCellValue.bind(this),
      rangeValue: this.callRangeValue.bind(this),
    };

    this.variables = {};
    this.context = null;

    this
      .setVariable('TRUE', true)
      .setVariable('FALSE', false)
      .setVariable('NULL', null);
  }

  /**
   * Parse formula expression.
   *
   * @param {Cell} cell
   * @return {FormulaParseResponse}
   */

  parse(cell: Cell): FormulaParseResponse {
    this.context = cell;
    let result = null;
    let error = null;

    try {
      if (this.context.formula.expression === '') {
        result = '';
      } else {
        result = this.parser.parse(this.context.formula.expression);
      }
    } catch (e) {
      if (e instanceof EngineError) {
        error = e;
      }
      // const message = errorParser(e.message);
      // if (message) {
      //   error = message;
      // } else {
      //   error = errorParser(ERROR);
      // }
    }

    if (result instanceof EngineError) {
      error = result;
      result = null;
      // error = errorParser(result.message) || errorParser(ERROR);
      // result = null;
    }

    return {
      error,
      result,
    };
  }

  /**
   * Set predefined variable name which can be visible while parsing formula expression.
   *
   * @param name Variable name.
   * @param value Variable value.
   * @returns {Parser}
   */

  setVariable(name: string, value: any): this {
    this.variables[name] = value;
    return this;
  }

  /**
   * Get variable name.
   *
   * @param name Variable name.
   * @returns {CellValue}
   */

  getVariable(name: string): CellValue {
    return this.variables[name];
  }

  /**
   * Retrieve variable value by its name.
   *
   * @param name Variable name.
   * @returns {CellValue}
   * @private
   */

  private callVariable(name: string): CellValue {
    let value = this.getVariable(name);

    this.emit('callVariable', name, (newValue) => {
      if (newValue !== void 0) {
        value = newValue;
      }
    });

    if (value === void 0) {
      throw Error(ERROR_NAME);
    }

    return value;
  }

  /**
   * Get `CellReference` while accounting for `sharedFormulas`
   *
   * @param {string} sheet
   * @param label
   */

  getRelativeCellReference(sheet: string, label: string): CellReference {
    const { row, column, isColumnAbsolute, isRowAbsolute } = getAddressIndices(label);
    if (this.context.formula.isShared) {
      return getCellReference(
        sheet,
        row + (!isRowAbsolute ? this.context.formula.rowOffset : 0),
        column + (!isColumnAbsolute ? this.context.formula.columnOffset : 0),
      );
    }
    return getCellReference(sheet, row, column);
  }

  /**
   * Retrieve value by its label (`B3`, `B$3`, `B$3`, `$B$3`).
   *
   * @param label
   * @param sheet
   * @returns {CellValue}
   * @private
   */

  private callCellValue(label: string, sheet: string): CellValue {
    let value = null;
    this.emit('callCellValue', this.getRelativeCellReference(sheet, label), (_value) => {
      value = _value;
    });
    return value;
  }

  /**
   * Retrieve value by its label (`B3:A1`, `B$3:A1`, `B$3:$A1`, `$B$3:A$1`).
   *
   * @param startLabel Coordinates of the first cell
   * @param endLabel Coordinates of the last cell
   * @param sheet
   * @returns {CellValue[]} Returns an array of mixed values.
   * @private
   */

  private callRangeValue(startLabel: string, endLabel: string, sheet: string): CellValue[] {

    const start = getAddressIndices(startLabel);
    const end = getAddressIndices(endLabel);

    const startRow = start.row <= end.row ? start.row : end.row;
    const endRow = start.row <= end.row ? end.row : start.row;
    const startColumn = start.column <= end.column ? start.column : end.column;
    const endColumn = start.column <= end.column ? end.column : start.column;

    const fragment = [];
    for (let row = startRow; row <= endRow; row++) {
      const colFragment = [];
      for (let column = startColumn; column <= endColumn; column++) {
        this.emit('callCellValue', this.getRelativeCellReference(sheet, getAddress({
          ...start,
          row,
          column,
        })), (value) => {
          colFragment.push(value);
        });
      }
      fragment.push(colFragment);
    }

    if (!fragment.length) {
      throw new Error('No `fragments` for `CallRangeValue`');
    }

    return fragment;
  }

  /**
   * Try to throw error by its name.
   *
   * @param errorName Error name.
   * @returns {void}
   * @private
   */

  static throwError(errorName: string): void {
    // if (isErrorValid(errorName)) {
    //   throw Error(errorName);
    // }
    throw Error(errorName);
  }

}
