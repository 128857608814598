import * as React from 'react';
import { hot } from 'react-hot-loader/root';

import { Store as UserStore, UserState } from './stores/user-store';
import { Store as TemplateStore, TemplateState } from './stores/template-store';
import { Store as ProjectStore, ProjectState } from './stores/project-store';
import { Store as FeedbackStore, FeedbackState } from './stores/feedback-store';
import { Store as SettingsStore, SettingsState } from './stores/account-store';
import { Store as AppStore, AppState } from './stores/app-store';
import { Store as ClientStore, ClientState } from './stores/client-store';
import {
  Store as AnalyticsStore,
  AnalyticsState
} from './stores/analytics-store';
import { Store as FolderStore, FolderState } from './stores/folder-store';
import {
  Store as AttachmentStore,
  AttachmentState
} from './stores/attachment-store';
import { Store as TenantStore, TenantState } from './stores/tenant-store';

import { Container } from 'flux/utils';
import { AppView } from './app-view';
import * as Actions from './actions';
import { createMiddleware } from './dispatcher';
import { RouteProps } from 'react-router';

const middleware = createMiddleware(getState);

type TActions = {
  actions: {
    template: Actions.template.ITemplate;
    project: Actions.project.IProject;
    user: Actions.user.IUser;
    tenant: Actions.tenant.ITenant;
    feedback: Actions.feedback.IFeedback;
    app: Actions.app.IApp;
    client: Actions.client.IClient;
    group: Actions.group.IGroup;
    attachment: Actions.attachment.IAttachment;
    account: SettingsState;
  };
};

type TMiddleware = any;

type TReact = {
  route: RouteProps;
};

export type State = TemplateState &
  ProjectState &
  FolderState &
  UserState &
  FeedbackState &
  TenantState &
  AppState &
  ClientState &
  SettingsState &
  TActions &
  TReact &
  TMiddleware;

export function getStores(): any {
  return [
    UserStore,
    TemplateStore,
    ProjectStore,
    FeedbackStore,
    AppStore,
    ClientStore,
    SettingsStore,
    AnalyticsStore,
    FolderStore,
    AttachmentStore,
    TenantStore
  ];
}

export function getState(): State {
  const { profile, profileDelta, loggedIn, token } = UserStore.getState();
  const { accounts, selectedAccount } = SettingsStore.getState();
  const {
    templates,
    selectedTemplate,
    hasReceivedTemplates,
    isFetchingTemplates
  } = TemplateStore.getState();
  const {
    projects,
    selectedProject,
    projectDraft,
    hasUnsavedChanges,
    hasReceivedProjects,
    isFetchingProjects
  } = ProjectStore.getState();
  const { messages } = FeedbackStore.getState();
  const {
    sidePanelOpen,
    isLoading,
    loadingMessage,
    language,
    redirectPath,
    showContextDrawer
  } = AppStore.getState();
  const { client, clientState, clientError } = ClientStore.getState();
  const {
    folders,
    selectedFolder,
    isFetchingFolders,
    hasReceivedFolders
  } = FolderStore.getState();
  const { tenants, selectedTenant, theme } = TenantStore.getState();

  return {
    profile,
    profileDelta,
    tenants,
    selectedTenant,
    theme,
    templates,
    selectedTemplate,
    hasReceivedTemplates,
    isFetchingTemplates,
    projects,
    selectedProject,
    projectDraft,
    hasReceivedProjects,
    isFetchingProjects,
    messages,
    loggedIn,
    token,
    sidePanelOpen,
    showContextDrawer,
    hasUnsavedChanges,
    isLoading,
    loadingMessage,
    language,
    redirectPath,
    client,
    clientState,
    clientError,
    accounts,
    selectedAccount,
    folders,
    selectedFolder,
    isFetchingFolders,
    hasReceivedFolders,
    middleware,
    actions: Actions
  };
}

export default hot(
  Container.createFunctional<State, State>(AppView, getStores, getState)
);
