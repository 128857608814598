// tslint:disable object-shorthand-properties-first

import Dispatcher from '../dispatcher';
import { ActionTypes } from '../action-types';
import { setLanguage } from '@spreax/i18n';

export namespace user {
  export interface IUser {
    login(username: string, password: string): void;
    logout(): void;
    update(key: string, value: any): void;
    save(): void;
    makeSureUserIsLoggedIn(props);
    makeSureHasUserData(props);
  }

  export function login(username: string, password: string): void {
    Dispatcher.dispatch({
      type: ActionTypes.LOGIN,
      username,
      password
    });
  }

  export function logout(): void {
    Dispatcher.dispatch({
      type: ActionTypes.LOGOUT
    });
  }

  export function update(key, value): void {
    Dispatcher.dispatch({
      type: ActionTypes.UPDATE_PROFILE,
      key,
      value
    });
  }

  export function save(): void {
    Dispatcher.dispatch({
      type: ActionTypes.SAVE_PROFILE
    });
  }

  export function makeSureUserIsLoggedIn(props) {
    return async (getState, dispatch) => {
      const { route } = props;
      const { loggedIn, token } = getState();
      if (loggedIn === null && token) {
        localStorage.setItem('redirect_url', route.match.url);
        dispatch(() => user.login(null, null));
      } else if (!loggedIn && route.match.path !== '/login') {
        route.history.push('/login');
      } else if (loggedIn === true && route.match.path === '/login') {
        route.history.push(localStorage.getItem('redirect_url') || '/');
        localStorage.removeItem('redirect_url');
      }
    };
  }
}
