import { EngineError, ERROR_VALUE, ERROR_NUM } from '../error';

export function flattenShallow(array) {
  if (!array || !array.reduce) {
    return array;
  }
  return array.reduce((a, b) => {
    const aIsArray = Array.isArray(a);
    const bIsArray = Array.isArray(b);
    if (aIsArray && bIsArray) {
      return a.concat(b);
    }
    if (aIsArray) {
      a.push(b);
      return a;
    }
    if (bIsArray) {
      return [a].concat(b);
    }
    return [a, b];
  });
}

export function isFlat(array) {
  if (!array) {
    return false;
  }
  for (let i = 0; i < array.length; ++i) {
    if (Array.isArray(array[i])) {
      return false;
    }
  }
  return true;
}

export function flatten(...args) {
  let result = argsToArray.apply(null, args);
  while (!isFlat(result)) {
    result = flattenShallow(result);
  }
  return result;
}

export function argsToArray(arg) {
  const result = [];
  arrayEach(arg || [], value => {
    result.push(value);
  });
  return result;
}

export function numbers(...args) {
  const possibleNumbers = this.flatten.apply(null, args);
  return possibleNumbers.filter(el => {
    return typeof el === 'number';
  });
}

export function cleanFloat(number) {
  const power = 1e14;
  return Math.round(number * power) / power;
}

export function parseBool(bool) {
  if (typeof bool === 'boolean') {
    return bool;
  }
  if (bool instanceof EngineError) {
    return bool;
  }
  if (typeof bool === 'number') {
    return bool !== 0;
  }
  if (typeof bool === 'string') {
    const up = bool.toUpperCase();
    if (up === 'TRUE') {
      return true;
    }
    if (up === 'FALSE') {
      return false;
    }
  }
  // @ts-ignore
  if (bool instanceof Date && !isNaN(bool)) {
    return true;
  }
  return new EngineError('parseBool', ERROR_VALUE, arguments);
}

export function parseNumber(string) {
  if (string === undefined || string === '') {
    return new EngineError('parseNumber', ERROR_VALUE, arguments);
  }
  if (!isNaN(string)) {
    return parseFloat(string);
  }
  return new EngineError('parseNumber', ERROR_VALUE, arguments);
}

export function parseNumberArray(arr) {
  let len;
  if (!arr || (len = arr.length) === 0) {
    return new EngineError('parseNumberArray', ERROR_VALUE, arguments);
  }
  let parsed;
  while (len--) {
    parsed = parseNumber(arr[len]);
    if (parsed instanceof EngineError && parsed.name === ERROR_VALUE) {
      return parsed;
    }
    arr[len] = parsed;
  }
  return arr;
}

export function parseMatrix(matrix) {
  let n;
  if (!matrix || (n = matrix.length) === 0) {
    return new EngineError('parseMatrix', ERROR_VALUE, arguments);
  }
  for (let i = 0; i < matrix.length; i++) {
    const pnarr = parseNumberArray(matrix[i]);
    matrix[i] = pnarr;
    if (pnarr instanceof EngineError) {
      return pnarr;
    }
  }
  return matrix;
}

var d1900 = new Date(1900, 0, 1);

export function parseDate(date) {
  if (!isNaN(date)) {
    if (date instanceof Date) {
      return new Date(date);
    }
    let d = parseInt(date, 10);
    if (d < 0) {
      return new EngineError('parseDate', ERROR_NUM, arguments);
    }
    if (d <= 60) {
      return new Date(d1900.getTime() + (d - 1) * 86400000);
    }
    return new Date(d1900.getTime() + (d - 2) * 86400000);
  }
  if (typeof date === 'string') {
    date = new Date(date);
    if (!isNaN(date)) {
      return date;
    }
  }
  return new EngineError('parseDate', ERROR_VALUE, arguments);
}

export function parseDateArray(arr) {
  let len = arr.length;
  let parsed;
  while (len--) {
    parsed = this.parseDate(arr[len]);
    if (parsed instanceof EngineError && parsed.name === ERROR_VALUE) {
      return parsed;
    }
    arr[len] = parsed;
  }
  return arr;
}

export function anyIsError(...args) {
  let n = args.length;
  while (n--) {
    if (args[n] instanceof EngineError) {
      return true;
    }
  }
  return false;
}

export function arrayValuesToNumbers(arr) {
  let n = arr.length;
  let el;

  while (n--) {
    el = arr[n];
    if (typeof el === 'number') {
      continue;
    }
    if (el === true) {
      arr[n] = 1;
      continue;
    }
    if (el === false) {
      arr[n] = 0;
      continue;
    }
    if (typeof el === 'string') {
      const number = parseNumber(el);
      if (number instanceof EngineError) {
        arr[n] = 0;
      } else {
        arr[n] = number;
      }
    }
  }

  return arr;
}

export function rest(array, idx = 1) {
  if (!array || typeof array.slice !== 'function') {
    return array;
  }
  return array.slice(idx);
}

export function initial(array, idx) {
  idx = idx || 1;
  if (!array || typeof array.slice !== 'function') {
    return array;
  }
  return array.slice(0, array.length - idx);
}

export function arrayEach(array, iteratee) {
  const length = array.length;
  let index = -1;

  while (++index < length) {
    if (iteratee(array[index], index, array) === false) {
      break;
    }
  }

  return array;
}

export function transpose(matrix) {
  if (!matrix) {
    return new EngineError('transpose', ERROR_VALUE, arguments);
  }
  return matrix[0].map((col, i) => {
    return matrix.map(row => {
      return row[i];
    });
  });
}
