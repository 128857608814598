import * as React from 'react';

export function ErrorView (props) {
  const { message } = props;
  return (
    <div
      className="sx-widget"
    >
      <div
        className="sx-widget__error"
      >
        <i className="material-icons sx-widget__error__icon">error_outline</i> {message || 'Something went wrong'}
      </div>
    </div>
  );
}
