import { EngineError, ERROR_VALUE, ERROR_NOT_AVAILABLE, ERROR } from '..';
import { parseNumber, flatten, anyIsError } from '.';
import { Value, ErrorValue, LogicalValue, NumberValue, TextValue } from '.';
import numbro from 'numbro';

// export function ASC () {
//   throw new Error('ASC is not implemented');
// }

// export function BAHTTEXT () {
//   throw new Error('BAHTTEXT is not implemented');
// }

/**
 * Returns the character specified by a number. Use CHAR to translate code page numbers you might get from files on
 * other types of computers into characters.
 *
 * @param {Value} number
 * @returns {Value | ErrorValue}
 */

export function CHAR(number: Value): TextValue | ErrorValue {
  number = parseNumber(number);
  if (number instanceof EngineError) {
    return number;
  }
  return String.fromCharCode(number);
}

/**
 * Removes all nonprintable characters from text. Use CLEAN on text imported from other applications that contains
 * characters that may not print with your operating system. For example, you can use CLEAN to remove some low-level
 * computer code that is frequently at the beginning and end of data files and cannot be printed.
 *
 * @param {Value} text
 * @returns {TextValue}
 */

export function CLEAN(text: Value = ''): TextValue {
  const re = /[\0-\x1F]/g;
  return text.toString().replace(re, '');
}

/**
 * Returns a numeric code for the first character in a text string. The returned code corresponds to the character set
 * used by your computer.
 *
 * @param text {Value}
 * @returns {NumberValue | ErrorValue}
 */

export function CODE(text: Value = ''): NumberValue | ErrorValue {
  const result = text.toString().charCodeAt(0);
  if (isNaN(result)) {
    return new EngineError('CODE', ERROR_NOT_AVAILABLE, arguments);
  }
  return result;
}

/**
 * Use CONCATENATE, one of the text functions, to join two or more text strings into one string.
 *
 * @param {Value[]} _args
 * @returns {TextValue}
 */

export function CONCATENATE(..._args: Value[]): TextValue {
  const args = flatten(_args);
  let trueFound = 0;
  while ((trueFound = args.indexOf(true)) > -1) {
    args[trueFound] = 'TRUE';
  }
  let falseFound = 0;
  while ((falseFound = args.indexOf(false)) > -1) {
    args[falseFound] = 'FALSE';
  }
  return args.join('');
}

// export function DBCS () {
//   throw new Error('DBCS is not implemented');
// }

/**
 * The DOLLAR function, one of the TEXT functions, converts a number to text using currency format, with the decimals
 * rounded to the number of places you specify. DOLLAR uses the $#,##0.00_);($#,##0.00) number format, although the
 * currency symbol that is applied depends on your local language settings.
 *
 * @param {Value} number
 * @param {NumberValue} decimals
 * @returns {TextValue}
 */

export function DOLLAR(number: Value, decimals: NumberValue = 2): TextValue {
  number = parseNumber(number);
  // @ts-ignore
  decimals = parseNumber(decimals);
  if (anyIsError(number, decimals)) {
    // @ts-ignore
    return new EngineError('DOLLAR', ERROR_VALUE, arguments);
  }
  let format = '';
  if (decimals <= 0) {
    number =
      // @ts-ignore
      Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
    format = '($0,0)';
  } else if (decimals > 0) {
    format = `($0,0.${new Array(decimals + 1).join('0')})`;
  }
  return numbro(number).format(format);
}

/**
 * Compares two text strings and returns TRUE if they are exactly the same, FALSE otherwise. EXACT is case-sensitive but
 * ignores formatting differences. Use EXACT to test text being entered into a document.
 *
 * @param {Value} text1
 * @param {Value} text2
 * @returns {LogicalValue | ErrorValue}
 */

export function EXACT(text1: Value, text2: Value): LogicalValue | ErrorValue {
  if (arguments.length !== 2) {
    return new EngineError('EXACT', ERROR_NOT_AVAILABLE, arguments);
  }
  return text1 === text2;
}

/**
 * FIND and FINDB locate one text string within a second text string, and return the number of the starting position of
 * the first text string from the first character of the second text string.
 *
 * @param {TextValue} findText
 * @param {TextValue} withinText
 * @param {NumberValue} position
 * @returns {NumberValue | ErrorValue}
 */

export function FIND(
  findText: TextValue,
  withinText: TextValue,
  position: NumberValue = 0
): NumberValue | ErrorValue {
  if (arguments.length < 2) {
    return new EngineError('FIND', ERROR_NOT_AVAILABLE, arguments);
  }
  return withinText ? withinText.indexOf(findText, position - 1) + 1 : null;
}

/**
 * Rounds a number to the specified number of decimals, formats the number in decimal format using a period and commas,
 * and returns the result as text.
 *
 * @param {NumberValue} number
 * @param {NumberValue} decimals
 * @param {LogicalValue} noCommas
 * @returns {TextValue | ErrorValue}
 */

export function FIXED(
  number: NumberValue,
  decimals: NumberValue = 2,
  noCommas: LogicalValue = false
): TextValue | ErrorValue {
  // @ts-ignore
  number = parseNumber(number);
  // @ts-ignore
  decimals = parseNumber(decimals);
  if (anyIsError(number, decimals)) {
    return new EngineError('FIXED', ERROR_VALUE, arguments);
  }
  let format = noCommas ? '0' : '0,0';
  if (decimals <= 0) {
    number =
      Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
  } else if (decimals > 0) {
    format += '.' + new Array(decimals + 1).join('0');
  }
  return numbro(number).format(format);
}

// export function HTML2TEXT (value) {
//   var result = '';
//   if (value) {
//     if (value instanceof Array) {
//       value.forEach((line) => {
//         if (result !== '') {
//           result += '\n';
//         }
//         result += (line.replace(/<(?:.|\n)*?>/gm, ''));
//       });
//     } else {
//       result = value.replace(/<(?:.|\n)*?>/gm, '');
//     }
//   }
//   return result;
// }

/**
 * Returns the first character or characters in a text string, based on the number of characters you specify.
 *
 * @param {TextValue} text
 * @param {NumberValue} number
 * @returns {TextValue | ErrorValue}
 */

export function LEFT(
  text: TextValue,
  number: NumberValue = 1
): TextValue | ErrorValue {
  // @ts-ignore
  number = parseNumber(number);
  // @ts-ignore
  if (number instanceof EngineError || typeof text !== 'string') {
    return new EngineError('LEFT', ERROR_VALUE, arguments);
  }
  return text ? text.substring(0, number) : null;
}

/**
 * Returns the number of characters in a text string.
 *
 * @param {TextValue} text
 * @returns {NumberValue | TextValue}
 */

export function LEN(text: TextValue): NumberValue | ErrorValue {
  if (arguments.length === 0) {
    return new EngineError('LEN', ERROR, arguments);
  }
  if (typeof text === 'string') {
    return text ? text.length : 0;
  }
  // @ts-ignore
  if (text.length) {
    // @ts-ignore
    return text.length;
  }
  return new EngineError('LEN', ERROR_VALUE, arguments);
}

/**
 * Converts all uppercase letters in a text string to lowercase.
 *
 * @param {TextValue} text
 * @returns {TextValue | ErrorValue}
 */

export function LOWER(text: TextValue): TextValue | ErrorValue {
  if (typeof text !== 'string') {
    return new EngineError('LOWER', ERROR_VALUE, arguments);
  }
  return text ? text.toLowerCase() : text;
}

/**
 * MID returns a specific number of characters from a text string, starting at the position you specify, based on the
 * number of characters you specify.
 *
 * @param {TextValue} text
 * @param {NumberValue} start
 * @param {NumberValue} number
 * @returns {TextValue | ErrorValue}
 */

export function MID(
  text: TextValue,
  start: NumberValue,
  number: NumberValue
): TextValue | ErrorValue {
  // @ts-ignore
  start = parseNumber(start);
  // @ts-ignore
  number = parseNumber(number);
  if (anyIsError(start, number) || typeof text !== 'string') {
    // @ts-ignore
    return number;
  }
  const begin = start - 1;
  const end = begin + number;
  return text.substring(begin, end);
}

// TODO
// export function NUMBERVALUE (text, decimal_separator, group_separator)  {
//   decimal_separator = (typeof decimal_separator === 'undefined') ? '.' : decimal_separator;
//   group_separator = (typeof group_separator === 'undefined') ? ',' : group_separator;
//   return Number(text.replace(decimal_separator, '.').replace(group_separator, ''));
// }

// TODO
// export function PRONETIC () {
//   throw new Error('PRONETIC is not implemented');
// }

/**
 * Capitalizes the first letter in a text string and any other letters in text that follow any character other than a
 * letter. Converts all other letters to lowercase letters.
 *
 * @param {TextValue} text
 * @returns {TextValue | ErrorValue}
 */

export function PROPER(text: TextValue): TextValue | ErrorValue {
  if (text === undefined || text.length === 0) {
    return new EngineError('PROPER', ERROR_VALUE, arguments);
  }
  // @ts-ignore
  if (text === true) {
    text = 'TRUE';
  }
  // @ts-ignore
  if (text === false) {
    text = 'FALSE';
  }
  // @ts-ignore
  if (isNaN(text) && typeof text === 'number') {
    return new EngineError('PROPER', ERROR_VALUE, arguments);
  }
  if (typeof text === 'number') {
    // @ts-ignore
    text = text.toString();
  }
  return text.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}

// export function REGEXEXTRACT (text, regex) {
//   if (arguments.length < 2) {
//     return new EngineError('REGEXEXTRACT', ERROR_NOT_AVAILABLE, arguments);
//   }
//   const match = text.match(new RegExp(regex));
//   return match ? (match[match.length > 1 ? match.length - 1 : 0]) : null;
// }
//
// export function REGEXMATCH (text, regex, full) {
//   if (arguments.length < 2) {
//     return new EngineError('REGEXMATCH', ERROR_NOT_AVAILABLE, arguments);
//   }
//   const match = text.match(new RegExp(regex));
//   return full ? match : !!match;
// }
//
// export function REGEXREPLACE (text, regex, replacement) {
//   if (arguments.length < 3) {
//     return new EngineError('REGEXREPLACE', ERROR_NOT_AVAILABLE, arguments);
//   }
//   return text.replace(new RegExp(regex), replacement);
// }

/**
 * Replaces part of a text string, based on the number of characters you specify, with a different text string.
 *
 * @param {TextValue} text
 * @param {NumberValue} position
 * @param {NumberValue} length
 * @param newText
 * @constructor
 */

export function REPLACE(
  text: TextValue,
  position: NumberValue,
  length: NumberValue,
  newText: TextValue
): TextValue | ErrorValue {
  // @ts-ignore
  position = parseNumber(position);
  // @ts-ignore
  length = parseNumber(length);
  if (
    anyIsError(position, length) ||
    typeof text !== 'string' ||
    typeof newText !== 'string'
  ) {
    return new EngineError('REPLACE', ERROR_VALUE, arguments);
  }
  return (
    text.substr(0, position - 1) + newText + text.substr(position - 1 + length)
  );
}

/**
 * Repeats text a given number of times. Use REPT to fill a cell with a number of instances of a text string.
 *
 * @param {TextValue} text
 * @param {NumberValue} number
 * @returns {TextValue | ErrorValue}
 */

export function REPT(
  text: TextValue,
  number: NumberValue
): TextValue | ErrorValue {
  // @ts-ignore
  number = parseNumber(number);
  // @ts-ignore
  if (number instanceof EngineError) {
    return number;
  }
  return new Array(number + 1).join(text);
}

/**
 * Returns the last character or characters in a text string, based on the number of characters you specify.
 *
 * @param {TextValue} text
 * @param {NumberValue} number
 * @returns {TextValue | ErrorValue}
 */

export function RIGHT(
  text: TextValue,
  number: NumberValue = 1
): TextValue | ErrorValue {
  // @ts-ignore
  number = parseNumber(number);
  // @ts-ignore
  if (number instanceof EngineError) {
    return number;
  }
  return text
    ? text.substring(text.length - number)
    : new EngineError('RIGHT', ERROR_NOT_AVAILABLE, arguments);
}

/**
 * Locate one text string within a second text string, and return the number of the starting position of the first
 * text string from the first character of the second text string.
 *
 * @param {TextValue} findText
 * @param {TextValue} withinText
 * @param {NumberValue} position
 * @returns {NumberValue | ErrorValue}
 */

export function SEARCH(
  findText: TextValue,
  withinText: TextValue,
  position: NumberValue = 0
): NumberValue | ErrorValue {
  var foundAt;
  if (typeof findText !== 'string' || typeof withinText !== 'string') {
    return new EngineError('SEARCH', ERROR_VALUE, arguments);
  }
  foundAt =
    withinText.toLowerCase().indexOf(findText.toLowerCase(), position - 1) + 1;
  return foundAt === 0
    ? new EngineError('SEARCH', ERROR_VALUE, arguments)
    : foundAt;
}

// export function SPLIT (text, separator) {
//   return text.split(separator);
// }

/**
 * Substitutes `newText` for `oldText` in a text string. Use SUBSTITUTE when you want to replace specific text in a
 * text string; use REPLACE when you want to replace any text that occurs in a specific location in a text string.
 *
 * @param {TextValue} text
 * @param {TextValue} oldText
 * @param {TextValue} newText
 * @param {NumberValue} occurrence
 * @returns {TextValue | ErrorValue}
 */

export function SUBSTITUTE(
  text: TextValue,
  oldText: TextValue,
  newText: TextValue,
  occurrence: NumberValue = null
): TextValue | ErrorValue {
  if (arguments.length < 2) {
    return new EngineError('SUBSTITUTE', ERROR_NOT_AVAILABLE, arguments);
  }
  if (!text || !oldText || !newText) {
    return text;
  }
  if (occurrence === null) {
    return text.replace(new RegExp(oldText, 'g'), newText);
  }
  let index = 0;
  let i = 0;
  while (text.indexOf(oldText, index) > 0) {
    index = text.indexOf(oldText, index + 1);
    i++;
    if (i === occurrence) {
      return (
        text.substring(0, index) +
        newText +
        text.substring(index + oldText.length)
      );
    }
  }
}

/**
 * Returns the text referred to by value.
 *
 * @param {Value} value
 * @returns {TextValue}
 */

export function T(value: Value): TextValue {
  return typeof value === 'string' ? value : '';
}

// TODO incomplete implementation
export function TEXT(value: Value, format: Value): TextValue | ErrorValue {
  value = parseNumber(value);
  if (anyIsError(value)) {
    return new EngineError('TEXT', ERROR_NOT_AVAILABLE, arguments);
  }
  return numbro(value).format(format);
}

/**
 * Removes all spaces from text except for single spaces between words. Use TRIM on text that you have received from
 * another application that may have irregular spacing.
 *
 * @param {TextValue} text
 * @returns {TextValue | ErrorValue}
 */

export function TRIM(text: TextValue): TextValue | ErrorValue {
  if (typeof text !== 'string') {
    return new EngineError('TRIM', ERROR_VALUE, arguments);
  }
  return text.replace(/ +/g, ' ').trim();
}

export const UNICHAR = CHAR;

export const UNICODE = CODE;

/**
 * Converts text to uppercase.
 *
 * @param {TextValue} text
 * @returns {TextValue | ErrorValue}
 */

export function UPPER(text: TextValue): TextValue | ErrorValue {
  if (typeof text !== 'string') {
    return new EngineError('UPPER', ERROR_VALUE, arguments);
  }
  return text.toUpperCase();
}

/**
 * Converts a text string that represents a number to a number.
 *
 * @param {TextValue} text
 * @returns {NumberValue | ErrorValue}
 */

export function VALUE(text: TextValue): NumberValue | ErrorValue {
  if (typeof text !== 'string') {
    return new EngineError('VALUE', ERROR_VALUE, arguments);
  }
  // @ts-ignore
  const result = numbro().unformat(text);
  return result === void 0 ? 0 : result;
}
