// tslint:disable object-shorthand-properties-first

import Dispatcher from '../dispatcher';
import { ActionTypes } from '../action-types';
import { State } from '../container';
import { __, i18n } from '@spreax/i18n';
import { TenantGUID } from '@spreax/model';

export namespace account {
  export interface IAccount {
    loadAccounts(): void;
    selectAccount(id: string): void;
    makeSureHasAccounts(props: State);
  }

  export function loadAccounts(tenantId: TenantGUID): void {
    Dispatcher.dispatch({
      type: ActionTypes.REQUEST_ACCOUNTS,
      tenantId
    });
  }

  export function selectAccount(id: string): void {
    Dispatcher.dispatch({
      type: ActionTypes.REQUEST_SELECT_ACCOUNT,
      accountId: id
    });
  }

  export function makeSureHasAccounts(props: State) {
    return async (getState, dispatch) => {
      const { route } = props;
      const { actions, accounts, selectedAccount, selectedTenant } = getState();
      const accountId = route.match.params.accountId;
      if (selectedTenant && !accounts) {
        await dispatch(() => actions.account.loadAccounts(selectedTenant.id));
      }
      if (accounts && !selectedAccount) {
        await dispatch(() => actions.account.selectAccount(accountId));
      }
    };
  }
}
