import { Cell as CellLiteral } from '.';
import { Cell } from './cell';
import { log } from '@spreax/lib';

type Index = {
  [key: string]: Cell[][];
};

type Map = {
  [key: string]: {
    [key: string]: Cell;
  };
};

export class CellMap {

  private _cells: Cell[];
  private index: Index;
  private map: Map;

  constructor (cells: CellLiteral[]) {
    this.index = {};
    this.map = {};
    this._cells = cells
      .map(CellMap.bootstrapCell)
      .map(this.createCellIndices.bind(this));

    log.debug('New `CellMap` Created:', this._cells, this.index, this.map);
  }

  get cells () {
    return this._cells;
  }

  /**
   * Create `Cell` index and map to access them faster
   *
   * @param {Cell} cell
   * @returns {Cell}
   */

  private createCellIndices (cell: Cell) : Cell {
    if (!this.index[cell.sheetRef]) {
      this.index[cell.sheetRef] = [];
    }
    if (!this.index[cell.sheetRef][cell.row]) {
      this.index[cell.sheetRef][cell.row] = [];
    }
    this.index[cell.sheetRef][cell.row][cell.column] = cell;
    if (!this.map[cell.sheetRef]) {
      this.map[cell.sheetRef] = {};
    }
    this.map[cell.sheetRef][cell.ref] = cell;
    return cell;
  }

  private static bootstrapCell (cell: CellLiteral) : Cell {
    return new Cell(cell);
  }

  /**
   * Get cell by reference from map
   *
   * @param sheet
   * @param ref   for example A1, BC7
   * @returns {Cell}
   */

  public getCellByRef (sheet: string, ref: string) : Cell {
    return this.map[sheet][ref];
  }

  /**
   * Get cell by index from index
   *
   * @param sheet
   * @param row
   * @param column
   * @returns {Cell}
   */

  public getCellByIndex (sheet: string, row: number, column: number) : Cell {
    if (this.index[sheet] && this.index[sheet][row] && this.index[sheet][row][column]) {
      return this.index[sheet][row][column];
    }
    return null;
  }

  /**
   * Delete cached `Cell` value
   */

  public clearCache () : void {
    // this._cells.map((cell, index) => {
    //   if (cell.isDirty) {
    //     delete cell['$'];
    //     cell.isDirty = false;
    //   }
    //   return cell;
    // });
  }

  public reset () : void {
    this._cells.map((cell, index) => {
      cell.value = cell.originalValue;
      cell.isCustom = false;
      return cell;
    });
  }

}
