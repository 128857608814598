// tslint:disable object-shorthand-properties-first

import * as Actions from '../actions';
import { __, i18n } from '@spreax/i18n';
import Dispatcher from '../dispatcher';
import { ActionTypes } from '../action-types';
import { Tracker } from '@spreax/lib';

export function onFullfilled(
  message?,
  then?: Function | Function[],
  getPayload?: any,
  redirect?: string | Function
) {
  return data => {
    if (message) {
      Actions.feedback.add('info', __(message));
    }
    Actions.app.unsetLoading();
    const payload = getPayload && getPayload(data);
    if (payload) {
      Dispatcher.dispatch(payload);
    }
    if (redirect) {
      if (typeof redirect === 'function') {
        Dispatcher.dispatch({
          type: ActionTypes.SET_REDIRECT,
          redirect: redirect(data)
        });
      }
      if (typeof redirect === 'string') {
        Dispatcher.dispatch({
          type: ActionTypes.SET_REDIRECT,
          redirect
        });
      }
    }
    if (Array.isArray(then)) {
      return then.reduce(
        (promise, then) => promise.then(then()),
        Promise.resolve()
      );
    }
    return then && then();
  };
}

export function onRejection(message: string = i18n.FEEDBACK.EXCEPTION) {
  return error => {
    Tracker.exception(error);
    console.error(error);
    Actions.feedback.add('error', __(message));
    Actions.app.unsetLoading();
  };
}
