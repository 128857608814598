var statistical = require("../statistical");

import { EngineError, ERROR_VALUE, ERROR_NUM } from "../../error";
import {
  parseNumber,
  parseNumberArray,
  flatten,
  anyIsError,
  arrayEach,
  argsToArray
} from "../utils";
import { ISNUMBER } from "../information";
import { PRODUCT } from "../math-and-trigonometry";

/**
 * Returns the secant of an angle.
 *
 * @param number Number is the angle in radians for which you want the secant.
 */
export function SEC(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  return 1 / Math.cos(number);
}

/**
 * Returns the hyperbolic secant of an angle.
 *
 * @param number Number is the angle in radians for which you want the
 * hyperbolic secant.
 */
export function SECH(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  return 2 / (Math.exp(number) + Math.exp(-number));
}

/**
 * Many functions can be approximated by a power series expansion.
 *
 * @param x The input value to the power series.
 * @param n The initial power to which you want to raise x.
 * @param m  The step by which to increase n for each term in the series.
 * @param coefficients A set of coefficients by which each successive power of
 * x is multiplied. The number of values in coefficients determines the number
 * of terms in the power series. For example, if there are three values in
 * coefficients, then there will be three terms in the power series.
 */
export function SERIESSUM(x, n, m, coefficients) {
  x = parseNumber(x);
  n = parseNumber(n);
  m = parseNumber(m);
  coefficients = parseNumberArray(coefficients);
  if (anyIsError(x, n, m, coefficients)) {
    return new EngineError("SERIESSUM", ERROR_VALUE, arguments);
  }
  var result = coefficients[0] * Math.pow(x, n);
  for (var i = 1; i < coefficients.length; i++) {
    result += coefficients[i] * Math.pow(x, n + i * m);
  }
  return result;
}

/**
 * Determines the sign of a number. Returns 1 if the number is positive, zero
 * (0) if the number is 0, and -1 if the number is negative.
 *
 * @param number Any real number.
 */
export function SIGN(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  if (number < 0) {
    return -1;
  } else if (number === 0) {
    return 0;
  } else {
    return 1;
  }
}

/**
 * Returns the sine of the given angle.
 *
 * @param number The angle in radians for which you want the sine.
 */
export function SIN(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  return Math.sin(number);
}

/**
 * Returns the hyperbolic sine of a number.
 *
 * @param number Any real number.
 */
export function SINH(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  return (Math.exp(number) - Math.exp(-number)) / 2;
}

/**
 * Returns a positive square root.
 *
 * @param number The number for which you want the square root.
 */
export function SQRT(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  if (number < 0) {
    return new EngineError("SQRT", ERROR_NUM, arguments);
  }
  return Math.sqrt(number);
}

/**
 * Returns the square root of (number * pi).
 *
 * @param number The number by which pi is multiplied.
 */
export function SQRTPI(number) {
  number = parseNumber(number);
  if (number instanceof Error) {
    return number;
  }
  return Math.sqrt(number * Math.PI);
}

// TODO NOT_DEFINED in https://support.office.com/
export function SQRT1_2() {
  return 1 / Math.sqrt(2);
}

// TODO NOT_DEFINED in https://support.office.com/
export function SQRT2() {
  return Math.sqrt(2);
}

/**
 * Returns a subtotal in a list or database.
 *
 * @param function_code The number 1-11 or 101-111 that specifies the function
 * to use for the subtotal. 1-11 includes manually-hidden rows, while 101-111
 * excludes them; filtered-out cells are always excluded.
 * @param ref[] ref1, ref2,...    ref1 is required, subsequent
 * refs are optional. The named range or reference for which you want the
 * subtotal.
 */
export function SUBTOTAL(function_code, ref1) {
  function_code = parseNumber(function_code);
  if (function_code instanceof Error) {
    return function_code;
  }

  switch (function_code) {
    case 1:
      return statistical.AVERAGE(ref1);
    case 2:
      return statistical.COUNT(ref1);
    case 3:
      return statistical.COUNTA(ref1);
    case 4:
      return statistical.MAX(ref1);
    case 5:
      return statistical.MIN(ref1);
    case 6:
      return PRODUCT(ref1);
    case 7:
      return statistical.STDEV.S(ref1);
    case 8:
      return statistical.STDEV.P(ref1);
    case 9:
      return SUM(ref1);
    case 10:
      return statistical.VAR.S(ref1);
    case 11:
      return statistical.VAR.P(ref1);
    // no hidden values for us
    case 101:
      return statistical.AVERAGE(ref1);
    case 102:
      return statistical.COUNT(ref1);
    case 103:
      return statistical.COUNTA(ref1);
    case 104:
      return statistical.MAX(ref1);
    case 105:
      return statistical.MIN(ref1);
    case 106:
      return PRODUCT(ref1);
    case 107:
      return statistical.STDEV.S(ref1);
    case 108:
      return statistical.STDEV.P(ref1);
    case 109:
      return SUM(ref1);
    case 110:
      return statistical.VAR.S(ref1);
    case 111:
      return statistical.VAR.P(ref1);
  }
}

/**
 * The SUM function adds values. You can add individual values, cell references
 * or ranges or a mix of all three.
 *
 * @param ref[] ref1, ref2,... ref1 is required, subsequent
 * refs are optional. The refs to sum
 */
export function SUM(...args) {
  var result = 0;
  arrayEach(argsToArray(args), value => {
    if (typeof value === "number") {
      result += value;
    } else if (typeof value === "string") {
      var parsed = parseFloat(value);
      !isNaN(parsed) && (result += parsed);
    } else if (Array.isArray(value)) {
      result += SUM.apply(null, value);
    }
  });
  return result;
}

/**
 * You use the SUMIF function to sum the values in a range that meet criteria
 * that you specify.
 *
 * @param range The range of cells that you want evaluated by criteria. Cells in
 * each range must be numbers or names, arrays, or references that contain
 * numbers. Blank and text values are ignored
 * @param criteria The criteria in the form of a number, expression, a cell
 * reference, text, or a function that defines which cells will be added.
 * @param sumRange Optional. The actual cells to add, if you want to add cells
 * other than those specified in the range argument.
 */
export function SUMIF(range, criteria, sumRange) {
  range = flatten(range);
  sumRange = flatten(sumRange);
  // range = utils.parseNumberArray(utils.flatten(range));
  // if (range instanceof Error) {
  //   return range;
  // }
  var result = 0;
  for (var i = 0; i < range.length; i++) {
    var value = sumRange ? sumRange[i] || 0 : range[i];
    // if (typeof range[i] === 'string') {
    result += range[i] == criteria ? value : 0;
    // } else {
    //  result += (eval(range[i] + criteria)) ? value : 0; // jshint ignore:line
    // }
  }
  return result;
}

// TODO NOT_DEFINED in https://support.office.com/
export function SUMIFS() {
  var args = argsToArray(arguments);
  var range = parseNumberArray(flatten(args.shift()));
  if (range instanceof Error) {
    return range;
  }
  var criteria = args;

  var n_range_elements = range.length;
  var n_criterias = criteria.length;

  var result = 0;
  for (var i = 0; i < n_range_elements; i++) {
    var el = range[i];
    var condition = "";
    for (var c = 0; c < n_criterias; c++) {
      condition += el + criteria[c];
      if (c !== n_criterias - 1) {
        condition += "&&";
      }
    }
    if (eval(condition)) {
      // jshint ignore:line
      result += el;
    }
  }
  return result;
}

/**
 * returns the sum of the products of corresponding ranges or arrays. The
 * default operation is multiplication, but addition, subtraction, and division
 * are also possible.
 *
 * @param number[] number, number,... number is required, subsequent
 * refs are optional. The argument whose components you want to multiply and
 * then add.
 */
export function SUMPRODUCT(...args: any[]) {
  if (!arguments || arguments.length === 0) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, args);
  }
  var arrays = arguments.length + 1;
  var result = 0;
  var product;
  var k;
  var _i;
  var _ij;
  for (var i = 0; i < args[0].length; i++) {
    if (!(args[0][i] instanceof Array)) {
      product = 1;
      for (k = 1; k < arrays; k++) {
        _i = parseNumber(args[k - 1][i]);
        if (_i instanceof Error) {
          return _i;
        }
        product *= _i;
      }
      result += product;
    } else {
      for (var j = 0; j < args[0][i].length; j++) {
        product = 1;
        for (k = 1; k < arrays; k++) {
          _ij = parseNumber(args[k - 1][i][j]);
          if (_ij instanceof Error) {
            return _ij;
          }
          product *= _ij;
        }
        result += product;
      }
    }
  }
  return result;
}

// TODO NOT_DEFINED in https://support.office.com/
export function SUMSQ() {
  var numbers = parseNumberArray(flatten(arguments));
  if (numbers instanceof Error) {
    return numbers;
  }
  var result = 0;
  var length = numbers.length;
  for (var i = 0; i < length; i++) {
    result += ISNUMBER(numbers[i]) ? numbers[i] * numbers[i] : 0;
  }
  return result;
}

/**
 * Returns the sum of the difference of squares of corresponding values in two
 * arrays.
 * 
 * @param array_x  The first array or range of values.
 * @param array_y The second array or range of values.
 */
export function SUMX2MY2(array_x, array_y) {
  array_x = parseNumberArray(flatten(array_x));
  array_y = parseNumberArray(flatten(array_y));
  if (anyIsError(array_x, array_y)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  var result = 0;
  for (var i = 0; i < array_x.length; i++) {
    result += array_x[i] * array_x[i] - array_y[i] * array_y[i];
  }
  return result;
}

/**
 * Returns the sum of the sum of squares of corresponding values in two arrays.
 * The sum of the sum of squares is a common term in many statistical
 * calculations.
 * 
 * @param array_x The first array or range of values.
 * @param array_y The second array or range of values.
 */
export function SUMX2PY2(array_x, array_y) {
  array_x = parseNumberArray(flatten(array_x));
  array_y = parseNumberArray(flatten(array_y));
  if (anyIsError(array_x, array_y)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  let result = 0;
  array_x = parseNumberArray(flatten(array_x));
  array_y = parseNumberArray(flatten(array_y));
  for (let i = 0; i < array_x.length; i += 1) {
    result += array_x[i] * array_x[i] + array_y[i] * array_y[i];
  }
  return result;
}

/**
 * Returns the sum of squares of differences of corresponding values in two arrays.
 * 
 * @param array_x The first array or range of values.
 * @param array_y The second array or range of values.
 */
export function SUMXMY2(array_x, array_y) {
  array_x = parseNumberArray(flatten(array_x));
  array_y = parseNumberArray(flatten(array_y));
  if (anyIsError(array_x, array_y)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  let result = 0;
  array_x = flatten(array_x);
  array_y = flatten(array_y);
  for (let i = 0; i < array_x.length; i += 1) {
    result += Math.pow(array_x[i] - array_y[i], 2);
  }
  return result;
}
