import * as React from 'react';
import injectSheet from 'react-jss';
import * as Inputs from './components/inputs';
import { format, Property } from '@spreax/engine';

const styles = theme => ({
  container: {
    width: '100%',
  },
});

class InputFactoryComponent extends React.Component<any, any> {

  constructor (props) {
    super(props);
    this.state = {
      edit: false,
      lastValidValue: this.props.property.value,
    };
  }

  componentWillReceiveProps (nextProps, nextState) {
    this.setState(Object.assign({}, nextProps, {
      lastValidValue: nextProps.property.value,
    }));
  }

  render () {
    const { property } = this.props;
    let InputComponent = null;
    switch (property.type) {
      case 'dropdown':
        InputComponent = Inputs.Dropdown;
        break;
      // case 'radio':
      //   InputComponent = Inputs.RadioInput;
      //   break;
      case 'checkbox':
        InputComponent = Inputs.Checkbox;
        break;
      case 'label':
        InputComponent = Inputs.Label;
        break;
      case 'paragraph':
        InputComponent = Inputs.Paragraph;
        break;
      case 'inputField':
      default:
        InputComponent = Inputs.InputField;
        break;
    }
    return (
      <InputComponent
        edit={this.state.edit}
        property={property}
        onBlur={this.onBlur.bind(this)}
        onChange={this.onChange.bind(this)}
        onFocus={this.onFocus.bind(this)}
        editValue={this.state.editValue}
        onKeyPress={(event) => {
          if (+event.charCode === 13) {
            this.onBlur(property)();
            this.onFocus(property)();
          }
        }}
      />
    );
  }

  onFocus (property: Property) {
    return () => {
      setTimeout(
        () => {
          this.props.actions.onFocus();
        },
        0,
      );
      if (!property.readOnly) {
        this.setState({
          edit: true,
          editValue: format.format(this.props.property, this.props.property.value, true),
        });
      }
    };
  }

  onBlur (property: Property) {
    return () => {

      if (property.readOnly) {
        setTimeout(
          () => {
            this.props.actions.onBlur();
          },
          0,
        );
        return;
      }

      property.value = format.parse(this.props.property, this.state.editValue);

      // if (property.value === null) {
      //   property.value = this.state.lastValidValue;
      // }

      property.value = format.validate(this.props.property, property.value);

      if (property.value === this.state.lastValidValue) {
        this.setState({
          parameter: this.state.parameter,
          edit: false,
          editValue: null,
        });
        return;
      }

      this.setState({
        parameter: this.state.parameter,
        edit: false,
        lastValidValue: property.value,
        editValue: null,
      });

      setTimeout(
        () => {
          this.props.actions.onChange(property.id, property.value);
          this.props.actions.onBlur();
        },
        0,
      );

    };

  }

  onChange (property: Property) {
    return (e) => {
      let value = e.target ? e.target.value : null;

      if (!value && e.key) {
        value = e.key;
      }

      if (!value && typeof e !== 'object') {
        value = e;
      }

      this.setState({
        editValue: value,
      });
    };
  }

}

export const InputFactory = injectSheet(styles)(InputFactoryComponent);
