var utils = require("../utils");
var jStat = require("jStat").jStat;

import { EngineError, ERROR_VALUE } from "../../error";

/**
 * Returns the k-th largest value in a data set. You can use this function to
 * select a value based on its relative standing.
 *
 * @param range The array or range of data for which you want to determine the
 * k-th largest value.
 * @param k The position (from the largest) in the array or cell range of data
 * to return.
 */
export function LARGE(range, k) {
  range = utils.parseNumberArray(utils.flatten(range));
  k = utils.parseNumber(k);
  if (utils.anyIsError(range, k)) {
    return range;
  }
  return range.sort(function(a, b) {
    return b - a;
  })[k - 1];
}

/**
 * The LINEST function calculates the statistics for a line by using the "least
 * squares" method to calculate a straight line that best fits your data, and
 * then returns an array that describes the line.
 *
 * @param data_y The set of y-values that you already know in the relationship
 * y = mx + b.
 * @param data_x  Optional. A set of x-values that you may already know in the
 * relationship y = mx + b.
 *
 * FIXME Untested. Function signature mismatch
 * https://support.office.com/en-us/article/LINEST-function-84D7D0D9-6E50-4101-977A-FA7ABF772B6D
 */
export function LINEST(data_y, data_x) {
  data_y = utils.parseNumberArray(utils.flatten(data_y));
  data_x = utils.parseNumberArray(utils.flatten(data_x));
  if (utils.anyIsError(data_y, data_x)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  var ymean = jStat.mean(data_y);
  var xmean = jStat.mean(data_x);
  var n = data_x.length;
  var num = 0;
  var den = 0;
  for (var i = 0; i < n; i++) {
    num += (data_x[i] - xmean) * (data_y[i] - ymean);
    den += Math.pow(data_x[i] - xmean, 2);
  }
  var m = num / den;
  var b = ymean - m * xmean;
  return [m, b];
}

/**
 * In regression analysis, the LOGEST function calculates an exponential curve
 * that fits your data and returns an array of values that describes the curve.
 *
 * @param data_y The set of y-values you already know in the relationship
 * y = b*m^x.
 * @param data_x Optional. An optional set of x-values that you may already
 * know in the relationship y = b*m^x.
 * 
 * FIXME Untested. Function signature mismatch
 * https://support.office.com/en-us/article/LINEST-function-84D7D0D9-6E50-4101-977A-FA7ABF772B6D
 */
export function LOGEST(data_y, data_x) {
  // According to Microsoft:
  // http://office.microsoft.com/en-us/starter-help/logest-function-HP010342665.aspx
  // LOGEST returns are based on the following linear model:
  // ln y = x1 ln m1 + ... + xn ln mn + ln b
  data_y = utils.parseNumberArray(utils.flatten(data_y));
  data_x = utils.parseNumberArray(utils.flatten(data_x));
  if (utils.anyIsError(data_y, data_x)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  for (var i = 0; i < data_y.length; i++) {
    data_y[i] = Math.log(data_y[i]);
  }

  var result = LINEST(data_y, data_x);
  result[0] = Math.round(Math.exp(result[0]) * 1000000) / 1000000;
  result[1] = Math.round(Math.exp(result[1]) * 1000000) / 1000000;
  return result;
}

/**
 * Returns the lognormal distribution of x, where ln(x) is normally distributed
 * with parameters Mean and Standard_dev.
 * 
 * @param x The value at which to evaluate the function.
 * @param mean The mean of ln(x).
 * @param sd  The standard deviation of ln(x).
 * @param cumulative  A logical value that determines the form of the function.
 * If cumulative is TRUE, LOGNORM.DIST returns the cumulative distribution
 * function; if FALSE, it returns the probability density function.
 */
export function LOGNORM_DIST(x, mean, sd, cumulative) {
  x = utils.parseNumber(x);
  mean = utils.parseNumber(mean);
  sd = utils.parseNumber(sd);
  if (utils.anyIsError(x, mean, sd)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  return cumulative
    ? jStat.lognormal.cdf(x, mean, sd)
    : jStat.lognormal.pdf(x, mean, sd);
}

/**
 * Returns the inverse of the lognormal cumulative distribution function of x,
 * where ln(x) is normally distributed with parameters Mean and Standard_dev.
 * 
 * @param probability  A probability associated with the lognormal distribution.
 * @param mean The mean of ln(x).
 * @param sd The standard deviation of ln(x).
 */
export function LOGNORM_INV(probability, mean, sd) {
  probability = utils.parseNumber(probability);
  mean = utils.parseNumber(mean);
  sd = utils.parseNumber(sd);
  if (utils.anyIsError(probability, mean, sd)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  return jStat.lognormal.inv(probability, mean, sd);
}
