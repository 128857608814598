import { ReduceStore } from 'flux/utils';
import Dispatcher from '../dispatcher';
import { ActionTypes } from '../action-types';
import * as DataManager from '../data-managers';
import { set } from 'lodash';
import { Template } from '@spreax/model';
import * as API from '@spreax/api-client';

function onReceiveTemplates(state: TemplateState, action): TemplateState {
  const { templates } = action;
  const selectedTemplate =
    state.selectedTemplate &&
    templates.find(template => template.id === state.selectedTemplate.id);
  return Object.assign({}, state, {
    templates,
    selectedTemplate,
    hasReceivedTemplates: true,
    isFetchingTemplates: false
  });
}

function onSelectTemplate(state: TemplateState, action): TemplateState {
  return Object.assign({}, state, {
    selectedTemplate: state.templates.find(model => model.id === action.id)
  });
}

function onUpdateTemplate(state: TemplateState, action): TemplateState {
  const template = state.templates.find(model => model.id === action.id);
  set(template, action.field, action.value);
  const templates = state.templates.filter(model => model.id !== action.id);
  return Object.assign({}, state, {
    templates: [template, ...templates]
  });
}

function onSaveTemplate(state: TemplateState, action): TemplateState {
  const template = state.templates.find(model => model.id === action.id);
  DataManager.template.save(action.tenantId, template);
  return state;
}

function onLoadTemplates(state: TemplateState, action): TemplateState {
  setTimeout(() => DataManager.template.get(action.tenantId), 0);
  return Object.assign({}, state, {
    isFetchingTemplates: true
  });
}

// function onPrintTemplate (state: TemplateState, action) : TemplateState {
//   const { id, session } = action;
//
//   const form = document.createElement('form');
//   form.style.display = 'none';
//   form.setAttribute('method', 'post');
//   form.setAttribute('action', process.env['ENGINE_URI'] + '/templates/' + id + '/print');
//   form.setAttribute('target', 'print_popup');
//
//   const hiddenField = document.createElement('input');
//   hiddenField.setAttribute('name', 'sessionId');
//   hiddenField.setAttribute('value', session);
//   form.appendChild(hiddenField);
//
//   document.body.appendChild(form);
//   window.open('', 'print_popup');
//
//   form.submit();
//
//   return state;
// }

function onLogout(state: TemplateState, action): TemplateState {
  return {
    selectedTemplate: null,
    templates: [],
    hasReceivedTemplates: false,
    isFetchingTemplates: false
  };
}

class TemplateStore extends ReduceStore<TemplateState, any> {
  constructor() {
    super(Dispatcher);
  }

  getInitialState() {
    return {
      selectedTemplate: null,
      templates: [],
      hasReceivedTemplates: false,
      isFetchingTemplates: false
    };
  }

  reduce(state: TemplateState, action): TemplateState {
    switch (action.type) {
      case ActionTypes.RECEIVE_TEMPLATES:
        return onReceiveTemplates(state, action);

      case ActionTypes.SELECT_TEMPLATE:
        return onSelectTemplate(state, action);

      case ActionTypes.UPDATE_TEMPLATE:
        return onUpdateTemplate(state, action);

      case ActionTypes.SAVE_TEMPLATE:
        return onSaveTemplate(state, action);

      case ActionTypes.LOAD_TEMPLATES:
        return onLoadTemplates(state, action);

      // case ActionTypes.PRINT_TEMPLATE:
      //   return onPrintTemplate(state, action);

      case ActionTypes.SELECT_TENANT:
      case ActionTypes.LOGOUT:
        return onLogout(state, action);

      default:
        return state;
    }
  }
}

export interface TemplateState {
  selectedTemplate: Template;
  templates: Template[];
  hasReceivedTemplates: boolean;
  isFetchingTemplates: boolean;
}

export const Store = new TemplateStore();
