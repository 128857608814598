import { toNumber } from '../utils';
import { ERROR_DIV_ZERO, ERROR_VALUE, EngineError } from '../error';

export function divide(first, ...rest) {
  const isAllZero =
    rest.reduce((acc, value) => acc + toNumber(value), toNumber(first)) === 0;
  if (isAllZero) {
    return new EngineError('DIVIDE', ERROR_DIV_ZERO, [first, ...rest]);
  }
  const result = rest.reduce(
    (acc, value) => acc / toNumber(value),
    toNumber(first)
  );
  if (isNaN(result)) {
    return new EngineError('DIVIDE', ERROR_VALUE, [first, ...rest]);
  }
  return result;
}
