import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Container from './container';
import { Tracker } from '@spreax/lib';
import * as API from '@spreax/api-client';
import { loadConfig } from './config';

declare var CONFIG: {
  API_ENDPOINT: string;
  SENTRY_URI: string;
  GA_TRACKING_ID: string;
};

export function setup(config) {
  loadConfig(config);

  if (config.SENTRY_URI) {
    Tracker.setupRaven(config.SENTRY_URI);
  }

  API.connect(config.API_ENDPOINT);

  try {
    ReactDOM.render(<Container />, document.getElementById('container'));
  } catch (exception) {
    console.log(exception);
    Tracker.exception(exception);
  }
}
