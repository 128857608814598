import { ReduceStore } from 'flux/utils';
import Dispatcher from '../dispatcher';
import { ActionTypes } from '../action-types';

function onSetSpreaxClient(state: ClientState, action): ClientState {
  return Object.assign({}, state, {
    client: action.client
  });
}

function onSetState(state: ClientState, action): ClientState {
  return Object.assign({}, state, {
    clientState: action.state
  });
}

function onLogout(state: ClientState, action): ClientState {
  return {
    client: null,
    clientState: null,
    session: null,
    clientError: null
  };
}

function onSetError(state: ClientState, action): ClientState {
  return Object.assign({}, state, {
    clientError: action.error
  });
}

class ClientStore extends ReduceStore<ClientState, any> {
  constructor() {
    super(Dispatcher);
  }

  getInitialState() {
    return {
      client: null,
      clientState: null,
      session: null,
      clientError: null
    };
  }

  reduce(state: ClientState, action): ClientState {
    switch (action.type) {
      case ActionTypes.SET_SPREAX_CLIENT:
        return onSetSpreaxClient(state, action);

      case ActionTypes.SET_SPREAX_STATE:
        return onSetState(state, action);

      case ActionTypes.SET_SPREAX_ERROR:
        return onSetError(state, action);

      case ActionTypes.LOGOUT:
        return onLogout(state, action);

      default:
        return state;
    }
  }
}

export interface ClientState {
  client: any;
  clientState: number;
  session: string;
  clientError: Error;
}

export const Store = new ClientStore();
