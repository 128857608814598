import * as React from 'react';
import '../../../src/pages/profile/style.scss';
import { State } from '../../container';
import { __, i18n, SUPPORTED_LANGUAGES } from '@spreax/i18n';
import { App } from '../frame/app';
import { Checkbox, InputField, Button, Dropdown } from '@spreax/components';
import { PageTop } from '../../components/page';

export class Profile extends React.PureComponent<State> {
  onFieldChange(key: string, validator?) {
    const { actions } = this.props;
    return ev => {
      const { value } = ev.target;
      actions.user.update(key, value);
    };
  }

  render() {
    const { actions, profileDelta: profile } = this.props;
    return (
      <App {...this.props} documentTitle={__(i18n.PROFILE.DOCUMENT_TITLE)}>
        {profile && (
          <div className="sx-page">
            <PageTop
              primary={__(i18n.PROFILE.PRIMARY_TITLE)}
              actionsRight={[
                <Button
                  label={__(i18n.ACTIONS.SAVE)}
                  isPrimary={true}
                  onClick={actions.user.save}
                />
              ]}
            />
            <div className="sx-page__contents">
              <form className="sx-form">
                <fieldset>
                  <label>{__(i18n.LABELS.NAME)}</label>
                  <InputField
                    className="sx-form__input"
                    value={profile.name}
                    onChange={this.onFieldChange('name')}
                  />
                </fieldset>

                <fieldset>
                  <label>{__(i18n.LABELS.EMAIL)}</label>
                  <InputField
                    className="sx-form__input"
                    value={profile.email}
                    onChange={this.onFieldChange('email')}
                  />
                </fieldset>

                <fieldset>
                  <label>{__(i18n.PROFILE.CHANGE_PASSWORD)}</label>
                  <InputField
                    className="sx-form__input"
                    type="password"
                    placeholder={__(i18n.LABELS.NEW_PASSWORD)}
                    onChange={this.onFieldChange('password')}
                  />
                </fieldset>

                <fieldset>
                  <label>{__(i18n.PROFILE.CHANGE_LANGUAGE)}</label>
                  <Dropdown
                    className="sx-form__input"
                    value={profile.language}
                    onChange={this.onFieldChange('language')}
                    options={Object.keys(SUPPORTED_LANGUAGES).map(id => {
                      return {
                        key: id,
                        value: id,
                        label: SUPPORTED_LANGUAGES[id]
                      };
                    })}
                  />
                </fieldset>
              </form>
            </div>
          </div>
        )}
      </App>
    );
  }
}
