import * as React from 'react';
import injectSheet from 'react-jss';
import * as classNames from 'classnames';

const styles = theme => ({
  container: {
    float: 'left',
    outline: 'none',
    boxSizing: 'border-box',
    width: '100%',
    ...(theme.inputs &&
      theme.inputs.dropdown &&
      theme.inputs.dropdown.container)
  },
  input: {
    width: '100%',
    height: theme.themeOptions.inputHeight || 30,
    ...(theme.inputs && theme.inputs.dropdown && theme.inputs.dropdown.input)
  }
});

function DropdownComponent(props) {
  const { classes, className, onChange, onKeyPress, value, options } = props;
  return (
    <div className={classNames(classes.container, className)}>
      <select
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        className={classes.input}
      >
        {options.map(({ key, value, label }) => {
          return (
            <option key={key} value={value}>
              {label}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export const Dropdown = injectSheet(styles)(DropdownComponent);
