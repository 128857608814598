import { call, createFormData, getURL } from '..';

export namespace attachment {
  const PATH = 'attachment';

  export function upload(data) {
    const formData = createFormData(data);
    return call(getURL(PATH), {
      method: 'POST',
      body: formData
    });
  }

  export function remove(attachment) {
    return call(getURL(PATH, attachment.id), {
      method: 'DELETE'
    });
  }
}
