import * as React from 'react';
import '../../../src/components/card/style.scss';
import { __, i18n } from '@spreax/i18n';
import * as classNames from 'classnames';

export function Card(props) {
  const { title, lines, children, onClick, isDisabled } = props;

  return (
    <div
      className={classNames('sx-card', {
        'sx-card--is-disabled': isDisabled
      })}
      onClick={() => !isDisabled && onClick(props)}
    >
      {title && <div className="sx-card__title">{title}</div>}

      {children && <div className="sx-card__children">{children}</div>}

      {lines && <Lines>{lines}</Lines>}
    </div>
  );
}

function Lines(props) {
  const { children } = props;
  const n = children.length;

  return (
    <div className="sx-card__lines">
      {children.map((line, i) => {
        return (
          <div
            key={i}
            className={`sx-card__line ${
              n === i + 1 ? 'sx-card__last-line' : ''
            }`}
          >
            {line}
          </div>
        );
      })}
    </div>
  );
}

function Labels(props) {
  const { labels, maxLabels = 5 } = props;

  if (!labels) {
    return null;
  }

  let visibleLabels = labels;
  let nMoreLabels = <span></span>;

  if (labels.length > maxLabels) {
    visibleLabels = labels.slice(0, maxLabels);
    nMoreLabels = (
      <span className="sx-card__labels__label">
        {labels.length - maxLabels} {__(i18n.CARD_COMPONENT.MORE)}...
      </span>
    );
  }

  return (
    <p className="sx-card__labels">
      {visibleLabels.map(label => (
        <span key={label} className="sx-card__labels__label">
          {label}
        </span>
      ))}
      {nMoreLabels}
    </p>
  );
}
