var utils = require("../utils");
var jStat = require("jStat").jStat;
var misc = require("../miscellaneous");
var mathTrig = require("../math-and-trigonometry");

import { EngineError, ERROR_NUM, ERROR_VALUE } from "../../error";

const SQRT2PI = 2.5066282746310002;

/**
 * Returns the Pearson product moment correlation coefficient, r, a
 * dimensionless index that ranges from -1.0 to 1.0 inclusive and reflects the
 * extent of a linear relationship between two data sets.
 *
 * @param data_x A set of independent values.
 * @param data_y A set of independent values.
 */
export function PEARSON(data_x, data_y) {
  data_y = utils.parseNumberArray(utils.flatten(data_y));
  data_x = utils.parseNumberArray(utils.flatten(data_x));
  if (utils.anyIsError(data_y, data_x)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  var xmean = jStat.mean(data_x);
  var ymean = jStat.mean(data_y);
  var n = data_x.length;
  var num = 0;
  var den1 = 0;
  var den2 = 0;
  for (var i = 0; i < n; i++) {
    num += (data_x[i] - xmean) * (data_y[i] - ymean);
    den1 += Math.pow(data_x[i] - xmean, 2);
    den2 += Math.pow(data_y[i] - ymean, 2);
  }
  return num / Math.sqrt(den1 * den2);
}

/**
 * Returns the k-th percentile of values in a range, where k is in the range
 * 0..1, exclusive.
 * 
 * @param array The array or range of data that defines relative standing.
 * @param k The percentile value in the range 0..1, exclusive.
 */
export function PERCENTILE_EXC(array, k) {
  array = utils.parseNumberArray(utils.flatten(array));
  k = utils.parseNumber(k);
  if (utils.anyIsError(array, k)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  array = array.sort(function(a, b) {
    {
      return a - b;
    }
  });
  var n = array.length;
  if (k < 1 / (n + 1) || k > 1 - 1 / (n + 1)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NUM, arguments);
  }
  var l = k * (n + 1) - 1;
  var fl = Math.floor(l);
  return utils.cleanFloat(
    l === fl ? array[l] : array[fl] + (l - fl) * (array[fl + 1] - array[fl])
  );
}

/**
 * Returns the k-th percentile of values in a range, where k is in the range
 * 0..1, inclusive.
 * 
 * @param array The array or range of data that defines relative standing.
 * @param k The percentile value in the range 0..1, inclusive.
 */
export function PERCENTILE_INC(array, k) {
  array = utils.parseNumberArray(utils.flatten(array));
  k = utils.parseNumber(k);
  if (utils.anyIsError(array, k)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  array = array.sort(function(a, b) {
    return a - b;
  });
  var n = array.length;
  var l = k * (n - 1);
  var fl = Math.floor(l);
  return utils.cleanFloat(
    l === fl ? array[l] : array[fl] + (l - fl) * (array[fl + 1] - array[fl])
  );
}

/**
 * Returns the rank of a value in a data set as a percentage (0..1, exclusive
 *  of the data set.
 * 
 * @param array The array or range of data with numeric values that defines
 * relative standing
 * @param x The value for which you want to know the rank.
 * @param significance  Optional. A value that identifies the number of
 * significant digits for the returned percentage value. If omitted,
 * PERCENTRANK.EXC uses three digits (0.xxx).
 */
export function PERCENTRANK_EXC(array, x, significance) {
  significance = significance === undefined ? 3 : significance;
  array = utils.parseNumberArray(utils.flatten(array));
  x = utils.parseNumber(x);
  significance = utils.parseNumber(significance);
  if (utils.anyIsError(array, x, significance)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  array = array.sort(function(a, b) {
    return a - b;
  });
  var uniques = misc.UNIQUE.apply(null, array);
  var n = array.length;
  var m = uniques.length;
  var power = Math.pow(10, significance);
  var result = 0;
  var match = false;
  var i = 0;
  while (!match && i < m) {
    if (x === uniques[i]) {
      result = (array.indexOf(uniques[i]) + 1) / (n + 1);
      match = true;
    } else if (x >= uniques[i] && (x < uniques[i + 1] || i === m - 1)) {
      result =
        (array.indexOf(uniques[i]) +
          1 +
          (x - uniques[i]) / (uniques[i + 1] - uniques[i])) /
        (n + 1);
      match = true;
    }
    i++;
  }
  return Math.floor(result * power) / power;
}

/**
 * Returns the rank of a value in a data set as a percentage (0..1, inclusive)
 * of the data set.
 * 
 * @param array The array or range of data with numeric values that defines
 * relative standing.
 * @param x The value for which you want to know the rank.
 * @param significance  Optional. A value that identifies the number of
 * significant digits for the returned percentage value. If omitted
 * PERCENTRANK.INC uses three digits (0.xxx).
 */
export function PERCENTRANK_INC(array, x, significance) {
  significance = significance === undefined ? 3 : significance;
  array = utils.parseNumberArray(utils.flatten(array));
  x = utils.parseNumber(x);
  significance = utils.parseNumber(significance);
  if (utils.anyIsError(array, x, significance)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  array = array.sort(function(a, b) {
    return a - b;
  });
  var uniques = misc.UNIQUE.apply(null, array);
  var n = array.length;
  var m = uniques.length;
  var power = Math.pow(10, significance);
  var result = 0;
  var match = false;
  var i = 0;
  while (!match && i < m) {
    if (x === uniques[i]) {
      result = array.indexOf(uniques[i]) / (n - 1);
      match = true;
    } else if (x >= uniques[i] && (x < uniques[i + 1] || i === m - 1)) {
      result =
        (array.indexOf(uniques[i]) +
          (x - uniques[i]) / (uniques[i + 1] - uniques[i])) /
        (n - 1);
      match = true;
    }
    i++;
  }
  return Math.floor(result * power) / power;
}

/**
 * Returns the number of permutations for a given number of objects that can be
 * selected from number objects
 * 
 * @param number An integer that describes the number of objects.
 * @param number_chosen An integer that describes the number of objects in each
 * permutation.
 */
export function PERMUT(number, number_chosen) {
  number = utils.parseNumber(number);
  number_chosen = utils.parseNumber(number_chosen);
  if (utils.anyIsError(number, number_chosen)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  return mathTrig.FACT(number) / mathTrig.FACT(number - number_chosen);
}

/**
 * Returns the number of permutations for a given number of objects (with
 * repetitions) that can be selected from the total objects.
 * 
 * @param number An integer that describes the total number of objects.
 * @param number_chosen  An integer that describes the number of objects in
 * each permutation.
 */
export function PERMUTATIONA(number, number_chosen) {
  number = utils.parseNumber(number);
  number_chosen = utils.parseNumber(number_chosen);
  if (utils.anyIsError(number, number_chosen)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  return Math.pow(number, number_chosen);
}

/**
 * Returns the value of the density function for a standard normal distribution.
 * 
 * @param x X is the number for which you want the density of the standard
 * normal distribution.
 */
export function PHI(x) {
  x = utils.parseNumber(x);
  if (x instanceof Error) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  return Math.exp(-0.5 * x * x) / SQRT2PI;
}

/**
 * Returns the Poisson distribution.
 * 
 * @param x The number of events.
 * @param mean The expected numeric value.
 * @param cumulative Required. A logical value that determines the form of the
 * probability distribution returned. If cumulative is TRUE, POISSON.DIST
 * returns the cumulative Poisson probability that the number of random events
 * occurring will be between zero and x inclusive; if FALSE, it returns the
 * Poisson probability mass function that the number of events occurring will
 * be exactly x.
 */
export function POISSON_DIST(x, mean, cumulative) {
  x = utils.parseNumber(x);
  mean = utils.parseNumber(mean);
  if (utils.anyIsError(x, mean)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  return cumulative ? jStat.poisson.cdf(x, mean) : jStat.poisson.pdf(x, mean);
}

/**
 * Returns the probability that values in a range are between two limits. If
 * upper_limit is not supplied, returns the probability that values in x_range
 * are equal to lower_limit.
 * 
 * @param range The range of numeric values of x with which there are associated
 * probabilities.
 * @param probability A set of probabilities associated with values in x_range.
 * @param lower The lower bound on the value for which you want a probability.
 * @param upper The optional upper bound on the value for which you want a
 * probability.
 */
export function PROB(range, probability, lower, upper) {
  if (lower === undefined) {
    return 0;
  }
  upper = upper === undefined ? lower : upper;

  range = utils.parseNumberArray(utils.flatten(range));
  probability = utils.parseNumberArray(utils.flatten(probability));
  lower = utils.parseNumber(lower);
  upper = utils.parseNumber(upper);
  if (utils.anyIsError(range, probability, lower, upper)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  if (lower === upper) {
    return range.indexOf(lower) >= 0 ? probability[range.indexOf(lower)] : 0;
  }

  var sorted = range.sort(function(a, b) {
    return a - b;
  });
  var n = sorted.length;
  var result = 0;
  for (var i = 0; i < n; i++) {
    if (sorted[i] >= lower && sorted[i] <= upper) {
      result += probability[range.indexOf(sorted[i])];
    }
  }
  return result;
}
