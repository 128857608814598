var utils = require("../utils");

import { EngineError, ERROR_NUM } from "../../error";

import { AVERAGE, AVERAGEA } from "../statistical";

/**
 * Calculates variance based on the entire population (ignores logical values
 * and text in the population).
 *
 * @param args Number arguments corresponding to a population.
 */
export function VAR_P(...args) {
  const range = utils.numbers(utils.flatten(args));
  const n = range.length;
  let sigma = 0;
  const mean = AVERAGE(range);
  for (let i = 0; i < n; i += 1) {
    sigma += Math.pow(range[i] - mean, 2);
  }
  const result = sigma / n;
  if (isNaN(result)) {
    return new EngineError("VAR_P", ERROR_NUM, arguments);
  }
  return result;
}

/**
 * Estimates variance based on a sample (ignores logical values and text in the
 * sample).
 *
 * @param args Number arguments corresponding to a sample of a population.
 */
export function VAR_S(...args) {
  const range = utils.numbers(utils.flatten(args));
  const n = range.length;
  let sigma = 0;
  const mean = AVERAGE(range);
  for (let i = 0; i < n; i += 1) {
    sigma += Math.pow(range[i] - mean, 2);
  }
  return sigma / (n - 1);
}

/**
 * Estimates variance based on a sample.
 *
 * @param args Value1, value2, ...    Value1 is required, subsequent values are
 * optional. 1 to 255 value arguments corresponding to a sample of a population.
 */
export function VARA(...args) {
  var range = utils.flatten(args);
  var n = range.length;
  var sigma = 0;
  var count = 0;
  var mean = AVERAGEA(range);
  for (var i = 0; i < n; i++) {
    var el = range[i];
    if (typeof el === "number") {
      sigma += Math.pow(el - mean, 2);
    } else if (el === true) {
      sigma += Math.pow(1 - mean, 2);
    } else {
      sigma += Math.pow(0 - mean, 2);
    }

    if (el !== null) {
      count++;
    }
  }
  return sigma / (count - 1);
}

/**
 * Calculates variance based on the entire population.
 * 
 * @param args Value1, value2, ...    Value1 is required, subsequent values are
 * optional. 1 to 255 value arguments corresponding to a sample of a population.
 */
export function VARPA(...args) {
  const range = utils.flatten(args);
  const n = range.length;
  let sigma = 0;
  let count = 0;
  const mean = AVERAGEA(range);
  for (let i = 0; i < n; i += 1) {
    const el = range[i];
    if (typeof el === "number") {
      sigma += Math.pow(el - mean, 2);
    } else if (el === true) {
      sigma += Math.pow(1 - mean, 2);
    } else {
      sigma += Math.pow(0 - mean, 2);
    }
    if (el !== null) {
      count++;
    }
  }
  const result = sigma / count;
  if (isNaN(result)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NUM, args);
  }
  return result;
}
