import { ReduceStore } from 'flux/utils';
import Dispatcher from '../dispatcher';
import { ActionTypes } from '../action-types';
import * as DataManager from '../data-managers';
import { User } from '@spreax/model';

function onLogin(state: UserState, action): UserState {
  DataManager.user.login(action.username, action.password);
  return Object.assign({}, state, {
    loggedIn: false,
    token: null,
    profile: null
  });
}

function onUserLoggedIn(state: UserState, action): UserState {
  localStorage.setItem('token', action.session.token);
  return Object.assign({}, state, {
    loggedIn: true,
    token: action.session.token,
    profile: action.session.profile,
    profileDelta: Object.assign({}, action.session.profile, { password: null })
  });
}

function onLogout(state: UserState, action): UserState {
  localStorage.removeItem('token');
  return Object.assign({}, state, {
    loggedIn: false,
    token: null,
    profile: null
  });
}

function onUpdateProfile(state: UserState, action): UserState {
  return Object.assign({}, state, {
    profileDelta: Object.assign({}, state.profile, {
      [action.key]: action.value
    })
  });
}

function onSaveProfile(state: UserState, action): UserState {
  DataManager.user.save(state.profileDelta);
  return Object.assign({}, state, {});
}

class UserStore extends ReduceStore<UserState, any> {
  constructor() {
    super(Dispatcher);
  }

  getInitialState() {
    return {
      token: localStorage.getItem('token'),
      loggedIn: null,
      profile: null,
      profileDelta: null
    };
  }

  reduce(state: UserState, action): UserState {
    switch (action.type) {
      case ActionTypes.LOGIN:
        return onLogin(state, action);

      case ActionTypes.LOGGED_IN:
        return onUserLoggedIn(state, action);

      case ActionTypes.LOGOUT:
        return onLogout(state, action);

      case ActionTypes.UPDATE_PROFILE:
        return onUpdateProfile(state, action);

      case ActionTypes.SAVE_PROFILE:
        return onSaveProfile(state, action);

      default:
        return state;
    }
  }
}

export interface UserState {
  token: string;
  loggedIn: boolean;
  profile: User;
  profileDelta: any;
}

export const Store = new UserStore();
