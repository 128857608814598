import * as React from 'react';
import { __, i18n } from '@spreax/i18n';

const separator = ' | ';

export function DocumentTitle(props) {
  const { title } = props;
  if (!title) {
    document.title = __(i18n.DOCUMENT_TITLE);
  } else {
    document.title = [title, __(i18n.DOCUMENT_TITLE)].join(separator);
  }
  return null;
}
