import { call, createFormData, getURL } from '..';

export namespace user {
  const PATH = 'user';

  export function get() {
    return call(getURL(PATH, 'auth'), {
      method: 'GET'
    });
  }

  export function auth(user) {
    const formData = createFormData(user);
    return call(getURL(PATH, 'auth'), {
      method: 'POST',
      body: formData
    });
  }

  export function update(user) {
    const formData = createFormData(user);
    return call(getURL(PATH, user.id), {
      method: 'PATCH',
      body: formData
    });
  }
}
