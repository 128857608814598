var utils = require("../utils");
var jStat = require("jStat").jStat;

var mathTrig = require("../math-and-trigonometry");

import {
  EngineError,
  ERROR_NUM,
  ERROR_NOT_AVAILABLE,
  ERROR_VALUE,
  ERROR_DIV_ZERO
} from "../../error";

/**
 * Returns the F probability distribution. You can use this function to
 * determine whether two data sets have different degrees of diversity.
 * 
 * @param x The value at which to evaluate the function.
 * @param d1 The numerator degrees of freedom.
 * @param d2 The denominator degrees of freedom.
 * @param cumulative A logical value that determines the form of the function.
 * If cumulative is TRUE, F.DIST returns the cumulative distribution function;
 * if FALSE, it returns the probability density function.
 */
export function F_DIST(x, d1, d2, cumulative) {
  x = utils.parseNumber(x);
  d1 = utils.parseNumber(d1);
  d2 = utils.parseNumber(d2);
  if (utils.anyIsError(x, d1, d2)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  return cumulative
    ? jStat.centralF.cdf(x, d1, d2)
    : jStat.centralF.pdf(x, d1, d2);
}

/**
 * Returns the (right-tailed) F probability distribution (degree of diversity)
 * for two data sets.
 * 
 * @param x The value at which to evaluate the function.
 * @param d1 The numerator degrees of freedom.
 * @param d2 The denominator degrees of freedom.
 */
export function F_DIST_RT(x, d1, d2) {
  if (arguments.length !== 3) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NOT_AVAILABLE, arguments);
  }

  if (x < 0 || d1 < 1 || d2 < 1) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NUM, arguments);
  }

  if (
    typeof x !== "number" ||
    typeof d1 !== "number" ||
    typeof d2 !== "number"
  ) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  return 1 - jStat.centralF.cdf(x, d1, d2);
}


/**
 * Returns the inverse of the F probability distribution. If p = F.DIST(x,...),
 * then F.INV(p,...) = x. The F distribution can be used in an F-test that
 * compares the degree of variability in two data sets.
 * 
 * @param probability A probability associated with the F cumulative
 * distribution.
 * @param d1 The numerator degrees of freedom.
 * @param d2 The denominator degrees of freedom.
 */
export function F_INV(probability, d1, d2) {
  probability = utils.parseNumber(probability);
  d1 = utils.parseNumber(d1);
  d2 = utils.parseNumber(d2);
  if (utils.anyIsError(probability, d1, d2)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  if (probability <= 0.0 || probability > 1.0) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NUM, arguments);
  }

  return jStat.centralF.inv(probability, d1, d2);
}

/**
 * Returns the inverse of the (right-tailed) F probability distribution.
 * 
 * @param p A probability associated with the F cumulative distribution.
 * @param d1 The numerator degrees of freedom.
 * @param d2 The denominator degrees of freedom.
 */
export function F_INV_RT(p, d1, d2) {
  if (arguments.length !== 3) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NOT_AVAILABLE, arguments);
  }

  if (
    p < 0 ||
    p > 1 ||
    d1 < 1 ||
    d1 > Math.pow(10, 10) ||
    d2 < 1 ||
    d2 > Math.pow(10, 10)
  ) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NUM, arguments);
  }

  if (
    typeof p !== "number" ||
    typeof d1 !== "number" ||
    typeof d2 !== "number"
  ) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }

  return jStat.centralF.inv(1.0 - p, d1, d2);
}

/**
 * Returns the result of an F-test, the two-tailed probability that the
 * variances in array1 and array2 are not significantly different.
 * 
 * @param array1 The first array or range of data.
 * @param array2 The second array or range of data.
 */
export function F_TEST(array1, array2) {
  if (!array1 || !array2) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NOT_AVAILABLE, arguments);
  }

  if (!(array1 instanceof Array) || !(array2 instanceof Array)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_NOT_AVAILABLE, arguments);
  }

  if (array1.length < 2 || array2.length < 2) {
    return new EngineError("{FUNCTION_NAME}", ERROR_DIV_ZERO, arguments);
  }

  var sumOfSquares = function(values, x1) {
    var sum = 0;
    for (var i = 0; i < values.length; i++) {
      sum += Math.pow(values[i] - x1, 2);
    }
    return sum;
  };

  var x1 = mathTrig.SUM(array1) / array1.length;
  var x2 = mathTrig.SUM(array2) / array2.length;
  var sum1 = sumOfSquares(array1, x1) / (array1.length - 1);
  var sum2 = sumOfSquares(array2, x2) / (array2.length - 1);

  return sum1 / sum2;
}

/**
 * Returns the Fisher transformation at x. This transformation produces a
 * function that is normally distributed rather than skewed
 * 
 * @param x A numeric value for which you want the transformation.
 */
export function FISHER(x) {
  x = utils.parseNumber(x);
  if (x instanceof Error) {
    return x;
  }
  return Math.log((1 + x) / (1 - x)) / 2;
}

/**
 * Returns the inverse of the Fisher transformation. Use this transformation
 * when analyzing correlations between ranges or arrays of data.
 * 
 * @param y The value for which you want to perform the inverse of the
 * transformation.
 */
export function FISHERINV(y) {
  y = utils.parseNumber(y);
  if (y instanceof Error) {
    return y;
  }
  var e2y = Math.exp(2 * y);
  return (e2y - 1) / (e2y + 1);
}

/**
 * Calculates, or predicts, a future value by using existing values. The
 * predicted value is a y-value for a given x-value. The known values are
 * existing x-values and y-values, and the new value is predicted by using
 * linear regression.
 * 
 * @param x The data point for which you want to predict a value.
 * @param data_y The dependent array or range of data.
 * @param data_x The independent array or range of data.
 */
export function FORECAST(x, data_y, data_x) {
  x = utils.parseNumber(x);
  data_y = utils.parseNumberArray(utils.flatten(data_y));
  data_x = utils.parseNumberArray(utils.flatten(data_x));
  if (utils.anyIsError(x, data_y, data_x)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  var xmean = jStat.mean(data_x);
  var ymean = jStat.mean(data_y);
  var n = data_x.length;
  var num = 0;
  var den = 0;
  for (var i = 0; i < n; i++) {
    num += (data_x[i] - xmean) * (data_y[i] - ymean);
    den += Math.pow(data_x[i] - xmean, 2);
  }
  var b = num / den;
  var a = ymean - b * xmean;
  return a + b * x;
}

/**
 * The FREQUENCY function calculates how often values occur within a range of
 * values, and then returns a vertical array of numbers.
 * 
 * @param data An array of or reference to a set of values for which you want
 * to count frequencies. If data_array contains no values, FREQUENCY returns an
 * array of zeros.
 * @param bins An array of or reference to intervals into which you want to
 * group the values in data_array. If bins_array contains no values, FREQUENCY
 * returns the number of elements in data_array.
 */
export function FREQUENCY(data, bins) {
  data = utils.parseNumberArray(utils.flatten(data));
  bins = utils.parseNumberArray(utils.flatten(bins));
  if (utils.anyIsError(data, bins)) {
    return new EngineError("{FUNCTION_NAME}", ERROR_VALUE, arguments);
  }
  var n = data.length;
  var b = bins.length;
  var r = [];
  for (var i = 0; i <= b; i++) {
    r[i] = 0;
    for (var j = 0; j < n; j++) {
      if (i === 0) {
        if (data[j] <= bins[0]) {
          r[0] += 1;
        }
      } else if (i < b) {
        if (data[j] > bins[i - 1] && data[j] <= bins[i]) {
          r[i] += 1;
        }
      } else if (i === b) {
        if (data[j] > bins[b - 1]) {
          r[b] += 1;
        }
      }
    }
  }
  return r;
}